import React, {Component} from 'react';
import {redirectPost} from './../../../../helpers/postHelper';
import Export from '../../base/Export';
import { env } from '../../../../config/vars';

export default class Preview extends Component {


    constructor(props){
        super(props)

        this.action = this.action.bind(this);
        
    }

    componentDidMount(){

    }

   


   

    action(e){

        let url         = env === 'development' ? 'http://localhost:3000/preview' : 'https://gigatron.rs/preview';
        let exp         = new Export(this.props.editor);
        let nodes       = exp.exportContent();
        let data        = {data:(JSON.stringify(nodes))};

        redirectPost(url, data);

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"Preview-btn-"+this.props.id} onMouseDown={this.action} title="Preview HTML">
                <label htmlFor={'Preview-'+this.props.id} className=" fa fa-eye"></label>
        </button>
    }

}