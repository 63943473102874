import React, { Component } from "react";
import { withRouter} from "react-router";
import { fetchHelper } from "./../../../helpers/fetchHelper";

import ProductForm from "../../../forms/ProductForm";

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_page:null,
      selected:null
    }


  }

 componentDidMount(){
    // var r = await fetchHelper.get(`products/list/6`);
    console.log('product Details')
    this.getFormData(this.props.match.params.id);
  }

  async getFormData(id){
    var r = await fetchHelper.get(`products/get_data/${id}`);
  
    if(r.status){
      let data=r.items
      this.setState({active_page:data,selected:id}) 
    }
  }


  render() {
    return (
      <>
        <div className="admin-home__main-section">
        <ProductForm data={this.state.active_page} index={this} product_id={this.props.match.params.id}/>
        </div>
      </>
    );
  }
}



  export default withRouter((ProductDetails));