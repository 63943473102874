import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';

export default class Block extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){
        e.stopPropagation();
        e.preventDefault();
        // var ins = new InsertNode(this.props.editor);
        // ins.wrap(e.currentTarget.value);
        // this.props.editor.registerChange();
    
        document.execCommand('formatBlock', false, `<${e.currentTarget.value}>`);
        this.props.editor.registerChange();

        return false;
    }


    render(){
        return <select className="get-btn selector-ga" id={"Block-btn-"+this.props.id} onChange={this.action}>
            <option>Block</option>
            <option value="h1">Heading 1</option>
            <option value="h2">Heading 2</option>
            <option value="h3">Heading 3</option>
            <option value="h4">Heading 4</option>
            <option value="h5">Heading 5</option>
            <option value="p">Paragraph</option>
            <option value="sub">Subscript</option>
            <option value="sup">Superscript</option>
            <option value="span">Span</option>
            <option value="main">Main</option>
            <option value="div">DIV</option>
        </select>
    }

}