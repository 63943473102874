import React, {Component} from 'react';

export default class Css extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(){

        this.props.editor.setState({showCssEditor: this.props.editor.state.showCssEditor ? false : true},()=>{
            if(this.props.editor.state.showCssEditor){
                this.props.editor.registerChange();
            }
        });
    }

    render(){
        return <button title={'CSS Style'} className="get-btn selector-ga" id={"Css-btn-"+this.props.id} onClick={this.action}><i className="fa fa-i-cursor" aria-hidden="true" ></i></button>
    }

}