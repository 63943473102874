import $ from 'jquery';
import React from 'react';
import InsertNode from './insertNode';

class ActiveNode {
    constructor(){

    }

    getFocusElement(editor, e){
        var t = e.target, clickedTarget = (e.target), active = null, parent = t.closest('.block-element-editor');
        if(parent){
            active = parent;
        } else {
            active = t;
            if(t.tagName.toLowerCase() !== 'p'){
                var t2 = $(t).closest('[data-type]');
                if(t2.length){
                    active = t2[0];
                } else {
                    var t3 = $(t).closest('p');
                    if(t3.length){
                        active = t3[0];
                    }
                }

                var t4 = $(t).closest('ul,ol');
                if(t4.length){
                    active = t4[0];
                }
            }       
        }

        var ed = t.closest('[contenteditable="true"]');

        if(ed && ed.className.indexOf('gigaEditorContent') > -1){
            ed = null;
        }
        if(ed){
            active = clickedTarget;

            if(t.tagName.toLowerCase() !== 'p'){
                var t2 = $(t).closest('[data-type]');
                if(t2.length){
                    active = t2[0];
                } else {
                    var t3 = $(t).closest('p');
                    if(t3.length){
                        active = t3[0];
                    }
                }

                var t4 = $(t).closest('ul,ol');
                if(t4.length){
                    active = t4[0];
                }
            }      
        }

        // var cltable = $(t).closest('table');
        // if(cltable.length){
        //     active = cltable[0];
        // }

        if(active){
           if(active.classList.contains('gigaEditorContent')){
               active = null;
           }
        }
        
        var actives = editor.ref.current.querySelectorAll('.active');
        if(actives && actives.length){
            for(var i = 0; i < actives.length; i++){
                var a = actives[i];
                a.classList.remove('active');
            }
        }
        
        editor.setState({active:active, toolbars: editor.state.toolbars}, async ()=>{
            if(editor.state.active){
                var activeType = null;
                var el = editor.state.active;

                if(el.tagName.toLowerCase() === 'p'){
                    activeType = ('text');
                } else {
                    activeType = (el.dataset.type);
                }

                if(activeType === undefined){
                    activeType = 'text';
                }

                var cm = null;

                if(el.dataset.onload){

                    try {
                        var CustomModal = await require( `./../modals/${el.dataset.onload}`);
                        
                        cm = <CustomModal.default editor={editor} element={el} insert={false} />;
                        console.log(cm);
                    } catch(err){
                        
                        try {
                            var CustomModal = await require( `./../plugins/templates/${el.dataset.onload}/${el.dataset.onload}Modal`);
                        
                            cm = <CustomModal.default editor={editor} element={el} insert={false}/>;
                            console.log(cm);
                        } catch(err2){
                            console.log(err);
                            console.log(err2);
                        }

                    }
                }


                editor.setState({activeType:activeType, customModal: cm});
                editor.state.active.classList.add('active');
            }
        });
    }
}

export default ActiveNode;