import React, { useState, useEffect } from "react";
import { useEditor } from "@craftjs/core";
import cx from 'classnames';
import { Toolbox } from "./Toolbox";
import { Sidebar } from "./Sidebar";
import {
  Button as MaterialButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
export const Viewport = ({ children }) => {
  const { enabled, connectors } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
  const [loaded, setLoaded] = useState(false);
  const [mouseEnabled, setMouseEnabled] = useState(false);
  const [dialog, setDialog] = useState(false);

  console.log(enabled);
  let unmounted = false;
  useEffect(() => {
    setTimeout(() => {
      if (!unmounted) setLoaded(true);
      setTimeout(() => {
       
        setTimeout(() => {
          if (!unmounted) setMouseEnabled(true);
        }, 200);
      }, 400);
    }, 1000);

    return () => {
      unmounted = true;
    };
  }, []);
  return (
    <div
      className={cx(['viewport'], {
        loaded: loaded,
        'mouse-enabled': mouseEnabled,
      })}
    >
     <div
        
        className={cx([
          'flex h-full overflow-hidden flex-row side-width-content',
          {
            'h-full': !enabled,
            fixed: enabled,
            relative: !enabled,
          },
        ])}
      >
        
        <Toolbox />
        <div className="flex-1 h-full">
          <div className="w-full h-full">
            <div
              className={cx([
                'craftjs-renderer h-full  w-full transition',
                {
                  'overflow-auto': enabled,
                  'bg-renderer-gray': enabled,
                },
              ])}
              ref={(ref) =>
                connectors.select(connectors.hover(ref, null), null)
              }
            >
              <div
                className={cx([
                  'relative flex-col flex items-center pb-16',
                  {
                    'pt-16': enabled,
                  },
                ])}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
        <Sidebar />
      </div>
    </div>
  );
};
