import $, { type, timers } from 'jquery';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { containHtml } from '../../../helpers/stringHelper';
import Initialize from './initialize';




class InsertNode {



    constructor(editor){
        this.editor = editor ? editor : null;

        this.selection = null;
        this.range = null;
        this.nodes = null;
        this.node = null;
    }


    __SELECTION(){
        if(window.getSelection){
            var selection = window.getSelection();
            if (!selection.rangeCount) return false;

            this.selection = selection;
            this.range = selection.getRangeAt(0);

            if(this.range){
                this.node = this.range.commonAncestorContainer;

                

                
                


                if(this.range.commonAncestorContainer.childNodes){
                    this.nodes = this.range.commonAncestorContainer.childNodes;
                }
            }

        }
    }


    __INSERT_AFTER(){

    }

    __INSERT_BEFORE(){

    }

    __NODES(){

    }

    __WRAP(){

    }

    __UNWRAP(){

    }

    __FORMAT(tag){

        this.__SELECTION();

        if(this.nodes && this.nodes.length){
            if(this.nodes.length > 1) return false;
        }
        
        var caret = this.__getCaret();
        if(!this.nodes) return false;
        if(this.nodes && this.nodes.length){
            if(this.__has_child_tag(tag)){
                this.__UNFORMAT(tag);
            } else {
                console.log(this.nodes);
                for(var i = 0; i < this.nodes.length; i++){
                    var node = this.nodes[i];
                    console.log(node);
                    console.log('tag 1')
                    let content = node.innerHTML ? node.innerHTML : node.innerText ? node.innerText : node.nodeValue ? node.nodeValue : '';

                    
                    if(content){
                        var t = document.createElement(tag);
                        t.innerHTML = content;
                        node.innerHTML = t.outerHTML;
                    }
                }
               
            }
        } else {
            if(this.node){
                if(this.node.tagName !== undefined && this.node.tagName.toLowerCase() === tag){
                    this.__UNFORMAT(tag);
                } else {
                    if(this.node.parentElement.tagName.toLowerCase() === tag){
                        this.__UNFORMAT(tag);
                    } else {
                        console.log('tag 2')
                        
                                this.range.deleteContents();
                                var el = document.createElement(tag);
                                el.innerHTML = caret;
                                this.range.insertNode(el);
                       

                    }
                }

            }

        }

        this.__remove_selection();
        var html = this.__normalizeReplace(this.editor.ref.current.innerHTML);
        this.editor.ref.current.innerHTML = html;
    }


    __UNFORMAT(tag){
        console.log(this.nodes)
        if(this.nodes && this.nodes.length){
            for(var i = 0; i < this.nodes.length; i++){
                var n = this.nodes[i];
                if(n.tagName && n.tagName.toLowerCase()===tag){
                    console.log('replace')
                    n.replaceWith(this.__get_node_value(n));
                } else {
                    var inner = n.innerHTML;
                    if(inner){
                        var r = new RegExp(`<${tag}[^>]+>(.*?)<\/?${tag}>`,'gi');
                        let content = inner.replace(r,"$1");
                        var r2 = new RegExp(`<${tag}>(.*?)<\/?${tag}>`,'gi');
                        content = content.replace(r2,"$1");
                        n.innerHTML = content;
                    }
                }
            }
        }
        
    }


    __STYLE(style,property){
        console.log('style usao')
        this.__SELECTION();
        var caret = this.__getCaret();
       
        var has = false;
        
        

        if(this.nodes && this.nodes.length){
            
            for(var i = 0; i < this.nodes.length; i++){
                var node = this.nodes.length;
                if(node.style && node.style[style]){
                    if(node.style[style] === property){
                        has = true;
                    }
                }
            }
        }

        var str = style+': '+property+';';

        if(caret.indexOf(str)>-1){
            has = true;
        }

       console.log('has',has)

        if(!has){
            if(this.nodes && this.nodes.length){
                for(var i = 0; i < this.nodes.length; i++){
                    var node = this.nodes[i];
                    if(node.tagName === '#text'){
                        var t = document.createElement('span');
                        t.style[style] = property;
                        t.innerHTML = node.innerHTML ? node.innerHTML : node.innerText ? node.innerText : node.nodeValue ? node.nodeValue : '';
                        node.parentNode.replaceChild(t,node);
                        console.log('lol');
                    } else {

                        if(node.style){
                            node.style[style] = property;
                            console.log('lol2')
                        }

                        
                    }
                }
            }
            console.log(this.node, this.node.tagName)
        
            if(this.node.tagName === undefined && (!this.nodes || !this.nodes.length)){
                console.log('lol4')
                var t = document.createElement('span');
                t.style[style] = property;
                t.innerHTML = caret;
                this.range.deleteContents();
                this.range.insertNode(t);
            }
            

        }

        if(!has && !this.nodes.length)
        if(this.node && this.node.style){
            if(this.node.style[style] === property){
                this.node.style[style] = '';
                has = true;
            } else {
                this.node.style[style] = property;
            }
        }


        if(has){
            var isRemoved = false;
            if(this.node && this.node.style){
                if(this.node.style[style] === property){
                    this.node.style[style] = '';
                    isRemoved = true;
                    console.log('remove 1')
                }
            }

            if(this.nodes && this.nodes.length){
                isRemoved = false;
                for(var i = 0; i < this.nodes.length; i++){
                    var node = this.nodes[i];
                    if(node.style && node.style[style]) {
                        node.style[style] = '';
                        isRemoved = true;
                        console.log('remove 2')
                    }
                }

                if(!isRemoved){
                    for(var i = 0; i < this.nodes.length; i++){
                        var node = this.nodes[i];
                        var c = node.innerHTML ? node.innerHTML : '';
                        if(c){
                            isRemoved = true;
                            var rgx = new RegExp(str,'gi');
                            c = c.replace(str,'');
                            node.innerHTML = c;
                        }
                    }
                }
            }

            if(!isRemoved){
                var rgx = new RegExp(str,'gi');
                caret = caret.replace(rgx,'');

                console.log(caret);
                this.range.deleteContents();
                var div = document.createElement('div');
                div.innerHTML  = caret;
                var nodes2 = (div.childNodes);
                var nn = [];
                for(var e = 0; e < nodes2.length; e++){
                    var node = nodes2[e].cloneNode(true);
                    nn.push(node);
                }

                nn.map(n=>{
                    this.range.insertNode(n);
                })
            }


        }

        this.__remove_selection();
        var html = this.__normalizeReplace(this.editor.ref.current.innerHTML);
        this.editor.ref.current.innerHTML = html;
        
    }

    __has_style(style,property,caret){

        var str = style+': '+property+';';
        var has = false;

        if(caret.indexOf(str) > -1){
            has = true;
        }

        return has;

    }


    delete(){
        this.__SELECTION();
        console.log(this.node);

        var node = this.node;

        if(node && node.className && node.className.indexOf('gigaEditorContent')>-1){
            node = null;
        }

        if(node){
            node.remove();
            this.__remove_selection();
        }
    }


    __CLEAR_STYLE(style,property){
        var caret = this.__getCaret();
        if(caret){
            var str = style+': '+property+';';
            var rgx = new RegExp(`str`,'gi');
            caret = caret.replace(rgx,'');
            console.log(caret);
        }
    }

    __CLEAR_ALL(){

    }

    __FORMAT_LIST(){

    }

    __UNFORMAT_LIST(){

    }


    __get_selection_content(){

        var str = this.__get_node_value(this.node);

        var x = this.range.startOffset;
        var y = this.range.endOffset;
       
      
        var start = x, end = y;

        console.log(this, x, y);


        var str = this.node.innerHTML;
        if(str === undefined) str = this.node.nodeValue

        var h = {
            start: str.substr(0,start),
            end: str.substr(end,str.length-1),
            selected: str.substr(start, end-start)
        }

        console.log('selection', h)

        return h;
    }

    __has_child_tag( tag ){
        var has = false;
        for(var i = 0; i < this.nodes.length; i++){
            var n = this.nodes[i];
            console.log(n);
            if(n.tagName){
                if(n.tagName.toLowerCase() === tag){
                    has = true;
                }
            }
        }


        if(this.node && this.node.tagName && this.node.tagName.toLowerCase() === tag){
            has = true;
        }

        var caret = this.__getCaret();
        var s = (`<${tag}>`);
        if(caret.indexOf(s)>-1){
            has = true;
        }

        return has;
    }


    __get_node_value(node){
        console.log(node.tagName);

        if(node !== undefined){
            var v = node.innerHTML;
            if(v === null || v === undefined){
                v = node.innerText;
            }
            if(v === null || v === undefined){
                v = node.nodeValue;
            }
            if(v === null || v === undefined){
                v = node;
            }
            console.log('value', v)
            return v;
        } 

        return '';
    }

    __remove_selection(){
        var s = this.selection;
        if(s){
            if(s.rangeCount > 1) {
                for(var i = 1; i < s.rangeCount; i++) {
                s.removeRange(s.getRangeAt(i));
                }
            }
            this.selection.removeAllRanges();
        }
    }


    ___format_bullets(){

    }















    append(el, editor){
        var _RANGE = editor.state.range;
        if(_RANGE){
            if (window.getSelection) {
                var sel, range;
                // IE9 and non-IE
                sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    range = _RANGE;
                    range.deleteContents();
                    var frag = document.createDocumentFragment(), node, lastNode;
                    while ( (node = el.firstChild) ) {
                        lastNode = frag.appendChild(node);
                    }
                    range.insertNode(frag);
                    
                    // Preserve the selection
                    if (lastNode) {
                        range = range.cloneRange();
                        range.setStartAfter(lastNode);
                        range.collapse(true);
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                } else {
                    editor.appendChild(el);
                }
            } else if (document.selection && document.selection.type != "Control") {
                // IE < 9
                _RANGE.pasteHTML(el);
            }
        }
    }


    __getCaret(){
        var html = "";
           if (typeof window.getSelection != "undefined") {
               var sel = window.getSelection();
               if (sel.rangeCount) {
                   var container = document.createElement("div");
                   for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                       var r = sel.getRangeAt(i);
                       var el = r.cloneContents();
                       if(r.commonAncestorContainer.className === 'gigaEditor'){
                            return false;
                       }
                       if(r.commonAncestorContainer.parentNode){
                           if(r.commonAncestorContainer.parentNode.tagName === 'SPAN')
                           el = r.commonAncestorContainer.parentNode
                       }

                       container.appendChild(el);
                   }
                   html = container.innerHTML;
               }
           } else if (typeof document.selection != "undefined") {
               if (document.selection.type == "Text") {
                   html = document.selection.createRange().htmlText;
               }
           }
           return html;
    }

    __getCaretNodes(){
       
        var caret = this.__getCaret();
        return this.__getNodes(caret);

    }



    __selection(){
        var el = this.editor.ref.current;
        if(window.getSelection){
            var selection = window.getSelection();
            if (!selection.rangeCount) return false;
            var indexes = {};
            var range = selection.getRangeAt(0);
            indexes.originalRange = range;
            var className = (range.commonAncestorContainer.parentNode.className);
            var parentNode = range.commonAncestorContainer.parentNode
            if(className === 'gigaEditor' || className === 'gigaEditorContent'){
                parentNode = range.commonAncestorContainer;

                if(this.isNodeList(parentNode)){
                    parentNode = null;
                }

                if(selection.rangeCount > 0){
                    selection.removeAllRanges();
                    for(var i = 0; i < range.commonAncestorContainer.childNodes.length; i++){
                        var n = range.commonAncestorContainer.childNodes[i];
                        let r = document.createRange();
                        r.selectNode(n);
                        selection.addRange(r);
                    }
                    range = selection.getRangeAt(0);
                    console.log(range)
                    className = '';
                } else {
                    return false;
                }
            }


            var clone = range.cloneRange();
            clone.selectNodeContents(el);
            clone.setEnd(range.endContainer, range.endOffset);
            indexes.end = clone.toString().length;
            clone.setStart(range.startContainer, range.startOffset);
            indexes.start = indexes.end - clone.toString().length;
            indexes.atStart = clone.startOffset === 0;
            indexes.commonAncestorContainer = clone.commonAncestorContainer;
            indexes.endContainer = clone.endContainer;
            indexes.startContainer = clone.startContainer;
            indexes.range = range;
            indexes.parentNode = parentNode;
            indexes.nodes = range ? range.commonAncestorContainer ? range.commonAncestorContainer.childNodes ? range.commonAncestorContainer.childNodes.length ? range.commonAncestorContainer.childNodes : range.commonAncestorContainer : null : null : null;

            if(indexes.nodes){
                for(var i = 0; i < indexes.nodes.length; i++){
                    if(!indexes.nodes[i]) delete indexes.nodes[i];
                }
            }

            return indexes;
        }

        return false;
    }


    __getNodes(caret){
        var p  = document.createElement('p');
        p.innerHTML = caret;
        var n = p.childNodes;
        return n;
    }



    __formatNodes(style, value, defaultValue, nodes, allowedStyles, fixedValue){

        let _nodes = [];

        if(nodes && nodes.length){
            for(var i = 0; i< nodes.length; i++){
                var node = nodes[i];
 
                if(node){
                    
                    switch(node.nodeName.toLowerCase()){
                        case "#text":
                            var span = document.createElement('span');
                            span.innerText = node.nodeValue;
                            span.style[style] = fixedValue ? fixedValue : ( value ? value : defaultValue ? defaultValue : '');
                            if(!fixedValue){
                                _nodes.push(span);
                            } else {
                                if(node){
                                    if(node.style === undefined){
                                        _nodes.push(node);
                                    } else {
                                        node.style[style] = fixedValue ? fixedValue : ( value ? value : defaultValue ? defaultValue : '');
                                        _nodes.push(node);
                                    }
                                }
                            }

                        break;
                        default:
                            var html = node.outerHTML, text = node.outerText, nodeStyles = node.style, styles = {};
                            Object.keys(nodeStyles).map(key=>{
                                if(allowedStyles[key]){
                                   
                                    if(!fixedValue){
                                        if(nodeStyles[key] != value && style == key){
                                            styles[key] = fixedValue ? fixedValue : value;
                                        }
                                        if(nodeStyles[key] == value && style == key){
                                            if(defaultValue){
                                                styles[key] = fixedValue ? fixedValue : defaultValue;
                                            } else {
                                                styles[key] = fixedValue ? fixedValue : '';
                                            }
                                        }

                                        if(style == key){
                                            value = styles[key];
                                        }

                                    } else {
                                        styles[key] = '';
                                    }
                                    if(style != key){
                                        styles[key] = nodeStyles[key];
                                    }
                                    

                                }
                            });

                            Object.keys(styles).map(k=>{
                                node.style[k] = styles[k];
                            });

                            if(node.childNodes && node.childNodes.length){
                                var nn = this.__formatNodes(style, value, defaultValue, node.childNodes, allowedStyles, styles[style]);
                                var x = node;
                                console.childNodes = nn;
                                _nodes.push(x);
                            } else {
                                _nodes.push(node);
                            }

                        break;
                    }
                }

            }
        }

        return _nodes;

    }


    __normalizeReplace(html){

        console.log(html);

        if (!String.prototype.unescapeHTML) {
            String.prototype.unescapeHTML = function() {
                return this.replace(/&[#\w]+;/g, function (s) {
                    var entityMap = {
                        "&amp;": "&",
                        "&lt;": "<",
                        "&gt;": ">",
                        '&quot;': '"',
                        '&#39;': "'",
                        '&#x2F;': "/",
                        '&nbsp;': " "
                    };
                    if(entityMap[s] === undefined) console.warn('undefined', s)
                    return entityMap[s];
                });
            };
        }

        html = html.unescapeHTML();
        

        html = html.replace(/\s+style=""/gi,'');
        html = html.replace(/<span>(.*?)<\/span>/gi,"$1");
        html = html.replace(/<[span]+><\/span>/gi,"");
        html = html.replace(/<span><\/span>/gi,"");
        html = html.replace(/<li><\/li>/gi,"");

        html = html.replace(/<br><\/p>/gi,"</p>");
        html = html.replace(/<br><p><\/p>/gi,"<p></p>");
        html = html.replace(/<ul><p>/gi,"<p>");
        html = html.replace(/<\/ul><\/p>/gi,"</p>");
        html = html.replace(/<ol><p>/gi,"<p>");
        html = html.replace(/<\/ol><\/p>/gi,"</p>");

        html = html.replace(/&amp;/gi,'&');
        html = html.replace(/&nbsp;/gi,' ');

        html = html.replace(/<[p]+><[p]+><\/p><\/p>/gi,"<p>$1</p>");
        html = html.replace(/<span[^>]+><p[^>]+>(.*?)<\/?p><\/?span>/gi,"<p>$1</p>");
        html = html.replace(/<span[^>]+><\/?span>/gi,"");

        html = html.replace(/<b[^>]+><\/?b>/gi,"");
        html = html.replace(/<i[^>]+><\/?i>/gi,"");
        html = html.replace(/<a[^>]+><\/?a>/gi,"");
        html = html.replace(/<u[^>]+><\/?u>/gi,"");
        html = html.replace(/<sup[^>]+><\/?sup>/gi,"");
        html = html.replace(/<sub[^>]+><\/?sub>/gi,"");
        html = html.replace(/<code[^>]+><\/?code>/gi,"");
        html = html.replace(/<ul[^>]+><\/?ul>/gi,"");

        html = html.replace(/<b><\/?b>/gi,"");
        html = html.replace(/<i><\/?i>/gi,"");
        html = html.replace(/<a><\/?a>/gi,"");
        html = html.replace(/<u><\/?u>/gi,"");
        html = html.replace(/<sup><\/?sup>/gi,"");
        html = html.replace(/<sub><\/?sub>/gi,"");
        html = html.replace(/<code><\/?code>/gi,"");
        html = html.replace(/<ul><\/?ul>/gi,"");
        html = html.replace(/<ol><\/?ol>/gi,"");

        html = html.replace(/<ul[^>]+><p[^>]+>(.*?)<\/p><\/ul>/gi,"<p>$1</p>");
        html = html.replace(/<ul[^>]+><p>(.*?)<\/p><\/ul>/gi,"<p>$1</p>");
        html = html.replace(/<ul>]+><p>(.*?)<\/p><\/ul>/gi,"<p>$1</p>");

        html = html.replace(/<ol[^>]+><p[^>]+>(.*?)<\/p><\/ol>/gi,"<p>$1</p>");
        html = html.replace(/<ol[^>]+><p>(.*?)<\/p><\/ol>/gi,"<p>$1</p>");
        html = html.replace(/<ol>]+><p>(.*?)<\/p><\/ol>/gi,"<p>$1</p>");

        html = html.replace(/<main[^>]+>(.*?)<\/?main>/gi,"$1");

        html = html.replace(/<span[^>]+><p[^>]+>(.*?)<\/?p><\/?span>/gi,"<p>$1</p>");
        html = html.replace(/<ul[^>]+>\&lt;p\&gt;(.*?)\&lt;\/p\&gt;<\/ul>/gi,"<p>$1</p>");
        html = html.replace(/<ol[^>]+>\&lt;p\&gt;(.*?)\&lt;\/p\&gt;<\/ol>/gi,"<p>$1</p>");

        html = html.replace(/<ul[^>]+>(?:(?!<\/li>)[^])*<\/ul>/gi,'');
        html = html.replace(/<ol[^>]+>(?:(?!<\/li>)[^])*<\/ol>/gi,'');
        html = html.replace(/class=""/gi,'');
        html = html.replace(/<p class>/gi,'<p>');

        return html;
    }


    __replaceSelection(formatedNodes){

        var selected, range;
        selected= window.getSelection();
        if (selected.rangeCount) {
            range = selected.getRangeAt(0);
            range.deleteContents();
            var replace = Array.prototype.reduce.call(formatedNodes, function(html, node) {
                return html + ( node.outerHTML || node.nodeValue );
            }, "");
            var _html = this.__normalizeReplace(replace);
            _html = this.__normalizeReplace(_html);
            _html = this.__normalizeReplace(_html);

           if(containHtml(_html)){
                var div = document.createElement('div');
                div.innerHTML = _html;
                var node = div.firstChild;
               
                range.insertNode( node );
           } else {
                var div = document.createElement('div');
                div.innerHTML = _html;
                var node = div.firstChild;
               range.insertNode(node);
           }

           var newContent = this.__normalizeReplace( this.editor.ref.current.innerHTML);

            if (!String.prototype.unescapeHTML) {
                String.prototype.unescapeHTML = function() {
                    return this.replace(/&[#\w]+;/g, function (s) {
                        var entityMap = {
                            "&amp;": "&",
                            "&lt;": "<",
                            "&gt;": ">",
                            '&quot;': '"',
                            '&#39;': "'",
                            '&#x2F;': "/"
                        };
            
                        return entityMap[s];
                    });
                };
            }

            this.editor.ref.current.innerHTML = newContent.unescapeHTML();

            var init = new Initialize();
            init.initializeAll(this.editor);
        }
    }


    style(style, value, defaultValue){

        var allowedStyles = {
            'fontSize':1,
            'fontWeight':1,
            'fontFamily':1,
            'textDecoration':1,
            'fontStyle':1,
            'textAlign': 1
        };
        
        var  caret = (this.__getCaret());

        if(!caret) return false;

        //var text = caret.replace( /(<([^>]+)>)/ig, '');

        var nodes = this.__getNodes(caret);
        var formatedNodes = this.__formatNodes(style,value,defaultValue,nodes, allowedStyles);

        this.__replaceSelection( formatedNodes );

    }


    getCursorPosition() {
        if (window.getSelection && window.getSelection().getRangeAt) {
            return window.getSelection().getRangeAt(0);
        } else if (document.selection && document.selection.createRange) {
            return document.selection.createRange();
        }
    }
    

    isNodeList(nodes){
        return nodes.nodeName === undefined;
    }


    wrap(tag, childTag, className){

        var selection = this.__selection();

        if(childTag){
            this.__wrapList(tag,childTag, selection);
        }

        
    }


    __wrapList(tag,childTag,selection){
        var list = [];
        var parentNode = selection.parentNode;
        if(this.isNodeList(selection.nodes)){
            for(var i = 0; i < selection.nodes.length; i++){
                var node = selection.nodes[i];
                if(tag === 'ul' || tag === 'ol'){
                    if(node.nodeName !== '#text'){
                        node = node.firstChild;
                    }
                }

                list.push(node);
            }
        }

        var el = document.createElement(tag);
        for(var i = 0; i < list.length; i++){
            if(childTag){
                console.log(childTag, list, i)
                var c = document.createElement(childTag);
                if(list[i]){
                    c.appendChild(list[i]);
                }
                el.appendChild(c);
            } else {
                if(list[i])
                el.appendChild(list[i]);
            }
        }

        selection.range.deleteContents();
        if((parentNode && parentNode.tagName !== 'P')|| !parentNode){
            var p = document.createElement('p');
            p.appendChild(el);
            selection.range.insertNode(p);
        } else {
            selection.range.insertNode(el);
        }
    }


    getClickedElement(){
        var sel = this.__selection();
        if(sel.commonAncestorContainer !== undefined){
            
            if(sel.commonAncestorContainer.nodeName === '#text'){
                return sel.commonAncestorContainer.parentNode;
            }

            return sel.commonAncestorContainer;
        }

        return null;
    }


    link(url,sel, range){

        var element = document.createElement('a');
        element.href = url;
        
        if (sel) {
            if (sel.rangeCount) {
                range.surroundContents(element);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
        var newContent = this.__normalizeReplace( this.editor.ref.current.innerHTML);
        this.editor.ref.current.innerHTML = newContent;

        var init = new Initialize();
        init.initializeAll(this.editor);
    }


    unlink(){

    }


    clear(repeat){
        
        var selection = this.__selection();

        var parentNode = selection.parentNode;
        
        if(parentNode){
            if(parentNode && parentNode.tagName !== 'P' && parentNode.tagName !== 'DIV'){
                this.unwrap(parentNode);
            }
        }

        if(selection.nodes){
            for(var i = 0; i < selection.nodes.length; i++){
                var node = selection.nodes[i];
                if(node && node.tagName !== '#text'){
                    console.log(node)
                    var textnode = node.innerText !== undefined ? document.createTextNode(node.innerText) : node;
                    node.parentNode.replaceChild(textnode, node);
                }
            }
        }

    }


    replaceWith(origin, target){
        origin.parentNode.replaceWith(target, origin);
    }


    unwrap(wrapper){
            // place childNodes in document fragment
        var docFrag = document.createDocumentFragment();
        while (wrapper.firstChild) {
            var child = wrapper.removeChild(wrapper.firstChild);
            docFrag.appendChild(child);
        }

        // replace wrapper with document fragment
        wrapper.parentNode.replaceChild(docFrag, wrapper);
    }   
    

    unwrapWraped(className){
        var nodes = this.getSelectedNodes();
        if(nodes && nodes.length){
            for(var i = 0; i < nodes.length; i++){
                var node = nodes[i];
                if(node.nodeName !== '#text')
                if(node.className.indexOf(className) > -1){
                    this.unwrap(node);
                }
            }
        }

        var newContent = this.__normalizeReplace( this.editor.ref.current.innerHTML);
        this.editor.ref.current.innerHTML = newContent;

        var init = new Initialize();
        init.initializeAll(this.editor);
    }
    
    onSelectionChange(event){
      
    }

    nextNode(node) {
        if (node.hasChildNodes()) {
            return node.firstChild;
        } else {
            while (node && !node.nextSibling) {
                node = node.parentNode;
            }
            if (!node) {
                return null;
            }
            return node.nextSibling;
        }
    }
    
    getRangeSelectedNodes(range) {
        var node = range.startContainer;
        var endNode = range.endContainer;
    
        // Special case for a range that is contained within a single node
        if (node == endNode) {
            return [node];
        }
    
        // Iterate nodes until we hit the end container
        var rangeNodes = [];
        while (node && node != endNode) {
            rangeNodes.push( node = this.nextNode(node) );
        }
    
        // Add partially selected nodes at the start of the range
        node = range.startContainer;
        while (node && node != range.commonAncestorContainer) {
            rangeNodes.unshift(node);
            node = node.parentNode;
        }
    
        return rangeNodes;
    }
    
    getSelectedNodes() {
        if (window.getSelection) {
            var sel = window.getSelection();
            if (!sel.isCollapsed) {
                return this.getRangeSelectedNodes(sel.getRangeAt(0));
            }
        }
        return [];
    }

    insertAfter(newNode, referenceNode) {
        return referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
    
    removeNode(node) {
        return node.parentNode.removeChild(node);
    }

    nextSiblings(node) {
        var all = [];
        while (node = node.nextSibling) {
          all = all.push(node);
        }
        return all;
    }

    isFragment(node) {
        return node.nodeType === Node.DOCUMENT_FRAGMENT_NODE;
    }
}

export default InsertNode;