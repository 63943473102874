import React  from 'react';
import { Dialog } from "./../windowManager/dialog";
import { renderToString } from 'react-dom/server';
import { b64DecodeUnicode } from './base64';

export const addPlugin = (editor, node, Form, View, initialData, pluginName, pluginTitle, pluginFolder, pluginIcon, width, height, customFn) => {

    if(node !== undefined){
        var onSuccess = async(editor, data) => {   
            if(customFn){
              data = await customFn(data);
            }
            delete data[""];
            var view = renderToString(<View data={data} folder={pluginFolder}  />);
            
            editor.selection.select(node);
            editor.insertContent(view, {format: 'raw'});
        };

        var onCancel = (editor) => {

        }

        if(node.dataset.json){
            var jsonstring = (b64DecodeUnicode(node.dataset.json))
            initialData = JSON.parse( jsonstring );
        }

        Dialog(editor, pluginTitle, Form, onSuccess, onCancel, node, initialData, width, height);

    } else if (window && window.tinymce) {

        const { tinymce } = window;
        tinymce.PluginManager.add(pluginName, (editor, url) => {
            var openDialog = function () {

                var onSuccess = async(editor, data) => {
                   if(customFn){
                     data = await customFn(data);
                    }
                    delete data[""];
                    var view = renderToString(<View data={data} folder={pluginFolder} />);
                    editor.insertContent(view, {format: 'raw'});
                };

                var onCancel = (editor) => {

                }

                Dialog(editor, pluginTitle, Form, onSuccess, onCancel, null, initialData, width, height);

              };

              if(pluginIcon)
                editor.ui.registry.addIcon(pluginName, pluginIcon);
              
              // Add a button that opens a window
              editor.ui.registry.addButton(pluginName, {
                text: pluginTitle,
                icon: pluginIcon ? pluginName : 'unselected',
                tooltip: pluginTitle,
                onAction: function () {
                  // Open window
                  openDialog();
                },

                onSetup: function(buttonApi) {
                    editor.formatter.formatChanged(pluginName, function(state) {
                     
                        buttonApi.setDisabled(state);
                    });
                },

              
              });
            
              return {
                getMetadata: function () {
                  return  {
                    name: pluginTitle,
                    url: "https://gigatron.rs"
                  };
                }
              };
        });
        
    }
}