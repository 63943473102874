import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';
export default class Bullets extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){

        e.stopPropagation();
        e.preventDefault();

        // var ins = new InsertNode(this.props.editor);
        // ins.wrap('ul','li');
        // this.props.editor.registerChange();

        // this.props.element.current.focus();
        document.execCommand('insertUnorderedList', false, '');
        this.props.editor.registerChange();
        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"Bullets-btn-"+this.props.id} onMouseDown={this.action}><i className="fa fa-list-ul" aria-hidden="true" ></i></button>
    }

}