import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Link,NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";

// import './SideMenu.scss';
import { startWith } from '../../../helpers/stringHelper';

import { fetchHelper } from '../../../helpers/fetchHelper';
import { setMasterActive,setSideNavMobile,setBreadcrumbs } from './../../../modules/navigation';


class Title extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        title: null,
        components: {},
    };
    this.stop = false;
  }

  componentDidMount() {
    
          
      
  }

  componentDidUpdate(prevProps, prevState) { 

    if (this.props.location.pathname != prevProps.location.pathname||this.state.title==null) {

      if(this.props.location.pathname.includes("/admin/products/categories")){
        // console.log(this.state.categories)
        if(!this.state.categories){
          this.loadProductCategories();
        }
        else{
          // console.log('//////////////////////////////////////////')
          if(this.props.location.pathname === "/admin/products/categories" ) return;
          this.stop = false
          this.setTitle(this.state.categories,'first')
        }
      } else{
        // console.log('************************************************')
        this.stop = false
        this.setTitle(this.props.navigation,'first')
      }

    }

  }

   //todo 3 korak deskop racunari
   async loadProductCategories(slug) {
    if (!this.state.categories) {
      var response = await fetchHelper.get('/categorization/get_categories');
      if (response.status && response.items.length > 0) { 

        this.setState({ categories: response.items })

        if(this.props.location.pathname=="/admin/products/categories"){
          // console.log('+++++++++++++++++++++++++++++++++++++++++++++++')
          this.stop = false;
          this.setTitle(this.props.navigation,'first')
        }
        else{
          // console.log("-------------------------------------------------")
          this.setTitle(response.items)
        }
      }
    }

  }



  setTitle(arr,str) {

    if (!arr || !arr.length) return; 
    
    // console.log(str,this.stop)
    if(this.stop && str) return;

    for(let x=0;x<arr.length;x++){
      let item = arr[x];

      if(str === 'first' && this.stop) return;
      // console.log(item)

      if (arr[x].slug === this.props.location.pathname) { 
        // console.log("\x1b[43m"+arr[x].name)
        this.setState({ title: arr[x].title || arr[x].name });
        this.stop = true
        break;
      } else { 
        this.setTitle(arr[x].child,'second')
      }

    }

  }

  //kako se snima kooki
// pronaci finalni nestovan child


  async loadCategories(){
    if(!this.state.categories){
    var response = await fetchHelper.get('/categorization/get_categories');
    if (response.status && response.items.length > 0) {
        console.log('1. loaded')
            this.setState({ categories: response.items })
        }
    }

}

  render() {
    if(!this.props.user){
      return '';
    }

    if(!this.props.navigation.length) return '';

    return (
      <>
      <h1 style={{marginLeft:'120px'}}>{this.state.title} </h1>
      </>
    );
  }
}


const mapStateToProps = state => ({
  navigation: state.navigation.navigation,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setMasterActive,
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Title));
