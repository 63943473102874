import React, { Component } from 'react';

import * as moment from 'moment';
import 'moment/locale/sr';

import './style.scss';
import { ArrayChunk } from '../../helpers/array';
import { date_rs_to_sql, date_rs_to_object, date_rs_validate } from '../../helpers/dateHelper';


function calcWeeksInMonth(date) {

    const dateFirst = moment(date).locale("rs").date(1);
    const dateLast = moment(date).locale("rs").date(date.daysInMonth());
    const startWeek = dateFirst.isoWeek();
    const endWeek = dateLast.isoWeek();
  
    if (endWeek < startWeek) {
      // Yearly overlaps, month is either DEC or JAN
      if (dateFirst.month() === 0) {
        // January
        return endWeek + 1;
      } else {
        // December
        if (dateLast.isoWeekday() === 7) {
          // Sunday is last day of year
          return endWeek - startWeek + 1;
        } else {
          // Sunday is NOT last day of year
          return dateFirst.isoWeeksInYear() - startWeek + 1;
        }
      }
    } else {
      return endWeek - startWeek + 1;
    }
}


function getDaysArrayByMonth(y,m) {

  var daysInMonth = moment(`${y}/${m}/01`).locale('rs').daysInMonth();
  var arrDays = [];

  while(daysInMonth) {
    var current = moment(`${y}/${m}/01`).locale('rs').date(daysInMonth);
    arrDays.push(current);
    daysInMonth--;
  }

  arrDays = arrDays.reverse();
  return arrDays.map((item) => {
      var value = `${item.format("DD")}.${item.format("MM")}.${item.format("YYYY")}`;
      return {day : item.format("DD"), name: item.locale('rs').format('ddd'), date: item.locale('rs'), month: item.locale('rs').format('MM'), year: item.locale('rs').format('YYYY'), monthStr: item.locale('rs').format('MMMM'), value:value};
  });
}


function getDaysArray(year,month){

  year = parseInt(year);
  month = parseInt(month)

  var daysArrayPrev       = getDaysArrayByMonth(month-1 > 0 ? year : year - 1,month-1 > 0 ? month-1 : 12);
  var daysArrayCurrent    = getDaysArrayByMonth(year,month);
  var daysArrayNext       = getDaysArrayByMonth(month+1 < 13 ? year : year + 1, month + 1 < 13 ? month + 1 : 1);

  if(daysArrayCurrent[0].name !== 'pon.')
    for(var i = daysArrayPrev.length-1; i > 0; i--){
        var d = daysArrayPrev[i];
      
        if(d.name !== 'pon.'){
          daysArrayCurrent.unshift(d);
        } else {
          daysArrayCurrent.unshift(d);
          i = 0;
        }
    }

  
  var last = daysArrayCurrent[daysArrayCurrent.length-1].name;
  if(last !== 'ned.'){
    for(var i = 0; i < daysArrayNext.length; i++){
      var d = daysArrayNext[i];
    
      if(d.name !== 'ned.'){
        daysArrayCurrent.push(d);
      } else {
        daysArrayCurrent.push(d);
        i =  99;
      }
    }
  }

  return daysArrayCurrent;

}


class GigaDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
        show: false,
        year: 2020,
        month: 1,
        day: 1,
        dstr: 'Sreda',
        weekdays: [],
        days: 28,
        weeks: 5,
        now: moment(),
        daysList: [],
        monthStr: 'Januar',
        value: '',
        ValueDate: '',
        hours: '00',
        minutes: '00'
    };

    this.ref = React.createRef();
    this.isBusy = false;
    this.changeTimeout = null;

    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onDateClick = this.onDateClick.bind(this);
    this.back = this.back.bind(this);
    this.next = this.next.bind(this);
    this.onHourChange = this.onHourChange.bind(this);
    this.onMinuteChange = this.onMinuteChange.bind(this);
    this.onFocusOut = this.onFocusOut.bind(this);
    this.close = this.close.bind(this);

  }

  componentDidMount(){

        window.removeEventListener('datepickerclick', this.onClick);
        window.addEventListener("datepickerclick", this.onClick); 

        var year      = moment().locale('rs').format('YYYY');
        var day       = moment().locale('rs').format('DD');
        var month     = moment().locale('rs').format('MM');
        var monthStr  = moment().locale('rs').format('MMMM');
        var dstr      = moment().locale('rs').format('dddd');
        var hours     = moment().locale('rs').format('HH');
        var minutes   = moment().locale('rs').format('mm');
        var dstr      = moment().locale('rs').format('dddd');
        var weekdays  = ['pon.','uto.','sre.','čet.','pet.','sub.','ned.'];
        var days      = moment().locale('rs').daysInMonth();
        var now       = moment().locale('rs');

        this.setState({
            year: year,
            month: month,
            day: day,
            dstr: dstr,
            weekdays: weekdays,
            days: days,
            now: now,
            weeks: calcWeeksInMonth(now),
            daysList: [],
            monthStr: monthStr,
            hours: hours,
            minutes: minutes,
            value: '',
            valueDate: `${day}.${month}.${year}`,
        }, () => {

         

          this.getMonthData(year,month);
          this.ref.current.value = this.state.value;
        })
  }

  close(){
    this.setState({show:false});
  }

  componentDidUpdate(prevProps){
    if(this.props.content_id&&prevProps.content_id!=this.props.content_id){
      this.close();
    }
    if(prevProps.value !== this.props.value){
     
      var n = date_rs_to_sql(this.props.value);
      if(n){
        var date = moment(n).locale('rs');
        this.updateState(date, (newValue)=>{

        },true);
      } else {
        var year      = moment().locale('rs').format('YYYY');
        var day       = moment().locale('rs').format('DD');
        var month     = moment().locale('rs').format('MM');
        var monthStr  = moment().locale('rs').format('MMMM');
        var dstr      = moment().locale('rs').format('dddd');
        var hours     = moment().locale('rs').format('HH');
        var minutes   = moment().locale('rs').format('mm');
        var dstr      = moment().locale('rs').format('dddd');
        var weekdays  = ['pon.','uto.','sre.','čet.','pet.','sub.','ned.'];
        var days      = moment().locale('rs').daysInMonth();
        var now       = moment().locale('rs');

        this.setState({
            year: year,
            month: month,
            day: day,
            dstr: dstr,
            weekdays: weekdays,
            days: days,
            now: now,
            weeks: calcWeeksInMonth(now),
            daysList: [],
            monthStr: monthStr,
            hours: hours,
            minutes: minutes,
            value: '',
            valueDate: `${day}.${month}.${year}`,
        }, () => {
          this.getMonthData(year,month);
          this.ref.current.value = this.state.value;
        })
      }
    }
  }

  updateState(date, callback, updateref){

      var year      = date.format('YYYY');
      var day       = date.format('DD');
      var month     = date.format('MM');
      var monthStr  = date.format('MMMM');
      var dstr      = date.format('dddd');
      var hours     = date.format('HH');
      var minutes   = date.format('mm');
      var dstr      = date.format('dddd');
      var days      = date.daysInMonth();
      var value     = `${day}.${month}.${year} ${hours}:${minutes}`

      this.setState({
          year: year,
          month: month,
          day: day,
          dstr: dstr,
          days: days,
          monthStr: monthStr,
          hours: hours,
          minutes: minutes,
          value: value,
          valueDate: `${day}.${month}.${year}`,
      }, () => {
        this.getMonthData(year,month);
        if(callback) callback(value);
        if(updateref === true && this.isBusy === false){
          this.ref.current.value = value;
        }
        this.isBusy = false;  
      })
  }

  getMonthData(year, month){
      var daysList = getDaysArray(year,month);
      this.setState({daysList:daysList});
  }

  isValid(d, value){
    if(!value.trim()) return false;
    if(d.isValid()){
       var n = value.split(' ');
       if(n.length < 2){
         return false;
       }

       var dt = n[0];
       var hr = n[1];

       var e = dt.split('.');
       if(e.length !== 3) return false;

       if(e[0].length < 1) return false;
       if(e[1].length < 1) return false;
       if(e[2].length < 1) return false;

       
       e = hr.split(':');
       if(e.length < 2) return false;

       if(e[0].length < 1) return false;
       if(e[1].length < 1) return false;

       var h = parseInt(e[0]);
       var m = parseInt(e[1]);

       if(h<0) return false;
       if(h > 23) return false;
       if(m<0) return false;
       if(m>59) return false;

       return true;
    }

    return false;
  }

  onChange(e){

    if(e.keyCode === 13){
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    this.setState({show:true});
    var v = e.currentTarget.value,
    dateSql = date_rs_to_sql(v),
    d = moment(dateSql).locale('rs');
    this.updateState(d,()=>{},false);
    if(date_rs_validate(d,v)){
        this.ref.current.classList.remove('error');
    } else {
      if(this.props.required === true || this.props.required === undefined){
        this.ref.current.classList.add('error');
      }
    }
  }

  onFocusOut(){
    clearTimeout(this.changeTimeout);
    if(this.ref.current){

      var c = this.ref.current;
      var n = this.props.name;

      this.changeTimeout = setTimeout(()=>{
          var v = this.state.value,
          dateSql = date_rs_to_sql(v),
          d = moment(dateSql).locale('rs');
          if(date_rs_validate(d,v)){
            if(this.ref.current)
              this.ref.current.classList.remove('error');
              if(date_rs_validate(d,v)){
                this.props.onChange({
                  currentTarget: {
                    name: n,
                    value: v
                  }
                });
              }
          } else {
            if(this.props.required === true || this.props.required === undefined){
                c.classList.add('error');
            }
          }
      },600);
    }
  }

  onClick(e,a){
    if(e.detail.field === this.props.name){
        this.setState({show:this.state.show ? false : true })
    } else {
        this.setState({show:false })
    }
  }


  dayCells(){
    var list = this.state.daysList;
    var n = ArrayChunk(list,7);
    return n.map((r,i)=>{
      return <tr key={i}>
        {r.map((d,a)=>{
          return <td className={
            (
              parseInt(this.state.month)!==parseInt(d.month)?'outer-month ':''
            ) 
            + 
            ( a === 5 || a === 6 ? 'day-week ':'') 
            + 
            (
              `${d.day}.${d.month}.${d.year}` === this.state.valueDate ?  ' active' : '' 
            )
          } key={i+'.'+a}><span onClick={(event)=>this.onDateClick(event,d)}>{d.day}</span></td>
        })}
      </tr>
    })
  }

  onDateClick(event, date){
    var d = moment(`${date.year}-${date.month}-${date.day} ${this.state.hours}:${this.state.minutes}`).locale('rs');
    this.updateState(d, () => {
        this.props.onChange({
            currentTarget: {
              name: this.props.name,
              value: this.state.value,
            },
        })
    });
    this.setState({show:false});
  }

  back(){
    var year = parseInt(this.state.year);
    var month = parseInt(this.state.month);
    month--;
    if(month < 1){
      month = 12;
      year--;
    }
    var monthStr = moment(`${year}-${month}-01`).locale('rs').format('MMMM');
    this.setState({
      month: month,
      year: year,
      monthStr: monthStr
    },()=>{
      this.getMonthData(parseInt(this.state.year), parseInt(this.state.month))
    })

  }

  next(){
    var year = parseInt(this.state.year);
    var month = parseInt(this.state.month);
    month++;
    if(month > 12){
      month = 1;
      year++;
    }
    var monthStr = moment(`${year}-${month}-01`).locale('rs').format('MMMM');
    this.setState({
      month: month,
      year: year,
      monthStr: monthStr
    },()=>{
      this.getMonthData(parseInt(this.state.year), parseInt(this.state.month))
    })
  }


  selectHours(){
    var n = [];
    for(var i = 0; i < 24; i++){
      var value = i;
      if(i<10){
        value = '0'+i;
      }
      n.push({text:value,value:i});
    }

    return n.map((o,a)=>{
      return <option key={a} value={o.value}>{o.text}</option>
    })
  }

  selectMinutes(){
    var n = [];
    for(var i = 0; i < 60; i++){
      var value = i;
      if(i<10){
        value = '0'+i;
      }
      n.push({text:value,value:i});
    }

    return n.map((o,a)=>{
      return <option key={a} value={o.value}>{o.text}</option>
    })
  }

  onHourChange(e){
      this.setState({hours:parseInt(e.currentTarget.value)},()=>{
        var d = `${this.state.day}.${this.state.month}.${this.state.year} ${this.state.hours}:${this.state.minutes}`;
        this.props.onChange({
          currentTarget: {
            name: this.props.name,
            value: d
          }
        });
      })
  }

  onMinuteChange(e){
      this.setState({minutes:parseInt(e.currentTarget.value)},()=>{
        var d = `${this.state.day}.${this.state.month}.${this.state.year} ${this.state.hours}:${this.state.minutes}`;
        this.props.onChange({
          currentTarget: {
            name: this.props.name,
            value: d
          }
        });
      })
  }


  calendar(){
      if(this.state.show === false) return '';

      return <div className="giga-date-calendar">
          <div className="gdc-header">
              <table>
                  <tbody>
                      <tr>
                          <td><span className="gdc-nav nav-left" onClick={this.back}><i className="fa fa-angle-left" /></span></td>
                          <td>
                                <span className="gdc-date"><u style={{textTransform:'capitalize'}}>{this.state.monthStr}</u> {this.state.year}</span>
                          </td>
                          <td><span className="gdc-nav nav-right" onClick={this.next}><i className="fa fa-angle-right" /></span></td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div className="gdc-body">
                <table>
                    <thead>
                        <tr>
                            {this.state.weekdays.map((w,i)=>(
                                <th key={i} data-d={w}>{w.slice(0,3)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.dayCells()}
                    </tbody>
                </table>
          </div>
          <div className="gdc-time">
            <span>
              <select onChange={this.onHourChange} value={this.state.hours !== 'Invalid date' ? parseInt(this.state.hours) : ''}>
                {this.selectHours()}
              </select>
            </span>
            <span>
              <select onChange={this.onMinuteChange} value={this.state.minutes !== 'Invalid date' ? parseInt(this.state.minutes) : ''}>
                {this.selectMinutes()}
              </select>
            </span>
          </div>
          {this.props.formDatePicker ?
          <>
           <button type="button"  onClick={()=>{this.emptyDate()}} className="giga-editor-delete-date">
              Izbrisi unos
          </button>

          <button type="button"  onClick={()=>{this.close()}} className="giga-editor-close-date">
              Zatvori 
          </button>
          </>
          : "" }          
          
      </div>
  }

  emptyDate(){
   this.props.onChange('');
   this.close();
  }

  render() {
    let placeholder=this.props.placeholder ? this.props.placeholder : "21.03.2020 13:45";
    let disabled=this.props.disabled ? this.props.disabled : false;
    let readOnly=this.props.readOnly ? this.props.readOnly : false;

    
    return (
        <div className="giga-date-picker-wrap" onClick={(e)=>e.stopPropagation()}>
            <div className="giga-date-picker ">
                <input 
                className={(this.props.formDatePicker ? this.props.size : "")+(this.props.className ? ' '+this.props.className:'')}
                ref={this.ref} 
                name={this.props.name} 
                placeholder={placeholder} 
                readOnly={readOnly}
                disabled={disabled}
                onMouseDown={()=>window.dispatchEvent(new CustomEvent('datepickerclick',{detail:{field: this.props.name}}))} 
                onKeyUp={(e)=>{return (readOnly ? false : this.onChange)}} 
                onBlur={this.onFocusOut} 
                />
            </div>
            {this.calendar()}
        </div>
    );
  }
}


  export default (GigaDatePicker);