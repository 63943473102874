export const actionHelpers={
    
    "add":(new_html,data)=>{ 
        let insert_in=data.insert_in;
        let content=data.content;
        let content_names=data.content_names; 
        
        if(insert_in=="main_item_wrapper"){ 
            new_html=new_html.concat(content);
        }
        else{
            new_html.map((x,index)=>{
                if(x.element_name==insert_in){

                    let new_wrapper_name=new_html[index].element_name+content_names;
                    new_html[index].element_name=new_wrapper_name;
                    new_html[index].content=new_html[index].content.concat(content); 
                    
                }
                else if(x.element_name.includes(insert_in)){
                    let new_wrapper_name=new_html[index].element_name+content_names;
                    new_html[index].element_name=new_wrapper_name;
                    new_html[index].content=actionHelpers.add(new_html[index].content,data)
                }
                else{

                }
                
            })
        } 
        return new_html;
    },

    "delete":(html,key)=>{

        for(let x=0;x<html.length;x++){ 
            let name=html[x].element_name;
            if(name.includes("wrapper")&&name!=key&&name.includes(key)){   
                //ako smo usli u wraper koji sadzi u kontentu sta treba a a nije element koji brisemo
                html[x].content=actionHelpers.delete(html[x].content,key);
                html[x].element_name="wrapper__"+html[x].content.map(z=>{
                    return z.element_name;
                }).join('__');
                if(!html[x].content.length){
                    html.splice(x, 1);   
                }
                break
                 
            }
            else if(name==key){ 

                html.splice(x, 1);
                break
            }
        } 
        return html;
    },

    "change_position":(content,data)=>{ 
        let droped_on_name=data.droped_on_name;
        let dragged_element=data.draggable_active_element;
        let draged_name=dragged_element.element_name; 
        let new_html=[];
        for(let x=0;x<content.length;x++){ 
            let name=content[x].element_name;
            
            if(name.includes("wrapper")&&name.includes(draged_name)&&name==droped_on_name){ 
                new_html.push(dragged_element);
            }
            if(name.includes("wrapper")&&(name.includes(draged_name)&&name!=draged_name||name.includes(droped_on_name)&&name!=droped_on_name)){
                // console.log("find in :",content[x].content,"this thing",draged_name)
                let new_content=actionHelpers.change_position(content[x].content,data);
                if(new_content.length){
                    let new_name="wrapper__"+new_content.map(z=>{ 
                        return z.element_name; 
                    }).join('__');
                    content[x].element_name=new_name;
                    content[x].content=new_content; 
                    
                    new_html.push(content[x]);
                }
            }
             
            else if(name==droped_on_name){ 
                new_html.push(dragged_element);
                new_html.push(content[x]);
            }
            else if(name!=draged_name||name.includes("wrapper")&&!name.includes(draged_name)&&!name.includes(droped_on_name)){
                new_html.push(content[x]);
            }
           
     
        } 
        return new_html;
    },

    "change_element_options":(html,data)=>{
        let {class_name,prop_name,value}=data;
        if(html.element_name==class_name){
            html[prop_name]=value; 
        }
        else{ 
            html.content.map((element,index)=>{
                if(element.element_name==class_name){ 
                    html.content[index][prop_name]=value; 
                }
                else if(element.element_name.includes("wrapper")){
                    html.content[index]=actionHelpers.change_element_options(element,data); 
                }
            }); 
        } 
        return html; 
    },

    "change_css":(html,data)=>{
        let class_name=data.class_name;
        let css_prop=data.name;
        let css_value=data.value;
        if(html.element_name==class_name){ 
            if(!css_value){
                delete html.css[css_prop];    
            }
            else{
                html.css[css_prop]=css_value;    
            }
            
        }
        else{ 
            html.content.map((element,index)=>{
                if(element.element_name==class_name){ 
                    if(!css_value){
                        delete element.css[css_prop];    
                    }
                    else{
                        element.css[css_prop]=css_value;    
                    }
                }
                else if(element.element_name.includes("wrapper")&&element.element_name.includes(class_name)){ 
                    html.content[index]=actionHelpers.change_css(element,data);
                }
            }); 
        } 
        return html; 
    }

 

}