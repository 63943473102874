import { env } from '../config/vars';

var FormData = require('form-data');
// import FormData from "form-data";
const axios = require('axios').default;
// const fetch = require('node-fetch');

export const fetchHelper = {

    api_url: process.env.NODE_ENV == 'development' && env !== 'production' ? 'http://localhost:5002/v1/' : 'https://dev.back-api.gigatron.rs/v1/',

    _uuid(){
        var user = {uuid:null};
        if (localStorage.getItem('user')) {
            
            var u = localStorage.getItem('user');
            if(u === '[object Object]' ){
                u = null;
            } else {
            
            }

            user = u ? JSON.parse(u)  : null;    
        }

        return user ?  user.uuid : null;
    },

    _queryBuild(params){
        if(params !== undefined)
            return  '?'+Object.keys(params).map(key => key + '=' + params[key]).join('&');
        else 
            return '';
    },

    _postBuild(params){
        let formData = new FormData();
        if (params != undefined && params != null) {
            Object.keys(params).map(function(a, e) {
                formData.append(a, params[a]);
            });
      
            if(params.files){
                Object.keys(params.files).map(function(a,e){
                    formData.append('file_' + a , params.files[a]);
                })
                formData.delete('files');
            }
        }
        return formData;
    },

    get: async function(path, query_data){
        
        var url = fetchHelper.api_url + path + fetchHelper._queryBuild(query_data);

        const requestOptions = {
            method: 'GET',
            headers: { 
                'Authorization':  fetchHelper._uuid(),
                'Identify': fetchHelper._uuid(),
            }
        };

        const response = await fetch(url, requestOptions).then(response=>response.json()).then(data=>{ return data;})

        return fetchHelper.resolve(response);
    },

    post: async function(path, query_data){
        var formData = new FormData();
        Object.keys(query_data).map((k)=>{
            var t = (query_data[k]);
            if(typeof t === 'object' && t !== undefined && t){
                Object.keys(t).map((a,i)=>{
                    var file = t[a];
                    formData.append(`${k}`,file);
                });
                
            } else {
                if(t === undefined) t = null;
                formData.append(k,t);
                }
        })
        let response = null;

        axios.defaults.headers.put['withCredentials'] = false;
        axios.defaults.headers.put['Identify'] = fetchHelper._uuid();
        axios.defaults.headers.put['Content-type'] = 'multipart/form-data';
        var url = fetchHelper.api_url + path;
        try {
            response = await axios.post(url, formData);

            
        } catch (err){
            console.log(err);
        } finally {
            return fetchHelper.resolve(response);
        }
        
    },

    // AXIOS HAS A PROBLEM, MAKED FETCH POST WHEN AXIOS CORS POLICY HAS ISSUE
    fetchPost: async(path,query_data) => {
        var formData = new FormData();
        Object.keys(query_data).map((k)=>{
            var t = (query_data[k]);
            if(typeof t === 'object' && t !== undefined && t){
                Object.keys(t).map((a,i)=>{
                    var file = t[a];
                    formData.append(`${k}`,file);
                });
                
            } else {
                if(t === undefined) t = null;
                formData.append(k,t);
                }
        })

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        var url = fetchHelper.api_url + path;

        return new Promise((resolve)=>{
        
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => resolve(data)).catch(error => {
                resolve(null);
                console.error('There was an error!', error);
            });

        })
    },


    put:  async function(path, query_data){
        axios.defaults.headers.put['Identify'] = fetchHelper._uuid();
        axios.defaults.headers.put['Content-type'] = 'application/json';
        var url = fetchHelper.api_url + path;
        const response = await axios.put(url, JSON.stringify(query_data));
        return fetchHelper.resolve(response);
    },
    fetchDelete:  async function(path, query_data){
        var url = fetchHelper.api_url + path;
        const requestOptions = {
            method: 'DELETE',
            body: {data:query_data}
        };
        return new Promise((resolve)=>{  
            fetch(url, requestOptions) 
                .then(response => response.json())
                .then(data => resolve(data)).catch(error => {
                    resolve(null);
                    console.error('There was an error!', error);
                });

        })
    },
    delete:  async function(path, query_data){
        axios.defaults.headers.delete['Identify'] = fetchHelper._uuid();
        axios.defaults.headers.put['Content-type'] = 'application/json';
        var url = fetchHelper.api_url + path;
        const response = await axios.delete(url, {data:query_data});
        return fetchHelper.resolve(response);
    },

    resolve(response){
        return response;

    }
};