import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from "connected-react-router"

import forms from "./forms";
import navigation from "./navigation";
import auth from "./auth";
import orderindex from "./orderindex";

export default (history) => combineReducers({
  router: connectRouter(history),
  form: formReducer,
  navigation,
  auth,
  forms,
  orderindex
});