export const SET_BUSY = "forms/SET_BUSY";
export const FULLSCREEN_LOADER = "forms/FULLSCREEN_LOADER";
export const SET_PREVIEW = "forms/SET_PREVIEW";

const initialState = {
  busy: false,
  fullscreenLoader: false,
  preview: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSY:
      return {
        ...state,
        busy: action.busy
      };
      case SET_PREVIEW:
      return {
        ...state,
        preview: action.preview
      };
      case FULLSCREEN_LOADER:
        return {
          ...state,
          fullscreenLoader: action.fullscreenLoader
        };
    default:
      return state;
  }
};

export const setBusy = (busy) => dispatch => {
  return new Promise(resolve => {
    dispatch({
        type: SET_BUSY,
        busy: busy
      });

    resolve(busy);
  });
}

export const setPreview = (preview) => dispatch => {
    dispatch({
        type: SET_PREVIEW,
        preview: preview
      });
}

export const setFullscreenLoader = (fullscreenLoader) => dispatch => {
  return new Promise(resolve => {
    dispatch({
        type: FULLSCREEN_LOADER,
        fullscreenLoader: fullscreenLoader
      });

    resolve(fullscreenLoader);
  });
}