import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';
import Initialize from '../../base/initialize';
import {fetchHelper} from './../../../../helpers/fetchHelper';
import { uniqString } from '../../../../helpers/stringHelper';
import { withRouter} from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setFullscreenLoader } from '../../../../modules/forms';

class Image extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);

        this.editorContent = null;
        this.refInp = React.createRef();

        this.file = [];
        this.url = '';
    }

    componentDidMount(){

        this.url = this.props.url;

        this.initialize();
    }

    initialize(){
        this.editorContent = this.props.element.current;
    }


    upload() {
        return new Promise( async ( resolve, reject ) => {
            var response = await this._sendRequest();

            if(response){
                resolve(response);
            } else {
                resolve(false);
            }
        } );
    }


    // Prepares the data and sends the request.
    async _sendRequest() {
        const data      = {};
        var file        = this.file;
        data.file       = {image:file};
        data.id         = this.props.editor.contentid;
        data.section    = this.props.section;

        const result    = await fetchHelper.post('upload/editor',data);

        if(result.status && result.data && result.data.status){
            var loader = {};
            loader.uploadTotal = result.data.items.length;
            loader.uploaded = result.data.items.map((item)=>this.url+item.src);

            this.props.editor.setState({files:result.data.list});

            return loader.uploaded;
        }

        return false;
    }

    getImage(e) {
        
        var file = this.file = e.currentTarget.files[0];
      
        var reader = new FileReader();
      
        let dataURI;

        let imgid = uniqString(5,'img');
      
        reader.addEventListener(
          "load",
          async () => {

            dataURI = reader.result;
      
            const img = document.createElement("img");
            img.src = dataURI;
             

            var html = `<img src="/images/loading.gif" id="${imgid}" class="block-element-editor" data-type="image" data-onload="ImageModal" />`;

            // var div = document.createElement('figure');
            // div.classList.add('block-element-editor');
            // div.innerHTML = html;
            // div.contentEditable = false;
            // div.dataset.type = 'image';
            // div.setAttribute('tabindex', '0');
            // div.onkeypress =(e)=>{
            //     if(e.keyCode === 127){
            //         div.remove();
            //         this.props.editor.registerChange();
            //     }
            // }
            // div.ondragstart = function() { return false; };
            // div.onmousedown = function(e){
            //     e.stopPropagation();
            //     e.preventDefault();
            //     return false;
            // }
            // var image = div.querySelector('img');
            
            
            
            const element = html;

           
            

            // var ins = new InsertNode();
            // ins.append(element,this.props.editor);

            document.execCommand('insertHTML',true, element);
            this.props.setFullscreenLoader(true);
            var images = await this.upload();
            if(images){
                //todo check za null src 
                var src = images[0];
                var image = document.getElementById(imgid);
                image.src = src;

                // var initialize = new Initialize();

                // div = image.closest('.block-element-editor');

                // initialize.initializeImage(div,this.props.editor);

                this.props.editor.registerChange();
            } else {

                image = document.getElementById(imgid);
                
                var div = document.createElement('div');
                div.innerHTML = '<div class="editor-error">Image is not uploaded. Please try again.</div>'
                div.onclick = (e=>{
                    e.currentTarget.remove();
                });

                image.replaceWith(div);
            }
            this.props.setFullscreenLoader(false);

            this.refInp.current.type = 'text';
            this.refInp.current.type = 'file';

          },
          false
        );
      
        if (file) {
          reader.readAsDataURL(file);
        }
    }

    action(e){
        this.getImage(e);
    }

    prevent(e){
        e.stopPropagation();
        e.preventDefault();

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"Image-btn-"+this.props.id}>
                <input type="file" id={'file-'+this.props.id} aria-hidden="true" accept="image/*" ref={this.refInp} onChange={this.action} style={{display:'none'}} />
                <label htmlFor={'file-'+this.props.id} className=" fa fa-file-image-o" onMouseDown={this.prevent}></label>
        </button>
    }

}

    const mapStateToProps = state => ({

    });
    
    const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setFullscreenLoader
        },
        dispatch
    );

    export default withRouter(
        connect(
        mapStateToProps,
        mapDispatchToProps
    )(Image));