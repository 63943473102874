import React, {Component} from 'react';
import $ from 'jquery';
import InsertNode from '../../base/insertNode';

export default class FontSize extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){
       
        // var ins = new InsertNode( this.props.editor );
        // ins.style('fontSize',e.currentTarget.value)

       // document.execCommand('fontSize',true,e.current)

        var spanString = $('<span/>', {
            'text': document.getSelection()
        }).css('font-size', e.currentTarget.value).prop('outerHTML');
    
        document.execCommand('insertHTML', false, spanString);

       
        this.props.editor.registerChange();
    }

    optionValues(){
        var n = [];
        for(var i = 7; i < 43; i++){
            n.push({text:i+'px',value:i+'px'});
        }

        return n.map((item,i)=>{
            return <option key={i} value={item.value}>{item.text}</option>
        })
    }

    render(){
        return <select className="get-btn selector-ga" id={"FontSize-btn-"+this.props.id} onChange={this.action}>
            <option>Font Size</option>
            {this.optionValues()}
        </select>
    }

}