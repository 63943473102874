import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "../../../helpers/fetchHelper";
import GigaTable from '../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper"; 
import GigaTabs from '../../../libraries/GigaTabs';
import GigaTab from '../../../libraries/GigaTabs/GigaTab';
import "../../../libraries/GigaTable/style.scss";
import BUGroupsAndPermissionsForm from "../../../forms/BUGroupsAndPermissionsForm";


class BUGroupsAndPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_user:null,
      selected: null,
      table: null,
      // detail: null, 
      
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
    this.addNew=this.addNew.bind(this);
  }

  
  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_user:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_user:row,selected:row.id})
  }
 



  async addNew(){
    var r = await fetchHelper.post('backoffice_users_groups_and_permissions/groups/change', {});

    if(r.data&&r.data.code==101){ 
      AlertyBox.serverError();
    }
    else if(r.status && r.data) {
      this.setState({ active_user: r.data.data, selected: r.data.data.id, });
      this.table.loadData(); 
    }
  }

  resetActive() {
    this.setState({active_user:null,selected:null})
  }

  
  render() {
    const cells = [
      {name:'ID',cell: 'id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Naslov',cell: 'title', width:150,truncate:true,editable:true,sort:true}, 
      {name:'Detalji', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return  <button onClick={()=>{ 
          this.setState({active_user:row,selected:row.id})
      }}><i className="fa fa-folder-open-o" /></button> 
      }},
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center', render:(row)=>{ 
        var value = parseInt(row.status); 
        return  <button onClick={()=> this.table.updateRowCell(row,'status',value?0:1)}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button>  
      }},
    ]; 
    const buttons = [
      {title: 'Nova korisnička grupa', type: 'button' , onClick: this.addNew},
    ];
 
    return (<><div>

    </div>
      <div className="admin-home__main-section"> 
        <div className="table-wrapper">
          <GigaTable 
            cells={cells} 
            pk='id' 
            height={300} 
            buttons={buttons} 
            id='backoffice_user_group' 
            db="backoffice_user_group"
            fetch="backoffice_users_groups_and_permissions/groups"
            delete={true} 
            selected={this.state.selected}
            getInstance={this.getTableInstance}
            run={this.runRow}
            resetActive={this.resetActive}
          />
        </div> 
        {/*chc*/}
        {this.state.active_user ? <BUGroupsAndPermissionsForm user_group_id={this.state.active_user.id} index={this}/> : "" }

      </div>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(BUGroupsAndPermissions));
