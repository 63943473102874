import React from 'react';

class View extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        
      };
    }

    render(){

        const { data, folder } = this.props;
        const json = JSON.stringify(data);

         /* obavezno tab="0" className="mceNonEditable" data-controller="plugin" data-plugin={folder}  data-json={json} */
        return <div 
            tab="0" 
            className="mceNonEditable" 
            data-controller="plugin" 
            data-plugin={folder}  
            data-json={json} 
            style={{padding:'40px', border:'solid 1px #ccc'}}  
        >
            Inserted: {data.title}
        </div>
    }
}

export default View;