import React, {Component} from 'react';
import PaintModal from '../../modals/PaintModal';

export default class Paint extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){


    }


    action(e){
        e.stopPropagation();
        e.preventDefault();
        this.props.editor.setState({modal:<PaintModal editor={this.props.editor} />})

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"Paint-btn-"+this.props.id} onMouseDown={this.action} title="Podešavanje pozadine">
                <label htmlFor={'Paint-'+this.props.id} className=" fa fa-paint-brush"></label>
        </button>
    }

}