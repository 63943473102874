import React, { Component, useReducer } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field , formValueSelector , change  } from 'redux-form'
import { reduxAsyncValidate, reduxReduceVaues, reduxPrepareFormData } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";

import { fetchHelper } from '../helpers/fetchHelper';
import AlertyBox from '../libraries/AletyBox';
import {setBusy} from '../modules/forms';

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderGigaEditor
} from "../helpers/formHelper";

import RenderFieldInput from '../helpers/formHelper';

import './../assets/css/form.scss';

import {galleryItemConf} from './../forms/galleryItemConf';
 

  
class GalleryItemForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            success: null,
            message: null,
            busy:false,
            rnd: 0,
        };


    }

  componentDidMount(){


  }

  componentDidUpdate(prevProps){
    if(this.props.data){
        reduxAsyncValidate(this.props.data, this.props);
    }
  }


  

  async submit(data){
        this.props.setBusy(true);
       
        var values = reduxReduceVaues(galleryItemConf.fields,data);
        
        values.section=this.props.data.section;
        values.id = this.props.data.id;
        values.img_name = this.props.data.img_name ;
        values.position = this.props.data.position;
        values.product_id = this.props.data.product_id;
      

     
        var response = await fetchHelper.post(`gallery/change`,values);
        this.props.setBusy(false);
      
        if(response.status && values && Object.keys(values).length){
            AlertyBox.message(response.data);
            this.props.index.getImages(this.props.id);
        } else {
            var m = {message:'Došlo je do greške prilikom snimanja',status:false};
            AlertyBox.message(m)
        }
        return true;
  }


  render() {
 
    if(!this.props.data) return '';

    const {handleSubmit , invalid,submitting,pristine,busy} = this.props;
    
    // remove null, set empty string
    galleryItemConf.initialValues = this.props.data;

    var refID = React.createRef();
    

   
    return (
        <>
            <form id="galleryItemForm" onSubmit={handleSubmit((data) => {
            this.submit(data);
            })} className="redux-form" encType="multipart/form-data" >
                {busy?<div className="form-mask"></div>:''}
                <Grid columns={1} gap="30px">
                    <Cell width={1} className="grid-column">
                        <div className="relative">
                            <div className="separate-right"></div>
                            <Field {...galleryItemConf.fields.title}  />
                            <Field {...galleryItemConf.fields.alt}  />
                            <Field {...galleryItemConf.fields.description} />
                            <Field {...galleryItemConf.fields.price} />
                            <Field {...galleryItemConf.fields.btn_text} />
                            <Field {...galleryItemConf.fields.link} />
                        </div>
                    </Cell>
                    
                </Grid>
                <button data-busy={busy} type="submit" disabled={invalid || submitting || busy }>{busy ? (<GigaSpinner />) : 'Sačuvaj'}</button>
            </form>
        </>
    );
  }
}

const selector = formValueSelector('galleryItemForm');

const mapStateToProps = state => ({
    initialValues: galleryItemConf.initialValues,
    busy: state.forms.busy,
});
  
const mapDispatchToProps = dispatch =>
bindActionCreators(
    {
        setBusy,
        updateField: (field , data) => change("galleryItemForm" , field , data),
    },
    dispatch
);

GalleryItemForm = reduxForm({
  form: "galleryItemForm",
  reduxAsyncValidate,
  enableReinitialize: true,
})(GalleryItemForm);
GalleryItemForm = connect(
    mapStateToProps, mapDispatchToProps
)(GalleryItemForm);
export default GalleryItemForm;