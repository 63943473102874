import React from 'react';
import { b64DecodeUnicode, b64EncodeUnicode } from '../../helpers/base64';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { uniqString } from '../../../../../helpers/stringHelper';
import Item from "./components/Item"; 
import { fetchApiHelper } from '../../../../../helpers/fetchApiHelper'; 
import { items_list_style }  from "./components/ItemListCSS";

class View extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        favorite_ids_on_start:null,
      };
 
    }

    componentDidMount(){
        this.getFavoriteIds()
 
    }
    async getFavoriteIds(){
        let r = await fetchApiHelper.postApi('core/favorite/ids', ""); 
        this.setState({ favorite_ids_on_start: r.data });
    }
 

    // onResize = e => {   
    //     // works on preview only
    //     console.log("!!!!!resize")
    // };

    // resize won't work when we click ok on plugin form and it sets content in tinyMceEditor..it will work only on preview..
    css_resize_fix_for_editor(l,media_screen_fix_class){ 
        let horizontal_space_beatween_items=l.horizontal_space_beatween_items;
        let horizontal_space_around_items=l.horizontal_space_around_items;
        let vertical_space_beatween_items=l.vertical_space_beatween_items;
        let vertical_space_around_items=l.vertical_space_around_items; 
        let breaking_points_columns=l.breaking_points_columns;  
        // items_wrapper 
        let items_wrapper_margin_top="";
        let items_wrapper_padding_top_bottom="";
        let items_wrapper_padding_left_right="";  
        // col_fix_wrapper
        let col_fix_wrapper_margin_left_right=""; 
        // align_col
        let align_col_padding_top=""; 
        let align_col_padding_left_right=""; 
        let align_col_width="";
        let align_col_display="";
        
        

        let last_to_show=0;
        Object.keys(horizontal_space_beatween_items).map((brp,i)=>{ 
            if(horizontal_space_beatween_items[brp]||horizontal_space_beatween_items[brp]===0){
                //.items_wrapper
                items_wrapper_margin_top=` @media screen and (max-width: ${brp}px  ) {
                    .${media_screen_fix_class}  .items_wrapper{ 
                        margin-top: -${horizontal_space_beatween_items[brp]}px;
                    }
                }`+ items_wrapper_margin_top; 
                //.align_col
                align_col_padding_top=` @media screen and (max-width: ${brp}px  ) {
                    .${media_screen_fix_class}  .align_col{ 
                        padding-top: ${horizontal_space_beatween_items[brp]}px;
                    }
                }`+ align_col_padding_top; 


            } 
            if(horizontal_space_around_items[brp]||horizontal_space_around_items[brp]===0){
                 //.items_wrapper
                items_wrapper_padding_top_bottom=` @media screen and (max-width: ${brp}px  ) {
                    .${media_screen_fix_class} .items_wrapper{  
                        padding-top:${horizontal_space_around_items[brp]}px;
                        padding-bottom:${horizontal_space_around_items[brp]}px; 
                    }
                
                }`+ items_wrapper_padding_top_bottom;
           
            }
            if(vertical_space_around_items[brp]||vertical_space_around_items[brp]===0){
                 //.items_wrapper
                items_wrapper_padding_left_right=` @media screen and (max-width: ${brp}px  ) {
                    .${media_screen_fix_class}  .items_wrapper{  
                        padding-left:${vertical_space_around_items[brp]}px;
                        padding-right:${vertical_space_around_items[brp]}px;
                    }
                }`+ items_wrapper_padding_left_right;
            }
            if(vertical_space_beatween_items[brp]||vertical_space_beatween_items[brp]===0){
                //.col_fix_wrapper
                col_fix_wrapper_margin_left_right=` @media screen and (max-width: ${brp}px  ) {
                    .${media_screen_fix_class}  .col_fix_wrapper{  
                        margin-left:-${Math.round(vertical_space_beatween_items[brp]/2)}px;
                        margin-right:-${Math.round(vertical_space_beatween_items[brp]/2)}px;
                    }
                }`+ col_fix_wrapper_margin_left_right;

                //.align_col 
                align_col_padding_left_right=` @media screen and (max-width: ${brp}px  ) {
                    .${media_screen_fix_class}  .align_col{ 
                        padding-left: ${Math.round(vertical_space_beatween_items[brp]/2)}px;
                        padding-right: ${Math.round(vertical_space_beatween_items[brp]/2)}px;
                    }
                }`+ align_col_padding_left_right; 
            }
            // align_col_width
            let max=Object.keys(breaking_points_columns)[i]; 
            if(breaking_points_columns[brp]||breaking_points_columns[brp]===0){  
                let width=(100/breaking_points_columns[brp]).toFixed(3).replace(/\.000$/, ''); 
                align_col_width=` @media screen and (max-width: ${max}px  ) {
                    .${media_screen_fix_class}  .align_col{ 
                        width:calc( ${width}% + 1px);
                    }
                }`+ align_col_width; 
 
            }
            if(l.item_html[brp].element_name){
                align_col_display=` @media screen and (min-width:${last_to_show}px) and (max-width: ${brp}px) {
                    .${media_screen_fix_class} .align_col-${brp}{ 
                        display:block;
                    }
                }`+ align_col_display; 
                last_to_show=brp;
            }

        })

 
        return  `<style> `+ 
        items_wrapper_margin_top +
        items_wrapper_padding_top_bottom +
        items_wrapper_padding_left_right + 
        col_fix_wrapper_margin_left_right + 
        align_col_padding_top +
        align_col_padding_left_right+
        align_col_width+
        ` .align_col{
            display:none;}
        `+
        align_col_display
        +
        `</style>`
    }
 
    view(data){ 
        if(!data){
            return ""
        }
        let media_screen_fix_class="msfc-"+new Date().toJSON().replace(/[^0-9]/g, '');
        let l=JSON.parse(data.layout);
        let item_html=l.item_html; 
        let items_wrapper_max_width = l.items_wrapper_max_width ? l.items_wrapper_max_width + "px" : "100%";
        return  <div className={`product_plugin_wrapper_that_gets_inserted ${media_screen_fix_class}`} 
            style={{
            background:l.product_plugin_background_color,
            overflow:"hidden",
            }}>
            

            <div className={`items_wrapper clear `}  style={{ 
            maxWidth: items_wrapper_max_width ,
            background:l.items_wrapper_background_color, 
            }}>
                <div className="col_fix_wrapper" style={{ 
                    marginTop:"0px",
                    marginBottom:"0px"
                }}>
                    {Object.keys(item_html).map((x,i)=>{
                        // resize won't work when we click ok on plugin form and it sets content in tinyMceEditor..it will work only on preview..
                        // so in here i mapped all content with display none on items whose breaking point isn't active;
                        // in project it will render like in form(inline css);
                        let item=item_html[x];
                        if(item.element_name){
                            return data.items.map((data,index)=>{
                                return <div  key={"item-"+i+index} 
                                    style={{ 
                                    paddingBottom:"0px"
                                    
                                    }}
                                    className={`align_col col`
                                    + ` align_col-`+x
                                    } >
                                
                                    <Item  item_html={item}  data={data} />
                                </div>
                                    
                            })
                        }
                    })}
                    
                </div>
            </div>
            {ReactHtmlParser(items_list_style())}   
            {ReactHtmlParser(this.css_resize_fix_for_editor(l,media_screen_fix_class))}      
        </div>
 
    }

    render(){
        const { data, folder } = this.props;
        const json = JSON.stringify(data);
        console.log(data)
        return <div 
            tab="0" 
            className="mceNonEditable" 
            data-controller="plugin" 
            data-plugin={folder}  
            data-json={b64EncodeUnicode(json)} 
        >{this.view(data)}</div>
    }
}

export default View;