import React, { Component } from "react";
import { Link } from "react-router-dom";
 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { setSideNavMobile} from "../../../modules/navigation";
 

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
  
    }

  }
  
  openSideNav(){
    this.props.setSideNavMobile(true);
  }

  render() {
    let b=this.props.breadcrumbs;
    return  b ? <div className="main-page-title">
          {b.main.name ? <Link to={b.main.slug} onClick={()=>{this.openSideNav()}}>{b.main.name}</Link>:""}
          {b.category.name ? <Link to={b.category.slug} onClick={()=>{return b.subcategory.name ? this.openSideNav() : ""}}> > {b.category.name}</Link>:""}
          {b.subcategory.name ? <Link to={"#"}> > {b.subcategory.name}</Link>:""}          
      </div> : "";
  }
}



const mapStateToProps = state => ({
  breadcrumbs:state.navigation.breadcrumbs
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSideNavMobile,
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Breadcrumbs));