import React, { useState }  from 'react';
import { addPlugin } from '../../helpers/addPlugin';
import View from './view';
import Form from './form';

import {ReactComponent as Icon} from './icon.svg';
import { renderToString } from 'react-dom/server';
import { date_sql_to_rs } from '../../../../../helpers/dateHelper';

export const sliderPlugin = (editor, node) => {

   

    var pluginName      = 'slider_plugin', 
        pluginTitle     = 'Slider', 
        pluginFolder    = 'sliderPlugin',
        pluginIcon      = ''

    var initialData = {
    }

    var icon = renderToString(<Icon />);

    addPlugin(editor, node, Form, View, initialData, pluginName, pluginTitle, pluginFolder, icon,'100%', '100%',);
};