
import { 
    renderFieldInput,
} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateString,
    ValidateAddress,
    ValidateFileImage
} from '../helpers/validation';



export const orderFormConf = {
    fields: {
        'date_c' : {
            name: 'date_c',
            type: 'text',
            component: renderFieldInput,
            label: 'Datum',
            labelHide: true,
            validate:[],
            size: 'medium',
            value: '',
        },
        'date_t' : {
            name: 'date_c',
            type: 'text',
            component: renderFieldInput,
            label: 'Datum',
            labelHide: true,
            validate:[],
            size: 'medium',
            value: '',
        },
    },
    initialValues: {}
}