import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';

export default class List extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){

        e.stopPropagation();
        e.preventDefault();

        // var ins = new InsertNode(this.props.editor);
        // ins.wrap('ol','li');
        // this.props.editor.registerChange();
        // this.props.element.current.focus();
        document.execCommand('insertOrderedList', false, '');
        this.props.editor.registerChange();

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"List-btn-"+this.props.id} onClick={this.action}><i className="fa fa-list-ol" aria-hidden="true" ></i></button>
    }

}