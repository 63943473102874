import React from 'react';
{/*  
block_setting_from_input={true} ///when we remove input name(because we don't wanna set data directly from this form field to View),in parent component we can use name_helper,and set the data where we want..
*/}
export default class PadField extends React.Component {

    constructor(props){
        super(props);
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }


    onChange(e){
        if(this.props.onChange){
            this.props.onChange(e);
        }
    } 

    render(){
        let disabled=this.props.disabled ? disabled : "";
        return <div className={`form-group padd-form-group ${this.props.className ? this.props.className : ""}`}>
            <label>{this.props.label}</label>
            <div className="row padd-field">
                <div className="col col-4">
                    
                </div>
                <div className="col col-4">
                    <span>Top</span>
                    <input type="number" value={this.props.top.value} name={this.props.top.name} onChange={this.props.onChange} />
                </div>
                <div className="col col-4">
                    
                </div>
            </div>

            <div className="row padd-field">
                <div className="col col-4">
                    <span>Left</span>
                    <input type="number" value={this.props.left.value} name={this.props.left.name} onChange={this.props.onChange}  />
                </div>
                <div className="col col-4">
                    
                </div>
                <div className="col col-4">
                    <span>Right</span>
                    <input type="number" value={this.props.right.value} name={this.props.right.name} onChange={this.props.onChange}   />
                </div>
            </div>

            <div className="row padd-field">
                <div className="col col-4">

                </div>
                <div className="col col-4">
                    <span>Bottom</span>
                    <input type="number" value={this.props.bottom.value} name={this.props.bottom.name} onChange={this.props.onChange}  />
                </div>
                <div className="col col-4">
                    
                </div>
            </div>

           
        </div>
    }
}