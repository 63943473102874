import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";

import { Sync } from './../../../modules/orderindex';

class OrderIndex extends React.Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentDidMount(){
        console.log(this.props);
        this.props.Sync();
    }

    render(){

        return <div className="page-infodesk text-right">
            <span>Update status: <b>not updated</b></span>
        </div>

    }

}


const mapStateToProps = state => ({
    user: state.auth.user
  });
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        Sync
      },
      dispatch
    );
  
    export default withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrderIndex));