import React, { useState }  from 'react';
import { addPlugin } from '../../helpers/addPlugin';
import View from './view';
import Form from './form';


export const customPlugin = (editor, node) => {

    var pluginName      = 'example', 
        pluginTitle     = 'Custom Plugin', 
        pluginFolder    = 'customPlugin';

    var initialData = {
      title: 'Test',
      size:  '12px',
      bold:  '1'
    }

    addPlugin(editor, node, Form, View, initialData, pluginName, pluginTitle, pluginFolder, null);
};