import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field , formValueSelector , change  } from 'redux-form'
import { reduxAsyncValidate  } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";

import {setBusy} from '../modules/forms';
import { orderFormConf } from "./OrderFormConf";

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';

import './../assets/css/form.scss';
import { fetchHelper } from '../helpers/fetchHelper';
import { date_sql_to_rs } from '../helpers/dateHelper';
import { format_number } from '../helpers/stringHelper';
import moment from 'moment';
import PlainTable from "../libraries/PlainTable/PlainTable";

class OrderForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false
        }
        this.editors = {};
    }

    componentDidMount() {
        orderFormConf.index = this;
        this.loadData();
    }

    async loadData(){
        const { type, id } = this.props.match.params;
        if( id && type ){

            var result = await fetchHelper.get(`orders/get_products_by_order_id/${type}/${id}`);

            this.setState({data:result.status ? result.items : null},()=>{
                if(this.state.data && this.props.setOrder){
                    this.props.setOrder(this.state.data.order_id);
                }
            });
        }

    }

    componentDidUpdate(prevProps){

    }


    orderStatus(status, final_status){
  
        if(status && final_status === undefined){
            switch(status){
                case 1:
                    return 'Nezavršena';
                case 2:
                    return 'Nije obrađena';
                default:
                    return 'Nepoznat status';
            }
        }

        if(final_status){
            switch(final_status){
                case 1:
                    return 'Neispravna';
                case 2:
                    return 'Nije obrađena';
                case 3:
                    return 'U obradi';
                case 4:
                    return 'Završena';
                case 5:
                    return 'Završena/dodeljeni bodovi';
                case 6:
                    return 'Završena/korisnik nije završio registraciju';
                case 7:
                    return 'Greška'
                case 8:
                    return 'Poništena'
                default:
                    return 'Nepoznat status';
            }
        }
    }

    pointsRelease(order){

        if(order.final){
            if(order.final.transaction_date){
                var m = moment(order.final.transaction_date,'YYYY-MM-DD').add(15,'days');
                return m.format('DD.MM.YYYY')
            }
        }

        return 'Nema podatka'
    }


    validate(key1, key2, order){    
        var a = order[key1];
        var b = null;
        if(order.user){
            b = order.user[key2];
        }

        if(a && b && b != 'N/A'){
            if(a != b){
                return <i className="fa fa-exclamation-triangle alert-icon" aria-hidden="true"></i>
            }
        }

        return '';
    }


    shipping(order){
       
        return (<>
            <div className="card-field">
                <label>Ime:</label>
                <span>{order.first_name} {order.last_name} {this.validate('first_name','first_name', order)}</span>
            </div>
            <div className="card-field">
                <label>Adresa:</label>
                <span>{order.address_2}</span>
            </div>
            <div className="card-field">
                <label>Poš.broj:</label>
                <span>{order.postal}</span>
            </div>
            <div className="card-field">
                <label>City:</label>
                <span>{order.city} {this.validate('city','city', order)}</span>
            </div>
            <div className="card-field">
                <label>Email:</label>
                <span>{order.email} {this.validate('email','email', order)}</span>
            </div>
            <div className="card-field">
                <label>Phone:</label>
                <span>{order.phone}</span>
            </div>
            <div className="card-field">
                <label>Firma:</label>
                <span>{order.company}</span>
            </div>
            <div className="card-field">
                <label>Odgovorno lice:</label>
                <span>{order.company_person}</span>
            </div>
            <div className="card-field">
                <label>PIB:</label>
                <span>{order.PIB}</span>
            </div>
            <div className="card-field">
                <label>Račun:</label>
                <span>{order.bankaccount}</span>
            </div>
        </>);
                    
    }


    render() {

        const {  busy } = this.props;

        if(this.state.data === false) return 'Loading...';

        if(this.state.data === null) return 'Not found';

        const {data} = this.state;

        const cells = [
            {name:'Slika',cell: 'image',width:130,  className:'', onBefore: (value)=>{
                return <img src={value} />
            }},  
            {name:'Ean',cell: 'ean',width:130,  className:''},        
            {name:'Model',cell: 'model',width:150,  className:''},   
            {name:'Naziv',cell: 'name',width:'auto',  className:''},   
            {name:'Količina',cell: 'quantity',width:100,  className:''},   
            {name:'Jed. cena',cell: 'price',width:100,  className:'',onBefore: (value)=>{
                return format_number(value,0,'',',') + ' RSD'
            }},  
            {name:'Cena',cell: 'price_discounted',width:100,  className:'',onBefore: (value)=>{
                return format_number(value,0,'',',') + ' RSD'
            }},  
            {name:'Popust',cell: 'discount',width:100,  className:'',onBefore: (value)=>{
                return format_number(value,0,'',',') + ' RSD'
            }},  
          ]

        return (
            <>
             <form>
        
             <GigaTabs tab={0}>
                {/* GiaTab title, heigth */}
                <GigaTab title="Porudžbenica" /*height={400}*/>
                   
                        {busy ? <div className="form-mask"></div> : ''}
                        <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                            <Cell width={1} className="grid-column">
                                <div className="relative card">
                                    <h4>Podaci porudžbenice</h4>
                                    <div className="separate-right"></div>
                                    <div className="card-field">
                                        <label>ID:</label>
                                        <span>{data.order_id}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Kreiran iznos:</label>
                                        <span>{format_number( data.total_price, 0, '', ',')} RSD</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Finalni iznos:</label>
                                        <span>{data.final ?  (format_number( data.final.total_price, 0, '', ',') + 'RSD')  : ''}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>ID:</label>
                                        <span>{data.order_id}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Datum kreiranja:</label>
                                        <span>{date_sql_to_rs( data.date )}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Datum realizacije:</label>
                                        <span>{data.final ? date_sql_to_rs( data.final.transaction_date ) : ''}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>NIS kartica:</label>
                                        <span>{ data.nis_card ? data.nis_card : 'NE'}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Koristio bodove:</label>
                                        <span>{ data.use_points ? 'DA' : 'NE'}</span>
                                    </div>
                                    {data.use_points?(
                                        <div className="card-field">
                                            <label>Iskoristio bodova:</label>
                                            <span>{ data.points }</span>
                                        </div>
                                    ):''}
                                    <div className="card-field">
                                        <label>Osvaja bodova:</label>
                                        <span>{ data.earn_points}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Tip korisnika:</label>
                                        <span>{ data.type}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Status:</label>
                                        <span><b>{this.orderStatus(data.status, data.final ? data.final.status: undefined)}</b></span>
                                    </div>
                                    <div className="card-field">
                                        <label>Vreme dobijanja bodova:</label>
                                        <span><b>{this.pointsRelease(data)}</b></span>
                                    </div>
                                </div>
                            </Cell>
                            <Cell width={1} className="grid-column">
                            <div className="relative card">
                                    <h4 className={data.user && data.user.id === 2 ? 'alert':''}>Podaci o korisniku</h4>

                                    {data.user ? data.user.id === 2 ? (
                                        <div className="card-field">
                                            <label></label>
                                            <span>Neregistrovan korisnik</span>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="separate-right"></div>
                                            <div className="card-field">
                                                <label>ID:</label>
                                                <span>{data.user.id}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Barkod:</label>
                                                <span>{data.user.barcode}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Ime:</label>
                                                <span>{data.user.first_name}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Prezime:</label>
                                                <span>{data.user.last_name}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Adresa:</label>
                                                <span>{data.user.address}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Broj stana/kuće:</label>
                                                <span>{data.user.street_num ? data.user.street_num:''}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>P.broj:</label>
                                                <span>{data.user.postal_code}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Grad:</label>
                                                <span>{data.user.city}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Datum registracije:</label>
                                                <span>{date_sql_to_rs( data.user.login_date )}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Datum prijave:</label>
                                                <span>{data.user.login_date ? date_sql_to_rs( data.user.login_date ) : ''}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Email:</label>
                                                <span>{ data.user.email}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Telefon:</label>
                                                <span>{ data.user.phone}</span>
                                            </div>
                                            <div className="card-field">
                                                <label>Bodova:</label>
                                                <span>{ data.user.points}</span>
                                            </div>
                                            {data.user.type === 2?(
                                                <>
                                                    <h5>Podaci o kompaniji</h5>
                                                </>
                                            ):''}
                                        </>
                                    ):(
                                        <div className="card-field">
                                            <label>Greška:</label>
                                            <span>Podaci o korisniku nisu dostupni</span>
                                        </div>
                                    )}
                                </div>
                            </Cell>
                            <Cell width={1} className="grid-column">
                                <div className="relative card">
                                    <h4>Podaci o dostavi i načinu plaćanja</h4>
                                    <div className="separate-right"></div>
                                    <div className="card-field">
                                        <label>Način plaćanja:</label>
                                        <span>{data.payment}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Način dostave:</label>
                                        <span>{data.shipping}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Auth. kod:</label>
                                        <span>{data.auth_code}</span>
                                    </div>
                                    <div className="card-field">
                                        <label>Tip kartice:</label>
                                        <span>{data.card_type}</span>
                                    </div>
                                    <h5>Podaci o dostavi</h5>
                                    {this.shipping(data)}
                                    <h5>Lokacija</h5>
                                    <div className="card-field">
                                        <label>Prodavnica:</label>
                                        <span>{data.location_id}</span>
                                    </div>
                                </div>
                            </Cell>
                            <Cell width={1} className="grid-column">
                                <div className="relative card">
                                    <h4>Ostali podaci</h4>
                                    <div className="separate-right"></div>
                                    <div className="card-field">
                                        <label>Napomena:</label>
                                        <span>{data.order_note}</span>
                                    </div>
                                </div>
                            </Cell>
                        </Grid>
                      
                </GigaTab>
                <GigaTab title="Sadrzaj poručeno">
                    <Grid columns={1} gap="30px">
                        <Cell width={1} className="grid-column">
                            <div className="relative">
                                <PlainTable itemsPerPage={10} height={"600px"} cells={cells} rows={data.items} /> 
                            </div>
                        </Cell>
                    </Grid>
                </GigaTab>
                <GigaTab title="Sadržaj finalno">
                    <Grid columns={1} gap="30px">
                        <Cell width={1} className="grid-column">
                            <div className="relative">
                                <PlainTable itemsPerPage={10} height={"600px"} cells={cells} rows={data.items_final} /> 
                            </div>
                        </Cell>
                    </Grid>
                </GigaTab>
                <GigaTab title="Istorijat">
                    <Grid columns={1} gap="30px">
                        <Cell width={1} className="grid-column">
                            <div className="relative">
                                <PlainTable itemsPerPage={10} height={"600px"} cells={cells} rows={data.items_final} /> 
                            </div>
                        </Cell>
                    </Grid>
                </GigaTab>
            </GigaTabs>
            </form>
            </>
        );
    }
}


const selector = formValueSelector('productform');

const mapStateToProps = state => ({
    initialValues: orderFormConf.initialValues,
    busy: state.forms.busy,
});
  
const mapDispatchToProps = dispatch =>
bindActionCreators(
    {
        setBusy,
        updateField: (field , data) => change("productform" , field , data),
    },
    dispatch
);

OrderForm = reduxForm({
    form: "productform",
    reduxAsyncValidate,
    enableReinitialize: true,
  })(OrderForm);
  OrderForm = connect(
      mapStateToProps, mapDispatchToProps
  )(OrderForm);
  export default OrderForm;