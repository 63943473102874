import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "./../../../helpers/fetchHelper";
import GigaTable from './../../../libraries/GigaTable';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      table: null,
      active_user:null
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);

  }

  componentDidMount(){
    
  }

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_user:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_user:row,selected:row.id})
  }


  resetActive() {
    //ne bacati na runRow 
    this.setState({active_user:null,selected:null})
  }
  render() {
    const cells = [
      {name:'ID',cell: 'id',width:90, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Naziv',cell: 'name',width:200, fixed:true, className:'wraptext'},
      // {name:'Datum kreiranja',cell: 'date_created', width:180,fixed:false,editable:false,className:'draggable', onBefore:(value)=>{
      //   return date_sql_to_rs(value);
      // }, onSave:(value)=>{
      //   return date_rs_to_sql(value);
      // }},
      {name:'Datum izmene',cell: 'date_modify', width:180,fixed:false,editable:false,className:'draggable', onBefore:(value)=>{
        return date_sql_to_rs(value);
      }, onSave:(value)=>{
        return date_rs_to_sql(value);
      }},
      {name:'Grupe', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return row.id !== 1499709 ? <button onClick={()=>{ this.setState({active_user:row,selected:row.id}) }}><Link target='_blank' to={{pathname:`/admin/products/categories/${row.id}`, search:`?master_id=${row.master_id}`}}><i className="fa fa-folder-open-o" /></Link></button> : ''
      }},
      {name:'Edit', cell:'custom2', custom:true, width:55, align:'center', render:(row)=>{
        return row.id !== 1499709 ? <button onClick={()=>{ this.setState({active_user:row,selected:row.id}) }}><Link target='_blank' to={`/admin/products/category/edit/${row.id}`}><i className="fa fa-edit"></i></Link></button> : ''
      }},
      {name:'Status', cell:'custom3', custom:true, width:55, align:'center', render:(row)=>{
        var value = parseInt(row.status); 
        return row.id !== 1499709 ? <button onClick={()=> this.table.updateRowCell(row,'status',value?0:1)}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button> : ''
      }},
    ];

    const buttons = [
      {title: 'Nova kategorija',target:'_blank', type: 'link', link:'/admin/products/add-category'},
    ];
    const fetch = `categorization/get_categories`
    
    return (
      <>
        <div className="admin-home__main-section">
      <h1>Kategorije</h1>
        <div className="table-wrapper">
          <GigaTable 
            cells={cells} 
            pk='id' 
            height={300} 
            buttons={buttons}
            id='products-table' 
            db="gigatron_product_data"
            fetch={fetch}
            delete={true} 
            selected={this.state.selected}
            getInstance={this.getTableInstance}
            run={this.runRow}
            resetActive={this.resetActive}
          />
        </div>
      </div>
      </>
    );
  }
}



  export default withRouter(Categories);