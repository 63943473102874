import React from 'react';
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
{/* <InputRangeField
        label={"Name"}
        name={"name"}
        maxValue={20}
        minValue={10}
    /> */}

export default class InputRangeField extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            value: 0, 
        }
    
    }

    componentDidMount(){
        let min=this.props.minValue;
        if(min){
            this.setState({value:min})
        }
    }

    render(){
        let p=this.props;
        if(p.name&&p.label){
            return <div className="form-group">
            <label>{this.props.label}</label>
            <div className="input_range_field">
                <InputRange 
                    name={this.props.name}
                    maxValue={this.props.maxValue ? this.props.maxValue : 10 }
                    minValue={this.props.minValue ? this.props.minValue :  0 }
                    onChange={value => this.setState({ value })}
                    value={this.state.value}
                />
            </div>
            <br/>
        </div>
        }
        else{
            return "";
        }   
    }
}