import React, { Component } from "react";
import {
  Link,
  withRouter
} from "react-router-dom";
import $ from "jquery";
 
import Stickers from "./ItemElements/Stickers";
import Price from "./ItemElements/Price";
import { site_url,env } from '../../../../../../config/vars'; 

export default class Item extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          ids:[],
      };
  
    }

    is_link(element,product){
        let is_link=element.is_link; 
        if(is_link){
            let slug="";
            let element_name=element.element_name;
            if(element_name=="product_type"){
                // return slugify(this.props.product.category) + (this.props.product.group_name ? '/' + slugify(this.props.product.group_name) : '') + '/' + slugify(this.props.product.subcategory);
                let category=product.category;
                let subcategory=product.subcategory;
                let group_name=subcategory&&subcategory.parent ? "/"+subcategory.parent.slug : "";

                let sub_url=category.slug + group_name + '/' + subcategory.slug;
                slug="/"+sub_url; 

            }
            else if(element_name=="brand_image"){
                slug="/pretraga?pojam="+product.brand.name; 
            }
            else{
                slug=product.url; 
            }
            window.location=site_url+slug;
            
        }
    }

    component(element,product,i){
        let events={
            onClick:()=>{ return this.is_link(element,product)}
        } 
        let key=element.element_name+i;
        let content={
            "product_image":()=>{
                let src=product&&product.image&& product.image.sizes ? product.image.sizes.medium : "";
                return <div
                        className={element.element_name}
                        key={key}
                        style={element.css}
                        {...events}
                    >  
                        <img itemProp="image" alt={product.title} src={src}  
                        style={{ 
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        maxWidth: "100%",
                        maxHeight:"100%"}}
                        /> 
                </div>
            },
            'energy': ()=>{
                let energy="";
                if (product.energy_sticker&&product.energy_sticker.color) {
                    energy = <div 
                    className={element.element_name+i}
                    key={key}
                    style={element.css}
                    {...events}
                    >  
                        <div style={{ 
                            backgroundColor: product.energy_sticker.color,
                            borderColor: product.energy_sticker.color,
                            paddingTop:"4px", 
                            clipPath: "polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)"}}>
                            <div>
                                <img src={product.energy_sticker.img} alt="Energetska klasa" 
                                style={{height:"22px"}}
                                />
                            </div>
                        </div>
                    </div>
                }
                return energy;
            },  
            "product_item_name":()=>{
                return <h4 
                    className={element.element_name}
                    key={key}
                    style={element.css}
                    {...events}
                    > 
                        {product.title}
                    </h4> 
            },
            "product_type":()=>{
                let d=product.declaration;
                return <p 
                className={element.element_name}
                key={key}
                style={element.css}
                {...events}
                >  
                    {d&&d.type ? d.type.data : ""}
                </p>
            },
            "brand_image":()=>{
                return <div
                className={element.element_name}
                key={key}
                style={element.css}
                {...events}
                >  
                    <img 
                    src={product.brand ? product.brand.img : ""}
                    style={{ 
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        height:"auto",
                        
                    }}/>
                </div>
            },
            "show_more_button":()=>{
                return  <div
                className={element.element_name}
                key={key}
                style={element.css}
                {...events}
                >  
                Saznaj vise {">"}</div>
            },
            "stars":()=>{
                var rating = 0;  
                if (product.statistic_rating === undefined) {
                rating = Math.ceil(parseFloat(product.rating)); 
                } else {
                rating = Math.ceil(parseFloat(product.statistic_rating)); 
                }
                return    <div  
                className={element.element_name}
                key={key}
                style={element.css}
                {...events}
                >   
                    {/* <ReactStars  
                    className="stars" 
                    count={5} 
                    value={rating} 
                    size={22} 
                    edit={false} 
                    color2={"#ffd700"}
                    half={false} 
                    />  */}
                    {Array(5).fill().map((item, i)=>{
                        return <span style={{ whiteSpace: "nowrap",color: i+1 <= rating ? "#ffd700":"grey", 
                        // fontSize: "22px"
                    }} key={"star"+i}>★</span>
                    })}  
                </div>
            },
            "votes":()=>{
                var votes = 0; 
                if (product.statistic_rating === undefined) { 
                votes =product.rating_count;
                } else { 
                votes = product.statistic_votes;
                }
                return  <span  
                className={element.element_name}
                key={element.element_name}
                style={element.css}
                {...events}
                > 
                ( {votes} )
                </span>
            },
            "favorite":()=>{
                return <div   
                className={element.element_name}
                key={key}
                style={element.css}
                {...events}
                > 
                <span 
                    className={"fa fa-heart"}  
                    style={{
                        font:"normal normal normal 18px/1 FontAwesome",
                        display:"inline-block",
                        backgroundImage: "url(" + "https://i.ibb.co/Hgpq8yF/fa-fa-heart.png" + ")",
                        backgroundPosition: 'center',
                        backgroundSize: '18px',
                        backgroundRepeat: 'no-repeat',
                        height: "18px",
                        width: "18px",
                        color:"#ccc"
                    }}>
                    </span>
                </div>
            },
            "compare":()=>{
                return <div   
                className={element.element_name}
                key={key}
                style={element.css}
                {...events}
                >  
                    <span 
                    style={{
                        border: "1px solid #ccc",
                        height: "18px",
                        width: "18px",
                        display: "inline-block",
                        position: "relative",
                        borderRadius: "4px",
                        cursor: "pointer"
                    }}
                    />
                    <span style={{   
                        position: "absolute",
                        left: "28px",
                        textTransform: "uppercase",
                        fontSize: "13px",
                        color: "#444",
                    }}>UPOREDI</span>
                </div>
            },
            "sticker_top":()=>{
                return  <Stickers key={key} style={element.css} item={product} position="top"/>  
            },
            "sticker_bottom":()=>{
                return  <Stickers key={key} style={element.css} item={product} position="bottom"/> 
            },
            
            "old_price":()=>{
                return <Price key={key} style={element.css} price_type={element.element_name} product={product} /> 
            },
            "saving_price":()=>{
                return <Price key={key} style={element.css} price_type={element.element_name} product={product} /> 
            },
            "main_price":()=>{
                return <Price key={key} style={element.css} price_type={element.element_name} product={product} /> 
            },

            "price_helper":()=>{
                return <Price key={key} style={element.css} price_type={element.element_name} product={product} /> 
            },

        };

        if(content[element.element_name]){
            return content[element.element_name]();
        }
        else{
            alert("ERROR")
        }
    }
 

    deepNesting(html,product){
        
        return html.content.map((element,i)=>{  
            if(element.element_name.includes("wrapper")){
                return <div className={element.element_name} key={element.element_name+i} 
                        style={{
                            display:"flex",
                            // whiteSpace:"unset",
                            flexWrap:element.nowrap ?  "nowrap"  : "wrap",
                            flexDirection:element.wrapper_direction=="v" ? "column" : "row",
                            ...element.css
                        }}
                    >
                    {this.deepNesting(element,product)}
                </div>
            
            }
            else{ 
                return this.component(element,product,i); 
            }
            
        })
    }
    render() { 
        let product=this.props.data;
        let html=this.props.item_html; 
        let id=this.props.id;
        let style={
            position:"relative",margin:"0px auto",
            display:"flex",
            flexWrap:html.nowrap ?  "nowrap"  : "wrap",
            flexDirection:html.wrapper_direction=="v" ? "column" : "row",
            ...html.css

        };
     
        if(!product||!html) return;
        return (
            <div 
            key={product.product_id} 
            data-id={product.product_id}
            id={"product-" + product.product_id}
            style={{...style}}
            >
                {this.deepNesting(html,product)}               
            </div>
          
        );
    }
}
 

 





 
 

 
