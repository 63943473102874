import React from 'react';
import { date_rs_to_sql, date_sql_to_rs } from '../../../../../helpers/dateHelper';
import { b64EncodeUnicode } from '../../helpers/base64';
import './style.scss';

class View extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            start: null,
            end: null
      };

      this.intval = null;
      this.interval = this.interval.bind(this);
      this.timer = this.timer.bind(this);
    }


    time(value){
        var v = parseInt(value);
        if(v < 10){
            return '0'+v;
        }

        return value;
    }

    componentWillMount(){
       
        this.timer();
        
        
       
    }


    componentDidMount(){
        this.interval();
    }


    timer(){

        const {data} = this.props;

        var end = data.end_date ? new Date(date_rs_to_sql(data.end_date)).getTime() : null;

        if(!end){
            return;
        }


        var countDownDate = end;

        // Update the count down every 1 second
       

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.setState({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        },() => {
            // console.log(this.state)
        });
    }



    interval(){
        clearInterval( this.intval );
        this.intval = setInterval(this.timer,1000);
    }

    componentWillUnmount(){
        clearInterval( this.intval );
    }


    view(data){

        var start   = data.start_date ? new Date(date_rs_to_sql(data.start_date)).getTime() : null;
        var end     = data.end_date ? new Date(date_rs_to_sql(data.end_date)).getTime() : null;
        var now     = new Date().getTime();

        if(start && start > now){
            return <div className="timer-info timer-start" >Akcija počinje {data.start_date.replace(/\s+/,' godine u ')+' sati.'}</div>;
        }

        if(end && end < now){
            return <div className="timer-info timer-end">Akcija se završila {data.end_date.replace(/\s+/,' godine u ')+' sati.'}</div>;
        }
        
        if(!end){
            return <div className="timer-info timer-error">!!!Timer Error!!!</div>;
        }

        return <div className="mce-plugin-timer" style={{backgroundColor:data.backgroundColor}}>
            <h5 style={{color:data.titleColor}}>{data.count_start_label}</h5>
            <em style={{color: data.labelColor}}>Dana<b style={{color: data.counterColor}}>{this.time(this.state.days)}</b></em>
            <em style={{color: data.labelColor}}>Sati<b style={{color: data.counterColor}}>{this.time(this.state.hours)}</b></em>
            <em style={{color: data.labelColor}}>Minuta<b style={{color: data.counterColor}}>{this.time(this.state.minutes)}</b></em>
            <em style={{color: data.labelColor}}>Sekundi<b style={{color: data.counterColor}}>{this.time(this.state.seconds)}</b></em>
        </div>

    }

    render(){

        const { data, folder } = this.props;
        const json = JSON.stringify(data);
        

         /* !!! obavezno:
             tab="0" 
             className="mceNonEditable"
              data-controller="plugin" 
              data-plugin={folder}  
              data-json={json} 
        */
        return <div 
            tab="0" 
            className="mceNonEditable" 
            data-controller="plugin" 
            data-plugin={folder}  
            data-json={b64EncodeUnicode(json)} 
        >{this.view(data)}</div>
    }
}

export default View;