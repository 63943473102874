import React from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';

class Draggable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isActive: false,
        }
        this.ref = React.createRef();

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp   = this.onMouseUp.bind(this);

        this.startX = 0;
        this.nodeX = 0;

        this.x = 0;
        this.y = 0;

        this.set = null;
    }

    saveSession(data){
        localStorage.setItem('SORTCELL_'+this.props.id, data);
    }

    onMouseDown(e){
        if(e.currentTarget.classList.contains('draggable')){
            this.startX = (e.clientX);
            this.nodeX = this.props.node.current.offsetLeft;
            this.setState({isActive:true});
        }
    }

    onMouseMove(e){
        if(this.state.isActive){

            var el = e.currentTarget;
            
            this.x = e.clientX;
            this.y = e.clientY;

            var diff = this.startX - e.clientX;

            if(e.movementX < 0){
                this.moveRight(diff);
            }
            if(e.movementX > 0){
                this.moveLeft(diff);
            }
        }
    }

    onMouseUp(e){
        if(this.state.isActive){
            this.setState({isActive:false});
            this.ref.current.style.left = 0+'px';
            var c = this.props.node.current.children;
            $(c).removeClass('dragover');

            if(this.set){
                var key = this.ref.current.dataset.id;
                var cells = this.props.cells;

                var nc = [];
                var master = null;
                var after = null;
                cells.map(cell=>{
                    if(cell.cell === key){
                        master = cell;
                    }
                    if(cell.cell === this.set){
                        after = cell;
                    }
                })

                cells.map(cell=>{
                    if(cell.cell !== master.cell && cell.cell !== after.cell){
                        nc.push(cell);
                    }

                    if(cell.cell === after.cell){
                        nc.push(after);
                        nc.push(master);
                    }
                });

                this.set = null;
                this.props.index.setState({cells:nc});
                
                var forSession = [];
                nc.map((a)=>{
                    forSession.push(a.cell);
                });
                this.saveSession(forSession);
            }
        }
    }

    moveLeft(x){
        this.ref.current.style.left = -(x) + 'px';
        this.overEl();
    }

    moveRight(x){
        this.ref.current.style.left = -(x) + 'px';
        this.overEl();
    }

    overEl(){
        var x = this.x;
        var y = this.y;
        var minus = parseInt(this.ref.current.width);
      
        var nodes = this.props.node.current.children;
        if(nodes.length){
            for(var n in nodes){
                var node = nodes[n];
              
   
                if((node.classList && !node.classList.contains('dragged')) && node.classList.contains('draggable')){
                    var cX = node.offsetLeft;
                    var cE = cX + parseInt(node.width) + minus;

                    if(x-minus > cX && x < cE){
                        $(node).addClass('dragover');
                        this.set = node.dataset.id;
                    } else {
                        $(node).removeClass('dragover');
                    }
                }
                
            }
        }
    }

    render() {
    let p=this.props;
    return (
        <th 
        // {...this.props} 
        align={p.align}
        className={p.className}
        data-id={p["data-id"]}
        id={p.id}
        width={p.width}

        onMouseDown={this.onMouseDown} 
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp} 
        onMouseOut={this.onMouseUp}
        ref={this.ref} 
        className={(this.state.isActive?'dragged ':'')+(this.props.className!==undefined?this.props.className:'')}
        >
          {this.props.children}
        </th>
      )
    }
  }

  export default Draggable;