import React from 'react';
import 'rc-color-picker/assets/index.css';
import ColorPicker from 'rc-color-picker';
var colorString = require("color-string");

//usecase in parent:

//<ColorPickerField 
// color={on start, color can be in any tested format,best to stick to name,rgb,and rgba}
// name="items_wrapper_background_color"  
// block_setting_from_input={true}    
// enableAlpha={true} 
// onChange={this.onColorChangeLastStep} 
// set_edit_container_inside_div_with_this_id={if you wanna move it to some other container} 
// />

//color_handeling=>on start we divide full color to color and alpha(this.getColorParts()) so  <ColorPicker/> can use it,then on change we convert it to rgba(rgba(0, 0, 255, 0.1) and pass it to ColorPickerField parent component;
//data_saving=>data saving from <Form/> to <View/> is done thru input field(<input name="bck_color" value="red" />) for each input(so View data gets a list of names and values,for every input that has a name);
//if we wanna use ColorPickerField data in parent component without saving it thru ColorPickerFiled input field, we can just add  block_setting_from_input={true};
//we can then use color data in parent component as we wish(pass it to View in some other Form input field(as single value or group it),or not pass it at all); 

export default class ColorPickerField extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(colors){
        let full_color=colorString.get.rgb(colors.color);
        full_color[3]=colors.alpha/100;  
        full_color=colorString.to.rgb(full_color); 
        if(this.props.onChange){ 
            var e = {[this.props.name] : full_color }
            this.props.onChange(e);
        }
    }


    getColorParts(name){ 
        // start color test
        // let named=colorString.get('blue')
        // let rgb=colorString.get('rgb(0, 0, 255)') 
        // let rgba=colorString.get('rgba(0, 0, 255, 0.1)')  
        // let hex=colorString.get('#0000ff')                     
        // let hex_transparent=colorString.get('#0000ffdb')                         
        // let hsl=colorString.get('hsl(240, 100%, 50%)') 
        // let hsla=colorString.get('hsla(240, 100%, 50%, 0.4)')        
        // let hwb=colorString.get('hwb(240, 0%, 0%)')  
        // console.log("named:",named, '\n',
        // "rgb:",rgb, '\n',
        // "rgba:",rgba, '\n',
        // "hex:",hex, '\n',
        // "hex_transparent:",hex_transparent, '\n',
        // "hsl:",hsl, '\n',
        // "hsla:",hsla, '\n',
        // "hwb:",hwb)  
        let col=this.props.color ? this.props.color : "transparent";
        if(col&&this.props.name){ 
            let full_color=colorString.get(col).value;
            if(name=="color"){
                full_color.pop(); 
                let color=colorString.to.hex(full_color); 
                return color;
            }
            else{
                let alpha=full_color[3]*100; 
                return alpha;
            }
        }

    }


    render(){
        return <div className={`form-group ${this.props.className||""}`} id={this.props.name}>
            <label>{this.props.label}</label>
                {this.props.block_setting_from_input  ? "" : <input type="text" name={this.props.name} value={this.props.color}  style={{display:'none'}} readOnly/>}
            <ColorPicker 
                getCalendarContainer={()=>{return this.props.set_edit_container_inside_div_with_this_id ? document.getElementById(this.props.set_edit_container_inside_div_with_this_id) :  document.body }}   
                onChange={this.onChange} color={this.getColorParts("color")} 
                alpha={this.getColorParts("alpha")} 
                enableAlpha={this.props.enableAlpha}
                />
        </div>
    }
}