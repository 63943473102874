import React, {Component} from 'react';

export default class AlignLeft extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){
        // if(this.props.editor.state.active){
        //     this.props.editor.state.active.style.textAlign = 'left';
        //     this.props.editor.registerChange();
        // }
        e.stopPropagation();
        e.preventDefault();

        document.execCommand('justifyLeft');
        this.props.editor.registerChange();

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"AlignLeft-btn-"+this.props.id} onMouseDown={this.action}><i className="fa fa-align-left" aria-hidden="true" ></i></button>
    }

}