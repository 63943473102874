import React, { Component } from "react";
import { Link, Route, Switch,Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";

import ProtectedAdminRoute from './../components/routes/ProtectedAdminRoute';
import ProtectedUserRoute from './../components/routes/ProtectedUserRoute';
import AdminHome from './../components/adminPanel/home/AdminHome';
import Login from './pages/Login';
import Register from './../components/AddUserForm';

import {getUser,setUser} from './../modules/auth';

import Users from './pages/administrator/Users';
import Vouchers from './pages/administrator/Vouchers';
import VouchersCreate from './pages/administrator/VouchersCreate';
import UsersBoughtProducts from './../components/adminPanel/users/UsersBoughtProducts';
import AddProductForm from './../components/adminPanel/products/AddProductForm';
import EditProductForm from './../components/adminPanel/products/EditProductForm';
import Messages from './../components/adminPanel/messages/Messages';
import EditAdminProfileForm from './../components/adminPanel/profile/EditAdminProfileForm';
import ProductList from './../components/shop/ProductList';
import Dashboard from './../components/adminPanel/home/Dashboard';
import Product from '../routes/pages/administrator/Product';
import Groups from '../routes/pages/administrator/Groups';
import SubCategories from '../routes/pages/administrator/SubCategories';
import CategorizationEdit from '../routes/pages/administrator/CategorizationEdit';
// import Categorization from '../routes/pages/administrator/Categorization';
import ProductDetails from '../routes/pages/administrator/ProductDetails';
import Pages from './pages/administrator/Pages';
import GalleryItemDetails from './pages/administrator/GalleryItemDetails';
import Sliders from './pages/administrator/Sliders';
import Orders from "./pages/administrator/Orders";
import Points from "./pages/administrator/Points";
import OrderDetails from "./pages/administrator/OrderDetails";
import BackofficeUsers from "./pages/administrator/BackofficeUsers";
import BUGroupsAndPermissions from "./pages/administrator/BUGroupsAndPermissions"; 
import Articles from './pages/administrator/Articles';
import ArticlesDemo from './pages/administrator/ArticlesDemo';
import BackofficePages from "./pages/administrator/BackofficePages";
import FileTypes from "./pages/administrator/Morph/FileTypes";
import FileTypesItems from "./pages/administrator/Morph/FileTypesItems"; 
import Categories from "./pages/administrator/Categories";
import Stickers from "./pages/administrator/Stickers";
import StickersEdit from "./pages/administrator/StickersEdit";
import CategoryWidget from "./pages/administrator/action/category/VB";
import Blank from "./test/blank";


const API = `http://localhost:5000/`;

class Routes extends Component {
  constructor(props) {
    super(props);

    const user = props.user;

  }

  componentDidMount(){

  }


  componentDidUpdate(){
    
  }





  render() {

    const { pathname } = this.props.location;
    let user=this.props.user;

    if(user === null){
      return <Switch>
        <Route path="/login" render={props => <Login {...props} url={`auth/login`} />}/>
        <Route render={() =><Redirect to={"/login"} /> } />
      </Switch>
    }
    return <Switch>
            <Route path={`/admin/promotions/stickers/edit/:id`} exact render={props => ( <StickersEdit {...props} url={API}  />)}/>
            <Route path={`/admin/promotions/stickers`} exact render={props => ( <Stickers {...props} url={API}  />)}/>
            <Route path={`/admin/users`} exact render={props => <Users {...props} url={API} />} />
            <Route path={`/admin/users/orders/details/:type/:id`} exact render={props => <OrderDetails {...props} url={API} />} />
            <Route path={`/admin/users/orders`} exact render={props => <Orders {...props} url={API} />} />
            <Route path={`/dashboard`} exact render={props => <Dashboard {...props} url={'/admin'} />} />
            <Route path={`/admin/products/add`} render={props => <AddProductForm {...props} url={`/admin/products`} />} />
            <Route path={`/admin/products/add-category`} exact render={props => ( <CategorizationEdit {...props} url={API}  />)}/>                    
            <Route path={`/admin/products/add-group`} exact render={props => ( <CategorizationEdit {...props} url={API}  />)}/>                    
            <Route path={`/admin/products/add-subcategory`} exact render={props => ( <CategorizationEdit {...props} url={API}  />)}/>                    
            <Route path={`/admin/products/:id/edit`} exact render={props => (<ProductDetails {...props} url={API+'products'} /> )} />
            <Route path={`/admin/products/category/edit/:id`} exact render={props => ( <CategorizationEdit {...props} url={API}  />)}/> 
            <Route path={`/admin/products/group/edit/:id`} exact render={props => ( <CategorizationEdit {...props} url={API}  />)}/>                    
            <Route path={`/admin/products/subcategory/edit/:id`} exact render={props => ( <CategorizationEdit {...props} url={API}  />)}/>
            <Route path={`/admin/products/categories`} exact render={props => ( <Categories {...props} url={API}  />)}/>                    
            <Route path={`/admin/products/categories/:category`} exact render={props => ( <Groups {...props} url={API}  />)}/>                    
            <Route path={`/admin/products/categories/:category/:group`} exact render={props => ( <SubCategories {...props} url={API}  />)}/>                    
            <Route path={`/admin/products/:catMasterId/:id`} exact render={props => ( <Product {...props} url={API}  />)}/>                    
            {/* <Route path={`/admin/products/categories/:category/:group/:subcategory`} exact render={props => ( <Groups {...props} url={API}  />)}/>                     */}
            <Route path={`/admin/gallery-item/:id`} exact render={props => (<GalleryItemDetails {...props}/> )} />
            <Route path={`/admin/messages`} render={props => <Messages {...props} url={API} />} />
            <Route path={`/admin/news/pages/:name/:id`} exact render={props => <Pages {...props} />} />
            <Route path={`/admin/news/cat-widget`} exact render={props => <CategoryWidget {...props} />} />
            <Route path={`/admin/sliders`} render={props =>  <Sliders {...props} url={API}  />} />
            <Route path={`/admin/points`} render={props =>  <Points {...props} url={API}  />} />
            <Route path={`/admin/backoffice-users`} render={props =>  <BackofficeUsers {...props} url={API}  />} />
            <Route path={`/admin/backoffice-users-groups-and-permissions`} render={props =>  <BUGroupsAndPermissions {...props} url={API}  />} /> 
            
            <Route path={`/admin/articles/demo`} render={props => <ArticlesDemo /> }/>
            <Route path={`/admin/articles`} render={props => <Articles /> }/>
            <Route path={`/admin/backoffice-pages`} render={props =>  <BackofficePages {...props} url={API}  />} /> 
            <Route path={`/admin/product/attributes/:file_id/items`} render={props =>  <FileTypesItems {...props} url={API}  />} /> 
            <Route path={`/admin/product/attributes`} render={props =>  <FileTypes {...props} url={API}  />} /> 
            <Route path={`/admin/promotions/vouchers`} render={props =>  <Vouchers {...props} url={API}  />} /> 
            <Route path={`/admin/promotions/new`} render={props =>  <VouchersCreate {...props} url={API}  />} /> 

            <Route path={`/blank/page`} render={props =>  <Blank {...props} url={API}  />} /> 
            {/*....^old versions*/}

    </Switch>
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser,
      setUser,
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Routes));