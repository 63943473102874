import $ from 'jquery';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderGigaEditor,
    renderDatePicker,
 

} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidateFileImage,
    ValidateDate,
    ValidateString,
    
} from '../helpers/validation';
import AlertyBox from "../libraries/AletyBox";
import { fetchHelper } from "../helpers/fetchHelper";
import { reducer , change as changeFieldValue, change } from "redux-form";
import { 
    server_img_host,
    page_icon_path,
    page_icon_mobile_path, page_thumb_path, page_image_path
 } from './../config/vars';


//todo css, size ,editor..mobilni content?slike.
//validacija za decimale i  datume koji nisu obavezni.
export const pagesConf = {
    fields: {
        'id' : {
            name: 'id',
            type: 'text',
            component: renderFieldInput,
            label: 'ID',
            size: 'small',
            value: '1',
            readonly:true,
        },
        'master_id' : {
            name: 'master_id',
            component: renderFieldInput,
            label: 'Master ID',
            size: 'small',
            value: '',
            readonly:true,
            
        },
        'section_id' : {
            name: 'section_id',
            component: renderFieldInput,
            label: 'Section ID',
            size: 'small',
            value: '',
            maxLength:"2",
            readonly:true,
             
        },
        'vendor_id' : {
            name: 'vendor_id',
            component: renderFieldInput,
            label: 'Vendor ID',
            validate:[ValidateNumeric],
            size: 'small',
            value: '',
            maxLength:"10",
             
        },
        'promocode_id' : {
            name: 'promocode_id',
            component: renderFieldInput,
            label: 'Promokod',
            validate:[ValidateNumeric],
            size: 'small',
            value: '',
            maxLength:"20",
        },
        'category_id' : {
            name: 'category_id',
            component: renderFieldInput,
            label: 'Produkt ID',
            validate:[ValidateNumeric],
            size: 'small',
            value: '',
            maxLength:"10",             
        },

        //
        'name' : {
            name: 'name',
            type: 'text',
            component: renderFieldInput,
            validate:[ValidateRequire],
            label: 'Ime',
            size: 'large',
            value: '',
            maxLength:"60",
        },
        'title' : {
            name: 'title',
            type: 'text',
            component: renderFieldInput,
            label: 'Naslov',
            size: 'large',
            value: '',
            maxLength:"60",
        },
        'description' : {
            name: 'description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Opis',
            size: 'large',
            height: 100,
            value: '',
        },
 
        'meta_title' : {
            name: 'meta_title',
            type: 'text',
            component: renderFieldInput,
            label: 'Meta tag-naslov',
            size: 'large',
            value: '',
            maxLength:"240",
        },
        'meta_keywords' : {
            name: 'meta_keywords',
            component: renderFieldTextarea,
            label: 'Meta tag-ključne reci',
            size: 'large',
            height: 100,
            maxLength:"240",
        },
        'meta_description' : {
            name: 'meta_description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Meta tag-opis stranice',
            size: 'large',
            height: 100,
            value: '',
            maxLength:"300",
        },
        'content' : {
            name: 'content',
            component: renderGigaEditor,
            label: 'Sadržaj stranice',
            height: 500,
            value: '',
            contentid:'',
            index: null,
            url: 'http://ozone.devgigatron.com/',
            img_list_url:'http://ozone.devgigatron.com/img/gigaapi/',
        },
        'content_mobile' : {
            name: 'content_mobile',
            component: renderGigaEditor,
            label: 'MOB-Sadržaj stranice',
            height: 500,
            value: '',
            contentid:'',
            index: null,
            url: 'http://ozone.devgigatron.com/',
            img_list_url:'http://ozone.devgigatron.com/img/gigaapi/',
        },
        'status' : {
            name: 'status',
            component: renderFieldSelect,
            label: 'Status',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
            options:[
                {text:'Neaktivan',value:0},
                {text:'Aktivan',value:1},
                
            ]
        },
        'position' : {
            name: 'position',
            component: renderFieldInput,
            label: 'Pozicija',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [],
            size: 'small',
            value: '',   
            maxLength:"10",          
        },

        //todo upl.
        'icon' : {
            name: 'icon',
            component: renderFieldUploadImage,
            label: 'Ikonica',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                width: 300,
                height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 1
            })],
            warn: [],
            value: '',
            url: server_img_host + page_icon_path,
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            maxLength:"100", 
            //todo slika bck
            remove: (e,index,name) => {
                if(e.current){
                    AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                        if(result.isConfirmed){
                            let id=e.current.value;
                            var response = await fetchHelper.delete('pages/remove_image/'+id+"/"+name);
                            AlertyBox.message(response.data);
                            if(response.status && response.data.status){
                                index.setState({active_page:response.data.data});
                            }
                        }
                    })
                }
            }   
        }, 
        'icon_mobile' : {
            name: 'icon_mobile',
            component: renderFieldUploadImage,
            label: 'MOB-ikonica',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                width: 300,
                height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 1
            })],
            warn: [],
            value: '',
            url: server_img_host + page_icon_mobile_path,
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            maxLength:"100", 
            remove: (e,index,name) => {
                if(e.current){
                    AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                        if(result.isConfirmed){
                            let id=e.current.value;
                            var response = await fetchHelper.delete('pages/remove_image/'+id+"/"+name);
                            AlertyBox.message(response.data);
                            if(response.status && response.data.status){
                                index.setState({active_page:response.data.data});
                            }
                        }
                    })
                }
            } 
        },
        'featured' : {
            name: 'featured',
            type: 'text',
            component: renderFieldInput,
            label: 'Istaknuto',
            validate:[ValidateNumeric],
            size: 'small',
            value: '',
            maxLength:"2", 
        },
        //todo
        'date_created' : {
            name: 'date_created',
            component: renderDatePicker,
            label: 'Datum kreiranja',
            size: 'medium',
            value: '', 
            readonly:true,
            disabled:true,    
        },
        'date_updated' : {
            name: 'date_updated',
            component: renderDatePicker,
            label: 'Poslednja izmena',
            size: 'medium',
            value: '',  
            readonly:true,
            disabled:true,
                  
        },
        'start_date' : {
            name: 'start_date',
            component: renderDatePicker,
            label: 'Početak prikazivanja',
            // validate:[ValidateDate],
            size: 'medium',
            value: '', 
            readonly:true,
           
                     
        },
        'end_date' : {
            name: 'end_date',
            component: renderDatePicker,
            label: 'Kraj prikazivanja',
            // validate:[ValidateDate],
            size: 'medium',
            value: '',  
            readonly:true,
                  
        },
        'thumb' : {
            name: 'thumb',
            component: renderFieldUploadImage,
            label: 'Thumb slika',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                width: 300,
                height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 1
            })],
            value: '',
            url: server_img_host + page_thumb_path,
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            maxLength:"100", 
            remove: (e,index,name) => {
                if(e.current){
                    AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                        if(result.isConfirmed){
                            let id=e.current.value;
                            var response = await fetchHelper.delete('pages/remove_image/'+id+"/"+name);
                            AlertyBox.message(response.data);
                            if(response.status && response.data.status){
                                index.setState({active_page:response.data.data});
                            }
                        }
                    })
                }
            } 
        },
        'image' : {
            name: 'image',
            component: renderFieldUploadImage,
            label: 'Slika',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                // width: 300,
                // height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 1
            })],
            warn: [],
            value: '',
            url: server_img_host + page_image_path,
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            maxLength:"100", 
            remove: (e,index,name) => {
                if(e.current){
                    AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                        if(result.isConfirmed){
                            let id=e.current.value;
                            var response = await fetchHelper.delete('pages/remove_image/'+id+"/"+name);
                            AlertyBox.message(response.data);
                            if(response.status && response.data.status){
                                index.setState({active_page:response.data.data});
                            }
                        }
                    })
                }
            } 
        },
        'statistic_visits' : {
            name: 'statistic_visits',
            component: renderFieldInput,
            label: 'Broj pregleda',
            size: 'medium',
            value: '', 
            maxLength:"20",
            readonly:true,            
        },
        'statistic_votes' : {
            name: 'statistic_votes',
            component: renderFieldInput,
            label: 'Ocene korisnika',
            size: 'medium',
            value: '',
            maxLength:"10",
            readonly:true,                  
        },
        
        'statistic_rating' : {
            name: 'statistic_rating',
            component: renderFieldInput,
            label: 'Rangiranje',
            size: 'medium',
            value: '',
            readonly:true,       
        },
        'statistic_comments' : {
            name: 'statistic_comments',
            component: renderFieldInput,
            label: 'Broj komentara',
            size: 'medium',
            value: '',
            maxLength:"10",
            readonly:true,                 
        },
 



 

        

    },

    initialValues: {}
}