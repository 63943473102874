import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "../../../../helpers/fetchHelper";
import GigaTable from '../../../../libraries/GigaTable';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../../helpers/dateHelper";
import { UrlQueryParameter } from "../../../../helpers/urlHelper";
import FileTypeForm from "../../../../forms/Morph/FileTypeForm";
const queryString = require('query-string');

class FileTypesItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      table: null,
      items: [],
      active_item:null,
      file_id: '',
      page: 1
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
    this.items = null;
    this.busy = false;
  }

  componentDidMount(){
   this.setState({file_id:this.props.match.params.file_id},()=>{
      
   });

  }
  
  
  componentDidUpdate(prevProps, prevState) {
    var id = this.props.match.params.file_id;
    if(this.state.file_id !== id && id && !this.busy){
      this.setState({file_id:id},()=>{
       
      })
      
    }
  }
  

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_item:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_item:row,selected:row.id})
  }


  resetActive() {
    //ne bacati na runRow 
    this.setState({active_item:null,selected:null})
  }
  render() {

    if(!this.state.file_id) return '';

    const cells = [
      {name:'Id',cell: 'id',width:90, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Naziv',cell: 'title', width:150,fixed:true, className:''},
      {name: 'Sekcija', cell: 'morph.file.name', width:150, className: ''},
      {name:'Edit', cell:'custom1', custom:true, width:60, align:'center', render:(row)=>{
        return <button onClick={()=> this.setState({active_item:row,selected:row.id}) }><i className="fa fa-edit"></i></button>
      }},
      {name:'Items', cell:'custom2', custom:true, width:60, align:'center', render:(row)=>{
        return <button><Link to={"/admin/product/attributes/"+this.state.file_id+'/items'} target="_blank" ><i className="fa fa-th"></i></Link></button>
      }},
    ];

    const buttons = [
      {title: 'Nova grupa', type: 'link', link:`/admin/be_morph_filetypes/add`,search:`?file_id=${this.state.file_id}`},
    ];
    
    return (
      <>
        <div className="admin-home__main-section">
          <div className="table-wrapper">
            <GigaTable 
              cells={cells} 
              pk='id' 
              height={300} 
              buttons={buttons} 
              id='morph-filetypes' 
              db="ge_morph_filetypes"
              fetch={`morph/get-items/${this.state.file_id}`}
              delete={true} 
              selected={this.state.selected}
              getInstance={this.getTableInstance}
              run={this.runRow}
              resetActive={this.resetActive}
            />
          </div>
          <FileTypeForm data={this.state.active_item}  onSave={this.onSave} index={this} file_id={this.state.file_id} />
        </div>
      </>
    );
  }
}



  export default withRouter((FileTypesItems));