import {fetchHelper} from './../helpers/fetchHelper';

export const SET_USER = "auth/SET_USER";
export const SET_IS_MOBILE = "auth/SET_IS_MOBILE";

const initialState = {
  user: false,
  is_mobile:false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case SET_IS_MOBILE:
    return {
      ...state,
      is_mobile: action.is_mobile
    };
    default:
      return state;
  }
};



export const setIsMobile = is_mobile => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_IS_MOBILE,
      is_mobile: is_mobile
    });

    resolve(is_mobile);
});



export const setUser = (user) => dispatch => {
  return new Promise(resolve => {
    dispatch({
        type: SET_USER,
        user: user
      });

    resolve(user);
  });
}


export const getUser = () => dispatch => {
  return  new Promise(async resolve=>{
     var result = await fetchHelper.get('auth/get');
     console.log(result)
     if(!result || !result.status){
       dispatch({
         type: SET_USER,
         user: null
       });
     } else {
      dispatch({
        type: SET_USER,
        user: result.user
      });
     }

     resolve(result);
  })
}