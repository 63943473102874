import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { withRouter } from 'react-router-dom';
import { reduxAsyncValidate, reduxReduceVaues } from '../../../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";
import AlertyBox from '../../../libraries/AletyBox';
import { SubmitDataPickerValue } from "../../../helpers/dateHelper";

import { setBusy } from '../../../modules/forms';
import { ruleGeneratorConf } from "./ruleGeneratorConf";

import GigaSpinner from '../../../libraries/GigaSpinner';
import GigaTabs from '../../../libraries/GigaTabs';
import GigaTab from '../../../libraries/GigaTabs/GigaTab';

import '../../../assets/css/form.scss';
import { fetchHelper } from '../../../helpers/fetchHelper';
import { stringTrim } from '../../../helpers/stringHelper';

import './rule.scss';

class RuleGeneratorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allow_submit: false,
            tables: [],
            columns: [],
            cels: [],
            cels2: [],
            master_title: null,
            origin_table: null,
            origin_table_cell: null,
            depedency_table: null,
            depedency_table_cell: null,
            master_table: null,
            master_table_cell: null,
            attributes: {},
            data: null,
            queries: {}
        }

        this.addDepedency = this.addDepedency.bind(this);
    }

    componentDidMount() {
        ruleGeneratorConf.index = this;
        this.getTables();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data) {

            reduxAsyncValidate(this.props.data, this.props);
            this.setInitialValues();

        }

    }

    setInitialValues() {
        if (this.state.files && !this.state.lastId) {
            let lastId = 0
            this.state.files.map(file => {
               lastId = file.id
            });
            this.setState({lastId})
        }
    }


    async getTables(){
        var response = await fetchHelper.get('morph/get_tables');
        if (response.status && response.items.length > 0) {

            let Options = this.formatData(response.items);
            ruleGeneratorConf.fields.origin_table.options = Options;
            ruleGeneratorConf.fields.origin_table.disabled = false;

            ruleGeneratorConf.fields.depedency_table.options = Options;
            ruleGeneratorConf.fields.depedency_table.disabled = false;

            ruleGeneratorConf.fields.master_table.options = Options;
            ruleGeneratorConf.fields.master_table.disabled = false;

            this.setState({ tables: response.items });

            var items= response.items;
            if(items){
                var columns = {};
                items.map(table=>{
                    var cels = table.columns.split(',');
                    columns[table.id] = {};
                    if(cels){
                        cels.map((k)=>{
                            columns[table.id][k] = k;
                        })
                    }
                });
                
                ruleGeneratorConf.fields.origin_table_cell.options = [];
                ruleGeneratorConf.fields.depedency_table_cell.options = [];
                ruleGeneratorConf.fields.master_table_cell.options = [];
                // ruleGeneratorConf.fields.origin_table.disabled = false;

                this.setState({columns:columns})
            }

        }
    }

    formatData(data) {
        var formatedData = [];
        if (!data || !data.length) return '';

        data.map(obj => {
            var option = {}
            if (obj.position) {
                option.position = obj.position;
            }
            option.text = obj.name;
            option.value = obj.id;
            formatedData.push(option)
        })
        return formatedData.sort((a, b) => {
            return a.position - b.position;
        });
    }


    async submit(data,formType) {
      
        this.props.setBusy(true);
        var values = reduxReduceVaues(ruleGeneratorConf.fields, data);
        
        var response = await fetchHelper.post('morph/change_item', values);

        this.props.setBusy(false);
        
        if (response.status && values && Object.keys(values).length) {
            if (this.props.onSave) {
                this.props.onSave(values);
            }
            AlertyBox.message(response.data);
        } else {
            var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
            AlertyBox.message(m)
        }
    }


    addDepedency(instance,e){

        var data = {
            title: this.state.master_title,
            master: {
                table: this.state.master_table,
                cell: this.state.master_table_cell,
            },
            origin: {
                table: this.state.origin_table,
                cell: this.state.origin_table_cell
            },
            depedency: {
                table: this.state.depedency_table,
                cell: this.state.depedency_table_cell
            }
        }

        this.setState({data:data});
    }

    async addTables(){
        
        var d = this.state.data;
        var status = false;
        if(d){
            if(d.master.table && d.master.cell){
                if(d.origin.table && d.origin.cell){
                    status = true;
                    if(d.depedency.table && !d.depedency.cell){
                        status = false;
                    }
                }
            }
        }

        if(!status){
            var m = { message: 'Opcije ne ispunjavaju uslov', status: false };
            AlertyBox.message(m);
            return false;
        }

        if(!stringTrim(d.title)){
            var m = { message: 'Uslov mora da ima naslov', status: false };
            AlertyBox.message(m);
            return false;
        }

        
        var post = {
            title: d.title,
            master_table: d.master.table,
            master_cell: d.master.cell,
            origin_table: d.origin.table,
            origin_cell: d.origin.cell,
            depedency_table: d.depedency.table,
            depedency_cell: d.depedency.cell
        }

        const result = await fetchHelper.post('morph/add_rules', post);
        console.log(result);
        if(result.status === false || result.data.status === false){
            var m = { message: result.message, status: false };
            AlertyBox.message(m);
            return false;
        } else {
            var data = result.data;
            var queries = this.state.queries;
            queries[data.title] = data.queries;
            this.setState({queries:queries});

        }

    }

    tableCellAdd(instance){
        return <span className="field-add" onClick={(event) => this.addDepedency(instance,event)}><i className="fa fa-plus"></i></span>;
    }

    form(refID) {
        return (
            <>
                <Cell width={1} className="grid-column">

                    <div className="relative">
                        <table className="morph-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(this.state.queries).map((title)=>{
                                    return <tr>
                                        <td>{title}</td>
                                        <td>
                                            <table className="morph-table">
                                                <tbody>
                                                    {Object.keys(this.state.queries[title]).map((table)=>{
                                                        return <tr>
                                                            <td>{table}</td>
                                                            <td>{this.state.queries[title][table]}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="relative">
                        <Field {...ruleGeneratorConf.fields.master_title}  instance={ruleGeneratorConf} controler={this} />
                    </div>

                    <div className="relative">
                        <h4>Master</h4>
                        <div className="separate-right"></div>
                        <Field {...ruleGeneratorConf.fields.master_table}  instance={ruleGeneratorConf} controler={this} />
                        <Field {...ruleGeneratorConf.fields.master_table_cell} instance={ruleGeneratorConf} controler={this} button={this.tableCellAdd.bind(this)} /> 

                    </div>
                    <div className="relative">
                        <h4>Origin</h4>
                        <div className="separate-right"></div>
                        <Field {...ruleGeneratorConf.fields.origin_table}  instance={ruleGeneratorConf} controler={this} />
                        <Field {...ruleGeneratorConf.fields.origin_table_cell} instance={ruleGeneratorConf} controler={this} button={this.tableCellAdd.bind(this)} /> 

                    </div>
                    <div className="relative">
                        <h4>Depedency</h4>
                        <div className="separate-right"></div>
                        <Field {...ruleGeneratorConf.fields.depedency_table}  instance={ruleGeneratorConf} controler={this} />
                        <Field {...ruleGeneratorConf.fields.depedency_table_cell} instance={ruleGeneratorConf} controler={this} button={this.tableCellAdd.bind(this)} /> 
                    </div>
                    <div className="relative">
                        <button onClick={this.addTables.bind(this)}>Add</button>
                    </div>
                </Cell>
            </>
        )
    }

    render() {
        if (!this.props.data) return '';

        const { handleSubmit, invalid, submitting, pristine, busy } = this.props;
        
        ruleGeneratorConf.initialValues = this.props.data;

        var refID = React.createRef();

        return (
            <>
                {this.form(refID)}
            </>
        );
    }
}


const selector = formValueSelector('RuleGeneratorForm');

const mapStateToProps = state => ({
    initialValues: ruleGeneratorConf.initialValues,
    busy: state.forms.busy,
    id: selector(state, 'id'),
    // category_id: selector(state, 'category_id'),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBusy,
            updateField: (field, data) => change("RuleGeneratorForm", field, data),
        },
        dispatch
    );

    RuleGeneratorForm = reduxForm({
    form: "RuleGeneratorForm",
    reduxAsyncValidate,
    enableReinitialize: true,
})(RuleGeneratorForm);
RuleGeneratorForm = connect(
    mapStateToProps, mapDispatchToProps
)(RuleGeneratorForm);
export default withRouter(RuleGeneratorForm);