import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { withRouter } from 'react-router-dom';
import { reduxAsyncValidate, reduxReduceVaues } from '../../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";
import AlertyBox from '../../libraries/AletyBox';
import { SubmitDataPickerValue } from "../../helpers/dateHelper";

import { setBusy } from '../../modules/forms';
import { fileTypeConf } from "./fileTypeConf";

import GigaSpinner from '../../libraries/GigaSpinner';
import GigaTabs from '../../libraries/GigaTabs';
import GigaTab from '../../libraries/GigaTabs/GigaTab';

import '.././../assets/css/form.scss';
import { fetchHelper } from '../../helpers/fetchHelper';
import RuleGeneratorForm from './RuleGenerator/RuleGeneratorForm';

class FileTypeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allow_submit: false,
            files: [],
            tables: [],
            columns: [],
        }
    }

    componentDidMount() {
        fileTypeConf.index = this;
        this.getFiles();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data) {

            reduxAsyncValidate(this.props.data, this.props);
            this.setInitialValues();

        }

    }

    setInitialValues() {
        if (this.state.files && !this.state.lastId) {
            let lastId = 0
            this.state.files.map(file => {
               lastId = file.id
            });
            this.setState({lastId})
        }
    }


    async getFiles() {
        var response = await fetchHelper.get('morph/get_files');
        if (response.status && response.items.length > 0) {

            let Options = this.formatData(response.items);
            fileTypeConf.fields.file_id_select.options = Options;
            fileTypeConf.fields.file_id_select.disabled = false;
            this.setState({ files: response.items });
        }
    }

    
    formatData(data) {
        var formatedData = [];
        if (!data || !data.length) return '';

        data.map(obj => {
            var option = {}
            if (obj.position) {
                option.position = obj.position;
            }
            option.text = obj.name;
            option.value = obj.id;
            formatedData.push(option)
        })
        return formatedData.sort((a, b) => {
            return a.position - b.position;
        });
    }


    async submit(data,formType) {

        this.props.setBusy(true);
        var values = reduxReduceVaues(fileTypeConf.fields, data);
        var response = await fetchHelper.post('morph/change_item', values);

        this.props.setBusy(false);
        
        if (response.status && values && Object.keys(values).length) {
            if (this.props.onSave) {
                this.props.onSave(values);
            }
            AlertyBox.message(response.data);
        } else {
            var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
            AlertyBox.message(m)
        }
    }

    form(refID) {
        return (
            <>
                <Cell width={1} className="grid-column">
                    <div className="relative">
                        <div className="separate-right"></div>
                        <Field {...fileTypeConf.fields.id} ref={refID} />
                        <Field {...fileTypeConf.fields.title} />
                        <Field {...fileTypeConf.fields.description} />
                        <Field {...fileTypeConf.fields.file_id_select} />
                        <Field {...fileTypeConf.fields.file_id} />
                        <Field {...fileTypeConf.fields.morph_id} />
                    </div>
                </Cell>
                <Cell width={1} className="grid-column">
                    <div className="relative">
                        <div className="separate-right"></div>

                        <RuleGeneratorForm data={this.props.data}  />
                    </div>
                </Cell>
            </>
        )
    }

    render() {
        if (!this.props.data) return '';

        const { handleSubmit, invalid, submitting, pristine, busy } = this.props;
        
        fileTypeConf.initialValues = this.props.data;
        fileTypeConf.initialValues.file_id_select = this.props.data.morph.file_id;
        fileTypeConf.initialValues.file_type = this.props.data.morph.file_type;
        fileTypeConf.initialValues.morph_id = this.props.data.morph.id;

        var refID = React.createRef();

        return (
            <>
                <form id="categoryEditForm" onSubmit={handleSubmit((data) => {
                    if (this.state.allow_submit) {
                        this.setState({ allow_submit: false })
                        this.submit(data,this.props.fileid);
                    }
                })} className="redux-form" encType="multipart/form-data" >

                    <GigaTabs tab={0}>
                        {/* GiaTab title, heigth */}
                        <GigaTab title="Edit " /*height={400}*/>

                            {busy ? <div className="form-mask"></div> : ''}
                            <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                                {this.form(refID)}
                            </Grid>

                        </GigaTab>
                        <GigaTab title="Sadrzaj">
                            <Grid columns={1} gap="30px">
                                <Cell width={1} className="grid-column">
                                    <div className="relative">
                                        {/* <div className="separate-right"></div> */}

                                    </div>
                                </Cell>
                            </Grid>
                        </GigaTab>
                    </GigaTabs>
                    <div className="page-bottom-bar">
                        <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
                    </div>
                </form>
            </>
        );
    }
}


const selector = formValueSelector('categoryEditForm');

const mapStateToProps = state => ({
    initialValues: fileTypeConf.initialValues,
    busy: state.forms.busy,
    id: selector(state, 'id'),
    // category_id: selector(state, 'category_id'),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBusy,
            updateField: (field, data) => change("categoryEditForm", field, data),
        },
        dispatch
    );

FileTypeForm = reduxForm({
    form: "categoryEditForm",
    reduxAsyncValidate,
    enableReinitialize: true,
})(FileTypeForm);
FileTypeForm = connect(
    mapStateToProps, mapDispatchToProps
)(FileTypeForm);
export default withRouter(FileTypeForm);