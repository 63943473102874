import React, {Component} from 'react';
import './style.scss';

export default class TemplateTabs extends Component {


    constructor(props){
        super(props)
        
        this.state = {
           active: 0,
        }

    }

    componentDidMount(){

    }


    tabButtons(){
        var tabs = [];
        var n = parseInt(this.props.parent.state.tabs);
        var a = parseInt(this.props.active);
        var t = this.props.element;
        if(t){
            var tbs = t.querySelectorAll('.giga-tabs-buttons span');
            if(tbs && tbs.length){
                for(var i = 0; i < tbs.length; i++){
                    var tt = tbs[i];
                    tabs.push(<span key={i} contentEditable={true} className={i===a?'active-tab':''}  >{tt.innerHTML}</span>)
                }
            }
        }

        for(var i = tabs.length; i < n; i++){
            tabs.push(<span key={i} contentEditable={true} className={i===a?'active-tab':''}  >Tab {i}</span>);
        }

        return tabs;

    }


    tabContens(){
        var tabs = [];
        var n = parseInt(this.props.parent.state.tabs);
        var a = parseInt(this.props.active);
        var t = this.props.element;
        if(t){
            var tbs = t.querySelectorAll('.tabcontent');
            if(tbs && tbs.length){
                for(var i = 0; i < tbs.length; i++){
                    var tt = tbs[i];
                    tabs.push(<div key={i} contentEditable={true} className={i===a?'active-tab tabcontent':'tabcontent'}  >{tt.innerHTML}</div>)
                }
            }
        }

        for(var i = tabs.length; i < n; i++){
            tabs.push(<div key={i} contentEditable={true} className={i===a?'active-tab tabcontent':'tabcontent'}  >Tab Content {i}</div>);
        }

        return tabs;

    }

    render(){
        return (
            <div className="block-element-editor" contentEditable={false} 
                data-type="template" 
                data-module="TemplateTabsModal" 
                tabIndex={1} 
                data-onload="TemplateTabs" 
                data-stop={true} 
                data-tabs={this.props.parent.state.tabs} 
                data-active={this.props.active} 
                style={{
                    padding: this.props.parent.state.padding,
                    margin: this.props.parent.state.margin
                }}
            
            >
                <div className="giga-tabs">
                    <div className="giga-tabs-buttons">
                        {this.tabButtons()}
                    </div>
                    <div className="giga-tabs-content">
                        {this.tabContens()}
                    </div>
                </div>
            </div>)
    }

}