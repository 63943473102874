import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';
import Initialize from '../../base/initialize';
import TableModal from '../../modals/TableModal';

export default class Table extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);

        this.editorContent = null;
        this.refInp = React.createRef();

    }

    componentDidMount(){

        this.url = this.props.url;

        this.initialize();
    }

    initialize(){
        this.editorContent = this.props.element.current;
    }

    getRange(){
        if (window.getSelection) {
            var sel, range;
            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
            }
            return range;
        }
    }

    action(e){
        e.stopPropagation();
        e.preventDefault();

        this.props.editor.setState({modal:<TableModal editor={this.props.editor} element={this.props.element} insert={true} /> });

        return false;
    }

    render(){
        return <button onMouseDown={this.action} className="get-btn selector-ga" id={"Table-btn-"+this.props.id}><i className="fa fa-table" aria-hidden="true" ></i></button>
    }

}