import React from 'react';
import { ToolbarSection, ToolbarItem , ToolbarTextInput } from '../../editor/Toolbar';
import { ToolbarRadio } from '../../editor/Toolbar/components/ToolbarRadio';

export const TabsSettings = () => {
    return (
      <React.Fragment>
         
        
      </React.Fragment>
    );
  };