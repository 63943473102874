import React, { Component } from "react";
import { Link } from "react-router-dom";

import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import { fetchHelper } from "../../../helpers/fetchHelper";
import GigaTable from '../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import PagesForm from "../../../forms/PagesForm";


class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:null,


      active_page:null,
      selected: null,
      table: null,

    //chc.
      section:"pages",
    }

    this.table = null;

    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.addNew=this.addNew.bind(this);
    this.resetActive=this.resetActive.bind(this);
    this.getFormData=this.getFormData.bind(this);

  }

  componentDidMount(){
    
  }

  componentDidUpdate(){
    let id=this.props.match.params.id
    this.getSections();
    id = 'pageSection-'+ id;
    if  (id !== this.state.id) {
      this.setState({id:id, active_page:null})

    }
  }

    getTableInstance(table){
      this.table = table;
    }

    onSave(data,response){
      if(this.table && data.id){
        this.table.loadData(false,()=>{
          if(response.status && response.data && response.data.status){
            this.setState({active_page:response.data.data});
          }
        });
      }
    }

    runRow(row){
      if(row)
      this.setState({active_page:row,selected:row.id})
    }
    
    async addNew(){
      
      var r = await fetchHelper.post(`pages/change`,{section_id:this.props.match.params.id});

      if(r.status&&r.data){
        this.setState({active_page:r.data.data,selected:r.data.data.id});
        this.table.loadData();
        //+show last
      }
      else{
        AlertyBox.serverError();
      }
    }

    async getFormData(id){
      var r = await fetchHelper.get(`pages/get_form_data/${id}`);
    
      if(r.status){
        let data=r.items
        this.setState({active_page:data,selected:id}) 
      }
      else{
       
      }
    }

    async getSections(){
      let id=this.props.match.params.id
      let sections = await fetchHelper.get(`pages/get_sections/${id}`)
      if(sections.items.title !== this.state.title){
        this.setState({title: sections.items.title})
      }
    }

        
    resetActive() {
      this.setState({active_page:null,selected:null})
    }


  render() {
    let id=this.props.match.params.id
    const cells = [
      {name:'ID',cell: 'id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Naslov',cell: 'title', width:450,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Master ID',cell: 'master_id', width:200,fixed:false,className:'draggable'},


      {name:'Detalji', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return   <button onClick={()=>{ this.getFormData(row.id)}}>
          <i className="fa fa-folder-open-o" />
          </button> 
      }},
      //proveri custom2
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center', render:(row)=>{
        var value = parseInt(row.status); 
        return  <button onClick={()=> this.table.updateRowCell(row,'status',value?0:1)}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button>
      }},
    ];
    const buttons = [
      {title: 'Nova stranica', type: 'button' , onClick: this.addNew},
    ];
    
    var fetch=`pages/get_table_data/${id}`;

    if(!id){
      return ""
    }
    return (
    <>
       <div className="admin-home__main-section">
         <h1>{this.state.title ? this.state.title : ''}</h1>
        <div className="table-wrapper">
            <GigaTable 
            cells={cells} 
            pk='id' 
            height={300} 
            buttons={buttons} 
            id={this.state.id} 
            db="giga_pages"
            fetch={fetch}
            delete={true} 
            selected={this.state.selected}
            getInstance={this.getTableInstance}
            run={this.runRow}
            resetActive={this.resetActive}
          />


        </div>
        {/* +loader */}
        {this.state.active_page ? <PagesForm data={this.state.active_page} onSave={this.onSave} index={this} />  : ""}
      
      </div>
    </>);
  }
}

const mapStateToProps = state => ({

  user: state.auth.user

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
   
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Pages));