import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';
export default class Underline extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){
        // var ins = new InsertNode(this.props.editor);
        // ins.__FORMAT('u');

        e.stopPropagation();
        e.preventDefault();
        document.execCommand('underline');
        this.props.editor.registerChange();
        return false;
    }

    render(){
        return <button onMouseDown={this.action} className="get-btn selector-ga" id={"Underline-btn-"+this.props.id}><i className="fa fa-underline" aria-hidden="true" ></i></button>
    }

}