import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "../../../helpers/fetchHelper";
import GigaTable from '../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import UserForm from "../../../forms/UserForm";
import OrderIndex from "../../../components/adminPanel/OrderIndex";

class Orders extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      active_user:null,
      selected: null,
      table: null,
      user_images:null,
      section:"users",
      detail: null
  
      
      
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
    this.addNew=this.addNew.bind(this);
  }

  componentDidMount(){
    
  }

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_user:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_user:row,selected:row.id})
  }


  async addNew(){
    var r = await fetchHelper.post('users/change',{});
    if(r.status&&r.data){
      this.setState({active_user:r.data.data,selected:r.data.data.id,});
      this.table.loadData();
      //+scroll to left bottom
    }
    else{
      AlertyBox.serverError();
    }
  }

  resetActive() {
    this.setState({active_user:null,selected:null})
  }


  render() {

    const cells = [
      {name:'Tip',cell: 'order_type',width:60, fixed:false, className:'',sort:false, onBefore: (value)=>{
        return value === 1 ? 'WEB' : 'SHOP'
      }},
      {name:'Order',cell: 'order_id', width:150,fixed:false, truncate:true,className:'draggable'},
      {name:'Ime',cell: 'first_name',width:200, fixed:false, className:'', custom:true, render:(row)=>{
        return row.company ? row.company : row.name ? (row.name + ' ' + row.surname) : (row.user_name + ' ' + row.user_surname)
      }},
      {name:'Email',cell: 'email', width:200,fixed:false, truncate:true,className:'draggable'},
      {name:'Tel',cell: 'phone', width:150,fixed:false, truncate:true,editable:false,className:'draggable'},
      {name:'Adresa',cell: 'address', width:250,fixed:false,truncate:true,editable:false,className:'draggable'},
      {name:'Grad',cell: 'city', width:150,fixed:false,editable:false,className:'draggable'},
      {name:'Date',cell: 'date_c', width:150,fixed:false,editable:false,sort:true,className:'draggable', onBefore:(value)=>{
        return date_sql_to_rs(value);
      }, onSave:(value)=>{
        return date_rs_to_sql(value);
      }},
      {name:'Trans. Date',cell: 'date_t', width:150,fixed:false,editable:false,sort:true,className:'draggable', onBefore:(value)=>{
        var d = date_sql_to_rs(value);
        return d === 'error' ? '---' : d.replace(/00\:00\:00/g,'');
      }, onSave:(value)=>{
        return date_rs_to_sql(value);
      }},
      {name:'Detalji', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return <Link to={"/admin/users/orders/details/" + row.order_type + "/" + row.order_id} target="_blank">{<button><i className="fa fa-folder-open-o"></i></button>}</Link>
      }},
      {name:'Status', cell:'status', custom:true, width:110, align:'center', render:(row)=>{
        switch(row.status){
          case 1:
            return "Nezavršena";
          break;
          case 2:
            return "Završena";
          break;
          default: return "(?)";
        }
      }},
      {name:'Status final', cell:'status_final', custom:true, width:300, align:'center', render:(row)=>{
        switch(parseInt(row.status_final)){
          case 1:
            return "Porudžbenica primljena";
          break;
          case 2:
            return "Korisnik obavešten o kreiranju";
          break;
          case 3:
            return "U obradi";
          break;
          case 4:
            return "Obrađena";
          break;
          case 5:
            return "Obrađena/dodeljeni bodovi";
          break;
          case 6:
            return "Obrađena/istekli bodovi";
          break;
          case 7:
            return "Greška";
          break;
          case 8:
            return "Poništena";
          break;
          default: return "(?)";
        }
      }},
    ];

    const buttons = [
      // {title: 'Novi korisnik', type: 'button' , onClick: this.addNew},
    ];
    
    return (<>
      <div className="page-sidebar-opt--fixed">
        <span title="Sve" class="active"><i className="fa fa-circle-thin"></i></span>
        <span title="Neobrađene"><i className="fa fa-hourglass-o"></i></span>
        <span title="Obrađene"><i className="fa fa-hourglass"></i></span>
        <span title="Neregistrovane"><i className="fa fa-user-secret"></i></span>
        <span title="Poništene"><i className="fa fa-ban"></i></span>
      </div>
      <div className="admin-home__main-section with--sidebar-opt--fixed">
          <OrderIndex />
          <div className="table-wrapper">
            <GigaTable 
              cells={cells} 
              pk='id' 
              height={600} 
              buttons={buttons} 
              id='orders-table' 
              db="gigatron_index_order"
              fetch="orders/all_user_orders"
              delete={false} 
              title="Kupovine"
              selected={this.state.selected}
              getInstance={this.getTableInstance}
              run={this.runRow}
              resetActive={this.resetActive}
            />
          </div>
          <UserForm detail={this.state.detail} data={this.state.active_user} user_images={this.state.user_images} onSave={this.onSave} index={this} />
      </div>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Orders));
