import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderGigaEditor,
    renderDatePicker,
    renderGallery
} from "./../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidateFileImage,
    ValidateDate,
    ValidateString,
} from './../helpers/validation';

import AlertyBox from "../libraries/AletyBox";
import { SubmitDataPickerValue } from "../helpers/dateHelper";
import { fetchHelper } from "../helpers/fetchHelper";


export const slidersConf = {
    
    fields: {
        
        'id' : {
            name: 'id',
            type: 'text',
            component: renderFieldInput,
            label: 'Id',
            size: 'small',
            value: '',
            readonly:true,
        },
        'title' : {
            name: 'title',
            type: 'text',
            component: renderFieldInput,
            label: 'Naslov',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'large',
            value: '',
        },
        'description' : {
            name: 'description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Opis',
            // validate:[ValidateAlphaNumeric],
            // warn: WarnAlphaNumeric,
            height: 100,
            size: 'medium',
            value: '',
        },
        'status' : {
            name: 'status',
            component: renderFieldSelect,
            label: 'Status',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
            options:[
                {text:'Neaktivan',value:0},
                {text:'Aktivan',value:1},
                
            ]
        },
        'start_date' : {
            name: 'start_date',
            component: renderDatePicker,
            label: 'Početak prikazivanja',
            validate:[ValidateRequire,ValidateDate],
            size: 'medium',
            value: '', 
        },
        'end_date' : {
            name: 'end_date',
            component: renderDatePicker,
            label: 'Kraj prikazivanja',
            validate:[ValidateRequire],
            size: 'medium',
            value: '',  
        },
        'img_name' : {
            name: 'img_name',
            type: 'text',
            component: renderFieldInput,
            label: 'Naziv slike',
            // validate:[ValidateAlphaNumeric],
            // warn: WarnAlphaNumeric,
            size: 'large',
            value: '',
        },
        'gallery' : {
            name: 'gallery',
            component: renderGallery,
            label: 'Galerija',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                width: 300,
                height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 2
            })],
            warn: [],
            value: '',
            url: 'http://ozone.devgigatron.com/img/gigaapi/gallery/thumb/',
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            section:'sliders',
            callback : async(images,onsave,formData) => {
                console.log(images)
                
                var position = false;
                var image = images[0];
                
                Object.keys(images).map(item => {
                    if(images[item].position === 1 || images[item].position === undefined ){
                        position = true;
                    }
                })

                if(position){

                    let img_name = image ? image.img_name : null;
                    let id = image ? image.product_id : images.item_id ;
                    
                    formData = SubmitDataPickerValue(formData, ['start_date', 'end_date']);
                    let {description,status,title} = formData;

                    var values = {
                        id:id,
                        img_name: img_name,
                        description:description,
                        status:status,
                        title: title,
                        start_date: formData.start_date,
                        end_date: formData.end_date
                    }

                    let response = await fetchHelper.post('sliders/change',values);
                      
    
                    if (response.status && values && Object.keys(values).length) {
                        if (onsave) {
                            onsave(values,response);
                        }
                        // AlertyBox.message(response.data);
                    } else {
                        var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
                        AlertyBox.message(m)
                    }
                    return true;
                }
               
            }
        },
    },
    initialValues: {}
}