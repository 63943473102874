 
import React, { Component } from "react";
import ColorPickerField from '../../../fields/ColorPickerField';
import TextField from '../../../fields/TextField';
import $ from "jquery";
import EditorCSS from "./EditorCSS";
import EditorAddNew from "./EditorAddNew";

class EditorHTML extends Component {

    constructor(props) {
        super(props);
        this.state = {
 
 
            wrapper_we_clicked_to_add_new_content:"",

            draggable_active_element:"",

            item_component_active_name:"main_item_wrapper",
            item_component_active_title:"Product wrapper",
            active_css_editor_tab: "Background", 

        };
 
    }

    setActiveComponent(class_name,name){
        this.setState({
            item_component_active_name: class_name,
            item_component_active_title: name,
            active_css_editor_tab:class_name=="main_item_wrapper"?"Background" : "Position", 
        });
    } 
    

  
    //1 item wrapper data 
    items_wrapper(layout){
        let l=layout;
        return  <div className="item_tree_wrapper">
            <div className="item_tree_main_title">
                <p>Items wrapper</p>
                <span className="mq_info">mw</span>
            </div>
            <ul className="item_tree_main_content">
                <li>
                    <div className="item_tree_title">
                        <p>Items wrapper max width</p>
                        <TextField  type={"number"} className="items_wrapper_max_width" name="items_wrapper_max_width" value={l.items_wrapper_max_width} onChange={(e)=>{this.props.onChange(e,"text")}} block_setting_from_input={true}
                        />
                    </div>
                </li>
                <li>
                    <div className="item_tree_title">
                        <p>Items wrapper background colors</p>
                        <ColorPickerField color={l.product_plugin_background_color} block_setting_from_input={true} set_edit_container_inside_div_with_this_id={"product_plugin_background_color"} name="product_plugin_background_color"  enableAlpha={true} onChange={ (e)=>this.props.onChange(e,"color") } />
                        <ColorPickerField color={l.items_wrapper_background_color}  block_setting_from_input={true} set_edit_container_inside_div_with_this_id={"items_wrapper_background_color"}  name="items_wrapper_background_color"   enableAlpha={true} onChange={ (e)=>this.props.onChange(e,"color") }/>
                    </div>
                </li> 
            </ul>  
        </div> 
            
    }

    //2  table:1.remove data for bitem for diff breaking point(exept main) 2.set item grid
    columns_on_diff_screen_sizes(layout,brp,item_grid,active_breaking_point){
        return    <div className="">
        <p className="item_tree_main_title">Content preview and clear
            {/* <i className="fa fa-info" aria-hidden="true"></i> */}
        </p>
        <table className={`columns_on_diff_screen_sizes`}  >
            <tbody>
                <tr className="top_titles">
                    <th>Max width</th>
                    <th className="title_html">Item <br/> html</th>
                    <th>Col <br/> 1-12</th>
                    {item_grid.map((name,index)=>{
                        return  <th className={`title_${name}`} key={"th"+name+index}>
                            <div className="item_position_title_img">
                                <div/> <div/> <div/> <div/>
                            </div>
                        </th>
                    })}
                </tr>  
                {Object.keys(brp).reverse().map((width_number,i)=>{
                    return  <tr key={"mdq"+i} className={active_breaking_point==width_number ? "focus_on_active_breaking_point":""}>
                        <td>
                            <p className="">{width_number}</p>
                        </td>
                        <td>
                            <div  className={`arrow_checker ${i==0 ? " biggest_size":""} ${Object.keys(layout.item_html[width_number]).length ? "checked" : " "}`} onClick={()=>{ this.props.emptyHtml(width_number) }}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-chevron-down fa-w-14"><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" ></path></svg>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-angle-down fa-w-10"><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>
                            </div>
                            
                        </td>
                        <td>
                            <TextField  type={"number"} className={"breaking_points_columns"} name={"breaking_points_columns"} value={layout.breaking_points_columns[width_number]} onChange={(e)=>this.props.onChangeTableData(e,width_number)}  block_setting_from_input={true}/>
                        </td>
                        {item_grid.map((name,index)=>{
                            return  <td key={"td"+name+index}>
                                <TextField  type={"number"} className={name} name={name} value={layout[name][width_number]} onChange={(e)=>this.props.onChangeTableData(e,width_number)}  block_setting_from_input={true}/>
                            </td>
                        })}
                    </tr>
                })}
            </tbody>
        </table>
    </div>
    }
 
    // 3
    item_structure(layout ){

        let data=layout.item_html;
        let current_html=this.props.emptyFallbackToWiderMediaQueryData(data);
        let n=current_html ? this.item_tree_content([current_html]) : "";
        return current_html ?   
            <div className="item_tree_wrapper">
                <p className="item_tree_main_title">Item html
                    {/* <i className="fa fa-info" aria-hidden="true"></i> */}
                </p>
                 <ul className="item_tree_main_content">
                    {n.html}
                </ul> 
                
                <EditorCSS
                    active_css={n.css}
                    item_component_active_title={this.state.item_component_active_title}
                    item_component_active_name={this.state.item_component_active_name}
                    active_css_editor_tab={this.state.active_css_editor_tab}
                    change_sidenav_position={this.props.change_sidenav_position} 
                    changeElementCSSOptionsForDiffMediaQ={ (data)=>{this.props.itemTreeAddDeleteChange(data,"change_css")} }
                    changeActiveEditorTab={(title)=>{this.setState({active_css_editor_tab:title})}}
                />
            </div> 
        : "" 
    }

    events(element){
        let events={ 
            onDragStart:(event)=>{ 
                event.stopPropagation();
                event.target.classList.add("dragged_id_active_tree_title");  

                this.setState({draggable_active_element:element});
                $('.dropzone_active_tree_title *').css('pointer-events','none');
                $(`.dropzone_active_tree_title[name=${element.element_name}]`).css('pointer-events','none');
          
            } ,
            onDragEnd:(event)=>{ 
                // console.log("DRAG END")
                event.target.classList.remove("dragged_id_active_tree_title");
                this.setState({draggable_active_element:""});
                $('.dropzone_active_tree_title *').css('pointer-events','all');
            },
            onDragOver:(event)=>{  
                event.preventDefault();  
                event.stopPropagation();
            },
            
            onDragEnter:(event)=>{ 
                event.preventDefault();  
                event.stopPropagation();
                let dragged_name=this.state.draggable_active_element.element_name;
                let block=dragged_name.includes(event.target.getAttribute('name')); 
                if ( event.target.classList.contains("dropzone_active_tree_title")&&!block) {
                    event.target.classList.add("id_under_dragged_id_active_tree_title")
                }
            },
            onDragLeave:(event)=>{ 
                if ( event.target.classList.contains("dropzone_active_tree_title")) {
                    event.target.classList.remove("id_under_dragged_id_active_tree_title")
                }
                
            },
            onDrop:(event)=>{ 
                event.preventDefault();
                event.stopPropagation();
                if ( event.target.classList.contains("dropzone_active_tree_title") ) {
                    event.target.classList.remove("id_under_dragged_id_active_tree_title");
                    let droped_on_name=element.element_name;
                    let dragged_name=this.state.draggable_active_element.element_name;
                    if(droped_on_name!=dragged_name&&element.can_change_position){
                        this.props.itemTreeAddDeleteChange({droped_on_name:droped_on_name,draggable_active_element:this.state.draggable_active_element},"change_position"); 
                    }
                }
                $('.dropzone_active_tree_title *').css('pointer-events','all');

            },
        }
        return events;
    }

    changeElementProps(){

    }
    item_tree_content(data){
        if(data){
            let info={html:"",css:""};
            info.html=data.map((element,index)=>{
                let element_name=element.element_name;
                let element_title=element.element_title;
                let element_css=element.css;
                let content=element.content;
               

                let active_name=this.state.item_component_active_name==element_name;
              


                let new_data=content ? this.item_tree_content(content) : "";  
                info.css=info.css||(active_name&&element_css)||new_data.css || "";
                // info.css=info.css ? info.css : active_name ? element.css : new_data.css  ? new_data.css : "";



                let events=element.can_change_position ? this.events(element) : ""; 
                return  <li className="dropzone_active_tree_title "   name={element_name} key={element_name+index} draggable={element.can_change_position ? true : false} {...events} >
                  
                    {/* title/name */}
                     <div className={`item_tree_title ${active_name ? "active_tree_title":""}`}>
                        <p onClick={()=>{ this.setActiveComponent(element_name,element_title)}} >{element_title}</p>   
                        <i className="fa fa-times" aria-hidden="true" 
                            style={{color:element.removable ? "grey" : "#eee"}}
                            onClick={()=>{ 
                                if(element.removable){ 
                                    this.setActiveComponent("main_item_wrapper","Product wrapper",element_css);
                                    this.setState({hide_css_editor:true})
                                    this.props.itemTreeAddDeleteChange(element_name,"delete");                         
                                }
                            }}
                        />

                        <svg aria-hidden="true" focusable="false" data-prefix="far" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" 
                            onClick={()=>{ 
                                if(element.is_linkable){ 
                                    this.setActiveComponent(element_name,element_title,element_css); 
                                    this.props.itemTreeAddDeleteChange({class_name:element_name,value:!element.is_link,prop_name:"is_link"},"create_link");    
                                }  
                            }}    
                            className={element.is_link ?  "fa-link" : element.is_linkable  ? "fa-unlink": "fa-unlink  link_disabled"}
                            >
                            {element.is_link ? 
                                <path fill="currentColor"  d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z"></path>
                                :
                                <path fill="currentColor" d="M304.083 388.936c4.686 4.686 4.686 12.284 0 16.971l-65.057 65.056c-54.709 54.711-143.27 54.721-197.989 0-54.713-54.713-54.719-143.27 0-197.989l65.056-65.057c4.686-4.686 12.284-4.686 16.971 0l22.627 22.627c4.686 4.686 4.686 12.284 0 16.971L81.386 311.82c-34.341 34.341-33.451 88.269.597 120.866 32.577 31.187 84.788 31.337 117.445-1.32l65.057-65.056c4.686-4.686 12.284-4.686 16.971 0l22.627 22.626zm-56.568-243.245l64.304-64.304c34.346-34.346 88.286-33.453 120.882.612 31.18 32.586 31.309 84.785-1.335 117.43l-65.056 65.057c-4.686 4.686-4.686 12.284 0 16.971l22.627 22.627c4.686 4.686 12.284 4.686 16.971 0l65.056-65.057c54.711-54.709 54.721-143.271 0-197.99-54.71-54.711-143.27-54.72-197.989 0l-65.057 65.057c-4.686 4.686-4.686 12.284 0 16.971l22.627 22.627c4.685 4.685 12.283 4.685 16.97-.001zm238.343 362.794l22.627-22.627c4.686-4.686 4.686-12.284 0-16.971L43.112 3.515c-4.686-4.686-12.284-4.686-16.971 0L3.515 26.142c-4.686 4.686-4.686 12.284 0 16.971l465.373 465.373c4.686 4.686 12.284 4.686 16.97-.001z" className=""></path>
                            }
                        </svg> 

                        {content ? <> 
                            <i className={`fa fa-arrow-circle-o-${element.wrapper_direction=="v"?"down": "right"}`} aria-hidden="true" 
                                onClick={()=>{  
                                    this.setActiveComponent(element_name,element_title,element_css); 
                                    this.props.itemTreeAddDeleteChange({class_name:element_name,value:element.wrapper_direction=="v"? "h":"v",prop_name:"wrapper_direction"},"change_wrapper_direction"); 
                                }}
                            />

                            <i className={`fa fa-${element.nowrap ? "lock" : "unlock"}`} aria-hidden="true"
                                onClick={()=>{  
                                    this.setActiveComponent(element_name,element_title,element_css); 
                                    this.props.itemTreeAddDeleteChange({class_name:element_name,value:!element.nowrap,prop_name:"nowrap"},"block_wrapping"); 
                                }}
                            />
                        </> 
                        :""}
                    </div>
       
                    
                    {content ? 
                        <ul className="item_tree_content"> 
                            <li>
                                <div className={`item_tree_title ${element.add_data ? "" : "block"}`} 
                                    onClick={()=>{return  element.add_data ?  this.setState({wrapper_we_clicked_to_add_new_content:element_name}) : "" }}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>  
                                    <p id={"name"+element_name}>Add new</p>   
                                </div>

                                {this.state.wrapper_we_clicked_to_add_new_content==element_name ?  
                                    <EditorAddNew  wrapper_we_clicked_to_add_new_content={element_name} 
                                        itemTreeAddDeleteChange={(data)=>{  
                                            this.props.itemTreeAddDeleteChange(data,"add"); 
                                        }}
                                        showAddNewTab={(data)=>{this.setState({wrapper_we_clicked_to_add_new_content:data})}}
                                    /> 
                                  
                                :"" }
                    
                            </li>
                            {new_data.html}
                        </ul> 
                    : ""} 
                 
                </li> 
            });
            
           
            return info;
        }
      
    }
    

    render() {
        
        let layout=this.props.layout;
        let brp=layout.breaking_points_columns;
        let item_grid=["horizontal_space_around_items","vertical_space_around_items","vertical_space_beatween_items","horizontal_space_beatween_items"];
        let active_breaking_point=this.props.active_breaking_point;
 

        return <div className={`table_scroll_side_nav ${this.props.change_sidenav_position}`}> 

            {this.items_wrapper(layout)}
            {this.columns_on_diff_screen_sizes(layout,brp,item_grid,active_breaking_point)}
            {this.item_structure(layout)}
           
        </div>

     
    }
 
}
 
 

export default (EditorHTML) ;
