import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import "./PlainTable.scss";
import $ from 'jquery';
//example with data in GigaTable index : kill Detalji&Status  in cells for proper view
//data
// const cells = [
//     {name:'Ean',cell: 'ean',width:100,  className:''},        
//     {name:'Model',cell: 'model',width:100,  className:''},   
//     {name:'Naziv',cell: 'name',width:100,  className:''},   
//     {name:'Order ID',cell: 'order_id',width:100,  className:'' },
//     {name:'Registration date',cell: 'registration_date',width:100,  className:'',
//     onBefore:(value)=>{
//       return date_sql_to_rs(value); }},
// ] 
// let rows =[
//     {ean: "433243240", model: "OUH24W", name: "Omegae", order_id: "G-XXXXXX", unused: "X" },
//     {ean: "32423676542", model: "AX K410", name: "Tastatura", order_id: "G-XXXXXXX", unused: "X"}
// ]
// <PlainTable 
    // itemsPerPage={10}  
    // height={"500px"}  
    // cells={this.state.cells} 
    // rows={this.state.rows} 
    // removeFooter={true} //optional
    // className={"classy"} //optional
    // title={"title"} //optional
    // /> 
 

class PlainTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page:1,
            page_before_empty:0,
            hover_id:"",
            value:"",
        };

        this.search = '';

    }
 
    setDataForHiddenText(e,value){
        let child=e.target.lastChild; 
        if(child){ 
            let scrollWidth = child.scrollWidth;
            let clientWidth = child.clientWidth; 
            if(scrollWidth > clientWidth){ 
                this.setState({hover_id:e.target.id,value:value});
            } 
        }
    }

    onMouseLeave(){
        return this.state.hover_id && this.setState({hover_id:"",value:""});
    }
    showAndCopyLargeHiddenData(){ 
        if(this.state.hover_id){   
            
            var element = document.getElementById(this.state.hover_id);
            var top = element.getBoundingClientRect().top + window.scrollY;
            var left = element.getBoundingClientRect().left + window.scrollX;
  
            let style={left:left,top:top,display:"block"};
            return <div className="popup_content" onMouseLeave={(e)=>{this.onMouseLeave()}} style={style}>
                <div  id="copy_text" >
                    <img  src="https://img.icons8.com/offices/72/copy-2.png" className="copy_btn" onClick={(e)=>{ 
                        navigator.clipboard.writeText(this.state.value)
                        }}/>
                        {this.state.value}
                    </div>
            </div>
        }
    
    }
    tbody(cells,rows){
        let itemsPerPage=this.props.itemsPerPage;
        let page=this.state.page ? this.state.page : this.state.page_before_empty;

        if(cells&&rows&&itemsPerPage){
            let p=itemsPerPage*page;
            let r=rows.slice(p-itemsPerPage,p);
            return r.map((x,ind)=>{
                return <tr key={"td" + ind}>
                    {cells.map((i)=>{
                        return Object.keys(x).map((y,index)=>{
                            if(y==i.cell){
                                let value=x[i.cell];
                                if(i.onBefore !== undefined){
                                    value = i.onBefore(value,x);
                                }
                                let id=ind+i.cell;
                                return <td key={"data" + index} id={id}  width={i.width} className="content_td"
                                    onMouseEnter={(e)=>{this.setDataForHiddenText(e,value)}} 
                                    > 
                                    <span>{value}</span>
                                </td>
                                
                            } 
                        })
                    })}                
                    <td></td>
                </tr>
            })
        }
    }


    dataTbody(cells,rows){
        if(cells&&rows){
            let r=rows;
            return r.map((x,ind)=>{
                return <tr key={"td" + ind}>
                    {cells.map((i)=>{
                        return Object.keys(x).map((y,index)=>{
                            if(y==i.cell){
                                let value=x[i.cell];
                                if(i.onBefore !== undefined){
                                    value = i.onBefore(value,x);
                                }
                                let id=ind+i.cell;
                                return <td key={"data" + index} id={id}  width={i.width} className="content_td"
                                    onMouseEnter={(e)=>{this.setDataForHiddenText(e,value)}} 
                                    > 
                                    <span>{value}</span>
                                </td>
                                
                            } 
                        })
                    })}                
                    <td></td>
                </tr>
            })
        }
    }
  

    onPageChange(e){
        let value=e.currentTarget.value;

        if(value!=""&&(isNaN(value)||value<1)){
            return;
        }
        else {
            if(value==""){
                this.setState({page_before_empty:this.state.page},()=>{
                    this.setState({busy:true});
                    if(this.props.onPage){
                        this.props.onPage(value,()=>{
                            this.setState({busy:false});
                        })
                    }
                });
            }
            else if(value.length>1){
                value=value.trim()
            }
            this.setState({page:value},()=>{
                if(this.props.onPage){
                    this.setState({busy:true});
                    this.props.onPage(value,()=>{
                        this.setState({busy:false});
                    })
                }
            })
        }
    }

    onArrowClick(value){
        let page=parseInt(this.state.page);
        if(page){
            if(value=="+"){
                if(this.currentPageIsLastPage()){
                    return;
                }
                page=page+1;
            }
            else if(page>1){
                page=page-1;
            }
            else{
                return;
            }
            this.setState({page:page})
        }
    }

    currentPageIsLastPage(){
        //data can alsow come in chunks so don't move lastPage to state on mount;
        let page=parseInt(this.state.page);
        let rows=this.props.rows.length==0 ? "1" : this.props.rows.length;
        let lastPage=Math.ceil(rows/this.props.itemsPerPage);
        return lastPage==page&&true;
    }   

    onKeyDown(e){
        if(e.key === 'Enter'){
            if(this.props.search){
                this.setState({busy:true})
                this.props.search(this.search,()=>{
                    setTimeout(()=>{
                        this.setState({busy:false});
                    },500)
                });
            }
        }
    }

    onChange(e){
        this.search = e.currentTarget.value.replace('%','');
    }

    render() {
        let cells=this.props.cells;
        let rows=this.props.rows;
        if(cells&&rows){
             
            return (
                <div className={`plain_table ${this.props.className ? this.props.className : ""}`}>
                    {this.state.busy?(
                        <div className="plain-busy"></div>
                    ):null}
                     {this.showAndCopyLargeHiddenData()} 
                    {this.props.title ?  <h5 className="plain_table_title">{this.props.title}</h5> : ""}
                    <div className="pt_table" style={{height:this.props.height}}>
                        <table>
                            <thead>
                                <tr>
                                    {cells.map((x,i)=>{
                                        return <th className={x.className} width={x.width} key={"sl"+i}><span>{x.name}</span></th>
                                    })}
                                    <th><span>&nbsp;</span></th>  
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.tbody ? this.dataTbody(cells,rows) : this.tbody(cells,rows)}
                            </tbody>
                        </table>
                    </div>
                    {this.props.removeFooter ? "" :
                    <div className="pt_footer">
                        <div className="pt_page_change">
                            <i className={`fa fa-angle-left icon-btn ${this.state.page==1&&"disabled"}`} onClick={()=>{this.onArrowClick("-")}}/> 
                                <input value={this.state.page} onChange={(e)=>{this.onPageChange(e)}}/>
                            <i className={`fa fa-angle-right icon-btn ${this.currentPageIsLastPage()&&"disabled"}`} onClick={()=>{this.onArrowClick("+")}}/>
                        </div>  
                        {this.props.total !== undefined?(
                            <div className="pt_center">
                                <span>Pages: {this.props.page} / {this.props.total}</span>
                                <span>Items: {this.props.count}</span>
                            </div>
                        ):null}
                        {this.props.search?(
                            <div class="gtf-section">
                                <input class="giga-table-search" onChange={this.onChange.bind(this)} onKeyDown={this.onKeyDown.bind(this)} placeholder="search..." />
                            </div>
                        ):null}
                        
                    </div>
                    }
                </div>
               
                
            );
        }
        else{
            return ""
        }
    }
}


const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default
    withRouter(connect(
        mapStateToProps,
        mapDispatchToProps
    )(PlainTable));