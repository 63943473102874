import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { uniqString } from '../../helpers/stringHelper';
import GigaTableTh from './GigaTableTh';

class GigaTableHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      select_all: false,
    };

    this.ref = React.createRef();

    this.onDragChange = this.onDragChange.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  componentDidMount(){
    this.setState({id:uniqString(10,'gigatable-')},()=>{

    })
  }
  componentDidUpdate(prevProps){
    if(this.props.uncheckAndClearState === true){
      // console.log("HEADER")
      // console.log(this.props.uncheckAndClearState,"!=",prevProps.uncheckAndClearState)
      this.props.index.setState({select_all:false})
      this.setState({select_all: false})
      this.props.index.setState({uncheckAndClearState:false})
    }
  }

  onDragChange(item){

  }

  selectAll(){
    this.setState({select_all: this.state.select_all ? false : true},()=>{
      this.props.onSelectAll(this.state.select_all);
    });
  }

  render() {

   if(!this.props.cells) return '';

    return (
      <div className="giga-table-header">
          <table width={this.props.width} id={this.state.id}>
            <thead>
                <tr ref={this.ref}>
                    <th width="25" align="left"  className="giga-fixed-td"><input type="checkbox" onChange={this.selectAll} checked={this.state.select_all ? true:false} /></th>
                    {this.props.cells.map((c,i)=>{
                        return <GigaTableTh 
                          node={this.ref}
                          index={this.props.index}
                          cell={c} 
                          key={i} 
                          opacity={0.8} 
                          enable={true} 
                          cells={this.props.cells} 
                          id={this.props.id}
                          onChange={this.onDragChange} />
                    })}
                    <th></th>
                </tr>
            </thead>
          </table>
      </div>
    );
  }
  
}


const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default 
    withRouter(connect(
      mapStateToProps,
      mapDispatchToProps
  )(GigaTableHeader));