import React from 'react';

export default class Empty extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
          
        };
      }


      render(){

            return ''

      }
}