import $ from 'jquery';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderDatePicker,
 

} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidatePhone, 
    ValidateDate,
    ValidateString,
    
} from '../helpers/validation';
import AlertyBox from "../libraries/AletyBox";
import { fetchHelper } from "../helpers/fetchHelper";
import { reducer , change as changeFieldValue, change } from "redux-form";
import { 
    server_img_host,
    page_icon_path,
    page_icon_mobile_path, page_thumb_path, page_image_path
 } from '../config/vars';

export const backofficePagesConf = {
    fields: {
        'id' : {
            name: 'id',
            type: 'text',
            component: renderFieldInput,
            label: 'ID',
            validate:[ValidateNumeric],
            warn: [],
            size: 'small',
            value: '',
            readonly:true,
        },
        'parent_id' : {
            name: 'parent_id',
            type: 'text',
            component: renderFieldInput,
            label: 'ID roditeljske stranice',
            validate:[ValidateNumeric],
            warn: [],
            size: 'small',
            value: '', 
        },
        'position' : {
            name: 'position',
            type: 'text',
            component: renderFieldInput,
            label: 'Pozicija',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [],
            size: 'small',
            value: '', 
        },
        'title' : {
            name: 'title',
            type: 'text',
            component: renderFieldInput,
            validate:[ValidateRequire],
            label: 'Naziv stranice',
            warn: [],
            size: 'medium',
            value: '', 
        },
        'slug' : {
            name: 'slug',
            type: 'text',
            component: renderFieldInput,
            validate:[ValidateRequire],
            label: 'Slug',
            warn: [],
            size: 'medium',
            value: '', 
        },
        'icon' : {
            name: 'icon',
            type: 'text',
            component: renderFieldInput,
            validate:[],
            label: 'Ikonica',
            warn: [],
            size: 'medium',
            value: '', 
        },
        'view' : {
            name: 'view',
            type: 'text',
            component: renderFieldInput,
            validate:[],
            label: 'Pomocno polje za SideMenu',
            warn: [],
            size: 'medium',
            value: '', 
        },
        'nav' : {
            name: 'nav',
            type: 'text',
            component: renderFieldInput,
            label: 'Nav',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [],
            size: 'small',
            value: '', 
        },
        'description' : {
            name: 'description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Opis',
            size: 'large',
            height: 700,
            value: '',
        },
        'status' : {
            name: 'status',
            component: renderFieldSelect,
            label: 'Status',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [],
            size: 'medium',
            value: '',
            options:[
                {text:'Neaktivan',value:0},
                {text:'Aktivan',value:1},
                
            ], 

        },

    },
    initialValues: {}
}