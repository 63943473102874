import React from 'react';

// import {useNode}
import {Editor, Frame, Canvas, Selector, Element } from "@craftjs/core";
import {useNode } from "@craftjs/core";

import { Text , Container , Save} from '../../../../../components/selectors';
import { Viewport , RenderNode } from '../../../../../components/editor';


class Articles extends React.Component{
    constructor(props){
        super(props);
    }
  
    render(){
        return(
            <div className="admin-home__main-section">
                
                <Editor resolver={{Text,Container , Save}} onRender={RenderNode}>
                    <Save />
                    <Viewport>
                    <Frame>

                    <Element
                            canvas
                            is={Container}
                            width="800px"
                            height="auto"
                            background={{ r: 255, g: 255, b: 255, a: 1 }}
                            padding={['40', '40', '40', '40']}
                        >
                        <Element
                            canvas
                            is={Container}
                            flexDirection="row"
                            width="100%"
                            height="auto"
                            padding={['40', '40', '40', '40']}
                            margin={['0', '0', '40', '0']}
                            >
                            <Text text="acaaaaa" />
                        </Element>
                        <Element
                            canvas
                            is={Container}
                            flexDirection="row"
                            width="100%"
                            height="auto"
                            padding={['40', '40', '40', '40']}
                            margin={['0', '0', '40', '0']}
                            >
                            <Text text="acaaaaa" />
                        </Element>
                        <Element
                            canvas
                            is={Container}
                            flexDirection="row"
                            width="100%"
                            height="auto"
                            padding={['40', '40', '40', '40']}
                            margin={['0', '0', '40', '0']}
                            >
                            <Container   background={{ r: 0, g: 0, b: 0, a: 1 }} />
                        </Element>
                    </Element>
                        
                    </Frame>
                    </Viewport>
                </Editor>
            </div>
        )
    }
}

export default Articles;