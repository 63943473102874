import React, { Component } from "react";
import { withRouter} from "react-router";
import { Generator } from "../../libraries/Generator";

class Blank extends Component {
  constructor(props) {
    super(props);
    this.state = {
        json: {
            state: {
                title: 'Ovo je ludilo',
                subtitle: 'Podnaslov koji nismo updejtovali',
                cart: 0
            },
            header: {
                props: {
                    className: 'header-page',
                    style: {
                        backgroundColor: 'rgb(249,206,9)',
                        height: 50,
                        position: 'relative',
                    }
                },
                children: [
                    {
                        h2: {
                            text: ['title']
                        },
                    },
                    {
                        small: {
                            text: ['subtitle']
                        }
                    },
                    {
                        div: {
                            props: {
                                style: {
                                    position: 'absolute',
                                    top: 10,
                                    right: 20
                                }
                            },
                            children: [
                                {string: 'Korpa: '},
                                {b:{text:['cart']}}
                            ]
                        }
                    }
                ]
            },
            div: {
                props: {
                    style: {
                        width: 300,
                        height: 300,
                        border: 'solid 1px #ccc'
                    }
                },
                children: [
                    {
                        p: {
                            text: ['title'],
                            props: {
                                className: 'noselect',
                                style: {
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    padding: '10px'
                                }
                            },
                            events: {
                                onClick: [
                                    {changeColor:['black','red']},
                                    {changeColorTo: ['span1',['black','green']]},
                                    {state:{subtitle:'Promena na click'}}
                                ]
                            },
                            children: [
                                {
                                    span: {
                                        text: 'Nekada: ',
                                        props: {
                                            id: 'span1',
                                            style: {
                                                display: 'block',
                                                fontSize: 12
                                            }
                                        },
                                        children: [
                                            { string: ' i ' },
                                            { b: { text: 'kao' } },
                                            { string: ' sada' }
                                        ]
                                    }
                                },
                                {
                                    img: {
                                        props: {
                                            src: 'https://img.gigatron.rs/img/products/medium/image5ece7e575cb85.webp',
                                            title: 'Test slika',
                                            width: 200,
                                            style: {
                                                display: 'block',
                                                margin: 'auto',
                                                maxWidth: '90%'
                                            }
                                        }
                                    }
                                },
                                {
                                    button: {
                                        text: 'Add to cart',
                                        events: {
                                            onClick: [
                                                {self: {text:'Dodato u korpu'}},
                                                {fetch:{method:'get',query:{id:111},url:'http://localhost:5002/v1/cart/add'}}
                                            ]
                                        },
                                        props: {
                                            id: 111,
                                            style: {
                                                padding: '10px 20px',
                                                backgroundColor: 'green',
                                                color: '#fff',
                                                cursor: 'pointer'
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        children: [],
        pageState: {}
    }

    this.controlState = null;
    this.loadingstate = false;


    // test params start

    // test params end

  }


  componentDidMount(){

    this.load();

    // TEST UPDATE STATE
    // example: state change can be initialized by fetch
    // setTimeout(()=>{
    //         var json = this.mergeJSON({state:{title:'Idemooo'}});
    //         this.setState({json:json},()=>{
    //             this.load();
    //         });
        
    // },2000);
    // setTimeout(()=>{
    //     var json = this.mergeJSON({state:{title:'Updateeee'}});
    //     this.setState({json:json},()=>{
    //         this.load();
    //     });
    // },4000);
    // setTimeout(()=>{
    //     var json = this.mergeJSON({state:{title:'Ma nemoj'}});
    //     this.setState({json:json},()=>{
    //         this.load();
    //     });
    // },6000);
    // setTimeout(()=>{
    //     var json = this.mergeJSON({state:{subtitle:'Na kraju menjamo samo njega'}});
    //     this.setState({json:json},()=>{
    //         this.load();
    //     });
    // },8000);
    // TEST UPDATE END
      
  }


  mergeJSON(json){
    var final = this.state.json;
    Object.keys(json).map((k)=>{
        final[k] = {...final[k], ...json[k]};
    });
    return final;
  }


  async componentDidUpdate(){
    if(this.controlState !== this.state.pageState && !this.loadingstate){
        this.loadingstate = true;
        this.load().then(()=>{
            this.controlState = this.state.pageState;
            this.loadingstate = false;
        })
    }
  }


  async load(){
    return new Promise(async (resolve)=>{
        var json = JSON.stringify(this.state.json);
        // console.log(json)
        var generator = new Generator(this);
        var children = await generator.build( json );
        this.setState({children:children},()=>{
            resolve(1);
        });
    })
  }


  
  render(){
      return <div className="admin-home__main-section">
          {this.state.children}
      </div>
  }
}



  export default withRouter((Blank));