import React, { Component } from 'react'
import './style.scss';


class AlertMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        text:'',
        clss:'hidden'
    };

    this.tm = null;

  }

  componentDidUpdate(){
      
    var text = '';
    var clss = 'hidden';
    if(this.props.error){
        text = this.props.error;
        clss = 'error-alert';
    }
    if(this.props.success){
        text = this.props.success;
        clss = 'success-alert';
    }
    if(this.state.text !== text && this.state.clss !== clss)
    this.setState({text:text,clss:clss},()=>{
        clearTimeout(this.tm);
        this.tm = setTimeout(()=>{
            this.props.resetMessage();
        },4000);
    });
  }


  view(){
    return <div className={"giga-page-alert "+this.state.clss}>{this.state.text}</div>
  }
 

  render() {

    return (this.view());
  }
}



  export default(AlertMessage);