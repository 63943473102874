import React from 'react';
import { fetchHelper } from "../../../../helpers/fetchHelper";
import { product_img_path } from '../../../../config/vars';
import $ from "jquery";
import Check from "../../../Check/check";
import { fetchApiHelper } from "../../../../helpers/fetchApiHelper";
let delay;
export default class ProductsSearchField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",

            db_items: [],
            active_items_search:[],

            dropdown_type:"add-products",
            
            clicked_id:"",

            loading:false,
            msg:"",
        }
        this.onChange = this.onChange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
    }

    //OUTSIDE EVENT 
    componentDidMount() {
        global.document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        global.document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                value: "",
                db_items: [],
                active_items_search:[],
                dropdown_type:"add-products",
                clicked_id:"",
             })
        }
    }

    //CHANGE 
    onChange(e) {
        let v = e.target.value;
        this.setState({ value: v ,clicked_id:""});
        clearTimeout(delay);
        let d=v.trim();
        if(this.state.dropdown_type=="add-products"){
            this.searchDB(d)
        }
        else{
            this.searchAddedProducts(d)
        }
    }

    searchDB(d){
        if (d.length > 1) {
            this.setState({loading:true})
            delay = setTimeout(() => {
                this.getProductsEanName();
            },1000);
        }
        else {
            this.setState({ db_items: []});
        }
    }
    async getProductsEanName() {
        let x = { value: this.state.value };
        let r = await fetchHelper.get(`products/search`, x)
        if (r) {
            this.setState({ db_items: r.status ?  r.items : [],loading:false});
        }
    }
 


    searchAddedProducts(d){

            let values = d.split(" ");
            let active_items = this.props.active_items;
            let arr=[];
            active_items.map((x)=>{
                let score=0;
                let str=x.ean+" "+x.id+" "+x.title;
                values.map((i)=>{
                    if(str.toLowerCase().includes(i.toLowerCase())){
                        score=score+1;
                    }
                })
                if(score!=0){
                    x['score']=score;
                    arr.push(x);
                }
            })

            let data=arr.sort(function(a, b) { 
                return b.score - a.score;
            })
            this.setState({ active_items_search:data });

    }




    //DROPDOWN FOR ADD AND DELETE
    dropdown() {
        let d=this.data();
        let active_ids=this.props.active_ids;
            if (d&&d.length) {
                return d.map((x, i) => {
                    let image_name=x.image_name;//td
                    if(x.images){
                        image_name=x.images[0].name;
                    }
                    return <div className="search-item" key={"sdd" + i}>
                        <div>
                            <img src={product_img_path + 'small/' + image_name} />
                            {this.check(active_ids,x.id)}
                        </div>
                        
                        <div>
                            <p>{x.title}</p>
                            <p><span>ID:</span>{x.id}</p>
                            <p><span>EAN:</span>{x.ean}</p>
                        </div>

                        <div onClick={() => {return this.dubleAdd(active_ids,x.id) ? "" : this.onClick(x.id)}} 
                            className={this.dubleAdd(active_ids,x.id) ? "block-adding" :""}>
                            <i className={this.type("fa fa-plus","fa fa-times")} aria-hidden="true"></i>
                        </div>
                        
                    </div>
                })
            }
            else {
                let input_value=this.state.value.trim();
                return <p className="grey-font">
                    {this.type(
                    input_value.length>1 ? this.state.loading ? "...Loading" : "Not found" : "",
                    !input_value ? "Your item list is empty" : "Not found" 
                    )}
                </p>
            }

    }

    data(){
        let d=[];
        if(this.state.dropdown_type=="add-products" ){
            d= this.state.db_items;
        }
        else{
            let ai=this.state.active_items_search;
            if(ai.length){
                d=ai;
            }
            else{
                if(!this.state.value.trim()){
                    d=this.props.active_items;
                }
            }
        }
        return d;
    }
   
    check(ids,id){
        if(ids&&ids.includes(id.toString())){
            return <div className="check-wrapper">
                <Check active={id==this.state.clicked_id}/>
            </div>
        }
    }

    dubleAdd(ids,id){
        return this.state.fastClickDisable==id||this.state.dropdown_type=="add-products"&&ids&&ids.includes(id.toString());
    }

    type(x,y){
        return this.state.dropdown_type=="add-products" ? x : y;
    }


    // SETS(exept double)/REMOVES active items list in parent 
    async onClick(id) {
        //
        this.setState({fastClickDisable:id})
        if(this.state.dropdown_type=="add-products"){
            let r= await fetchApiHelper.postApi(`core/product/ids2`,{ids:id});
            if(r&&r.status){
                let item=r.data.items[0];
                this.props.onItemClick(item,"add");
                this.setState({clicked_id:id});
            }
        }
        else{
            this.props.onItemClick(id,"remove");
            let active_items_search=this.state.active_items_search.filter(item => id != item.id);
            this.setState({clicked_id:id,active_items_search:active_items_search});
        }
        this.setState({fastClickDisable:false})
    }

 

    btn(name,n){
        return <span className={`label-${this.state.dropdown_type==n  ? "active" : "inactive"}`} 
            onClick={()=>{
                this.setState({
                value:"",
                db_items:[],
                active_items_search:[],
                dropdown_type:n,
                clicked_id:"",
            })}}>{name}</span> 
    }

    render() {
        return <div className="form-group search-form-field" ref={this.wrapperRef} >
            <label>
                {this.btn("Add products ","add-products")}
                <i className="fa fa-exchange" aria-hidden="true"></i>
                {this.btn("Remove products","remove-products")} 
            </label> 

            <input className="textfield" value={this.state.value} 
                placeholder={this.type("Search by ean or name","Search added products")} 
                onChange={this.onChange} 
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                />
           
            <div className="search-dropdown">
                {this.dropdown()}
            </div>
        </div>
    }
}
