import React from 'react';
import { ToolbarSection, ToolbarItem , ToolbarTextInput } from '../../editor/Toolbar';
import { ToolbarRadio } from '../../editor/Toolbar/components/ToolbarRadio';

export const ImageSettings = () => {
    return (
      <React.Fragment>
           <ToolbarSection
             title="Image"
           >
            </ToolbarSection>
           <ToolbarSection
             title="Link"
           >
             <ToolbarTextInput  />
           </ToolbarSection>
        
      </React.Fragment>
    );
  };