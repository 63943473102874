 
import React, { Component } from "react";
import TextField from '../../../fields/TextField';
import ColorPickerField from '../../../fields/ColorPickerField';
import SelectField from '../../../fields/SelectField';
import $ from "jquery";

let position=[ 
    [
    //{name:"display",type:"select",options:["inline","block","inline-block","none"]},//ne treba jer se sa flexom odredjuje pozicija na dugme na htmlu
    {name:"position",type:"select",options:["static","relative","absolute"]},//fixed i sticky im ne treba
    {name:"top",type:"text",options:[]}, 
    {name:"bottom",type:"text",options:[]},
    {name:"left",type:"text",options:[]},
    {name:"right",type:"text",options:[]}],

    [{name:"zIndex",type:"text",options:[]},
    {name:"overflow",type:"select",options:["visible","scroll","hidden","auto"]},
    {name:"visibility",type:"select",options:["visible","hidden"]}]
] 
let dimensions=[ 
    [{name:"width",type:"text",options:[]},
    {name:"maxWidth",type:"text",options:[]},
    {name:"minWidth",type:"text",options:[]}],

    [{name:"height",type:"text",options:[]},
    {name:"minHeight",type:"text",options:[]},
    {name:"maxHeight",type:"text",options:[]}],

    [{name:"verticalAlign",type:"select",options:["baseline","text-top","text-bottom","sub","super"]}]

]

let background=[
    [{name:"background",type:"color",content:[]}]
    // backgroundPosition:{type:"color",content:["left top", "left center", "left bottom", "right top", "right center", "right bottom", "center top", "center center", "center bottom"]}
    // backgroundImage:{type:"image",content:[]},
]


let text=[
    [{name:"color",type:"color",options:[]},
    {name:"textShadow",type:"text",options:[]},//todo custom 
    {name:"textIndent",type:"text",options:[],class_name:""},
    // {name:"direction",type:"select",options:["ltr","rtl","initial","inherit"]},
    {name:"textAlign",type:"select",options:["left","center","right","justify"]},
    // left if direction is ltr, and right if direction is rtl todo 
    {name:"textDecoration",type:"select",options:["none","overline","line-through","underline"]},
    {name:"textDecorationColor",type:"color",options:[]}],

    [{name:"lineHeight",type:"text",options:[]},
    {name:"letterSpacing",type:"text",options:[]},
    {name:"textTransform",type:"select",options:["none","uppercase","lowercase","capitalize"]},
    {name:"whiteSpace",type:"select",options:["nowrap","normal","pre"]}, 
    ], 
]

let font=[
    [{name:"fontSize",type:"text",options:[]}, 
    {name:"fontWeight",type:"text",options:[]},
    {name:"fontFamily",type:"select",options:["inherit","Verdana, sans-serif","FreeMono, monospace","Apple Chancery, cursive"]}],//todo inject list

    [{name:"fontStyle",type:"select",options:["normal","italic","oblique","initial","inherit"]}, 
    {name:"fontVariant",type:"text",options:["normal","small-caps","initial","inherit"]},
    {name:"fontStretch",type:"text",options:["normal","ultra-condensed","extra-condensed","condensed","semi-condensed","semi-expanded","expanded","extra-expanded","ultra-expanded","initial","inherit"]}]

]

let boxes=[
   
    [{name:"margin",type:"text",options:[]},
    {name:"padding",type:"text",options:[]},],

    [{name:"border",type:"text",options:[]},
    {name:"borderColor",type:"color",options:[]},
    {name:"borderStyle",type:"text",options:[]},
    {name:"borderWidth",type:"text",options:[]}],
    [{name:"boxShadow",type:"text",options:[]},
    {name:"cursor",type:"select",options:["auto","pointer","text","none","url"]}
    ],

    // [{name:"marginTop",type:"text",options:[],class_name:"box-2"},
    // {name:"marginBottom",type:"text",options:[],class_name:""},
    // {name:"marginLeft",type:"text",options:[],class_name:""},
    // {name:"marginRight",type:"text",options:[],class_name:""}],

    // [{name:"paddingTop",type:"text",options:[],class_name:"box-3"},
    // {name:"paddingBottom",type:"text",options:[],class_name:""},
    // {name:"paddingLeft",type:"text",options:[],class_name:""},
    // {name:"paddingRight",type:"text",options:[],class_name:""}],

    // [{name:"borderTopColor",type:"text",options:[],class_name:"box-4"},
    // {name:"borderBottomColor",type:"text",options:[],class_name:""},
    // {name:"borderLeftColor",type:"text",options:[],class_name:""},
    // {name:"borderRightColor",type:"text",options:[],class_name:""}], 

    // [{name:"borderTopStyle",type:"text",options:[],class_name:"box-5"},
    // {name:"borderBottomStyle",type:"text",options:[],class_name:""},
    // {name:"borderLeftStyle",type:"text",options:[],class_name:""},
    // {name:"borderRightStyle",type:"text",options:[],class_name:""}],

    // [{name:"borderTopWidth",type:"text",options:[],class_name:"box-6"},
    // {name:"borderBottomWidth",type:"text",options:[],class_name:""},
    // {name:"borderLeftWidth",type:"text",options:[],class_name:""},
    // {name:"borderRightWidth",type:"text",options:[],class_name:""}],
]

 
class EditorCSS extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    
    }

 
    render() {

        let title=this.props.item_component_active_title;
        let class_name=this.props.item_component_active_name;
        let active_css=this.props.active_css; 
        let active_css_editor_tab=this.props.active_css_editor_tab;

        if(!(active_css&&active_css_editor_tab&&title&&class_name)){
            return "";
        }
        let titles=["Position","Dimensions","Background","Text","Font","Boxes"];
        if(class_name=="main_item_wrapper"){
            titles=["Background","Text","Font","Boxes"];
        }
        // if(this.state.item_component_active_name.includes("wrapper")){
        //     text[1].pop();//removed whiteSpace because we use flexWrap to align content in ItemTypes
        // }

        let left=this.props.change_sidenav_position ? "368px" :"0px";
        let right=this.props.change_sidenav_position ? "0px" :"368px";


        var p = (active_css_editor_tab.toLowerCase());
        var a = eval(p); 

        return <div className="item_tree_wrapper css_editor" style={{ left:left,right:right}}>
            <div className="item_tree_main_title"><p>CSS editor for item element: <span>{title}</span></p></div>
            <div className="item_tree_main_css_content"> 
                <div className="item_tree_titles">
                    {titles.map(title=>{
                        return  <p key={title} className={`item_tree_title  ${active_css_editor_tab==title ? "active":""}`}
                        onClick={()=>{ this.props.changeActiveEditorTab(title) }}
                        >{title}</p>
                    })}
                    
                </div>
                <div className="item_tree_params">
                    {  
                        a.map((z,indx)=>{
                            return <div key={"box"+indx} className="box">
                                {
                                    z.map(x=>{
                                        let name=x.name; 
                                        let css_el="";
                                        switch(x.type) {
                                            case "text":
                                                css_el= <TextField 
                                                // type={"number"}
                                                className="text_field_side_nav" 
                                                name={name}
                                                onChange={(e)=>{
                                                    let ct=e.currentTarget;
                                                    var value =ct.value;
                                                    this.props.changeElementCSSOptionsForDiffMediaQ({value:value,name:name,class_name:class_name});
                                                }}
                                                block_setting_from_input={true} 
                                                value={active_css[name]}

                                                />  
                                            break;
                                            case "select":
                                                css_el=<SelectField  
                                                className="select_field_side_nav"
                                                name={name}
                                                value={active_css[name]} 
                                                values={ 
                                                    [...x.options,"unset"].map((o)=>{
                                                        if(o=="unset"){
                                                            return {value:"", text:o}
                                                        }
                                                        return {value:o, text:o}
                                                    })
                                                }  
                                                block_setting_from_input={true} 
                                                onChange={(e)=>{
                                                    let value = e.currentTarget.value;
                                                    this.props.changeElementCSSOptionsForDiffMediaQ({value:value,name:name,class_name:class_name})}}
                                                />
                                                
                                            break;
                                            case "color": 
                                                css_el= <ColorPickerField 
                                                className="color_field_side_nav"
                                                color={active_css[name]} 
                                                block_setting_from_input={true} 
                                                name={name} 
                                                enableAlpha={true} 
                                                onChange={(e)=>{
                                                    // let name=Object.keys(e)[0];
                                                    let value=Object.values(e)[0];
                                                    this.props.changeElementCSSOptionsForDiffMediaQ({value:value,name:name,class_name:class_name})}}/>
                                            break;
                                                
                                        }
                                        return <div key={name} className={`item_tree_title`}>
                                            <p>{name.replace( /([a-z])([A-Z])/g, "$1 $2").toLowerCase().replace(/^./, str => str.toUpperCase())}</p>
                                            {css_el}
                                        </div>
                                    })
                                }
                            </div>
                            
                
                        
                        })
                    }     
                </div>    
            </div>
        </div>
       

     
    }
 
}
 
 

export default (EditorCSS) ;
