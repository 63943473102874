import React, { useState }  from 'react';
import { addPlugin } from '../../helpers/addPlugin';
import View from './view';
import Form from './form';

import {ReactComponent as Icon} from './icon.svg';
import { renderToString } from 'react-dom/server';
import { date_sql_to_rs } from '../../../../../helpers/dateHelper';

export const counterPlugin = (editor, node) => {

   

    var pluginName      = 'counter_plugin', 
        pluginTitle     = 'Counter', 
        pluginFolder    = 'counterPlugin',
        pluginIcon      = ''

    var initialData = {
      start_date: date_sql_to_rs(new Date()),
      end_date: date_sql_to_rs(new Date()),
      count_start_label: 'Vreme do početka akcije',
      titleColor: '#000000',
      labelColor: '#000000',
      counterColor: '#000000',
      backgroundColor: '#FFFFFF',
    }

    var icon = renderToString(<Icon />);

    addPlugin(editor, node, Form, View, initialData, pluginName, pluginTitle, pluginFolder, icon);
};