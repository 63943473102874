import React, { Component } from 'react';

import './style.scss';

class GigaSpinner extends Component {
  constructor(props) {
    super(props);

    this.state = {
        
    };

   
  }

  componentDidMount(){
   
  }

  render() {

    return (
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    );
  }
}


  export default (GigaSpinner);