import $ from 'jquery';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderFieldCKEditor,
    renderGallery,
    renderGigaEditor,
    renderEditor,
    renderDatePicker
} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateString,
    ValidateEmail,
    ValidateAddress,
    ValidateFileImage
} from '../helpers/validation';

import AlertyBox from "../libraries/AletyBox";
import { fetchHelper } from "../helpers/fetchHelper";



export const productConf = {
    fields: {
        'gallery' : {
            name: 'gallery',
            component: renderGallery,
            label: 'Galerija',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                width: 300,
                height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 2
            })],
            warn: [],
            value: '',
            url: 'http://ozone.devgigatron.com/img/gigaapi/gallery/thumb/',
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            section:'product',
        },
        'vendor_id' : {
            name: 'vendor_id',
            type:'text',
            component: renderFieldInput,
            label: 'Vendor ID',
            validate: [ValidateAlphaNumeric ],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: ''
        },
        'category_id': {
            name:'category_id',
            type: 'select',
            component: renderFieldSelect,
            label: 'Kategorija',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
            trigger: (value, input, instance, controler, parentValue) => {
                
                input.onChange(value);
                controler.cat = value;
                var data = controler.getGroups(value);
                data.then((res) => {
                    controler.props.updateField('groups' , res)
                    controler.props.updateField('subcategories' , '')
                })
                instance.fields.subcategory_id.options = [];
            },
            options:[
            ]
        },
        'group_id': {
            name: 'group_id',
            component: renderFieldSelect,
            label: 'Grupa',
            // validate:[ValidateRequire, ValidateNumeric],
            // warn:WarnNumeric,
            size:'medium',
            value:'',
            parentValue: '',
            trigger: (value, input, instance, controler, parentValue) => {
                
                input.onChange(value);
                controler.getSubCategories(value);

            },
            options:[
                               
            ],
        },
        'subcategory_id' : {
            name: 'subcategory_id',
            component: renderFieldSelect,
            label: 'Podkategorija',
            validate:[ValidateRequire, ValidateNumeric],
            warn:WarnNumeric,
            size:'medium',
            value:'',
            disabled:false,
            parentValue: '',
            options:[ ]
        },
        'name': {
            name:'name',
            component: renderFieldInput,
            label: 'Ime',
            validate:[ValidateRequire, ValidateString],
            warn:[],
            size:'medium',
            value:'',
        },
        'brand_id': {
            name: 'brand_id',
            component: renderFieldSelect,
            label: 'Brend',
            // validate:[ ValidateNumeric],
            // warn:WarnNumeric,
            size:'medium',
            value:'',
            options:[ ] //ValidateRequire
        },
        'energy': {
            name: 'energy',
            component: renderFieldSelect,
            label: 'Energetski razred',
            validate:[ ValidateNumeric],
            warn:WarnNumeric,
            size:'medium',
            value:'',
            options:[ ] //ValidateRequire
        },
        'warranty_id': {
            name: 'warranty_id',
            component: renderFieldSelect,
            label: 'Garancija',
            // validate:[ValidateRequire, ValidateNumeric],
            // warn:WarnNumeric,
            size:'medium',
            value:'',
            options:[ ]
        },
        'single_user_limitation': {
            name: 'single_user_limitation',
            component: renderFieldInput,
            label: 'Ogranicenje za pojedinacnog kupca',
            validate:[ValidateRequire, ValidateNumeric],
            warn:WarnNumeric,
            size:'medium',
            value:'',
            options:[ ]
        },
        'statistic_sold': {
            name:'statistic_sold',
            component: renderFieldInput,
            label: 'Prodato',
            validate:[ValidateRequire, ValidateAlphaNumeric],
            warn:WarnAlphaNumeric,
            size:'medium',
            value:'',
        },
        'statistic_visits': {
            name:'statistic_visits',
            component: renderFieldInput,
            label: 'Posete',
            validate:[ValidateRequire, ValidateAlphaNumeric],
            warn:WarnAlphaNumeric,
            size:'medium',
            value:'',
        },
        'statistic_reviews':{
            //grade or statistic_reviews
            name:'statistic_reviews',
            component: renderFieldInput,
            label: 'Ocene',
            validate:[ValidateRequire, ValidateNumeric],
            warn:WarnNumeric,
            size:'medium',
            value:'',
        },
        'statistic_rating':{
            name:'statistic_rating',
            component: renderFieldInput,
            label: 'Rejting',
            validate:[ValidateRequire, ValidateNumeric],
            warn:WarnNumeric,
            size:'medium',
            value:'',
        },
        'date_created':{
            name: 'date_created',
            component: renderDatePicker,
            label: 'Datum postavljanja',
            size: 'medium',
            value: '',  
            readonly:true,
            disabled:true,
        },
        'promotion': {
            name: 'promotion',
            label: 'Promocija',
            component: renderRadioButtons,
            validate:[ValidateRequire],
            warn: [],
            value: '',
            options: [
                {label:'Da',value:1,name:'promotion'},
                {label:'Ne',value:0,name:'promotion'}
            ]
        },
        'sale': {
            name: 'sale',
            label: 'Rasprodaja',
            component: renderRadioButtons,
            validate:[ValidateRequire],
            warn: [],
            value: '',
            options: [
                {label:'Da',value:1,name:'sale'},
                {label:'Ne',value:0,name:'sale'}
            ]
        },
        'outlet': {
            name: 'outlet',
            label: 'Outlet',
            component: renderRadioButtons,
            validate:[ValidateRequire],
            warn: [],
            value: '',
            options: [
                {label:'Da',value:1,name:'outlet'},
                {label:'Ne',value:0,name:'outlet'}
            ]
        },
        'status' : {
            name: 'status',
            label: 'Status',
            component: renderRadioButtons,
            validate:[ValidateRequire],
            warn: [],
            value: '',
            options: [
                {label:'Aktivan',value:1,name:'status'},
                {label:'Neaktivan',value:0,name:'status'}
            ]
        },
        'gigadrive': {
            name: 'gigadrive',
            label: 'Gigadrive',
            component: renderRadioButtons,
            validate:[ValidateRequire],
            warn: [],
            value: '',
            options: [
                {label:'Da',value:1,name:'giga_drive'},
                {label:'Ne',value:0,name:'giga_drive'}
            ]
        },
        'online_buy':{
            name: 'online_buy',
            label: 'Online kupovina',
            component: renderRadioButtons,
            validate:[ValidateRequire],
            warn: [],
            value: '',
            options: [
                {label:'Da',value:1,name:'online_shopping'},
                {label:'Ne',value:0,name:'online_shopping'}
            ]
        },
        'recommendation': {
            name: 'recommendation',
            label: 'Preporuka',
            component: renderRadioButtons,
            validate:[ValidateRequire],
            warn: [],
            value: '',
            options: [
                {label:'Nema',value:0,name:'recommendation'},
                {label:'Gigatron',value:1,name:'recommendation'},
                {label:'Tehnika',value:2,name:'recommendation'}
            ]
        },
        'description':{
            name: 'description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Siri opis proizvoda',
            size: 'large',
            height: 100,
            value: '',
        },
        'seo_title': {
            name:'seo_title',
            component: renderFieldInput,
            label: 'Seo naslov',
            validate:[ValidateRequire, ValidateAlphaNumeric],
            warn:WarnAlphaNumeric,
            size:'medium',
            value:'',
        },
        'seo_description': {
            name: 'seo_description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Seo opis',
            size: 'large',
            height: 100,
            value: '',
        },
        'price_list': {
            name: 'price_list',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Lista cena',
            size: 'large',
            height: 100,
            value: '',
        },
        'sticker': {},
        'new_product_synonim_for_search': {
            name:'new_product_synonim_for_search',
            component: renderFieldInput,
            label: 'Novi sinonim proizvoda za search',
            validate:[ValidateRequire, ValidateAlphaNumeric],
            warn:WarnAlphaNumeric,
            size:'medium',
            value:'',
        },
        'search_synonyms':{
            name: 'search_synonyms',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Sinonimi proizvoda za search',
            size: 'large',
            height: 100,
            value: '',
        },
        'other':{
            name: 'other',
            component: renderEditor,
            label: 'Ostalo',
            height: 500,
            value: '',
            contentid:'',
            index: null,
            url: 'http://ozone.devgigatron.com/',
            img_list_url:'http://ozone.devgigatron.com/img/gigaapi/',
        },
        'image':{
            name: 'image',
            component: renderFieldUploadImage,
            label: 'Slika',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                // width: 300,
                // height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 1
            })],
            warn: [],
            value: '',
            // url: server_img_host + page_image_path,
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            maxLength:"100", 
            remove: (e,index,name) => {
                if(e.current){
                    AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                        if(result.isConfirmed){
                            let id=e.current.value;
                            // var response = await fetchHelper.delete('pages/remove_image/'+id+"/"+name);
                            // AlertyBox.message(response.data);
                            // if(response.status && response.data.status){
                            //     index.setState({active_page:response.data.data});
                            // }
                        }
                    })
                }
            } 
        },
        'content': {
            name: 'content',
            component: renderEditor,
            label: 'Sadržaj',
            height: 500,
            value: '',
            contentid:'',
            index: null,
            url: 'http://ozone.devgigatron.com/',
            img_list_url:'http://ozone.devgigatron.com/img/gigaapi/',
        },
        'content_mobile':{
            name: 'content_mobile',
            component: renderEditor,
            label: 'Sadržaj Mobilna',
            height: 500,
            value: '',
            contentid:'',
            index: null,
            url: 'http://ozone.devgigatron.com/',
            img_list_url:'http://ozone.devgigatron.com/img/gigaapi/',
        }

    },
    initialValues: {}
}