import React from 'react';
import { renderToString } from 'react-dom/server';
import {ReactComponent as Icon} from './icon.svg';
import { b64DecodeUnicode } from '../helpers/base64';

export const pluginController = (editor) => {
  if (window && window.tinymce) {
    const { tinymce } = window;
    tinymce.PluginManager.add("plugincontroller", (editor, url) => {

        var icon = renderToString(<Icon />);

        var openDialog = async function() {
            var 
            node = editor.selection.getNode(),
            plugin = node.dataset.plugin;
            if(plugin){
              const Plugin = await import(`./${plugin}/index.js`);

              Plugin[plugin](editor, node, b64DecodeUnicode(node.dataset.json,node));
            }
        } 

        editor.ui.registry.addIcon('plugincontroller', icon);

        // Add a button that opens a window
        editor.ui.registry.addButton("plugincontroller", {
          text: "Edit Plugin",
          icon: 'plugincontroller',
          onAction: function() {
            // Open window
            openDialog();
          },
          onSetup: function(buttonApi) {
            buttonApi.setDisabled(true);
            editor.formatter.formatChanged("plugincontroller", function(state) {
              buttonApi.setDisabled(state?false:true);
            });
          },
        });

        return {
          getMetadata: function() {
            return {
              name: "Templates",
              url: "http://exampleplugindocsurl.com",
            };
          },
        };
    });
  }
};
