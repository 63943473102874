import React, { Component, useReducer } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { reduxAsyncValidate, reduxReduceVaues, reduxPrepareFormData } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";

import { fetchHelper } from '../helpers/fetchHelper';
import AlertyBox from '../libraries/AletyBox';
import { setBusy } from '../modules/forms';
import { stickersConf } from "./stickersConf";
// import { userConf } from "./userConf";

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';
import ColorPickerField from '../libraries/GigaEditor/tinymce/fields/ColorPickerField'

import './../assets/css/form.scss';
import GigaEditor from '../libraries/GigaEditor';
import { date_sql_to_rs, date_rs_to_sql, SubmitDataPickerValue } from "./../helpers/dateHelper";
import { EditorInstance } from '../libraries/GigaEditor/EditorInstance';
import ProductOptions from './ProductOptions';
import { API_URL, fetchApiHelper } from '../helpers/fetchApiHelper';

  
class StickersForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            success: null,
            message: null,
            busy: false,
            rnd: 0,
            allow_submit: false,
            color_txt: null,
            color_bg: null,
            definition: undefined,
            is_setup: false
        };

        this.file = null;
        this.editors = {};
        this.setEditorInstance = this.setEditorInstance.bind(this);
        this.onColorChange = this.onColorChange.bind(this);
        this.submit = this.submit.bind(this);
        this.setBusy = this.setBusy.bind(this);
        this.removeSticker = this.removeSticker.bind(this);

        this.is_state_set = false;
        this.optionsInstance = null;
    }

    componentDidMount() {
        stickersConf.index = this;
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data.id) {
            reduxAsyncValidate(this.props.data, this.props);
            if(this.state.color_txt !== this.props.data.color_txt && !this.state.color_txt && this.props.data.color_txt){
                this.setState({color_txt:this.props.data.color_txt})
            }
            if(this.state.color_bg !== this.props.data.color_bg && !this.state.color_bg && this.props.data.color_bg){
                this.setState({color_bg:this.props.data.color_bg})
            }

            if(!this.is_state_set){
                this.is_state_set = true;
                var definition = this.props.data.definition ? JSON.parse(this.props.data.definition) : null;
                if(definition){
                    this.setState({definition:definition});
                } else {
                    this.setState({definition:null});
                }
                
            }
        }
    }


    setBusy(b){
        this.setState({busy:b});
    }

    async submit(data) {
        console.log(data);
        var values = reduxReduceVaues(stickersConf.fields, data);

        var ins = this.optionsInstance.state;

        var categories = {};
        var groups = {};
        var subcategories = {};

        if(ins.categories){
            Object.keys(ins.categories).map((cat_id)=>categories[cat_id]=cat_id);
            Object.keys(ins.categories).map((cat_id)=>{
                var c = ins.categories[cat_id];
                if(c.groups){
                    Object.keys(c.groups).map((group_id)=>{
                        groups[group_id] = cat_id;

                        var g = c.groups[group_id];
                        if(g){
                            if(g.subcategories){
                                Object.keys(g.subcategories).map((sub_id)=>{
                                    console.log('subcategory', sub_id)
                                    subcategories[sub_id] = group_id;
                                })
                            }
                        }
                    })
                }
            });
        }

        // var categories = {};
        // Object.keys(ins.categories).map((cat_id)=>categories[cat_id]=cat_id);
        ins._categories = categories;

        // var groups = {};
        // Object.keys(ins.groups).map((sub_id)=>groups[sub_id]=ins.groups[sub_id].category_id);
        ins._groups = groups;

        // var subcategories = {};
        // Object.keys(ins.subcategories).map((sub_id)=>subcategories[sub_id]=ins.subcategories[sub_id].group_id);
        ins._subcategories = subcategories;

        var post = JSON.stringify( {...values,...ins} );

        this.setBusy(true);
        var response = await fetchApiHelper.postApi(API_URL + 'core/stickers/submit',{data:post}, true, this.file);

   
        this.setBusy(false);

        if (response.status && values && Object.keys(values).length) {
            AlertyBox.message(response.data);
            this.props.index.get();
        } else {
            var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
            AlertyBox.message(m)
        }
        return true;
    }

    onImage(e){
        var files = e.currentTarget.files;
        if(files && files.length){
            var file = files[0];
            this.file = {...this.file,...{image:file}};
        } else {
            this.file = {...this.file,...{image:null}};
            if(!this.file.image && !this.file.icon) this.file = null;
        }
    }

    onIcon(e){
        console.log(e);
        var files = e.currentTarget.files;
        if(files && files.length){
            var file = files[0];
            this.file = {...this.file,...{icon:file}};
        } else {
            this.file = {...this.file,...{icon:null}};
            if(!this.file.image && !this.file.icon) this.file = null;
        }
    }


    setEditorInstance(e){
        this.editors[e.props.id] = e;
    }

    onColorChange(e){ 
        console.log(e)
        let name=Object.keys(e)[0];
        let value=Object.values(e)[0];
        console.log('set',name,value);



        this.setState({[name]:value});
 
    }


    preview(){

        if(this.state.definition === undefined){
            return <h5>Loading...</h5>
        }

        if(!this.state.definition){
            return <h5>Podešavanje nije pronadjeno</h5>
        }

        var d = this.state.definition;

        if(!d.categories || !Object.keys(d.categories).length){
            if(!d.brands || !Object.keys(d.brands).length){
                return <h5>Stiker je podešen na sve proizvode</h5>
            } else {
                return <>
                    <h5>Stiker je podešen na sve proizvode za brendove:</h5>
                    <ul>
                        {Object.keys(d.brands).map((k,i)=>{
                            return <li key={i}>{d.brands[k].name}</li>
                        })}
                    </ul>
                </>
            }
        }


        const hasGroup = function(id, d, key){
            var items = [];
            if(d && Object.keys(d).length){
                Object.keys(d).map((k,v)=>{
                    var n = d[k];
                    if(n[key]===id){
                        items.push(n);
                    }
                })
            }
            return items.length ? items : false;
        }

        const filters = function(filters,sub_id){
            var items = [];
            if(!filters || !Object.keys(filters).length) return false;

            Object.keys(filters).map((fa,i)=>{
                var f = filters[fa];
                if(parseInt(f.sub_id) === parseInt(sub_id)){
                    items.push(f);
                }
            });

            return items.length ? items : false;
        }

        return Object.keys(d.categories).map((k,i)=>{
            var c = d.categories[k];
            return <div className="sticker-preview-item" key={i}>

                {d.minPrice?(<div className="prev-item"><span>Minimalna cena:</span><b>{d.minPrice}</b></div>):null}
                {d.maxPrice?(<div className="prev-item"><span>Maksimalna cena:</span><b>{d.maxPrice}</b></div>):null}
                {d.exclude?(<div className="prev-item"><span>Ignoriši proizvode:</span><b>{d.exclude}</b></div>):null}
                {d.include?(<div className="prev-item"><span>Uključi proizvode:</span><b>{d.include}</b></div>):null}
                {d.valueStickerOff === 'on' ?(<div className="prev-item">Ne važi za vrednosne stikere</div>):null}
                {d.shock === 'on' ?(<div className="prev-item">Ne važi za šok cenu</div>):null}

                <b>{c.name}</b>
                {d.catbrands[c.id]?(
                    <ul>
                        {Object.keys(d.catbrands[c.id]).map((cb,cbi)=>{
                            var brand = d.catbrands[c.id][cb];
                            return <li className="prev-brand" key={'cb-'+i+'-'+cbi}><b>{brand.name}</b></li>
                        })}
                    </ul>
                ):null}
                {hasGroup(c.id,d.groups,'category_id') ?(
                    <ul>
                        {hasGroup(c.id,d.groups,'category_id').map((group,gi)=>{
                            return <li key={'g-'+i+'-'+gi}><b>{group.name}</b> 
                            {d.subbrands[group.id]?(
                                <ul>
                                    {Object.keys(d.subbrands[group.id]).map((sb,sbi)=>{
                                        var brand = d.subbrands[group.id][sb];
                                        return <li className="prev-brand" key={'grb-'+i+'-'+gi+'-'+sbi}>{<b>{brand.name}</b>}</li>
                                    })}
                                </ul>
                            ):null}
                            {filters(d.filters,group.id)?(
                                <ul>
                                    {filters(d.filters,group.id).map((filter,fi)=>{
                                        return <li className="prev-spec" key={'f-'+i+'-'+fi+'-'+gi}><b>{filter.spec_name} : {filter.name}</b></li>
                                    })}
                                </ul>
                            ):null}
                            {hasGroup(group.id,d.subcategories,'group_id')?(
                                <ul>
                                    <li><h5>Podkategorije</h5></li>
                                    {hasGroup(group.id,d.subcategories,'group_id').map((sub,si)=>{
                                        return <li key={'s-'+i+'-'+gi+'-'+si}><b>{sub.name}</b>
                                        {filters(d.filters,sub.id)?(
                                            <ul>
                                                {filters(d.filters,sub.id).map((filter,fi)=>{
                                                    return <li className="prev-spec" key={'f-'+i+'-'+fi+'-'+gi}><b>{filter.spec_name} : {filter.name}</b></li>
                                                })}
                                            </ul>
                                        ):null}
                                            {d.subbrands[sub.id]?(
                                                <ul>
                                                    {Object.keys(d.subbrands[sub.id]).map((sb,sbi)=>{
                                                        var brand = d.subbrands[sub.id][sb];
                                                        return <li className="prev-brand" key={'sub-'+i+'-'+gi+'-'+si+'-'+sbi}>{<b>{brand.name}</b>}</li>
                                                    })}
                                                </ul>
                                            ):null}
                                        </li>
                                    })}
                                </ul>
                            ):null}
                            </li>
                        })}
                        </ul>
                ):null}
            </div>
        })
    }

    // gigatron_product_stickers
    async setup(){
        this.setState({is_setup:true});

        var response = await fetchApiHelper.postApi( API_URL + 'core/stickers/setup/'+this.props.id,{},true);
        this.setState({is_setup:false});
        if (response.status) {
            AlertyBox.message(response.data);
        } else {
            var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
            AlertyBox.message(m)
        }
    }

    async remove(){
        this.setState({is_setup:true});

        var response = await fetchApiHelper.postApi( API_URL + 'core/stickers/remove/'+this.props.id,{},true);
        this.setState({is_setup:false});
        if (response.status) {
            AlertyBox.message(response.data);
        } else {
            var m = { message: 'Došlo je do greške prilikom uklanjanja', status: false };
            AlertyBox.message(m)
        }
    }


    removeImage(){

        AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
            if(result.isConfirmed){
                var response = await fetchApiHelper.postApi(  API_URL +  'core/stickers/remove_image/'+this.props.id, {}, true);
                AlertyBox.message({status:true,message:'Slika je uklonjena'});
                this.props.index.get();
            }
        })
    }


    removeIcon(){

        AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
            if(result.isConfirmed){
                var response = await fetchApiHelper.postApi( API_URL + 'core/stickers/remove_icon/'+this.props.id, {}, true);
                AlertyBox.message({status:true,message:'Slika je uklonjena'});
                this.props.index.get();
            }
        })
    }


    removeSticker(id){
        AlertyBox.confirm('Da li ste sigurni da želite da obrišete stiker?').then(async (result)=>{
            if(result.isConfirmed){
                var response = await fetchApiHelper.postApi( API_URL + 'core/stickers/remove_sticker/'+this.props.id, {}, true);
                this.props.index.props.history.push('/admin/promotions/stickers');
            }
        })
    }


    render() {

        if (!this.props.data) return '';

        const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

      
        // remove null, set empty string
        stickersConf.initialValues = this.props.data;

        var refID = React.createRef();


        return (

            <>
            {this.state.busy?(
                <div style={{position:"fixed",top:0,left:0,right:0,bottom:0,zIndex:99999,backgroundColor:'rgba(255,255,255,0.7)'}}></div>
            ):null}
                <form id="stickersform" autoComplete="off" onSubmit={handleSubmit((data) => {
                    if (this.state.allow_submit) {
                        this.setState({ allow_submit: false });
                        // console.log('---------',data);
                        // let def = JSON.parse(data.definition);
                        // console.log('def', def);
                        // console.log(this.state);
                        // return;
                        console.log(data)
                        data = SubmitDataPickerValue(data, ['date_created', 'date_updated', 'start_date', 'end_date']);
                        // console.log(data);
                        this.submit(data);
                    }
                })} className="redux-form" encType="multipart/form-data" >
                    {busy ? <div className="form-mask"></div> : ''}
                    <GigaTabs tab={0}>

                        <GigaTab title="Osnovni podaci" /*height={400}*/>

                            <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                                <Cell className="grid-column">
                                    <div className="relative">
                                        <div className="separate-right"></div>
                                        {/* <Field {...pagesConf.fields.id} ref={refID} /> */}
                                        <Field {...stickersConf.fields.id} ref={refID} />
                                        <Field {...stickersConf.fields.name} />
                                        <Field {...stickersConf.fields.alias} />
                                        <span style={{display:'inline-block',marginRight:20}}>
                                            <Field {...stickersConf.fields.promocode_id} />
                                        </span>
                                        <span style={{display:'inline-block'}}>
                                            <Field {...stickersConf.fields.discount} />
                                        </span>
                                        <div>
                                            <span style={{display:'inline-block',marginRight:20}}>
                                                <Field {...stickersConf.fields.start_date} content_id={this.props.id} />
                                            </span>
                                            <span style={{display:'inline-block'}}>
                                                <Field {...stickersConf.fields.end_date} content_id={this.props.id} />
                                            </span>
                                        </div>
                                        <span style={{display:'block',width:'100%'}}>
                                            <Field {...stickersConf.fields.url} />
                                        </span>
                                        
                                        <Field {...stickersConf.fields.image} img_name={this.props.data.image} master={refID} index={this.props.index} onChange={this.onImage.bind(this)} remove={(id)=>this.removeImage(id)} />
                                        <Field {...stickersConf.fields.icon} img_name={this.props.data.icon} master={refID} index={this.props.index} onChange={this.onIcon.bind(this)} remove={(id)=>this.removeIcon(id)} />                        


                                        <span style={{display:'inline-block',marginRight:20}}>
                                            <Field {...stickersConf.fields.color_txt} value={this.state.color_txt?this.state.color_txt:'rgb(255,255,255)'} onChange={this.onChange} />
                                        </span>
                                        <span style={{display:'inline-block'}}>
                                            <Field {...stickersConf.fields.color_bg} value={this.state.color_bg?this.state.color_bg:'rgb(255,255,255)'} onChange={this.onChange} />
                                        </span>
                                
                                    </div>
                                </Cell>
                                <Cell className="grid-column">
                                    <div className="relative">
                                    <div className="separate-right"></div>
                                        <Field {...stickersConf.fields.status} />
                                        <Field {...stickersConf.fields.published} />  
                                        <Field {...stickersConf.fields.priority} />  
                                        <Field {...stickersConf.fields.type_id} />    
                                    </div>                            
                                </Cell>
                                {/* <Cell className="grid-column">
                                   
                                </Cell> */}
                            </Grid>

                        </GigaTab>
                        <GigaTab title="PODEŠAVANJE">
                            <Grid gap="30px"  columns={this.props.data.master_id ?"repeat(auto-fit, minmax(1200px, 1fr))":"minmax(1200px, 1fr)"}>
                                <Cell className="grid-column grid-column-nopad">
                                    <div className="relative">
                                        <ProductOptions categories={this.props.categories} brands={this.props.index.state.brands} instance={this} definition={this.state.definition}/>
                                    </div>
                                </Cell>
                            </Grid>
                        </GigaTab>
                        <GigaTab title="Pregled">
                            <Grid gap="30px"  columns={this.props.data.master_id ?"repeat(auto-fit, minmax(1200px, 1fr))":"minmax(1200px, 1fr)"}>
                                <Cell className="grid-column grid-column-nopad">
                                    <div className="relative">
                                        <div className="sticker-preview">{this.preview()}</div>
                                    </div>
                                </Cell>
                            </Grid>
                        </GigaTab>
                        <GigaTab title="Podesi">
                            <Grid gap="30px"  columns={this.props.data.master_id ?"repeat(auto-fit, minmax(1200px, 1fr))":"minmax(1200px, 1fr)"}>
                                <Cell className="grid-column grid-column-nopad">
                                    <div className="relative">
                                        <div className="sticker-preview">
                                            {this.state.is_setup === false ? (
                                                <>
                                                <button className="setup-btn" onClick={this.setup.bind(this)}>Podesi</button> 
                                                <button className="setup-btn" onClick={this.remove.bind(this)}>Ukloni stikere sa proizvoda</button> 
                                                </>
                                            ):(<p>Sačekajte...</p>)}
                                            
                                        </div>
                                    </div>
                                </Cell>
                            </Grid>
                        </GigaTab>

                    </GigaTabs>
                    <div className="page-bottom-bar">
                        <button className="save-changes-form-btn danger-btn" onClick={this.removeSticker.bind(this)}>Remove</button>
                        <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
                    </div>
                </form>
            </>
        );
    }
}

const selector = formValueSelector('stickersform');

const mapStateToProps = state => ({
    initialValues: stickersConf.initialValues,
    busy: state.forms.busy,
    id: selector(state, 'id'),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBusy,
            updateField: (field, data) => change("stickersform", field, data),
        },
        dispatch
    );

    StickersForm = reduxForm({
    form: "stickersform",
    reduxAsyncValidate,
    enableReinitialize: true,
})(StickersForm);
StickersForm = connect(
    mapStateToProps, mapDispatchToProps
)(StickersForm);
export default StickersForm;
// export default connect(mapStateToProps, mapDispatchToProps)(PagesForm);