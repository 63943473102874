import React, {Component} from 'react';
import GigaTabs from '../../GigaTabs';
import GigaTab from '../../GigaTabs/GigaTab';

export default class ImageModal extends Component {


    constructor(props){
        super(props)
        
        this.state={
            width: 'auto',
            height: 'auto',
            border: 'none',
            borderRadius: '0px',
            overflow: 'initial',
            src: '',
            caption: '',
            alt: '',
            title: '',
            float: 'none',
            marginLeft: 'auto',
            marginTop: 'auto',
            marginRight: 'auto',
            marginBottom: 'auto'
        }

        this.setStyle = this.setStyle.bind(this);
        this.onChange = this.onChange.bind(this);

        this.ref = React.createRef();
    }

    componentDidMount(){
        this.getStyle();
    }

    componentDidUpdate(prevProps){
        if(prevProps.element !== this.props.element){
            this.getStyle();
        }
    }


    setStyle(){

        var img = this.props.element;


        img.style.width     = this.state.width;
        img.style.height    = this.state.height;
        img.width           = this.state.width;
        img.height          = this.state.height;
        img.src             = this.state.src;
        img.alt             = this.state.alt;
        img.title           = this.state.title;

        img.style.float        = this.state.float ? this.state.float : 'none';
        img.style.border       = this.state.border;
        img.style.borderRadius = this.state.borderRadius;
        img.style.overflow     = this.state.borderRadius ?  'hidden' : 'initial';
        img.style.marginLeft   = this.state.marginLeft ?  this.state.marginLeft : 'auto';
        img.style.marginRight  = this.state.marginRight ? this.state.marginRight : 'auto';
        img.style.marginTop   = this.state.marginTop ?  this.state.marginTop : 'auto';
        img.style.marginBottom  = this.state.marginBottom ?  this.state.marginBottom : 'auto';
     

        this.props.editor.setState({modal:''});

        this.props.editor.registerChange();
    }


    getStyle(){

        var img = this.props.element;

        var width = img.style.width;
        var height = img.style.height;
        var src = img.src;
        var alt = img.alt;
        var title = img.title;
        var float = img.style.float ? img.style.float : 'none';

        var border = img.style.border ? img.style.border : 'none'; 
        var borderRadius = img.style.borderRadius;

        var overflow = borderRadius ?  'hidden' :  'initial';

        var marginLeft = img.style.marginLeft ? img.style.marginLeft : 'auto';
        var marginRight = img.style.marginRight ? img.style.marginRight : 'auto';
        var marginTop = img.style.marginTop ? img.style.marginTop : 'auto';
        var marginBottom = img.style.marginBottom ? img.style.marginBottom : 'auto';

        if(!width){
            if(img.width){
                width = img.width;
            }
        }

        if(!height){
            height = img.height;
        }
    
        this.setState({
            width: width,
            height: height,
            border: border,
            borderRadius: borderRadius,
            overflow: overflow,
            src: src,
            alt: alt,
            title: title,
            float: float,
            marginLeft: marginLeft,
            marginRight: marginRight,
            marginTop: marginTop,
            marginBottom: marginBottom
        });

        console.log('size',width, height)

        if(!width || !height){
            var image = new Image();
            image.onload = (e) => {
                console.log(e)
                this.setState({width:image.width, height: image.height});
            };
            console.log('load')
            image.src = src;
        }
    }

    onChange(e){
        this.setState({[e.currentTarget.name]:e.currentTarget.value});
    }


    render(){
       return (<>
        <div className="modal-content" ref={this.ref} style={{height:420}}>
            <GigaTabs tab={0}>
                <GigaTab title="Atributi">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Width</label>
                                        <input name="width" className="small" onChange={this.onChange} value={this.state.width}/>
                                    </div>
                                </td>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Height</label>
                                        <input name="height" className="small" onChange={this.onChange} value={this.state.height} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Radius</label>
                                        <input name="borderRadius" className="small" onChange={this.onChange} value={this.state.borderRadius} />
                                    </div>
                                </td>
                                <td style={{width:'50%'}}>
                                
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Border</label>
                                        <input name="border" className="largest" onChange={this.onChange} value={this.state.border} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Alt</label>
                                        <input name="alt" className="largest" onChange={this.onChange} value={this.state.alt} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Title</label>
                                        <input name="title" className="largest" onChange={this.onChange} value={this.state.title} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Float</label>
                                        <select name="float" className="medium" onChange={this.onChange} value={this.state.float} >
                                            <option value="none">None</option>
                                            <option value="left">Left</option>
                                            <option value="right">Right</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    <div className="ge-form-group">
                                        <label>Margin Top</label>
                                        <input name="marginTop" className="small" onChange={this.onChange} value={this.state.marginTop} />
                                    </div>
                                </td>
                                <td>
                                    <div className="ge-form-group">
                                        <label>Margin Bottom</label>
                                        <input name="marginBottom" className="small" onChange={this.onChange} value={this.state.marginBottom} />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div className="ge-form-group">
                                        <label>Margin Left</label>
                                        <input name="marginLeft" className="small" onChange={this.onChange} value={this.state.marginLeft} />
                                    </div>
                                </td>
                                <td>
                                    <div className="ge-form-group">
                                        <label>Margin Right</label>
                                        <input name="marginRight" className="small" onChange={this.onChange} value={this.state.marginRight} />
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </GigaTab>
                <GigaTab title="URL">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width:'100%'}} colSpan="2">
                                    <div className="ge-form-group">
                                        <label>URL</label>
                                        <input name="src" className="largest" onChange={this.onChange} value={this.state.src} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GigaTab>
            </GigaTabs>
        </div>
        <div className="modal-foot">
                
                <button onClick={this.setStyle}  className="primary-btn">Primeni</button>
                <button onClick={() => this.props.editor.setState({modal:''})}>Zatvori</button>
        </div>
        
    </>)
    }

}