import React, { Component } from "react";
import { Link } from "react-router-dom";

import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import { fetchHelper } from "../../../helpers/fetchHelper";
import GigaTable from '../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import PagesForm from "../../../forms/PagesForm";
import PlainTable from "../../../libraries/PlainTable/PlainTable";
import { fetchApiHelper } from "../../../helpers/fetchApiHelper";
import { API_URL } from "../../../helpers/fetchApiHelper";


class Stickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:null,
      active_page:null,
      selected: null,
      table: null,
      status: false,
      search: '',
      total: 0,
      count: 0,
      items: [],
      busy: false,
    }

    this.table = null;

    this.getTableInstance = this.getTableInstance.bind(this);
    this.addNew       = this.addNew.bind(this);
    this.resetActive  = this.resetActive.bind(this);
    this.search       = this.search.bind(this);

    this.searchtm = null;
    this.id = '';
    this.limit = 100;
    this.page = 1;

  }

  componentDidMount(){
    
  }

  componentDidUpdate(){
    let id=this.props.match.params.id
    id = 'stickerSection-'+ id;
    if  (id !== this.state.id) {
      this.setState({id:id, active_page:null})
        this.get();
    }
  }

  getTableInstance(table){
    this.table = table;
  }
    
  async addNew(){
    
    var r = await fetchHelper.post(`sticker/change`,{section_id:this.props.match.params.id});

    if(r.status&&r.data){
      this.setState({active_page:r.data.data,selected:r.data.data.id});
      this.table.loadData();
      //+show last
    }
    else{
      AlertyBox.serverError();
    }
  }

  async get(cb){
    let response = await fetchHelper.get(`sticker/get`,{limit:this.limit,page:this.page,id:this.id,search:this.state.search});
    this.setState({status:response.status,items: (response.status ? response.data: []), total:response.total, count:response.count});
    if(cb) cb();
  }


  search(value,cb){
      this.setState({search:value},()=>{
        this.get(cb);
      });
  }


  onPage(page,cb){
    this.page = page;
    this.get(cb);
  }

        
  resetActive() {
    this.setState({active_page:null,selected:null})
  }


  async createNew(){
    this.setState({busy:true});
    var result = await fetchApiHelper.postApi(API_URL + 'core/stickers/new_sticker',{}, true);
    this.setState({busy:false});
    if(result.status === 200){
      if(result.data.status){
        if(result.data.id){
          this.props.history.push("/admin/promotions/stickers/edit/"+result.data.id);
        }
      }
    }
  }


  render() {
    
    const cells = [
      {name:'ID',cell: 'id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Tip',cell: 'type_id', width:150,fixed:false, truncate:true},
      {name:'Naslov',cell: 'name', width:450,fixed:false, truncate:true},
      {name:'Alias',cell: 'alias', width:450,fixed:false},
      {name:'Date Start',cell: 'start_date', width:200,fixed:false},
      {name:'Status', cell:'status', custom:true, width:55, align:'center', onBefore:(row)=>{
        var value = parseInt(row); 
        return  <button>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button>
      }},
      {name:'Detalji', cell:'status', custom:true, width:55, align:'center', onBefore:(cell,row)=>{
        return   <a href={"/admin/promotions/stickers/edit/" + row['id']} target="_blank">
          <i className="fa fa-folder-open-o" />
          </a> 
      }},
    ];
    const buttons = [
      {title: 'Nova stranica', type: 'button' , onClick: this.addNew},
    ];
    

    return (
    <>
       <div className="admin-home__main-section">
        <div className="table-wrapper">
        {this.state.busy?'':<button className="btn" onClick={this.createNew.bind(this)}>Kreiraj novi</button>}
        <PlainTable 
          itemsPerPage={100} 
          height={"600px"} 
          cells={cells} 
          rows={this.state.items} 
          search={this.search} 
          onPage={this.onPage.bind(this)} 
          tbody={true} 
          total={this.state.total}
          count={this.state.count}
          page={this.page}
        /> 


        </div>
        {/* +loader */}
        {/* {this.state.active_page ? <PagesForm data={this.state.active_page} onSave={this.onSave} index={this}  />  : ""} */}
      
      </div>
    </>);
  }
}

const mapStateToProps = state => ({

  user: state.auth.user

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
   
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Stickers));