import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "./../../../helpers/fetchHelper";
import GigaTable from './../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { 
  server_img_host, gallery_thumb_path, 
} from './../../../config/vars';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import SlidersForm from "../../../forms/SlidersForm";

class Sliders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_user:null,
      selected: null,
      table: null,
      user_images:null,
      section:"sliders",
      detail: null,
  
      
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
    this.addNew=this.addNew.bind(this);
  }

  componentDidMount(){
    
  }

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_user:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_user:row,selected:row.id})
  }


  resetActive() {
    this.setState({active_user:null,selected:null})
  }

  async addNew(){
      var r = await fetchHelper.post('sliders/change',{});
      console.log(r)
    if(r.status&&r.data){
      this.setState({active_user:r.data.data,selected:r.data.data.id,});
      this.table.loadData();
      //+scroll to left bottom
    }
    else{
      AlertyBox.serverError();
    }
  }


  render() {

    const cells = [
      {name:'ID',cell: 'id',width:130, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Thumbnail prikaz',cell: 'img_name', width:150,fixed:false, truncate:true,className:'draggable',
      onBefore:(value)=>{
        return value ? <img src={server_img_host + gallery_thumb_path + value}/> : '';
      }},
      {name:'Naziv',cell: 'title',width:130, fixed:true, className:''},
      {name:'Opis',cell: 'description', width:130,fixed:true, className:'giga-td-last-fixed'},
      // {name:'Status',cell: 'status', width:130,fixed:false, truncate:true,className:'draggable'},
      {name:'Datum početka',cell: 'start_date', width:150,fixed:false, truncate:true,className:'draggable',onBefore:(value)=>{
        return date_sql_to_rs(value);
      }},
      {name:'Datum kraja',cell: 'end_date', width:150,fixed:false,truncate:true,className:'draggable',onBefore:(value)=>{
          return date_sql_to_rs(value);
        }},
      {name:'Detalji', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return row.id !== 1499709 ? <button onClick={()=>{ 
          this.setState({active_user:row,selected:row.id})
      }}><i className="fa fa-folder-open-o" /></button> : ''
      }},
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center', render:(row)=>{
        var value = parseInt(row.status); 
        return row.id !== 1499709 ? <button onClick={()=> this.table.updateRowCell(row,'status',value?0:1)}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button> : ''
      }},
    ];

    const buttons = [
      {title: 'Novi slider', type: 'button' , onClick: this.addNew},
    ];
    
    return (<>
      <div className="admin-home__main-section">
        <div className="table-wrapper">
          <GigaTable
            cells={cells} 
            pk='id' 
            height={300} 
            buttons={buttons} 
            id='sliders-table' 
            db="gigatron_sliders"
            fetch="sliders"
            delete={true} 
            delete_trigger={'controllers.gallery.delete_images'}
            selected={this.state.selected}
            getInstance={this.getTableInstance}
            run={this.runRow}
            resetActive={this.resetActive}
          />
        </div>
        <SlidersForm data={this.state.active_user}  onSave={this.onSave} index={this} />
      </div>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Sliders));
