import $ from 'jquery';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderGigaEditor,
    renderDatePicker,
 

} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidateFileImage,
    ValidateDate,
    ValidateString,
    
} from '../helpers/validation';
import { fetchHelper } from "../helpers/fetchHelper";
import AlertyBox from "../libraries/AletyBox";
import { reducer , change as changeFieldValue, change } from "redux-form";
import { 
    server_img_host,categorization_icon_path,categorization_slideshow_bg_path,categorization_slideshow_bg_mobile_path
 } from './../config/vars';


//todo css, size ,editor..mobilni content?slike.
//validacija za decimale i  datume koji nisu obavezni.
export const categoryEditConf = {
    fields: {
        'id' : {
            name: 'id',
            type: 'text',
            component: renderFieldInput,
            label: 'ID',
            size: 'small',
            value: '',
            readonly:true,
        },
        'master_id' : {
            name: 'master_id',
            component: renderFieldInput,
            label: 'Master ID',
            size: 'small',
            value: '',
            readonly:true,
            
        },
        'parent_id' : {
            name: 'parent_id',
            label: 'Parent ID',
            size: 'small',
            value: '',
            component: renderFieldSelect,
            validate:[ValidateRequire,ValidateNumeric],
            warn: [WarnNumeric], 
            options: []  
             
        },
        'alias' : {
            name: 'alias',
            component: renderFieldInput,
            label: 'Alias',
            validate:[ValidateAlphaNumeric],
            size: 'small',
            value: '',
            maxLength:"10",
             
        },
        'icon' : {
            name: 'icon',
            component: renderFieldUploadImage,
            label: 'Ikonica',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                width: 300,
                height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 1
            })],
            warn: [],
            value: '',
            url: server_img_host + categorization_icon_path,//add icon path
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            maxLength:"100", 
            remove: (e,index,name) => {
                console.log(e,index,name);
                if(e.current){
                    AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                        if(result.isConfirmed){
                            let id=e.current.value;
                            
                            var response = await fetchHelper.delete('categorization/remove_image/'+id+"/"+name);
                            AlertyBox.message(response.data);
                            if(response.status && response.data.status){
                                index.setState({active_page:response.data.data});
                            }
                        }
                    })
                }
            }   
        }, 
        'name' : {
            name: 'name',
            type: 'text',
            component: renderFieldInput,
            validate:[ValidateRequire],
            label: 'Ime',
            size: 'large',
            value: '',
            maxLength:"60",
        },
        'highlight' : {
            name: 'highlight',
            component: renderFieldSelect,
            validate:[ValidateRequire,ValidateNumeric],
            warn: [WarnNumeric],
            label: 'Vazno',
            size: 'small',
            value: '', 
            options: [
                {text:'Ne', value:0},
                {text:'Da', value:1}
            ]  
        },
        'meta_title' : {
            name: 'meta_title',
            type: 'text',
            component: renderFieldInput,
            label: 'Meta title',
            size: 'large',
            value: '',
            maxLength:"240",
        },
        'meta_keywords' : {
            name: 'meta_keywords',
            type: 'text',
            component: renderFieldInput,
            label: 'Meta ključne reči',
            size: 'large',
            value: '',
            maxLength:"240",
        },
        'meta_description' : {
            name: 'meta_description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Meta Opis',
            size: 'large',
            height: 100,
            value: '',
        },  
        'seo_description' : {
            name: 'seo_description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Seo Opis',
            size: 'large',
            height: 100,
            value: '',
        },        
        'seo_keywords' : {
            name: 'seo_keywords',
            type: 'text',
            component: renderFieldInput,
            label: 'Seo ključne reči',
            size: 'large',
            value: '',
            maxLength:"240",
        },
        'seo_title' : {
            name: 'seo_title',
            type: 'text',
            component: renderFieldInput,
            label: 'Seo title',
            size: 'large',
            value: '',
            maxLength:"240",
        },
        'slideshow_bg' : {
            name: 'slideshow_bg',
            component: renderFieldUploadImage,
            label: 'Slideshow pozadina',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                width: 300,
                height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 1
            })],
            warn: [],
            value: '',
            url: server_img_host + categorization_slideshow_bg_path ,
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            maxLength:"100", 
            remove: (e,index,name) => {
                if(e.current){
                    AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                        if(result.isConfirmed){
                            let id=e.current.value;
                            var response = await fetchHelper.delete("categorization/remove_image/"+id+"/"+name);
                            AlertyBox.message(response.data);
                            if(response.status && response.data.status){
                                index.setState({active_page:response.data.data});
                            }
                        }
                    })
                }
            }   
        },
        'slideshow_bg_mobile' : {
         name: 'slideshow_bg_mobile',
        component: renderFieldUploadImage,
        label: 'MOB - Slishow pozadina',
        validate:[(file)=>ValidateFileImage(file,{
            size:1,
            width: 300,
            height: 300,
            exactWidth: false,
            exactHeight: false,
            accept: ['jpg','jpeg','png'],
            files: 1
        })],
        warn: [],
        value: '',
        url: server_img_host + categorization_slideshow_bg_mobile_path,
        img_name: '',
        place: {
            width: 180,
            height: 180
        },
        maxLength:"100", 
        remove: (e,index,name) => {
            if(e.current){
                AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                    if(result.isConfirmed){
                        let id=e.current.value;
                        var response = await fetchHelper.delete("categorization/remove_image/"+id+"/"+name);
                        AlertyBox.message(response.data);
                        if(response.status && response.data.status){
                            index.setState({active_page:response.data.data});
                        }
                    }
                })
            }
        }   
        },
        'discount' : {
            name: 'discount',
            component: renderFieldSelect,
            label: 'Popust',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
            options:[
                {text:'Ne',value:0},
                {text:'Da',value:1},
                
            ]
        },
        'status' : {
            name: 'status',
            component: renderFieldSelect,
            label: 'Status',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
            options:[
                {text:'Neaktivan',value:0},
                {text:'Aktivan',value:1},
                
            ]
        },
        'mstatus' : {
            name: 'mstatus',
            component: renderFieldSelect,
            label: 'Status za mobilne uredjaje',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
            options:[
                {text:'Neaktivan',value:0},
                {text:'Aktivan',value:1},
                
            ]
        },
        'stock_status' : {
            name: 'stock_status',
            component: renderFieldSelect,
            label: 'Na stanju',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [WarnNumeric],
            size: 'small',
            value: '',   
            options: [
                {text:'Na stanju', value:0},
                {text:'Nije na stanju', value:0}
            ]       
        },
        'stock_status_start' : {
            name: 'stock_status_start',
            component: renderDatePicker,
            label: 'Stavljeno na stanje',
            // validate:[ValidateNumeric],
            size: 'medium',
            value: '',
            readonly:true
        },
        //todo
        'stock_status_end' : {
            name: 'stock_status_end',
            component: renderDatePicker,
            label: 'Skinuto sa stanja',
            size: 'medium',
            value: '', 
            readonly:true,
            // disabled:true,    
        },
        'date_modify' : {
            name: 'date_modify',
            component: renderDatePicker,
            label: 'Poslednja izmena',
            size: 'medium',
            value: '',  
            readonly:true,
            disabled:true,
                  
        },
        'sub_description_title' : {
            name: 'sub_description_title',
            type: 'text',
            component: renderFieldInput,
            label: 'Naslov opisa kategorije',
            size: 'medium',
            value: '', 
            maxLength:"20",
                  
        },
        'sub_description' : {
            name: 'sub_description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Opis podkategorije',
            size: 'large',
            height: 100,
            value: '',
        },
        'slug' : {
            name: 'slug',
            type: 'text',
            component: renderFieldInput,
            label: 'Slug',
            size: 'medium',
            value: '', 
            maxLength:"20",
                  
        },
        'free_delivery' : {
            name: 'free_delivery',
            component: renderFieldSelect,
            label: 'Besplatna dostava',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [WarnNumeric],
            size: 'small',
            value: '',   
            options: [
                {text:'Ne', value:0},
                {text:'Da', value:1}
            ]                
        },
        'in_recommended' : {
            name: 'in_recommended',
            component: renderFieldSelect,
            label: 'U preporučenim',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [WarnAlphaNumeric],
            size: 'small',
            value: '',   
            options: [
                {text:'Ne', value:0},
                {text:'Da', value:1}
            ]                         
        },
        'backoffice_slug' : {
            name: 'backoffice_slug',
            type: 'text',
            component: renderFieldInput,
            label: 'Backoffice Slug',
            size: 'medium',
            value: '', 
            maxLength:"100",
                  
        },
        // 'highlight_recommended' : {
        //     name: 'highlight_recommended',
        //     component: renderFieldSelect,
        //     label: 'Istaknuto preporučeno',
        //     validate:[ValidateRequire,ValidateNumeric],
        //     warn: [WarnNumeric],
        //     size: 'small',
        //     value: '',   
        //     options: [
        //         {text:'Ne', value:0},
        //         {text:'Da', value:1}
        //     ]                         
        // },
        
        'position' : {
            name: 'position',
            type:'text',
            component: renderFieldInput,
            validate:[ValidateRequire,ValidateNumeric],
            warn: [WarnNumeric],
            label: 'Pozicija',
            size: 'large',
            value: '',
            maxLength:'2',
            // readonly:true,       
        },
        'mposition' : {
            name: 'mposition',
            type:'text',
            component: renderFieldInput,
            label: 'Pozicija na mobilnim uredjajima',
            size: 'small',
            value: '',
            maxLength:'2',
            // readonly:true,       
        },
    },

    initialValues: {}
}