import React, { Component } from "react"; 

 
class Stickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
 
  }
  //todo: pozivi,i ref.kad se proveri
  stickers(){
    let item=this.props.item;
    let position=this.props.position;
    if(item&&position){ 
        if (item.gift_url && position === 'bottom') {
    
            var now = new Date().getTime();
            if(item.gift_start_date !== undefined && item.gift_start_date){
                var start = new Date(item.gift_start_date);
                if(start){
                    if(start.getTime() > now){
                    return '';
                    }
                }
                var end = new Date(item.gift_end_date);
                
                if(end){
                    end.setDate(end.getDate()+1);
                
                    if(end.getTime() < now){
                    return '';
                    }
                }
            }
    
            return <div className={"sticker gift-url"}><b>Poklon</b> {item.gift_url.replace(/(,(?=\S))/g,", ")}</div>;
        }
        
        if (item.stickers) {
            var st = item.stickers;
            if (st && st[position] !== undefined){
                if (st[position]) {
                var sticker = st[position][0];
                if(sticker)
                return <div className={"sticker sticker-" + position + ((position === 'top' && parseInt(item.shock) || (item.is_shock !== undefined && item.is_shock))?' sticker-shock':'') +(this.props.is_mobile ? ' removeDot' : '')} >{sticker.name}</div>;
                }
            }
        }
    
        if(position === 'top'){
            var shock = false;
    
            if(item.is_shock !== undefined && parseInt(item.is_shock)===1){
                shock = true;
            }
    
            if(item.shock !== undefined && parseInt(item.shock) === 1){
                shock = true;
            }
    
            if(item.price_type !== undefined && parseInt(item.price_type) === 1){
                shock = true;
            }
    
            if(shock){
    
                if(item.top && parseInt(item.top) === 1){
                    return <div className={"sticker sticker-top" + (this.props.is_mobile ? ' removeDot' : '')}>Top artikal</div>;
                }
    
                return <div className={"sticker sticker-top sticker-shock" + (this.props.is_mobile ? ' removeDot' : '')}>Šok cena</div>;
            }
        }
    }
  }
 
  render() {
      let stickers=this.stickers();
      if(stickers){
        return   <div 
        className={"sticker_"+this.props.position} 
        style={this.props.style}
        >{stickers}
        </div>     
      }
      else{
        return "";
      }
      
       
    }
}

 

export default (Stickers) ;


 