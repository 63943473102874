import React, { Component, useReducer } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change,reset } from 'redux-form'
import { reduxAsyncValidate, reduxReduceVaues, reduxPrepareFormData } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";

import { fetchHelper } from '../helpers/fetchHelper';
import AlertyBox from '../libraries/AletyBox';
import { setBusy } from '../modules/forms';
import { backofficePagesConf } from "./backofficePagesConf"; 

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';

import './../assets/css/form.scss';  

  
class BackofficePagesForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            success: null,
            message: null,
            busy: false,
            allow_submit: false,
        };

    }

 

    componentDidUpdate(prevProps) {
        if (this.props.data) {
            reduxAsyncValidate(this.props.data, this.props);
        }
        if(this.props.create_new_page!==prevProps.create_new_page&&prevProps.create_new_page==false){
            //todo:chc up;
            this.props.reset()
        }
    }




    async submit(data) {
        
        // console.log('submit data', data)

        this.props.setBusy(true);

        var values = reduxReduceVaues(backofficePagesConf.fields, data);
        var response = await fetchHelper.post(`backoffice_pages/change`, values);
   
        this.props.setBusy(false);

        if (response.status && values && Object.keys(values).length) {
            if (this.props.onSave) {
                values.id=response.data.data.id;
                this.props.onSave(values);
            }
            AlertyBox.message(response.data);
        } else {
            var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
            AlertyBox.message(m)
        }
        return true;
    }

    render() {
        if (!this.props.data&&!this.props.create_new_page) { 
            return '';
        }


        const { handleSubmit, invalid, submitting, pristine, busy } = this.props;
        backofficePagesConf.initialValues = this.props.data;
        var refID = React.createRef();


        return (

            <>
                <form id="bck_pagesform" autoComplete="off" onSubmit={handleSubmit((data) => {
                    if (this.state.allow_submit) {
                        this.setState({ allow_submit: false }) 
                        this.submit(data);
                    }
                })} className="redux-form" encType="multipart/form-data" >
                    {busy ? <div className="form-mask"></div> : ''}
                    <GigaTabs tab={0}>
           
                        <GigaTab title={this.props.create_new_page ? "Kreirajte stranicu" : "Osnovni podaci"}>

                            <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                                <Cell className="grid-column">
                                    <div className="relative">
                                        <div className="separate-right"></div>
                                        <Field {...backofficePagesConf.fields.id} ref={refID} />
                                        <Field {...backofficePagesConf.fields.parent_id} />
                                        <Field {...backofficePagesConf.fields.position} />
                                        <Field {...backofficePagesConf.fields.title} />
                                        <Field {...backofficePagesConf.fields.slug} />
                                        <Field {...backofficePagesConf.fields.icon} />
                                        <Field {...backofficePagesConf.fields.view} />
                                        <Field {...backofficePagesConf.fields.nav} /> 

                                    </div>
                                </Cell>
                                <Cell className="grid-column">
                                    <Field {...backofficePagesConf.fields.description} />    
                                </Cell>
                                <Cell className="grid-column">   
                                    <Field {...backofficePagesConf.fields.status} />    
                                </Cell>
                              
                            </Grid>

                        </GigaTab>
                 

                    </GigaTabs>
                    <div className="page-bottom-bar">
                        <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
                    </div>
                </form>
            </>
        );
    }
}

const selector = formValueSelector('bck_pagesform');

const mapStateToProps = state => ({
    initialValues: backofficePagesConf.initialValues,
    busy: state.forms.busy,
    id: selector(state, 'id'), 
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBusy,
            updateField: (field, data) => change("bck_pagesform", field, data),
        },
        dispatch
    );

BackofficePagesForm = reduxForm({
    form: "bck_pagesform",
    reduxAsyncValidate,
    enableReinitialize: true,
})(BackofficePagesForm);
BackofficePagesForm = connect(
    mapStateToProps, mapDispatchToProps
)(BackofficePagesForm);
export default BackofficePagesForm;
// export default connect(mapStateToProps, mapDispatchToProps)(BackofficePagesForm);