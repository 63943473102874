import React, { Component } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'

export default class AlertyBox {
  constructor(props) {
   
  }

  static message(props){
      if(props.status){
        AlertyBox.success(props);
      } else {
          AlertyBox.error(props);
      }
  }

  static error(props){
    Swal.fire({
        title: 'Upozorenje',
        text: props.message,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then((result) => {
       
    })
  }

  static success(props){
    Swal.fire({
        title: 'Obaveštenje',
        text: props.message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then((result) => {
        
    })
  }

  static confirm(message,hide_title){
      return Swal.fire({
        title:hide_title ? "" : 'Upozorenje',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      })
  }
  static loading(){  
      return Swal.fire({
        title: 'Please Wait !',
        html: 'Data uploading',
        allowOutsideClick: false,
        onBeforeOpen: () => {
            Swal.showLoading()
        },
    });
}
  static serverError(errorData){
    let msg="Došlo je do greške pokušajte kasnije";
    if(errorData){
      let e=errorData.response;
      msg= e && e.data && e.data.message ? e.data.message+ "<br> Greška: " + e.status : 'Došlo je do greške';
    }

    Swal.fire({
      title: 'Upozorenje',
      html: msg,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'OK',
    })
    
  }

}