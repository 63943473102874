import React, {Component} from 'react';

const timerOnLoad = function(e){

}

export default class TemplateTimer extends Component {


    constructor(props){
        super(props)
        
        this.state = {
           
        }
    }

    componentDidMount(){
        
    }

    initialize(){

    }

    title(){
        return this.props.parent.state.title ? <h4>{this.props.parent.state.title}</h4> : <h4>Odbrojavanje</h4>
    }

    timer(){
        return <div className="giga-timer-html">
            <div className="timer-part">
                <span className="timer-part-days">0 dan</span> 
                <span className="timer-part-hours">0 sata</span> 
                <span className="timer-part-minutes">0 minuta</span> 
                <span className="timer-part-seconds">0 sekundi</span> 
            </div>
        </div>
    }

    render(){
        return (
            <>
            <div className="block-element-editor" contentEditable={false} 
                data-type="template" 
                data-module="TemplateTimerModal" 
                tabIndex={1} 
                data-onload="TemplateTimer" 
                data-title={this.props.parent.state.title?this.props.parent.state.title:'Odbrojavanje'}
                data-start={this.props.parent.state.dateStart} 
                data-end={this.props.parent.state.dateEnd} 
                style={{
                    width: this.props.parent.state.width,
                    height: this.props.parent.state.height,
                    fontSize: this.props.parent.state.fontSize,
                    color: this.props.parent.state.color,
                    backgroundColor: this.props.parent.state.backgroundColor,
                    borderRadius: this.props.parent.state.borderRadius, 
                    padding: this.props.parent.state.padding,
                    margin: this.props.parent.state.margin
                }}
            
            >
                <div className="giga-timer">
                    {this.title()}
                    {this.timer()}
                </div>
            </div>
            <p></p>
            </>)
    }

}