import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "../../../helpers/fetchHelper";
import GigaTable from '../../../libraries/GigaTable';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
const queryString = require('query-string');

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      table: null,
      active_user:null,
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
    this.groups = null;
    this.id = null;
    this.master_id = null;
  }

  componentDidMount(){
   this.loadCategories();
  }

  async loadCategories(){
        
    var response = await fetchHelper.get('/categorization/get_categories');
    if (response.status && response.items.length > 0) {
      this.setState({categories: response.items})
    }
}
  

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_user:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_user:row,selected:row.id})
  }
  
  setTitleAndGroups(){
    if(this.state.categories){
      this.state.categories.map(category => {

        if(category.id == this.id){
          this.title = category.name + ' grupe';
          this.groups = category.child;
          this.master_id  = category.master_id;
        }
      })
    }

  }

  resetActive() {
    //ne bacati na runRow 
    this.setState({active_user:null,selected:null})
  }
  render() {

    this.id = this.props.match.params.category;

    this.setTitleAndGroups();

    const cells = [
      {name:'Id',cell: 'id',width:90, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Naziv',cell: 'name', width:150,fixed:true, className:''},
      {name:'Podkategorije', cell:'custom1', custom:true, width:100, align:'center', render:(row)=>{
         
          let icon;
          if(!this.groups) return'';

          this.groups.map(group => {
            if(!group.child.length && row.id === group.id){
            icon = <button onClick={()=>{ this.setState({active_user:row,selected:row.id}) }}><i className="fa fa-times" /></button>
          }
            if(group.child.length && row.id === group.id){
              console.log()
            icon = <button onClick={()=>{ this.setState({active_user:row,selected:row.id}) }}>
                    <Link target='_blank'to={{pathname:`/admin/products/categories/${this.id}/${group.id}`,search:`?master_id=${this.master_id}`}}>
                    <i className="fa fa-window-restore"></i>
                    </Link>
                  </button>
          }
        })
        return icon; 
      }},
      {name:'Proizvodi', cell:'custom2', custom:true, width:70, align:'center', render:(row)=>{
      
        return <button onClick={()=> this.setState({active_user:row,selected:row.id}) }><Link target="_blank" to={`/admin/products/${this.master_id}/${row.master_id}`}><i className="fa fa-table"></i></Link></button>
      }},
      {name:'Edit', cell:'custom3', custom:true, width:60, align:'center', render:(row)=>{
        return <button onClick={()=> this.setState({active_user:row,selected:row.id}) }><Link 
       to={{pathname:`/admin/products/group/edit/${row.id}`,search:`?category_id=${this.id}`}} target="_blank"
        ><i className="fa fa-edit"></i></Link></button>
      }},
    ];

    var fetch=`categorization/get_groups/${this.id}`;
    const buttons = [
      {title: 'Nova grupa', type: 'link', link:`/admin/products/add-group`,search:`?category_id=${this.id}`,target:true},
      {title: 'Nova podkategorija', type: 'link', link:`/admin/products/add-subcategory`,search:`?category_id=${this.id}`,target: true},
    ];
    return (
      <>
        <div className="admin-home__main-section">
          <h1>{this.title} </h1>
        <div className="table-wrapper">
          <GigaTable 
            cells={cells} 
            pk='id' 
            height={300} 
            buttons={buttons} 
            id='groups-table' 
            db="app_categories"
            fetch={fetch}
            delete={true} 
            selected={this.state.selected}
            getInstance={this.getTableInstance}
            run={this.runRow}
            resetActive={this.resetActive}
          />
        </div>
      </div>
      </>
    );
  }
}



  export default withRouter((Groups));