import React, {Component} from 'react';
import InsertNode from '../base/insertNode';
import { remove_link_hosts } from './../../../config/vars'; 

export default class LinkModal extends Component {


    constructor(props){
        super(props)
        
        this.state={
            url: '',
            id: null,
        }

        this.set = this.set.bind(this);
        this.onChange = this.onChange.bind(this);
        this.unlink = this.unlink.bind(this);

        this.caret = null;
        this.node = null;
        this.sel = null;
        this.selection = null;
        this.range = null;
        this.link = null;

        this.ref = React.createRef();
    }

    componentDidMount(){
        this.get();
    }

    componentDidUpdate(prevProps){
        if(prevProps.element !== this.props.element){
            this.get();
        }
    }

    set(e){
        e.preventDefault();
        e.stopPropagation();

        var selection = document.getSelection();
        selection.removeAllRanges();
        selection.addRange(this.props.editor.state.range);

        document.execCommand('CreateLink',false, this.state.url);

        // if(this.node){
        //     if(this.node.nodeName === undefined){

        //         var nodeList = this.removeOldLInks(this.node);
        //         var a = document.createElement('a');
        //         a.to = this.state.url;
        //         a.href = this.state.url;
        //         a.host = '';
                
        //         nodeList.map((e)=>{
        //             a.appendChild(e);
        //         });

        //         var node = document.createDocumentFragment();
        //         node.innerHTML = a;

        //         this.range.deleteContents();
        //         this.range.insertNode( a );
                
        //     } else {

        //         if(this.node.nodeName !== 'A'){
        //                 var a = document.createElement('a');
        //                 a.to = this.state.url;
        //                 a.href = this.state.url;
        //                 a.host = '';
        //                 var txt = this.selection.range.commonAncestorContainer.parentNode.innerText;
        //                 this.selection.range.deleteContents();
        //                 a.innerText = txt.substring(this.selection.start, this.selection.end);
        
        //                 this.range.insertNode( a );
        //         } else {
        //             this.node.to = this.state.url;
        //             this.node.href = this.state.url;
        //         }
               
        //     }
        // }

        this.props.editor.setState({modal:''});
        this.props.editor.registerChange();

        return false;
    }

    removeOldLInks(NodeList){
        var n = [];
        for(var i = 0; i < NodeList.length; i++){
            var node = NodeList[i];
            if(node.nodeName === 'A'){
                var nodeText = document.createTextNode(node.innerHTML);
                n.push(nodeText);
            } else {
                n.push(node);
            }
        }
        return n;
    }

    getSelectionLink(nodeList){
        for(var i = 0; i< nodeList.length; i++){
            var node = nodeList[i];
            if(node.nodeName === 'A'){
                this.link = node.to !== undefined ? node.to : node.href !== undefined ? node.href : '';
                remove_link_hosts.map(host=>{
                    var rgx = new RegExp('http://'+host,'g');
                    this.link = this.link.replace(rgx,'');
                    var rgx = new RegExp('https://'+host,'g');
                    this.link = this.link.replace(rgx,'');
                });
            }
        }
    }

    get(){

        this.sel = window.getSelection();
        this.range = this.sel.getRangeAt(0);
        var ins = new InsertNode(this.props.editor), caret = ins.__getCaret(), nodes = ins.getClickedElement();

        this.selection = ins.__selection();

        if(!nodes){
            nodes = ins.__getNodes( caret );
        }

        if(nodes){
            this.node = nodes;
            if(nodes.nodeName === undefined){
                // is NodeList      
                this.getSelectionLink(nodes);
            } else {
                //is NodeElement
                this.getSelectionLink([nodes]);
            }
            this.setState({url:this.link ? this.link : '/'});
        }
    }

    onChange(e){
        this.setState({[e.currentTarget.name]:e.currentTarget.value});
    }


    prevent(e){
        return false;
    }

    unlink(){

        var selection = document.getSelection();
        selection.removeAllRanges();
        selection.addRange(this.props.editor.state.range);

        document.execCommand('unlink',false, null);


        // var ins = new InsertNode(this.props.editor);
       
        // if(this.node.nodeName === undefined){
            
        //     if(this.range){
        //         this.node = this.range.commonAncestorContainer.childNodes;
        //     }

        //     for(var i = 0; i < this.node.length; i++){
        //         var node = this.node[i];
        //         if(node.nodeName === 'A'){
        //             ins.unwrap( node );
        //         }
        //     }
        // } else {
        //     ins.unwrap( this.node );
        // }
       
        this.props.editor.setState({modal:''});
        this.props.editor.registerChange();
    }

    render(){
       return (<>
        <div className="modal-content" ref={this.ref} onMouseDown={this.prevent}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width:'100%'}} colSpan={2} >
                                    <div className="ge-form-group">
                                        <label>URL</label>
                                        <input name="url" className="largest" onChange={this.onChange} value={this.state.url} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <button onClick={this.unlink}><i className="fa fa-chain-broken" aria-hidden="true"></i></button>
                                </td>
                                <td style={{width:'50%'}}>
                                
                                </td>
                            </tr>
                        </tbody>
                    </table>
        </div>
        <div className="modal-foot">
                <button onClick={() => this.props.editor.setState({modal:''})} >Zatvori</button>
                <button onMouseDown={this.set} className="primary-btn">Primeni</button>
        </div>
    </>)
    }

}