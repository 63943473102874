const environment = 'production';

module.exports = {
    env: environment,
    website: environment === 'development' ? 'http://localhost:3000/' : 'https://gigatron.rs/',
    server_img_host: 'http://ozone.devgigatron.com/',
    product_img_path: 'https://img.gigatron.rs/img/products/',
    categorization_icon_path: 'img/gigaapi/categorization/icon/',
    categorization_slideshow_bg_path: 'img/gigaapi/categorization/slideshow_bg/',
    categorization_slideshow_bg_mobile_path: 'img/gigaapi/categorization/slideshow_bg_mobile/',
    page_icon_path: 'img/gigaapi/pages/icon/',
    page_icon_mobile_path: 'img/gigaapi/pages/icon_mobile/',
    page_thumb_path: 'img/gigaapi/pages/thumb/',
    page_image_path: 'img/gigaapi/pages/image/',
    gallery_thumb_path: 'img/gigaapi/gallery/thumb/',
    user_avatar_path: 'img/gigaapi/users/avatar/',
    site_url:'http://gigatron.rs',
    remove_link_hosts: [
        'gigatron.rs',
        'b.gigatron.rs',
        'new.b.gigatron.rs',
        'localhost:3002'
    ],

};