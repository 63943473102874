import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "../../../helpers/fetchHelper";
import GigaTable from '../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import UserForm from "../../../forms/UserForm";
import OrderForm from "../../../forms/OrderForm";

class OrderDetails extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
       orderId: null
    }

    this.setOrderId = this.setOrderId.bind(this);

  }

  componentDidMount(){
    
  }


  setOrderId( id ){
    this.setState({orderId:id});
  }





  render() {

    return <div className="page-details">
        <h1>Order {this.state.orderId ? '#'+this.state.orderId :': Loading...'}</h1>
        <div className="page-details-hold">
            <OrderForm {...this.props} setOrder={this.setOrderId} />
        </div>
    </div>
   
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(OrderDetails));
