import React, {Component} from 'react';
import GigaTabs from '../../../../GigaTabs';
import GigaTab from '../../../../GigaTabs/GigaTab';
import ReactDOMServer from 'react-dom/server';
import Initialize from '../../../base/initialize';
import InsertNode from '../../../base/insertNode';
import TemplateTabs from './index';

export default class TemplateTabsModal extends Component {


    constructor(props){
        super(props)
        
        this.state={
            ids: '',
            active: 0,
            tabs: 2,
            padding: '10px 10px 10px 10px',
            margin: '0px 0px 0px 0px',
            stop: true,
        }

        this.setStyle = this.setStyle.bind(this);
        this.onChange = this.onChange.bind(this);

        this.ref = React.createRef();
    }

    componentDidMount(){
        if(this.props.insert === false){
            this.getStyle();
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.element !== this.props.element && this.props.insert === false){
            this.getStyle();
        }
    }

    setStyle(){
        if(this.props.insert === true){

            let htmlString = ReactDOMServer.renderToStaticMarkup(
                <TemplateTabs parent={this} active={this.state.active} />
            );
            
            var selection = document.getSelection();
            selection.removeAllRanges();
            selection.addRange(this.props.range);

            document.execCommand('insertHTML',true,htmlString);

            var ins = new Initialize();
            ins.initializeTemplates(this.props.editor);
            this.props.editor.registerChange();
            this.props.editor.setState({modal:null});
                
        
        } else {
                var a = this.props.element;
                let htmlString = ReactDOMServer.renderToStaticMarkup(
                    <TemplateTabs parent={this} element={this.props.element} active={this.state.active} />
                );
                var insert = new InsertNode(this.props.editor);
                htmlString = insert.__normalizeReplace(htmlString);
                var div = document.createElement('div');
                div.innerHTML = htmlString;
                var node = div.firstChild;
                a.replaceWith(node);
               

                var ins = new Initialize();
                ins.initializeAll(this.props.editor)

                this.props.editor.registerChange();
                this.props.editor.setState({modal:null});
        }
    }


    getStyle(){

        if(this.props.element){
            var t = this.props.element;
            this.setState({
                ids:                t.dataset.ids,
                tabs:               t.dataset.tabs, 
                margin:             t.style.margin,
                padding:            t.style.padding,
                stop:               t.dataset.stop,
                active:             parseInt(t.dataset.active),
            })
        }
        
    }

    onChange(e){
        this.setState({[e.currentTarget.name]:e.currentTarget.value});
    }


    render(){
       return (<>
        <div className="modal-content" ref={this.ref} style={{height:250}}>
            <GigaTabs tab={0}>
                <GigaTab title="Atributi">
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Margin</label>
                                        <input name="margin" className="largest" onChange={this.onChange} value={this.state.margin} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Padding</label>
                                        <input name="padding" className="largest" onChange={this.onChange} value={this.state.padding} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="ge-form-group">
                                        <label>Tabs</label>
                                        <input name="tabs" className="small" onChange={this.onChange} value={this.state.tabs} placeholder="2" />
                                    </div>
                                </td>
                                <td>
                                    <div className="ge-form-group">
                                        <label>Active</label>
                                        <input name="active" className="small" onChange={this.onChange} value={this.state.active} placeholder="1" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GigaTab>
                <GigaTab title="IDs">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="ge-form-group">
                                        <label>IDs</label>
                                        <textarea name="ids" className="largest" onChange={this.onChange} value={this.state.ids}></textarea>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GigaTab>
            </GigaTabs>
        </div>
        <div className="modal-foot">
                
                <button onClick={this.setStyle}  className="primary-btn">Primeni</button>
                <button onClick={() => this.props.editor.setState({modal:''})}>Zatvori</button>
        </div>
        
    </>)
    }

}