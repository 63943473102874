import React from 'react';
import { API_URL, fetchApiHelper } from '../../helpers/fetchApiHelper';
import { fetchHelper } from '../../helpers/fetchHelper';

import './style.scss';
var rf = React.createRef();
export default class ProductOptions extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            categories: {},
            groups: {},
            subcategories: {},
            loading:true,
            brands: {},
            popWindow: null,
            filters: {},
            catbrands: {},
            subbrands: {},
            minPrice:  '',
            maxPrice: '',
            exclude: '',
            include: '',
            valueStickerOff: false,
            shock: false,
            stiker_id: null,
        }


        this.cat_filters    = {};
        this.group_filters  = {};
        this.sub_filters    = {};


        this.onCategory = this.onCategory.bind(this);
        this.onGroup = this.onGroup.bind(this);
        this.onSubcategory = this.onSubcategory.bind(this);
        this.onBrand = this.onBrand.bind(this);
        this.loadSpecification = this.loadSpecification.bind(this);
        this.onSpecification = this.onSpecification.bind(this);
        this.loadBrand = this.loadBrand.bind(this);
        this.onCatBrand = this.onCatBrand.bind(this);
    }

    componentDidMount(){
        this.props.instance.optionsInstance = this;
    }

    componentDidUpdate(){
        if(this.props.categories && Object.keys(this.props.categories).length && this.state.loading && this.props.definition !== undefined){
            this.setState({loading:false});

            var d = this.props.definition;
            if(d){
                Object.keys(d).map(k=>{
                    var v = d[k];
                    this.setState({[k]:v});
                })
            }

        }
    }

    cleanList(list){
        var newlist = {};
        Object.keys(list).map(l=>{
            if(list[l]){
                newlist[list[l].id] = list[l];
            }
        });
        return newlist;
    }

    onBrand(e,brand){
        var brands = this.state.brands;
        if(!brands[brand.id]){
            brands[brand.id] = brand;
        } else {
            delete brands[brand.id];
        }
        var brs = this.cleanList(brands);
        this.setState({brands:brs});
    }

    onCategory(event, category, id){

        var t       = event.currentTarget;
        var value   = t.value;
        var checked = t.checked;
        var cts     = this.state.categories;
        if(checked){
            cts[value] = category;
        } else {
            delete cts[value];
            // remove groups where parent category removed --- start
            var groups = this.state.groups;
            Object.keys(groups).map((g)=>{
                var group = groups[g];
                if(group.category_id === value){
                    delete groups[g];
                }
            });
            var ggg = this.cleanList(groups);
            this.setState({groups:ggg});
            this.removeSpecifications(this.cat_filters[value]); 
            this.removeCatBrands(value);
             // remove groups where parent category removed --- end


            // remove subs 
            if(this.state.subcategories && Object.keys(this.state.subcategories).length){
                var s = this.state.subcategories;
                Object.keys(s).map((sub_id)=>{
                    var sub = s[sub_id];

                    if(sub.category_id === value){
                        delete s[sub_id];
                        this.removeSpecifications(this.sub_filters[sub_id]);
                    }
                });
                var sss = this.cleanList(s);
                this.setState({subcategories:sss});
            }
            // remove subs
        }
        var categories = this.cleanList(cts);
        this.setState({categories:categories});
        this.props.instance.setState({selected_categories: categories})
    }


    onGroup(event, category, group, cat_id, group_id){
        var t       = event.currentTarget;
        var value   = t.value;
        var checked = t.checked;
        var grs     = this.state.groups;
        if(checked){
            group.category_id = cat_id;
            grs[value] = group;
        } else {
            delete grs[value];
            var s = {};
            var sbs = this.state.subcategories;
            Object.keys(sbs).map((sub_id)=>{
                var sub = sbs[sub_id];
                if(sub.group_id !== value){
                    s[sub_id] = sub;
                }
            });
            this.setState({subcategories:s});
            this.removeSpecifications(this.group_filters[value]);
            this.removeSubBrands(value);
        }
        var groups = this.cleanList(grs);
        this.setState({groups:groups});
        this.props.instance.setState({selected_groups: groups})
    }

    onSubcategory(event, category, group, cat_id, group_id, subcategory, sub_id){
        var t       = event.currentTarget;
        var value   = t.value;
        var checked = t.checked;
        var srs     = this.state.subcategories;
        if(checked){
            subcategory.category_id = cat_id;
            subcategory.group_id = group_id;
            srs[value] = subcategory;
        } else {
            delete srs[value];
            this.removeSpecifications(this.sub_filters[value]);

            this.removeSubBrands(value);

        }
        var subcategories = this.cleanList(srs);
        this.setState({subcategories:subcategories});
        this.props.instance.setState({selected_subcategories: subcategories})
    }

    removeCatBrands(cat_id){
        var catbrands = this.state.catbrands;
        if(catbrands[cat_id]){
            catbrands[cat_id] = {};
            this.setState({catbrands:catbrands});
        }
    }

    removeSubBrands(sub_id){
        var subbrands = this.state.subbrands;
        if(subbrands[sub_id]){
            subbrands[sub_id] = {};
            this.setState({subbrands:subbrands});
        }
    }

    onCatBrand(e, b, sub_id, cat_id){
        var catbrands = this.state.catbrands;
        var subbrands = this.state.subbrands;

        if(cat_id){

            if(!catbrands[cat_id]) catbrands[cat_id] = {};

            if(!catbrands[cat_id][b.id]){
                catbrands[cat_id][b.id] = b;
            } else {
                delete catbrands[cat_id][b.id];
                catbrands[cat_id] = this.cleanList(catbrands[cat_id]);
            }
        } else {
            if(!subbrands[sub_id]) subbrands[sub_id] = {};

            if(!subbrands[sub_id][b.id]){
                subbrands[sub_id][b.id] = b;
            } else {
                delete subbrands[sub_id][b.id];
                subbrands[sub_id] = this.cleanList(subbrands[sub_id]);
            }
        }
        this.setState({catbrands:catbrands,subbrands:subbrands});
    }

    categories(){
        const {categories} = this.props;
   
        if(!categories || !Object.keys(categories).length) return <p><em>Nema opcija za proizvod</em></p>;

        return <div className="drop-list" style={{width: '350px', float: 'left', marginRight: 10}}>
            {Object.keys(categories).map((cat_id,i)=>{
                const c = categories[cat_id];
                return (<div style={{position:'relative'}} key={'ccc'+i}><label htmlFor={'cat-'+cat_id} className="drop-list-item dli-main noselect" >
                    <input 
                        type="checkbox"  
                        value={cat_id} 
                        id={'cat-'+cat_id} 
                        onChange={(e)=>this.onCategory(e,c, cat_id)} 
                        checked={this.state.categories[cat_id]?true:false}
                    /> {c.name}
                </label>
                <span className="item-opt">
                        {this.state.categories[cat_id]?(
                            <span className="item-opt-btn" onClick={(e)=>{this.loadBrand(null,c.id,c.name)}}><i className="fa fa-apple" title="Brendovi" /></span>
                        ):null}
                    </span>
                </div>
                )
            })}
        </div>
    }

    groups(){
        const {categories} = this.props;

        if(!categories || !Object.keys(categories).length) return <p><em>Nema izabranih kategorija</em></p>;

        return <div className="drop-list clear">
        {Object.keys(categories).map((cat_id,i)=>{
            const c = categories[cat_id];
            return (
            <div key={'groupcat-'+cat_id+'-'+i}  className="group-widget" >
                <h3>{c.name}</h3>
                <div className="cat-container">
                {c.groups && Object.keys(c.groups).length ? (
                Object.keys(c.groups).map((group_id,i)=>{
                const g = c.groups[group_id];
                if(g.name)
                return (<div style={{position:'relative'}} key={'group-'+cat_id+'-'+i}>
                    <label htmlFor={'group-'+group_id} className="drop-list-item dli-main noselect" >
                        <input 
                            type="checkbox"  
                            value={group_id} 
                            id={'group-'+group_id} 
                            onChange={(e)=>this.onGroup(e, c, g, cat_id, group_id)} 
                            checked={this.state.groups[group_id]?true:false}
                        /> {g.name}
                    </label> 

                    {this.state.groups[group_id]?
                    (<span className="item-opt">
                        <span className="item-opt-btn" onClick={(e)=>{this.loadBrand(g.id,null,g.name)}}><i className="fa fa-apple" title="Brendovi" /></span>
                        {!g || !Object.keys(g.subcategories).length 
                        ?(<span className="item-opt-btn" onClick={(e)=>{this.loadSpecification(g.id,cat_id)}}><i className="fa fa-list" title="Specifikacije" /></span> )
                        :<span className="item-opt-btn item-opt-empty"><i></i></span>}
                        
                    </span>):null}
                </div>
                )})):null}
                </div>
            </div>)
        })}
    </div>
    }

    subcategories(){
        const {categories} = this.props;
        if(!categories || !Object.keys(categories).length) return <p><em>Nema izabranih kategorija</em></p>;

        return  Object.keys(categories).map((cat_id,i)=>{
            const c = categories[cat_id];
            return (
                c.groups && Object.keys(c.groups).length && this.state.categories[cat_id] ? (<div className="clear" key={'bb-'+cat_id}>
                {Object.keys(c.groups).map((group_id,sd)=>{
                const g = c.groups[group_id];
                return g.subcategories && Object.keys(g.subcategories).length && this.state.groups[group_id] ? 
                (<div className="drop-list clear"  key={'addg'+sd+'-'+i+'-'+group_id+'-'+cat_id}>
                <div  className="clear group-widget"><div className="clear" style={{marginTop: 40}} >
                    <h3><sup>{c.name}</sup>{g.name}</h3>

                    <div className="cat-container">{Object.keys(g.subcategories).map((sub_id,y)=>{
                    var s = g.subcategories[sub_id];
                    return (<div style={{position:'relative'}} key={'subcategory-'+sub_id+'-'+i+'-'+y}>
                        <label htmlFor={'sub-'+sub_id} className="drop-list-item dli-main noselect" >
                        <input 
                            type="checkbox"  
                            value={sub_id} 
                            id={'sub-'+sub_id} 
                            onChange={(e)=>this.onSubcategory(e, c, g, cat_id, group_id, s, sub_id)} 
                            checked={this.state.subcategories[sub_id]?true:false}
                        /> {s.name}
                    </label>
                    {this.state.subcategories[sub_id]?
                    <span className="item-opt">
                        <span className="item-opt-btn" onClick={(e)=>{this.loadBrand(sub_id,null,s.name)}}><i className="fa fa-apple" title="Brendovi" /></span>
                        <span className="item-opt-btn"  onClick={(e)=>{this.loadSpecification(sub_id, cat_id, group_id)}}><i className="fa fa-list" title="Specifikacije" /></span> 
                    </span> : null}
                    </div>
                )})
                }</div></div></div>
                </div>):null})}</div>):null
            )
        })
    }


    brands(){
        const {brands} = this.props;
        if(!brands && !brands.length) return "";
        return <div className="drop-list" style={{width: '350px', float: 'left', marginRight: 10}}>
             <div className="cat-container">
            {brands.map((b,i)=>{
                return (<div style={{position:'relative'}} key={'bbb'+i}>
                    <label htmlFor={'brand-'+b.id} className="drop-list-item dli-main noselect"  style={{paddingRight:'70px'}}>
                        <input 
                            type="checkbox"  
                            value={b.id} 
                            id={'brand-'+b.id} 
                            onChange={(e)=>this.onBrand(e,b)} 
                            checked={this.state.brands[b.id]?true:false}
                        /> {b.name}
                        <img className="item-opt-img" src={b.img}  />
                    </label>
                    
                </div>
                )
            })}
            </div>
        </div>
    }


    popWindow(){
        if(!this.state.popWindow) return null;
        setTimeout(()=>{
            if(rf.current){
                rf.current.classList.add('active');
            }
        },1000);

        return <div ref={rf} className="pop-opt">
            <div className="pop-body">
                <div className="pop-header">
                    <h2>{this.state.popWindow.title}</h2>
                </div>
                <div className="pop-container">
                    {this.state.popWindow.items}
                </div>
            </div>
        </div>
    }

    closePopWindow(){
        // document.body.style.position = 'relative';
        this.setState({popWindow:null});
    }

    removeSpecifications(list){

        if(!list) return;

        var fs = this.state.filters;

        Object.keys(list).map(id=>{
            if(fs[id]){
                delete fs[id];
            }
        });

        var fss = this.cleanList(fs);
        this.setState({filters:fss});
    }

    onSpecification(e,s,f, sub_id, cat_id, group_id){
        var filters = this.state.filters;
        if(!this.state.filters[f.id]){
            
            f.spec_id = s.id;
            f.spec_name = s.name;
            f.category_id = cat_id;
            f.group_id = group_id;
            f.sub_id = sub_id;

            filters[f.id] = f;
            if(sub_id){
                if(!this.sub_filters[sub_id]) this.sub_filters[sub_id] = {};
                this.sub_filters[sub_id][f.id] = f.id;
            }
            if(cat_id){
                if(!this.cat_filters[cat_id]) this.cat_filters[cat_id] = {};
                this.cat_filters[cat_id][f.id] = f.id;
            }
            if(group_id){
                if(!this.group_filters[group_id]) this.group_filters[group_id] = {};
                this.group_filters[group_id][f.id] = f.id;
            }
        } else {
            delete filters[f.id];

            if(sub_id){
                if(!this.sub_filters[sub_id]) this.sub_filters[sub_id] = {};
                delete this.sub_filters[sub_id][f.id];
            }
            if(cat_id){
                if(!this.cat_filters[cat_id]) this.cat_filters[cat_id] = {};
                delete this.cat_filters[cat_id][f.id];
            }
            if(group_id){
                if(!this.group_filters[group_id]) this.group_filters[group_id] = {};
                delete this.group_filters[group_id][f.id];
            }
        }

        var fs = this.cleanList(filters);
        this.setState({filters:fs});
    }


    async loadSpecification(sub_id,cat_id, group_id){
        // document.body.style.position = 'fixed';
        this.setState({popWindow:{
            title: 'Specifikacije',
            items: <h4>Loading...</h4>
        }});

        var result = await fetchApiHelper.postApi(API_URL + 'core/specifications/all/' + sub_id, {}, true);
        
        if(result.status === 200){
            var data = result.data;

            var window = {
                title: 'Specifikacije',
                items: []
            }

            if(!data.filters || !data.filters.length){
                window.items = <div style={{height:100}}>
                    <h4>Specifikacije nisu pronadjene</h4>
                    <div className="pop-footer">
                        <button onClick={this.closePopWindow.bind(this)}>Close</button>
                    </div>
                </div>
            } else {
                window.items = (
                    <div className="drop-list">
                        <div className="cat-container">
                        {data.filters.map((s,i)=>{
                            return (<div style={{position:'relative'}} key={'s-'+i}>
                                <h4>{s.name}</h4>
                                {Object.keys(s.filters).map((spec_id,y)=>{
                                    var f = s.filters[spec_id];
                                    return <label htmlFor={'filt-'+f.id} className="drop-list-item dli-main noselect" key={'filt-'+f.id}>
                                        <input 
                                            type="checkbox"  
                                            value={f.id} 
                                            id={'filt-'+f.id} 
                                            onChange={(e)=>this.onSpecification(e,s,f, sub_id, cat_id, group_id)} 
                                            defaultChecked={this.state.filters[f.id]?true:false}
                                        /> {f.name}
                                    </label>
                                })}
                            </div>
                            )
                        })}
                        </div>
                        <div className="pop-footer">
                            <button onClick={this.closePopWindow.bind(this)}>Close</button>
                        </div>
                    </div>
                )
            }
            this.setState({popWindow:window})
        }
    }

    isBrandChecked(b,sub_id,cat_id){

        var catbrands = this.state.catbrands;
        var subbrands = this.state.subbrands;

        if(cat_id){

            if(!catbrands[cat_id]) catbrands[cat_id] = {};

            if(catbrands[cat_id][b.id]) return true;

        } else {

            if(!subbrands[sub_id]) subbrands[sub_id] = {};

            if(subbrands[sub_id][b.id]) return true;

        }

        return false;

    }

    async loadBrand(sub_id, cat_id, title_name){
        // document.body.style.position = 'fixed';
        this.setState({popWindow:{
            title: 'Brendovi',
            items: <h4>Loading...</h4>
        }});

        var result = await fetchApiHelper.postApi(API_URL + 'core/brands/get/' + ((sub_id ? sub_id : 'undefined') + (cat_id?'/'+cat_id:'')), {}, true);

        if(result.status === 200){
            var data = result.data;

            var window = {
                title: 'Brendovi - ' + title_name,
                items: []
            }

            if(!data.items || !data.items.length){
                window.items = <div style={{height:100}}>
                    <h4>Brendovi nisu pronadjeni</h4>
                    <div className="pop-footer">
                        <button onClick={this.closePopWindow.bind(this)}>Close</button>
                    </div>
                </div>
            } else {
                window.items = (
                    <div className="drop-list">
                        <div className="cat-container">
                        {data.items.map((b,i)=>{
                                return <label htmlFor={'brd-'+b.id} className="drop-list-item dli-main noselect" key={'br-'+b.id}>
                                    <input 
                                        type="checkbox"  
                                        value={b.id} 
                                        id={'brd-'+b.id} 
                                        onChange={(e)=>this.onCatBrand(e, b, sub_id, cat_id)} 
                                        defaultChecked={this.isBrandChecked(b,sub_id,cat_id)}
                                    /> {b.name} 
                                    <img className="item-opt-img" src={b.img}  />
                                </label>
                        })}
                        </div>
                        <div className="pop-footer">
                            <button onClick={this.closePopWindow.bind(this)}>Close</button>
                        </div>
                    </div>
                )
            }
            this.setState({popWindow:window})
        }
    }


    options(){

        return <div>
            <span style={{display:'inline-block',marginRight:20}}>
                <div className="form-group">
                    <label>Cena od:</label>
                    <input style={{width:100}} type="number" placeholder="min price..." name="minPrice" onChange={(e)=>{this.setState({[e.currentTarget.name]:e.currentTarget.value})}} defaultValue={this.state.minPrice} />
                </div>
            </span>
            <span style={{display:'inline-block'}}>
                <div className="form-group">
                    <label>Cena do:</label>
                    <input style={{width:100}} type="number"  placeholder="max price..." name="maxPrice" onChange={(e)=>{this.setState({[e.currentTarget.name]:e.currentTarget.value})}}  defaultValue={this.state.maxPrice} />
                </div>
            </span>
            <div className="form-group">
                <label>Uključi proizvode:</label>
                <textarea placeholder="22563,44587..." style={{width:'100%',height:120}} name="include" onChange={(e)=>{this.setState({[e.currentTarget.name]:e.currentTarget.value})}}  defaultValue={this.state.include}></textarea>
            </div>
            <div className="form-group">
                <label>Ignoriši proizvode:</label>
                <textarea placeholder="22563,44587..." style={{width:'100%',height:120}} name="exclude" onChange={(e)=>{this.setState({[e.currentTarget.name]:e.currentTarget.value})}}  defaultValue={this.state.exclude}></textarea>
            </div>
            <div>
                <span style={{display:'inline-block',marginRight:20}}>
                    <div className="form-group">
                        <label>Vredn. NAV stikeri:</label>
                        <select name="valueStickerOff" style={{width:150}} onChange={(e)=>{this.setState({[e.currentTarget.name]:e.currentTarget.value})}} defaultValue={this.state.valueStickerOff}>
                            <option value="off">OFF</option>
                            <option value="on">ON</option>
                        </select>
                    </div>
                </span>
                <span style={{display:'inline-block'}}>
                    <div className="form-group">
                        <label>Šok:</label>
                        <select name="shock" style={{width:150}} onChange={(e)=>{this.setState({[e.currentTarget.name]:e.currentTarget.value})}} defaultValue={this.state.shock} >
                            <option value="off">OFF</option>
                            <option value="on">ON</option>
                        </select>
                    </div>
                </span>
            </div>
        </div>

    }


    render(){

        if(this.state.loading){
            return 'Loading....'
        }

        return <div className="product-opt">
            <div className="row">
                <div className="col col-4 col-widget">
                    <h3 style={{backgroundColor:''}}>Categories</h3>
                    {this.categories()}
                    <div style={{clear:'both'}}><h3 style={{marginTop:50,clear:'both'}}>Product Groups</h3></div>
                    
                    {Object.keys(this.state.categories).length?this.groups():null}
                    <div style={{clear:'both'}}>
                        <h3 style={{marginTop:50,clear:'both'}} className="clear">Product Subcategories</h3>
                        {Object.keys(this.state.groups).length?this.subcategories():null}
                    </div>
                </div>

                <div className="col col-4 col-widget">
                    <h3 style={{backgroundColor:''}}><sup>Za ceo asortiman</sup> Brands</h3>
                    {this.brands()}
                </div>

                <div className="col col-4 col-widget">
                    <h3 style={{backgroundColor:''}}>Uslovi</h3>
                    {this.options()}
                </div>
            </div>
            {this.popWindow()}
        </div>
    }

}