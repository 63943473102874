import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field } from 'redux-form'
import { reduxAsyncValidate, reduxReduceVaues } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import GigaSpinner from '../libraries/GigaSpinner';
import { fetchHelper } from '../helpers/fetchHelper';
import AlertyBox from '../libraries/AletyBox';
import { loginConf } from "./loginConf";
import {setBusy} from './../modules/forms';

import './../assets/css/form.scss';
import './../assets/css/login.scss';

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            success: null,
            message: null,
            busy:false,
        };


    }

  componentDidMount(){

  }

  componentDidUpdate(){

  }


  

  async submit(data){
        this.props.setBusy(true);
        var values = reduxReduceVaues(loginConf.fields,data);
        var response = await fetchHelper.post(this.props.url,values);
        this.props.setBusy(false);
      
        if(response.status && response.data){
            
            localStorage.setItem('user', JSON.stringify(response.data));
            window.location = `/admin`;

        } else {
            var m = {message:'Korisnik nije pronađen',status:false};
            AlertyBox.message(m)
        }
        return true;
  }


  render() {

    const {handleSubmit , invalid,submitting,pristine,busy} = this.props;
    
    loginConf.initialValues = {username:'',password:''};

    return (
        <div className="login-wrap">
            <form onSubmit={handleSubmit((data) => { this.submit(data); })} className="redux-form" >
                <Field {...loginConf.fields.username} />
                <Field {...loginConf.fields.password} />
                <div className="form-buttons">
                    <button data-busy={busy} type="submit" disabled={invalid || submitting || busy}>{busy ? (<GigaSpinner />) : 'Prijava'}</button>
                </div>
            </form>
        </div>
    );
  }
}


const mapStateToProps = state => ({
    initialValues: loginConf.initialValues,
    busy: state.forms.busy
  });
  
const mapDispatchToProps = dispatch =>
bindActionCreators(
    {
        setBusy
    },
    dispatch
);

LoginForm = reduxForm({
  form: "loginform",
  enableReinitialize: true
})(LoginForm);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);