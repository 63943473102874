import React, { useState }  from 'react';
import { addPlugin } from '../../helpers/addPlugin';
import View from './view';
import Form from './form';
import {fetchApiHelper} from './../../../../../helpers/fetchApiHelper';
import {ReactComponent as Icon} from './icon.svg';
import { renderToString } from 'react-dom/server';
import { date_sql_to_rs } from '../../../../../helpers/dateHelper';

export const productsPlugin = (editor, node) => {

    var fetchBefore = async (data)=>{
        return new Promise(async (resolve)=>{
            //?transf.to  btn overlay. sa obavestenjem ako fali id il nije vratio..
            let r = await fetchApiHelper.postApi(`core/product/ids2`,{ids:data.active_ids});
            let new_array=[];
            if(r&&r.status){
                let arranged_ids=data.active_ids.split(",");
                let active_items=r.data.items;
                arranged_ids.map((id,i)=>{
                    active_items.map((item,index)=>{
                        if(item.id==id){
                            new_array.push(item);
                        }
                    })
                })
            }
            data.items = new_array;
            resolve(data);
        })
    }
   

    var pluginName      = 'products_plugin', 
        pluginTitle     = 'Products list:', 
        pluginFolder    = 'productsPlugin',
        pluginIcon      = ''

    var initialData = {
    
    }

    var icon = renderToString(<Icon />);

    addPlugin(editor, node, Form, View, initialData, pluginName, pluginTitle, pluginFolder, icon ,'100%', '100%', fetchBefore);
};