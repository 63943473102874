export const redirectPost = (url, data) => {

    var form = document.createElement('form');
    form.id = 'redirectform';
    form.action = url;
    form.method = 'post';
    form.target = '_blank';

    Object.keys(data).map((key,i)=>{
        var field = document.createElement('input');
        field.name = key;
        field.value = data[key];

        form.appendChild(field);
    });

    document.body.appendChild(form);
    form.submit(); 

}