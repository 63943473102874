

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderGigaEditor, 
    renderEditor,
    renderDatePicker,
} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidateFileImage,
    ValidateDate,
} from '../helpers/validation';

import AlertyBox from "../libraries/AletyBox";
import { fetchHelper } from "../helpers/fetchHelper";
import { reducer , change as changeFieldValue, change } from "redux-form";
import { server_img_host, user_avatar_path } from "../config/vars";

import { SubmitDataPickerValue } from "../helpers/dateHelper";

export const vouchersConf = {
    fields: {
        'id' : {
            name: 'id',
            type: 'text',
            component: renderFieldInput,
            label: 'ID',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'small',
            value: '1',
            readonly:true,
        },
        'length' : {
            name: 'length',
            type: 'text',
            component: renderFieldInput,
            label: 'Dužina karaktera',
            validate:[ValidateNumeric],
            warn: WarnNumeric,
            size: 'small',
            value: '5',
            info: 'Dužina generisanih kodova će se sabirati sa prefix ili sufix tekstom'
        },
        'barcode' : {
            name: 'barcode',
            type: 'text',
            component: renderFieldInput,
            label: 'Kod',
            validate:[ValidateRequire,ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
            readonly:true,
        },
        'product_id' : {
            name: 'product_id',
            type: 'text',
            component: renderFieldInput,
            label: 'Naziv',
            validate:[ValidateRequire,ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'contract_id' : {
            name: 'contract_id',
            type: 'text',
            component: renderFieldInput,
            label: 'Broj ugovora',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'store' : {
            name: 'store',
            type: 'text',
            component: renderFieldInput,
            label: 'Prodavinica',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'prefix' : {
            name: 'prefix',
            type: 'text',
            component: renderFieldInput,
            label: 'Prefix',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'sufix' : {
            name: 'sufix',
            type: 'text',
            component: renderFieldInput,
            label: 'Sufix',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'discount' : {
            name: 'discount',
            type: 'text',
            component: renderFieldInput,
            label: 'Popust',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
        },
        'count' : {
            name: 'count',
            type: 'text',
            component: renderFieldInput,
            label: 'Količina',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
        },
        'start_date' : {
            name: 'start_date',
            component: renderDatePicker,
            label: 'Početak prikazivanja',
            validate:[ValidateDate],
            size: 'medium',
            value: '', 
        },
        'end_date' : {
            name: 'end_date',
            component: renderDatePicker,
            label: 'Kraj prikazivanja',
            validate:[ValidateDate],
            size: 'medium',
            value: '',  
        },
        'status' : {
            name: 'status',
            component: renderFieldSelect,
            label: 'Status',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
            options:[
                {text:'Neaktivan',value:0},
                {text:'Aktivan',value:1},
                
            ]
        },
        'date_created' : {
            name: 'date_created',
            component: renderFieldInput,
            label: 'Datum kreiranja',
            validate:[],
            size: 'medium',
            value: '', 
            readonly:true,
        },
        'date_updated' : {
            name: 'date_updated',
            component: renderFieldInput,
            label: 'Datum promene',
            validate:[],
            size: 'medium',
            value: '',  
            readonly:true,
        },
        'usage_date' : {
            name: 'usage_date',
            component: renderFieldInput,
            label: 'Datum transakcije',
            validate:[],
            size: 'medium',
            value: '',  
            readonly:true,
        },
        'type': {
            name: 'type',
            label: 'Tip',
            component: renderRadioButtons,
            validate:[ValidateRequire],
            warn: [],
            value: '',
            options: [
                {label: 'Samo brojevi',value:1,name:'type'},
                {label:'Samo slova',value:2,name:'type'},
                {label:'Brojevi i slova',value:3,name:'type'}
            ]
        },
    },
    initialValues: {}
}