import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';
import Initialize from '../../base/initialize';
import {fetchHelper} from './../../../../helpers/fetchHelper';
import TemplateModal from '../../modals/templateModal';

export default class Template extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){
        this.initialize();
    }

    initialize(){
        
    }
  


    getIcons() {
       this.props.editor.setState({modal:<TemplateModal editor={this.props.editor} element={this.props.editor.ref} insert={true} />})
    }

    action(e){
        e.stopPropagation();
        e.preventDefault();
        this.getIcons();

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"Template-btn-"+this.props.id} onMouseDown={this.action} title="Izaberite module">
                <i className="fa fa-plug" aria-hidden="true"></i>
        </button>
    }

}