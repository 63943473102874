export const getFormData = (ref) => {

    var inputs = ref.current.querySelectorAll('input');
    var selects = ref.current.querySelectorAll('select');
    var textareas = ref.current.querySelectorAll('textarea');

    var data = {};

    if(inputs){
        for(var i = 0; i < inputs.length; i++){
            var field = inputs[i];
            var name = field.name;
            var value = field.value;
            var type = field.type;
            switch(type){
                case 'radio':
                case 'checkbox':
                    data[name] = field.checked ? value : field.dataset.default.value;
                break;
                default:
                    data[name] = value;
                break;
            }

        }
    }

    if(selects){
        for(var i = 0; i < selects.length; i++){
            var field = selects[i];
            var name = field.name;
            var value = field.value;
            data[name] = value;
        }
    }

    if(textareas){
        for(var i = 0; i < textareas.length; i++){
            var field = textareas[i];
            var name = field.name;
            var value = field.value;
            data[name] = value;
        }
    }

    return data;
}