import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';
import Initialize from '../../base/initialize';
import {fetchHelper} from '../../../../helpers/fetchHelper';
import Files from '../../base/Files';
import AlertyBox from '../../../AletyBox';
export default class Images extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
        this.onClick = this.onClick.bind(this);
        this.removeImage = this.removeImage.bind(this);

        this.editorContent = null;
    }

    componentDidMount(){

        this.url = this.props.url;

        this.initialize();
    }

    initialize(){
        this.editorContent = this.props.element.current;
    }


    onClick(e, src){
        e.stopPropagation();
        e.preventDefault();
        this.addImageToEditor(src);
        this.props.editor.setState({modal:false});
        return false;
    }

    addImageToEditor(src){

        const img = document.createElement("img");
        img.src = src;
        img.dataset.type = 'image';
        img.dataset.onload = 'ImageModal';
        img.className = 'block-element-editor';

        // var div = document.createElement('figure');
        // div.classList.add('block-element-editor');
        // div.appendChild(img);
        // div.contentEditable = false;
        // div.dataset.type = 'image';
        // div.setAttribute('tabindex', '0');
        // div.ondragstart = function() { return false; };

        // div.onkeypress =(e)=>{
        //     if(e.keyCode === 127){
        //         div.remove();
        //         this.props.editor.registerChange();
        //     }
        // }

        // const element = document.createElement("div");
        // element.appendChild(div);
       
        // var initialize = new Initialize();
        // initialize.initializeImage(div,this.props.editor);

        // var ins = new InsertNode();
        // ins.append(element,this.props.editor);

        document.execCommand('insertHTML',true, img.outerHTML);
    }


    removeImage(ref, item, src, event){

        event.stopPropagation();
        event.preventDefault();

        if(item){
            AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                if(result.isConfirmed){
                    let i=item;
                    let data={
                        img_name:  i.name , 
                        img_id:    i.id , 
                        user_id:   i.master_id , 
                        section:   i.section
                    };
                    var response = await fetchHelper.delete('upload/remove_img_from_editor_options_list',data).catch(function (error) {
                        AlertyBox.serverError(error);
                    });
                    if(response){
                        // index.setState({files:response.data.getImages});
                          
                    }
                    
                }
        })
        }
   
    }


    getImages(e) {
        
        this.props.editor.setState({modal:<Files editor={this.props.editor} files={this.props.editor.state.files} url={this.props.editor.props.img_list_url} remove={this.removeImage} onMouseDown={this.onClick} />})
        
    }

    action(e){

        e.stopPropagation();
        e.preventDefault();

        this.getImages(e);

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"Images-btn-"+this.props.id} onMouseDown={this.action} title="Lista uploadovanih slika">
                <label htmlFor={'images-'+this.props.id} className=" fa fa-clone"></label>
        </button>
    }

}