import React from 'react';
var FormData = require('form-data');
const axios = require('axios').default;


class GeneratorFetch {

    constructor(){
        this.query = {};
        this.control = null;
        this.generator = null;
    }

    _queryBuild(){
        var params = this.query;
        if(params !== undefined)
            return  '?'+Object.keys(params).map(key => key + '=' + params[key]).join('&');
        else 
            return '';
    }

    uniqid(a, b) {
        const c = Date.now()/1000;
        let d = c.toString(16).split(".").join("");
        while(d.length < 14) d += "0";
        let e = "";
        if(b){
            e = ".";
            e += Math.round(Math.random()*100000000);
        }
        return a + d + e;
    }

    cart_session(force) {
        try {
          if (localStorage !== undefined) {
            var uid = localStorage.getItem("UID3");
    
            if (uid !== undefined && uid !== null && force === undefined) {
              this.uid = uid;
            } else {
              this.uid = this.uniqid('uidxed',true);
    
              localStorage.setItem("UID3", this.uid);
            }
    
            return this.uid;
          }
        } catch (e) {}
    }

    _uuid(){

    }


    async get( url ){
        return new Promise(async (resolve)=>{
            axios.defaults.withCredentials                  = true;
            axios.defaults.headers.get['Identify']          = this._uuid();
            axios.defaults.headers.put['withCredentials']   = true;
            
    
            url += this._queryBuild();
    
            if (url.includes("?")) {
                url += "&uid=" + this.cart_session();
              } else {
                url += "?uid=" + this.cart_session();
            }
    
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Authorization':  this._uuid(),
                    'Identify': this._uuid(),
                }
            };
    
            const response = await fetch(url, requestOptions).then(response=>response.json()).then(data=>{ return data;})
            resolve(this.resolve(response));
        })
    }


    async post( url ){

    }


    resolve(response){
        if(response.status){
            return response.response;
        }
        return false;
    }

}


class GeneratorEvents {

    constructor(page, generator){
        this.page       = page;
        this.generator  = generator;
    }

    onClick(event,actions){
        this.mapEvents(event,actions);
    }

    mapEvents(event, actions){
        actions.map(a=>{
            Object.keys(a).map(fn=>{
                console.log(fn)
                if(!this[fn]) { console.warn('Undefined function ' + fn); } else {
                    this[fn](a[fn], event.currentTarget);
                }
            })
        })
    }

    changeColor(color, el){
        var next = null;
        if(Array.isArray(color)){
            color.map(c=>{
                if(c !== el.style.color){
                    next = c;
                }
            });
            if(!next) next = color[0];

        } else {
            next = color;
        }
        el.style.color = next;
    }

    changeColorTo(def, elCurrent){
        var el = document.getElementById(def[0]);
        if(el){
            this.changeColor(def[1],el);
        }
    }

    state(action, el){
        var state = this.page.state.pageState;
        
        Object.keys(action).map((key)=>{
            state = this.page.mergeJSON({state:{[key]:action[key]}});
        })

        this.page.setState({json:state},()=>{
            console.log('pageState',this.page.state.pageState);
            this.page.load();
        });
    }

    self(data, el){
        Object.keys(data).map((key)=>{
            switch(key){
                case 'text':
                    el.innerText = data[key];
                break;
            }
        })
    }

    async fetch(data,el){

        var F = new GeneratorFetch();
        F.query = data.query;
        F.control = this.page;
        F.generator = this.generator;

        var response = null;

        switch (data.method.toLowerCase()) {
            case 'get':
                response = await F.get(data.url);
            break;
            case 'post':
                response = await F.post(data.url);
            break;
        }

        if(response){
            this.mapEvents(el,response);
        }
    }

}


export class Generator {

    constructor(page){
        this.page = page;
        this.children = [];
        this.refs = {};
    }

    async build(jsonString){
        return new Promise((resolve)=>{
            var json = JSON.parse( jsonString );
            Object.keys(json).map((tag,i)=>{
                var res = this[tag](json[tag], i);

                if(res){
                    this.children.push( res );
                }
            })
            resolve( this.children );
        })
    }


    state(data){
        this.page.setState({pageState:data});
    }

    childrens(children){
        return children.map((child,i)=>{
            return Object.keys(child).map((tag,a)=>{
                return this[tag](child[tag],a);
            })
        })
    }

    _prepare_text(text){
        if(text){
            if(Array.isArray(text)){
                var d = undefined;
                text.map(key=>{
                    if(d === undefined) d = this.page.state.pageState;
                    d = d[key];
                });
                return d;
            } else {
                return text;
            }
        } else {
            return '';
        }
    }


    header(data,i){
        console.log(data)
        var ref = React.createRef();
        return <header key={i} ref={ref} {...data.props}>{data.children?this.childrens(data.children):''}</header>
    }

    h1(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <h1 ref={ref} {...data.props}  onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} key={i} >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</h1>;
        return el;
    }

    h2(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <h2 ref={ref} {...data.props}  onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} key={i} >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</h2>;
        return el;
    }

    h3(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <h3 ref={ref} {...data.props}  onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} key={i} >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</h3>;
        return el;
    }

    h4(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <h4 ref={ref} {...data.props}  onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} key={i} >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</h4>;
        return el;
    }

    h5(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <h5 ref={ref} {...data.props}  onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} key={i} >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</h5>;
        return el;
    }

    h6(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <h6 ref={ref} {...data.props}  onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} key={i} >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</h6>;
        return el;
    }

    div(data, i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <div ref={ref} {...data.props}  onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} key={i} >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</div>;
        return el;
    }

    p(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <p ref={ref} onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} {...data.props} key={i}  >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</p>;
        return el;
    }

    span(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <span ref={ref} onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} {...data.props} key={i}  >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</span>;
        return el;
    }

    small(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <small ref={ref} onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} {...data.props} key={i}  >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</small>;
        return el;
    }

    b(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <b ref={ref} onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} {...data.props} key={i}  >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</b>;
        return el;
    }

    button(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <button ref={ref} onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} {...data.props} key={i}  >{this._prepare_text(data.text)}{data.children?this.childrens(data.children):''}</button>;
        return el;
    }

    string(data,i){
        return data;
    }

    img(data,i){
        var events = new GeneratorEvents(this.page, this);
        var ref = React.createRef();
        var el = <img ref={ref} onClick={(event)=>data.events&&data.events.onClick?events.onClick(event, data.events.onClick):''} {...data.props} key={i} />;
        return el;
    }

}