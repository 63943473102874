/* warnings */

import { trim } from "./stringHelper"


export const stripTags = (str) => {
    return str ? str.replace(/(<([^>]+)>)/ig,"") : '';
}



export const WarnAlphaNumeric = (value) => {
    var test = (/^(?!0+$)[0-9 a-zA-Z\u0160\u0161\u0110\u0111\u010C\u010D\u0106\u0107\u017E\u017D]+$/gi.test(value));

    return value && !test
    ? 'Samo slova i brojevi su dozvoljeni'
    : undefined
}


export const WarnNumeric = (value) => {
    var test = (/^\d*\.?\d*$/.test(value));

    return value && !test
    ? 'Samo brojevi su dozvoljeni'
    : undefined
}




/* validations */
export const ValidateRequire = (value) => {
    return value || (parseInt(value) === 0 || value === '0') ? undefined : 'Polje je obavezno';
}

export const ValidateAlphaNumeric = (value) => {

    var test = (/^(?!0+$)[0-9 a-zA-Z\u0160\u0161\u0110\u0111\u010C\u010D\u0106\u0107\u017E\u017D]+$/gi.test(value));

    return value && !test
    ? 'Samo slova i brojevi su dozvoljeni'
    : undefined
}


export const ValidateString = (value) => {

    var test = stripTags(value);

    return value !== test 
    ? 'Html tagovi nisu dozvoljeni'
    : undefined
}

export const ValidateAddress = (value) => {

    var test = (/^(?!0+$)[0-9 a-zA-Z\u0160\u0161\u0110\u0111\u010C\u010D\u0106\u0107\u017E\u017D\-\.\/]+$/gi.test(value));

    return value && !test
    ? 'Samo slova i brojevi su dozvoljeni'
    : undefined
}

export const ValidateNumeric = (value) => {

    var test = (/^\d*\.?\d*$/.test(value));

    return value && !test
    ? 'Samo brojevi su dozvoljeni'
    : undefined
}


export const ValidateEmail = (value) => {
    var test = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value));

    return value && !test
    ? 'Email adresa nije ispravna'
    : undefined
}


export const ValidateMax = (value, ln) => {

    var test = value.length <= ln;

    return value && !test
    ? 'Maksimalan broj karaktera je ' + ln
    : undefined
}


export const ValidateMin = (value, ln) => {

    var test = value.length >= ln;

    return value && !test
    ? 'Minimalan broj karaktera je ' + ln
    : undefined
}

export const ValidatePhone = (value) => {
    var test = (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value));

    return value && !test
    ? 'Telefon nije ispravan'
    : undefined
}

export const ValidatePIB = (value) => {
    var test = (/^((?!(?:10000000))[0-9]{9})$/im.test(value));

    return value && !test
    ? 'Pib nije ispravan'
    : undefined
}

export const ValidateBankNum = (value) => {
    var str = value.split("-").join("");
    var test = (/^[0-9]{6,18}$/im.test(str));

    return value && !test
    ? 'Račun nije ispravan'
    : undefined
}


export const ValidateDate= (value) => {
    if (value) {

        var hasDate = function(string){

          if(typeof string === 'object'){
            if(string.currentTarget){
                string = string.currentTarget.value;
            }
          }

          console.log('validate date', string)
          string = string.replace(/\s+/,' ');
          string = string.trim();
          string = string.replace(/\-|\s|\/|\./gi,'-');
  
          var date_regex = /^(0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/ ;
          if(!(date_regex.test(string)))
          {
            return 'Datum nije ispravan';
          }
  
          var e = string.split('-');
  
          var isvalidYear  = false;
          var isValidMonth = false;
          var isValidDay   = false;
  
          e.map(a=>{
            if(a.length === 4) { 
              isvalidYear = a;
            } else { 
                if(parseInt(a) > 0 && parseInt(a) < 32 && ! isValidDay) { 
                  isValidDay = a; 
                } 
                else 
                if(parseInt(a) < 13 && parseInt(a) > 0 && isValidDay && !isValidMonth) {
                  isValidMonth = a;
                } 
            }
          })
  
          if(isValidDay && isValidMonth && isvalidYear){
            var date = isvalidYear + '-' + isValidMonth + '-' + isValidDay;
  
            var d = Date.parse(date);
  
            if (ValidateNumeric(d)) {
       
              var n = new Date(date).getTime();
              var f = new Date().getTime();
              if (f > n) return date;
            }
          }
  
          return 'Datum nije ispravan';
  
        };
  
        return hasDate(value) ? undefined : 'Datum nije ispravan';
    }
    //req se dodaje kad nema
    return undefined;
}


export const ValidateFileImage = (files,opt) => {
 
    //files.type temp fix todo!!!!!!!!!!
    
    if(files && files.length && files.type){

        if(files.length > opt.files){
            return `Maksimalan broj fajlova je ${opt.files}`;
        }
        
        for(var i = 0; i < files.length; i++){

            var file = files[i];

            var extension = file.name.split('.').pop().toLowerCase();

            if(!opt.accept.find(element => element === extension)){
                i = opt.length + 1;
                return `Fajl (${file.name}) nije dozvoljen. Dozvoljene ekstenzije su ` + opt.accept.join(',');
            }
        }


        // undefined se vraca ako je sve ok
        return undefined;
    } else {
        return undefined;
    }
}