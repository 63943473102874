import React, {Component} from 'react';

export default class Indent extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){
        e.stopPropagation();
        e.preventDefault();
        // this.props.element.current.focus();
        document.execCommand('indent', false, '');
        this.props.editor.registerChange();

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"Indent-btn-"+this.props.id} onMouseDown={this.action}><i className="fa fa-indent" aria-hidden="true" ></i></button>
    }

}