import React, { Component, useReducer } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { reduxAsyncValidate, reduxReduceVaues, reduxPrepareFormData } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";

import { fetchHelper } from '../helpers/fetchHelper';
import { date_sql_to_rs, date_rs_to_sql , SubmitDataPickerValue } from "../helpers/dateHelper";
import AlertyBox from '../libraries/AletyBox';
import { setBusy } from '../modules/forms';
import { backofficeUsersConf } from "./backofficeUsersConf";

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTable from '../libraries/GigaTable';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';





class BackofficeUsersForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      success: null,
      message: null,
      busy: false,
      // rnd: 0,
      allow_submit: false,
      table: null,
      // selected: null,
    };


    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    // this.onSave = this.onSave.bind(this);
    // this.runRow = this.runRow.bind(this);
    this.resetActive = this.resetActive.bind(this);
  }

  
  componentDidUpdate(prevProps) {
    if (this.props.data) {
      reduxAsyncValidate(this.props.data, this.props);
    }
  }



  getTableInstance(table) {
    this.table = table;
  }

  // onSave(data){
  //   if(this.table && data.id){
  //     this.table.loadData(false,()=>{
  //       var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
  //       if(row){
  //         this.setState({active_user:row});
  //       }
  //     });
  //   }
  // }

  // runRow(row) {
  //   if (row)
  //     this.setState({ active_user: row, selected: row.id })
  // }
 
  resetActive() {
    // this.setState({ active_user: null, selected: null })
  }

  
  async submit(data) {

    this.props.setBusy(true);
    var values = reduxReduceVaues(backofficeUsersConf.fields, data);
    var response = await fetchHelper.post('backoffice_users/change', values);
    this.props.setBusy(false);

    if (response.status && values && Object.keys(values).length) {
      if (this.props.onSave) {
        this.props.onSave(values);
      }
      AlertyBox.message(response.data);
    } else {
      var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
      AlertyBox.message(m)
    }
    return true;
  }

  
  render() {

    if (!this.props.data) return '';

    const { handleSubmit, invalid, submitting, pristine, busy } = this.props;
    backofficeUsersConf.initialValues = this.props.data;
    console.log(this.props.data)
    var refID = React.createRef();

   
    const cells = [
      {name:'ID',cell: 'id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true}, 
      {name:'Group ID',cell: 'group_id',width:100, fixed:true,sort:true},
      {name:'Title',cell: 'title',width:300, fixed:true,sort:true}, //never set editable,this is not the page for that,this is >/admin/backoffice-users-groups-and-permissions
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center',fixed:true,render:(row)=>{ 
        var value = parseInt(row.status);
        return  <button onClick={()=> { 
          if(!value){ 
            let additional_data=`{"user_id":${this.props.data.id},"group_id":${row.group_id}}`
            this.table.insertRowCell("BackofficeUserGroups",additional_data).then(()=>{ 
              this.props.index.table.loadData();
            });
          }
          else{   
            this.table.removeRowCell({ids:[row.id],table:"backoffice_user_groups"}).then(()=>{ 
              this.props.index.table.loadData();
            });
          }

           
      }}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button>  
      }}, 
 
    ];
 
 
    return (
      <>
        <GigaTabs tab={0}>

          <GigaTab title="Osnovni podaci">
            <form id="bck_users_form" onSubmit={handleSubmit((data) => {
              if (this.state.allow_submit) {
                this.setState({ allow_submit: false })
                data = SubmitDataPickerValue(data, ['date_created']);
                this.submit(data);
              }
            })} className="redux-form" encType="multipart/form-data" >
              {busy ? <div className="form-mask"></div> : ''}
              <Grid columns="repeat(auto-fit,minmax(260px,30%))" gap="30px">
                <Cell width={1} className="grid-column">
                  <div className="relative"> 
                    <Field {...backofficeUsersConf.fields.id} ref={refID} />
                    <Field {...backofficeUsersConf.fields.username} />
                    <Field {...backofficeUsersConf.fields.password} />
                    <Field {...backofficeUsersConf.fields.name} />
                    <Field {...backofficeUsersConf.fields.company} />
                    
                    <Field {...backofficeUsersConf.fields.city} />
                    <Field {...backofficeUsersConf.fields.email} />
                    <Field {...backofficeUsersConf.fields.phone_gsm} /> 

                  </div>
                </Cell>
                <Cell width={1} className="grid-column">
                  <div className="relative"> 
                    <Field {...backofficeUsersConf.fields.date_created} /> 
                    <Field {...backofficeUsersConf.fields.department} />

                  </div>
                </Cell>
                <Cell width={1} className="grid-column">
                  <div className="relative"> 
                    <Field {...backofficeUsersConf.fields.status} />
                  </div>
                </Cell>

              </Grid> 
              <div className="page-bottom-bar">
                <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
              </div>
            </form>
          </GigaTab> 
       
          {this.props.data.id ? 
            <GigaTab title="Tip korisnika">
              <GigaTable
                cells={cells}
                pk='id'
                id={`backoffice_user_groups`}
                height={600}
                db="backoffice_user_groups" 
                fetch={`backoffice_users/group/${this.props.data.id}`}
                buttons={false}
                delete={false}
                // selected={this.state.selected}//no details btns no need for this
                getInstance={this.getTableInstance}
                // run={this.runRow}//no need because we have no up and down arrows and we do not edit fields
                resetActive={this.resetActive}//left only because table calls it in update
              /> 
            </GigaTab> 
            : "" }

        </GigaTabs>
      </>
    );
  }
}

const selector = formValueSelector('bck_users_form');

const mapStateToProps = state => ({
  initialValues: backofficeUsersConf.initialValues,
  busy: state.forms.busy,
  id: selector(state, 'id'), 
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setBusy,
      updateField: (field, data) => change("bck_users_form", field, data),
    },
    dispatch
  );

  BackofficeUsersForm = reduxForm({
  form: "bck_users_form",
  reduxAsyncValidate,
  enableReinitialize: true,
})(BackofficeUsersForm);
BackofficeUsersForm = connect(
  mapStateToProps, mapDispatchToProps
)(BackofficeUsersForm);
export default BackofficeUsersForm; 