import $ from 'jquery';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderDatePicker,
 

} from "../../../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidatePhone, 
    ValidateDate,
    ValidateString,
    
} from '../../../helpers/validation';
import AlertyBox from "../../../libraries/AletyBox";
import { fetchHelper } from "../../../helpers/fetchHelper";
import { reducer , change as changeFieldValue, change } from "redux-form";
import { 
    server_img_host,
    page_icon_path,
    page_icon_mobile_path, page_thumb_path, page_image_path
 } from '../../../config/vars';

export const ruleGeneratorConf = {
    fields: {
        'id' : {
            name: 'id',
            type: 'text',
            component: renderFieldInput,
            label: 'ID',
            validate:[ValidateNumeric],
            warn: [],
            size: 'small',
            value: '',
            readonly:true,
        },
        'master_title' : {
            name: 'master_title',
            type: 'text',
            component: renderFieldInput,
            validate:[ValidateRequire],
            label: 'Name',
            warn: [],
            size: 'medium',
            value: '', 
            trigger: (value,input,instance,controller) => {
                console.log(value, input,controller,instance)
                input.onChange(value);
                controller.setState({master_title:value})
            }
        },
        'master_table': {
            name:'master_table',
            type: 'select',
            component: renderFieldSelect,
            label: 'Database Table',
            validate:[ValidateRequire],
            warn: [ValidateRequire],
            size: 'medium',
            value: '',
            trigger: (value, input, instance, controler, parentValue) => {

                var items = [];
                var cels = (controler.state.columns[value]);
                if(cels && Object.keys(cels).length){
                    Object.keys(cels).map((k,i)=>{
                        var a = cels[k];
                        items.push({value:a,text:a})
                    })
                }

                instance.fields.master_table_cell.options = items;
                instance.fields.master_table_cell.parentValue = value;
                
                controler.setState({cels2:items,master_table:value});
                controler.props.updateField('master_table_cell' , '');

                input.onChange(value);
            },
            options:[
            ]
        },
        'master_table_cell' : {
            id: 'master_table_cell',
            name: 'master_table_cell',
            component: renderFieldSelect,
            label: 'Column',
            validate:[ValidateRequire],
            warn: [ValidateRequire],
            size: 'medium',
            value: '',
            parentValue: '',
            display: (parentValue) => {
                console.log(parentValue)
                return parentValue  !== '' ? '':'none'
            },
            trigger: (value, input, instance, controler, parentValue) => {
                if(parentValue === '') return '';
                input.onChange(value);
                controler.setState({master_table_cell:value})
            },
            options:[
                
            ],
        },

        'depedency_table': {
            name:'depedency_table',
            type: 'select',
            component: renderFieldSelect,
            label: 'Database Table',
            validate:[],
            warn: [],
            size: 'medium',
            value: '',
            trigger: (value, input, instance, controler, parentValue) => {

                var items = [];
                var cels = (controler.state.columns[value]);
                if(cels && Object.keys(cels).length){
                    Object.keys(cels).map((k,i)=>{
                        var a = cels[k];
                        items.push({value:a,text:a})
                    })
                }

                instance.fields.depedency_table_cell.options = items;
                instance.fields.depedency_table_cell.parentValue = value;
                
                controler.setState({cels2:items,depedency_table:value});
                controler.props.updateField('depedency_table_cell' , '');

                input.onChange(value);
            },
            options:[
            ]
        },
        'depedency_table_cell' : {
            id: 'depedency_table_cell',
            name: 'depedency_table_cell',
            component: renderFieldSelect,
            label: 'Column',
            validate:[],
            warn: [],
            size: 'medium',
            value: '',
            parentValue: '',
            display: (parentValue) => {
                console.log(parentValue)
                return parentValue  !== '' ? '':'none'
            },
            trigger: (value, input, instance, controler, parentValue) => {
                if(parentValue === '') return '';
                input.onChange(value);
                controler.setState({depedency_table_cell:value})
            },
            options:[
                
            ],
        },
       
        'origin_table': {
            name:'origin_table',
            type: 'select',
            component: renderFieldSelect,
            label: 'Database Table',
            validate:[ValidateRequire],
            warn: [ValidateRequire],
            size: 'medium',
            value: '',
            trigger: (value, input, instance, controler, parentValue) => {

                var items = [];
                var cels = (controler.state.columns[value]);
                if(cels && Object.keys(cels).length){
                    Object.keys(cels).map((k,i)=>{
                        var a = cels[k];
                        items.push({value:a,text:a})
                    })
                }

                instance.fields.origin_table_cell.options = items;
                instance.fields.origin_table_cell.parentValue = value;
                
                controler.setState({cels:items,origin_table:value});
                controler.props.updateField('origin_table_cell' , '');

                input.onChange(value);
            },
            options:[
            ]
        },
        'origin_table_cell' : {
            id: 'origin_table_cell',
            name: 'origin_table_cell',
            component: renderFieldSelect,
            label: 'Column',
            validate:[ValidateRequire],
            warn: [ValidateRequire],
            size: 'medium',
            value: '',
            parentValue: '',
            display: (parentValue) => {
                console.log(parentValue)
                return parentValue  !== '' ? '':'none'
            },
            trigger: (value, input, instance, controler, parentValue) => {
                if(parentValue === '') return '';
                input.onChange(value);
                controler.setState({origin_table_cell:value})
            },
            options:[
                
            ],
        },
      

    },
    initialValues: {}
}