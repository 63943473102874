import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile, isChrome, isAndroid, isChromium } from "react-device-detect";
import {getUser,setIsMobile} from './modules/auth';
import { isServer } from "./store";
import './App.css';
import './fontAwesome';
import Footer from './components/common/Footer';

class AppRouting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: ''
    };

    this.url = null;
    this.onResize = this.onResize.bind(this);
  }

  onResize(){
    var w = global.window.innerWidth;
    if(w < 1025){
      this.props.setIsMobile(true);
    } else {
      this.props.setIsMobile(isMobile);
    }
  }



  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({ location: location.pathname });
    });

    this.validateUser();
  }

  componentDidMount(){
    if (!isServer) {
      this.props.setIsMobile(isMobile);
    }
    setTimeout(this.onResize,500);
    global.window.addEventListener('resize', this.onResize);
  }


  componentDidUpdate(){
    this.validateUser();
  }

  validateUser(){
    var url = this.props.location.pathname + this.props.location.search;
    if(url !== this.url){
      this.url = url;
      this.props.getUser();
    }
  }

  componentWillUnmount() {
    this.unlisten();
    global.window.removeEventListener('resize', this.onResize);
  }

  render() {
    return (
      <div id="app" className={this.props.is_mobile ? 'mobile-app' : 'desk-app'}>
        {this.props.children}
        {this.state.location.includes('admin') ? (
          <Footer className="footer--wrapper--admin" />
        ) : (
          <Footer />
        )}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser,
      setIsMobile
    },
    dispatch
  );

  export default 
    withRouter( connect(
      mapStateToProps,
      mapDispatchToProps
  )(AppRouting) );
