import React from 'react';
import GigaDatePicker from '../../../../GigaDatePicker';
import ColorPickerField from '../../fields/ColorPickerField';
import TextField from '../../fields/TextField';

class Form extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        start_date: '',
        end_date: '',
        count_start_label: 'Vreme do početka akcije',
        titleColor: '#000000',
        labelColor: '#000000',
        counterColor: '#000000',
        backgroundColor: '#FFFFFF',
      };

      this.onChange = this.onChange.bind(this);
      this.onColorChange = this.onColorChange.bind(this);
    }


    componentDidMount(){
       this.setState({
           start_date: this.props.data.start_date,
           end_date: this.props.data.end_date,
           count_start_label: this.props.data.count_start_label,
           titleColor: this.props.data.titleColor,
           labelColor: this.props.data.labelColor,
           counterColor: this.props.data.counterColor,
           backgroundColor: this.props.data.backgroundColor
       });
    }
   

    onChange(e,s){
        var value = e.currentTarget.value;
        this.setState({[e.currentTarget.name]:value})
    }


  
    onColorChange(e){ 
        let name=Object.keys(e)[0];
        let value=Object.values(e)[0];
        this.setState({[name]:value});
 
    }


    render(){

        return (<form>
            <div className="clear row-3">
                <div className="form-group">
                    <label>Date Start</label> 
                    <GigaDatePicker  name="start_date"  className="textfield" onChange={this.onChange} value={this.state.start_date} />
                </div>
                <div className="form-group">
                    <label>Date End</label>
                    <GigaDatePicker  name="end_date" className="textfield"  onChange={this.onChange} value={this.state.end_date} />
                </div>
            </div>
            <div className="clear row-1">
                <TextField label="Counter Start" name="count_start_label" value={this.state.count_start_label} onChange={this.onChange} />
            </div>
            <div className="clear row-4">
                <ColorPickerField color={this.state.titleColor} label="Title Color" name="titleColor" enableAlpha={false} onChange={this.onColorChange} />
                <ColorPickerField color={this.state.labelColor} label="Label Color" name="labelColor" enableAlpha={false} onChange={this.onColorChange} />
                <ColorPickerField color={this.state.counterColor} label="Counter Color" name="counterColor" enableAlpha={false} onChange={this.onColorChange} />
                <ColorPickerField color={this.state.backgroundColor} label="Background Color" name="backgroundColor" enableAlpha={false} onChange={this.onColorChange} />
            </div>
        </form>)
    }
}

export default Form;