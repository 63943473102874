import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import { withRouter } from "react-router-dom";
import { reduxAsyncValidate, reduxReduceVaues } from "../helpers/formHelper";
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";
import AlertyBox from "../libraries/AletyBox";
import { SubmitDataPickerValue } from "../helpers/dateHelper";

import { setBusy } from "../modules/forms";
import { vouchersConf } from "./vouchersConf";

import "./../assets/css/form.scss";
import { fetchHelper } from "../helpers/fetchHelper";
import { stringTrim } from "../helpers/stringHelper";

import GigaSpinner from "../libraries/GigaSpinner";
import GigaTabs from "../libraries/GigaTabs";
import GigaTab from "../libraries/GigaTabs/GigaTab";

class VouchersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allow_submit: false,
      files: [],
      lastId: null,
    };

    this.addDepedency = this.addDepedency.bind(this);
  }

  componentDidMount() {
    vouchersConf.index = this;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data) {
      reduxAsyncValidate(this.props.data, this.props);
    }
  }

  async submit(data, formType) {
    this.props.setBusy(true);
    var values = reduxReduceVaues(vouchersConf.fields, data);
    var response = await fetchHelper.post("morph/change_item", values);

    this.props.setBusy(false);

    if (response.status && values && Object.keys(values).length) {
      if (this.props.onSave) {
        this.props.onSave(values);
      }
      AlertyBox.message(response.data);
    } else {
      var m = {
        message: "Došlo je do greške prilikom snimanja",
        status: false,
      };
      AlertyBox.message(m);
    }
  }

  addDepedency(instance, e) {
    var data = {
      title: this.state.master_title,
      master: {
        table: this.state.master_table,
        cell: this.state.master_table_cell,
      },
      origin: {
        table: this.state.origin_table,
        cell: this.state.origin_table_cell,
      },
      depedency: {
        table: this.state.depedency_table,
        cell: this.state.depedency_table_cell,
      },
    };

    this.setState({ data: data });
  }

  addTables() {
    var d = this.state.data;
    var status = false;
    if (d) {
      if (d.master.table && d.master.cell) {
        if (d.origin.table && d.origin.cell) {
          status = true;
          if (d.depedency.table && !d.depedency.cell) {
            status = false;
          }
        }
      }
    }

    if (!status) {
      var m = { message: "Opcije ne ispunjavaju uslov", status: false };
      AlertyBox.message(m);
      return false;
    }

    if (!stringTrim(d.title)) {
      var m = { message: "Uslov mora da ima naslov", status: false };
      AlertyBox.message(m);
      return false;
    }
  }

  form(refID) {
    const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

    return (
      <>
        <form
          id="categoryEditForm"
          onSubmit={handleSubmit((data) => {})}
          className="redux-form"
          encType="multipart/form-data"
        >
          <GigaTabs tab={0}>
            {/* GiaTab title, heigth */}
            <GigaTab title="Detalji " /*height={400}*/>
              {busy ? <div className="form-mask"></div> : ""}
              <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                <Cell width={1} className="grid-column">
                  <div className="relative">
                    <Field
                      {...vouchersConf.fields.id}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.barcode}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true}
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.contract_id}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.product_id}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.discount}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.usage_date}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.store}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.status}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.start_date}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.end_date}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.date_created}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                    <Field
                      {...vouchersConf.fields.date_updated}
                      instance={vouchersConf}
                      controler={this}
                      readonly={true} 
                      disabled={true}
                    />
                  </div>
                </Cell>
              </Grid>
            </GigaTab>
          </GigaTabs>
        </form>
      </>
    );
  }

  // form(refID) {
  //     return (
  //         <>
  //             <Cell width={1} className="grid-column">
  //                 <div className="relative">
  //                     <Field {...vouchersConf.fields.product_id}  instance={vouchersConf} controler={this} />
  //                 </div>

  //                 <div className="relative">
  //                     <h4>Datum</h4>
  //                     <div className="separate-right"></div>
  //                     <Field {...vouchersConf.fields.start_date}  instance={vouchersConf} controler={this} />
  //                     <Field {...vouchersConf.fields.end_data} instance={vouchersConf} controler={this} button={this.tableCellAdd.bind(this)} />

  //                 </div>
  //                 <div className="relative">
  //                     <h4>Količina</h4>
  //                     <div className="separate-right"></div>
  //                     <Field {...vouchersConf.fields.count}  instance={vouchersConf} controler={this} />
  //                 </div>
  //                 <div className="relative">
  //                     <h4>Discount</h4>
  //                     <div className="separate-right"></div>
  //                     <Field {...vouchersConf.fields.discount}  instance={vouchersConf} controler={this} />
  //                 </div>
  //                 <div className="relative">
  //                     <h4>Patern</h4>
  //                     <div className="separate-right"></div>
  //                     <Field {...vouchersConf.fields.prefix}  instance={vouchersConf} controler={this} />
  //                     <Field {...vouchersConf.fields.sufix}  instance={vouchersConf} controler={this} />
  //                 </div>
  //                 <div className="relative">
  //                     <button onClick={this.addTables.bind(this)}>Add</button>
  //                 </div>
  //             </Cell>
  //         </>
  //     )
  // }

  render() {
    if (!this.props.data) return "";

    const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

    console.log("data", this.props.data);
    vouchersConf.initialValues = this.props.data;

    var refID = React.createRef();

    return <>{this.form(refID)}</>;
  }
}

const selector = formValueSelector("VouchersForm");

const mapStateToProps = (state) => ({
  initialValues: vouchersConf.initialValues,
  busy: state.forms.busy,
  id: selector(state, "id"),
  // category_id: selector(state, 'category_id'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBusy,
      updateField: (field, data) => change("VouchersForm", field, data),
    },
    dispatch
  );

VouchersForm = reduxForm({
  form: "VouchersForm",
  reduxAsyncValidate,
  enableReinitialize: true,
})(VouchersForm);
VouchersForm = connect(mapStateToProps, mapDispatchToProps)(VouchersForm);
export default withRouter(VouchersForm);
