import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import ReactTooltip from "react-tooltip";


export const SortableItem = SortableElement((props) => {
    return <li style={{ display: 'inline-block' }} className="sortable-item"  className={parseInt(props.active) === parseInt(props.value.id) ? 'sortable-item active':'sortable-item'}>
        <div>
            <img data-tip data-for="registerTip" onClick={() => props.edit(props.value)} src={props.args.url + props.value.img_name} style={{ width: props.args.place.width, height: props.args.place.width }} />
            {props.remove && props.value.img_name ? (
                <div className="remove-image-placeholder" style={{
                    position: 'absolute',
                    backgroundColor:'#fff',
                    width: '15px',
                    height:'20px',
                    right: '0px',
                    top: '-1px',
                    textAlign:'center',
                    cursor: 'pointer'
                }} onClick={(e) => {
                    props.remove(props.value.img_name, props.value.position, props.value.id, e)
                }}>
                    <i className="fa fa-times"></i>
                </div>
            ) : ''}
        </div>
    </li>
});