import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "./../../../helpers/fetchHelper";
import GigaTable from './../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import VouchersCreateForm from "../../../forms/VouchersCreateForm";
// import GridLayoutEditor from "../../../libraries/GridLayout/GridLayout";

class Vouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_item:null,
      selected: null,
      table: null,
      user_images:null,
      section:"users",
      detail: null
  
      
      
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
  }

  componentDidMount(){
    
  }

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_item:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_item:row,selected:row.id})
  }

  resetActive() {
    this.setState({active_item:null,selected:null})
  }









  render() {

    const cells = [
      {name:'ID',cell: 'id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Barcode',cell: 'barcode',width:160, fixed:true, className:''},
      {name:'Contract ID',cell: 'contract_id', width:160,fixed:true, truncate:true, sort: true},
      {name:'Name',cell: 'product_id', width:200,fixed:false, truncate:true,className:'draggable'},
      {name:'Discount',cell: 'discount', width:100,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Store',cell: 'store', width:100,fixed:false,truncate:true,editable:true,className:'draggable'},
      {name:'Date Start',cell: 'start_date', width:140,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Date End',cell: 'end_date', width:140,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Detalji', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return row.id !== 1499709 ? <button onClick={()=>{ 
          this.setState({active_item:row,selected:row.id})
      }}><i className="fa fa-folder-open-o" /></button> : ''
      }},
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center', render:(row)=>{
        var value = parseInt(row.status); 
        return row.id !== 1499709 ? <button onClick={()=> this.table.updateRowCell(row,'status',value?0:1)}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button> : ''
      }},
    ];

    const buttons = [
      {title: 'Kreiraj nove', type: 'link' , link: '/admin/promotions/new'},
    ];
    
    return (<><div>

    </div>
      <div className="admin-home__main-section">
        <VouchersCreateForm detail={this.state.detail} data={this.state.active_item} onSave={this.onSave} index={this} />
      </div>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Vouchers));
