import React, { Component } from 'react';

export default class Files extends Component {

    constructor(props) {
        super(props)

        this.ref = React.createRef();
    }

    // componentDidMount() {
    //     this.url = this.props.url;
    //     this.initialize();
    // }

    // initialize() {
    //     this.editor = this.props.editor;
    // }

    render() {
        //moze sve iz editora i moze sve ovde al ajd..
        let editor=this.props.editor;
        let url=this.props.url;
        let f = this.props.files;
        
        return <div className="GigaEditorFiles" ref={this.ref}>
            { f && f.status && url && editor && this.props.remove ?
                f.imgList.map((item, x) => {
                    return <div className="image-placeholder"   key={"edImg" + x} onMouseDown={(event) => this.props.onMouseDown(event, url+ item.name)} >
                        <div className="img-container">
                            <img src={ url+ item.name}  />
                        </div>
                        <div className="remove-image-placeholder"  onMouseDown={(e)=>{this.props.remove(this.ref, item, url+ item.name,e)}}>
                            <i className="fa fa-times"></i>
                        </div>
                    </div>
                }).reverse()
                : ""
            }
        </div>
    }

}