export const SET_NAVIGATION = "navigation/SET_NAVIGATION";
export const SET_MASTER_ACTIVE = "navigation/SET_MASTER_ACTIVE";
export const SET_MOB_NAV_OPEN = "navigation/SET_MOB_NAV_OPEN";
export const SET_BREADCRUMBS = "breadcrumbs/SET_BREADCRUMBS";

const initialState = {
  navigation: {},
  master_active: null,
  mob_nav_open:false,
  breadcrumbs:"",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NAVIGATION:
      return {
        ...state,
        navigation: action.navigation
      };
    case SET_MASTER_ACTIVE:
      return {
        ...state,
        master_active: action.master_active
      };
    case SET_MOB_NAV_OPEN:
      return {
        ...state,
        mob_nav_open: action.mob_nav_open
      };
    case SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.breadcrumbs
      };
    default:
      return state;
  }
};

export const setBreadcrumbs= (breadcrumbs) => dispatch => {
  return new Promise(resolve => {
    dispatch({
        type: SET_BREADCRUMBS,
        breadcrumbs: breadcrumbs
      });
    resolve(breadcrumbs);
  });
}

export const setSideNavMobile = (mob_nav_open) => dispatch => {
  return new Promise(resolve => {
    dispatch({
        type: SET_MOB_NAV_OPEN,
        mob_nav_open: mob_nav_open
      });

    resolve(mob_nav_open);
  });
}


export const setNavigation = (navigation) => dispatch => {
  return new Promise(resolve => {
    dispatch({
        type: SET_NAVIGATION,
        navigation: navigation
      });

    resolve(navigation);
  });
}


export const setMasterActive = (master_active) => dispatch => {
  return new Promise(resolve => {
    dispatch({
        type: SET_MASTER_ACTIVE,
        master_active: master_active
      });

    resolve(master_active);
  });
}