import React, { Component } from "react";
import { Link } from "react-router-dom";

import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import { fetchHelper } from "../../../helpers/fetchHelper";
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
//TODO creirati sticker form
import StickersForm from "../../../forms/StickersForm";
import ProductOptions from "../../../forms/ProductOptions";


class StickersEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:null,
      status: false,
      item: [],
      categories: [],
      brands: []
    }


    this.id = '';

  }

  componentDidMount(){
    
  }

  componentDidUpdate(){
    let id=this.props.match.params.id
    if  (id !== this.state.id) {
      this.setState({id:id, active_page:null})
        this.get();
    }
  }

    

  async get(cb){
    let id=this.props.match.params.id
    let response = await fetchHelper.get(`sticker/get`,{id:id});
    console.log('response',response)
    this.setState({
        status: response.status,
        item: (response.status ? response.item: null), 
        categories: response.categories,
        brands: response.brands 
    });
    if(cb) cb();
  }


  onSave(e){

  }


  render() {
    

    return (
    <>
       <div className="admin-home__main-section">
        {this.state.item ? <StickersForm data={this.state.item} categories={this.state.categories} onSave={this.onSave.bind(this)} index={this}  />  : ""}
      </div>
    </>);
  }
}

const mapStateToProps = state => ({

  user: state.auth.user

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
   
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(StickersEdit));