import React from 'react';
import { Element, useEditor } from '@craftjs/core';
import { Container } from '../../selectors/Container';
import { Text } from '../../selectors/Text';
import { Image } from '../../selectors/Image';
import { Tabs } from '../../selectors/Tabs';


import styled from 'styled-components';

const ToolboxDiv = styled.div`
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  ${(props) => (!props.enabled ? `width: 0;` : '')}
  ${(props) => (!props.enabled ? `opacity: 0;` : '')}
`;

const Item = styled.div`
  svg {
    width: 22px;
    height: 22px;
    fill: #707070;
  }
`;

export const Toolbox = () => {
    const {
      enabled,
      connectors: { create },
    } = useEditor((state) => ({ enabled: state.options.enabled }));
  
    return (
      <ToolboxDiv
        enabled={enabled && enabled}
        className="toolbox transition w-12 border-r h-screen bg-white"
      >
        <div className="flex flex-col items-center pt-3">
          <div
            ref={(ref) =>
              create(
                ref,
                <Element
                  canvas
                  is={Container}
                  background={{ r: 0, g: 0, b: 0, a: 1 }}
                  color={{ r: 255, g: 255, b: 255, a: 1 }}
                  height="300px"
                  width="100%"
                ></Element>
              )
            }
          >
            <Item className="m-2 pb-2 cursor-pointer block">
              <svg className="jss91" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><g><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></g></svg>

            </Item>
          </div>
          <div
            ref={(ref) =>
              create(ref, <Text fontSize="12" textAlign="left" text="Hi there" />)
            }
          >
            <Item className="m-2 pb-2 cursor-pointer block">
            <svg className="jss91" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><g><path d="M2.5 4v3h5v12h3V7h5V4h-13zm19 5h-9v3h3v7h3v-7h3V9z"></path></g></svg>
            </Item>
          </div>
          <div
            ref={(ref) =>
              create(ref, <Image src="https://i.imgur.com/jk6bnas.png" />)
            }
          >
            <Item className="m-2 pb-2 cursor-pointer block">
              <svg className="jss91" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><g><path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"></path></g></svg>
            </Item>
          </div>
          <div
            ref={(ref) =>
              create(ref, <Tabs  />)
            }
          >
            <Item className="m-2 pb-2 cursor-pointer block">
              <svg className="jss91" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><g><path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h10v4h8v10z"></path></g></svg>
            </Item>
          </div>
          
         
        </div>
      </ToolboxDiv>
    );
  };