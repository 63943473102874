import React, {Component} from 'react';
import {fetchHelper} from './../../helpers/fetchHelper';
import {SortableList} from './SortableList';
import arrayMove from 'array-move';


class SortableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgList: this.props.items
        };
        this.id = this.props.args.id;
        this.section = this.props.args.section;
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.items != this.props.items){
            this.setState({imgList: this.props.items})
        }
    }


    updatePositionAndReturnObject(arrOfObjects){
        var positions = [];

        arrOfObjects.map( o => {
            positions.push(o.position)
            positions.sort((a,b) => a-b);
        })


        positions.map((pos,index) => {
            arrOfObjects[index].position = pos;
        })

        return Object.assign({},arrOfObjects);
    }

    async updateOrder(){

        let imagesArr = [...this.state.imgList];
        let oldIndex = this.state.oldIndex;
        let newIndex = this.state.newIndex;

        if(oldIndex < newIndex){
            let objectsToUpdate = imagesArr.slice(oldIndex,newIndex+1)

            let data = this.updatePositionAndReturnObject(objectsToUpdate);   
            
            if(this.props.args.callback) {
                let formData = this.props.component.props.args.formData;

                this.props.args.callback(data,this.props.onSaveItem,formData);
            }         
            
            fetchHelper.post('gallery/update_order',data);
            return;
        } else {
            let objectsToUpdate = imagesArr.slice(newIndex,oldIndex+1)

            let data = this.updatePositionAndReturnObject(objectsToUpdate)

            if(this.props.args.callback) {
                let formData = this.props.component.props.args.formData;
                this.props.args.callback(data,this.props.onSaveItem,formData);
            }
           
            fetchHelper.post('gallery/update_order',data);
            return;
        }

    }
    

    onSortEnd = ({oldIndex, newIndex}) => {
      this.setState((state) => ({
        newIndex,
        oldIndex,
        imgList: arrayMove(state.imgList, oldIndex, newIndex),
      }));
      
      this.updateOrder()
    };
    
    render() {
      return <SortableList cmp={this.props.component} distance={1} hideSortableGhost={false}  axis='xy' helperClass="draggeding" args={this.props.args} items={this.state.imgList} onSortEnd={this.onSortEnd} remove={this.props.remove} edit={this.props.edit} active={this.props.active} />;
    }
  }


  export default SortableComponent;