import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import GigaTabs from '../../../../GigaTabs';
import GigaTab from '../../../../GigaTabs/GigaTab';
import GigaDatePicker from '../../../../GigaDatePicker';
import TemplateTimer from './index';
import ReactDOMServer, { renderToString } from 'react-dom/server';
import Initialize from '../../../base/initialize';
import InsertNode from '../../../base/insertNode';
import { rgbToHex } from '../../../../../helpers/stringHelper';

export default class TemplateTimerModal extends Component {


    constructor(props){
        super(props)
        
        this.state={
            width: 'auto',
            height: 'auto',
            border: 'solid 1px #ccc',
            backgroundColor: '#fff',
            borderRadius: '0px',
            title: '',
            color: '#000',
            fontSize: '20px',
            padding: '10px 10px 10px 10px',
            margin: '0px 0px 0px 0px',
            dateStart: '',
            dateEnd: '',
        }

        this.setStyle = this.setStyle.bind(this);
        this.onChange = this.onChange.bind(this);

        this.ref = React.createRef();
    }

    componentDidMount(){
        if(this.props.insert === false){
            this.getStyle();
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.element !== this.props.element && this.props.insert === false){
            this.getStyle();
        }
    }

    handleChange = (moment) => {
        this.setState({
          moment
        });
    }

    setStyle(){

        if(this.props.insert === true){
            if(this.state.dateStart && this.state.dateEnd){
               
                   
                    let htmlString = ReactDOMServer.renderToStaticMarkup(
                        <TemplateTimer parent={this} />
                    );
                    
                   
                    var selection = document.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(this.props.range);

                    document.execCommand('insertHTML',true,htmlString);

                    var ins = new Initialize();
                    ins.initializeTemplates(this.props.editor);

                    this.props.editor.registerChange();
                    this.props.editor.setState({modal:null});
                    
                

            }
        } else {
            if(this.state.dateStart && this.state.dateEnd){
                var old = this.props.element;
                let htmlString = ReactDOMServer.renderToStaticMarkup(
                    <TemplateTimer parent={this} />
                );
               
                var div = document.createElement('div');
                div.innerHTML = htmlString;
                var node = div.firstChild;
                old.replaceWith(node);

                var ins = new Initialize();
                ins.initializeTemplates(this.props.editor);

                this.props.editor.registerChange();
                this.props.editor.setState({modal:null});
            }
        }
        
    }


    getStyle(){

        if(this.props.element){
            var t = this.props.element;
            this.setState({
                width:              t.style.width,
                height:             t.style.height, 
                color:              rgbToHex( t.style.color ),
                backgroundColor:    rgbToHex( t.style.backgroundColor ), 
                fontSize:           t.style.fontSize, 
                margin:             t.style.margin,
                padding:            t.style.padding,
                borderRadius:       t.style.borderRadius,
                title:              t.dataset.title,
                dateStart:          t.dataset.start,
                dateEnd:            t.dataset.end
            })
        }
        
    }

    onChange(e){
        this.setState({[e.currentTarget.name]:e.currentTarget.value});
    }


    render(){
       return (<>
        <div className="modal-content" ref={this.ref} style={{height:420}}>
            <GigaTabs tab={0}>
                <GigaTab title="Atributi">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Width</label>
                                        <input name="width" className="small" onChange={this.onChange} value={this.state.width}/>
                                    </div>
                                </td>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Height</label>
                                        <input name="height" className="small" onChange={this.onChange} value={this.state.height} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Color</label>
                                        <input name="color" type="color" className="small" onChange={this.onChange} value={this.state.color}/>
                                    </div>
                                </td>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Background</label>
                                        <input name="backgroundColor" type="color" className="small" onChange={this.onChange} value={this.state.backgroundColor}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Radius</label>
                                        <input name="borderRadius" className="small" onChange={this.onChange} value={this.state.borderRadius} />
                                    </div>
                                </td>
                                <td style={{width:'50%'}}>
                                <div className="ge-form-group">
                                        <label>Font Size</label>
                                        <input name="fontSize" className="small" onChange={this.onChange} value={this.state.fontSize} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Border</label>
                                        <input name="border" className="largest" onChange={this.onChange} value={this.state.border} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Margin</label>
                                        <input name="margin" className="largest" onChange={this.onChange} value={this.state.margin} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Padding</label>
                                        <input name="padding" className="largest" onChange={this.onChange} value={this.state.padding} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Title</label>
                                        <input name="title" className="largest" onChange={this.onChange} value={this.state.title} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GigaTab>
                <GigaTab title="Time">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Date Start</label>
                                        <GigaDatePicker 
                                            name="dateStart"
                                            onChange={this.onChange}
                                            value={this.state.dateStart}
                                        />
                                    </div>
                                </td>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Date End</label>
                                        <GigaDatePicker 
                                            name="dateEnd"
                                            onChange={this.onChange}
                                            value={this.state.dateEnd}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GigaTab>
            </GigaTabs>
        </div>
        <div className="modal-foot">
                
                <button onClick={this.setStyle}  className="primary-btn">Primeni</button>
                <button onClick={() => this.props.editor.setState({modal:''})}>Zatvori</button>
        </div>
        
    </>)
    }

}