export const date_sql_to_rs = (str) => {

    if(str && typeof str === 'object'){
      if(str.currentTarget !== undefined){
        str = str.currentTarget.value;
      }
    }



    var d = Date.parse(str);
    if(!isNaN(d)){
      var date = new Date(str);
      var h = date.toTimeString();
      var n = h.split(' ');
      var j = n[0].split(':');
      var h = j[0]+':'+j[1];

      str = date.toLocaleDateString("rs-RS").toString().replace(/\//g,'.');
    
      var e = str.split('.');
      var m = e[0];
      var d = e[1];
      var y = e[2];

      if(y === undefined || d === undefined || m === undefined ) return '';
      if(y === 'Invalid date' || d === 'Invalid date' || m === 'Invalid date' ) return '';

      if(d < 10) d = '0'+parseInt(d);
      if(m < 10) m = '0'+parseInt(m);
      if(y === undefined || d === undefined || m === undefined ) return '';
      if(y === 'Invalid date' || d === 'Invalid date' || m === 'Invalid date' ) return '';

      var strdate = `${d}.${m}.${y} ${h}`;

      return strdate;

    } else {
      return 'error'
    }
}


export const date_rs_to_sql = (str) => {

  if(!str) return '';
    
   if(typeof str === 'object'){
     if(str.currentTarget !== undefined){
       str = str.currentTarget.value;
     }
   }

   console.log(str);

    var e = str.split(' ');
    var h = e[1];
    var a = e[0].split('.');
    var day = a[0];
    var month = a[1];
    var year = a[2];

    if(year === undefined || day === undefined || month === undefined ) return '';

    var strdate = `${year}-${month}-${day} ${h}`;

    console.log(strdate)

    return strdate;
}



export const date_rs_to_object = (str) => {
    var e = str.split(' ');
    if(e.length > 1){
      var h = e[1].split(':');
      var a = e[0].split('.');
      var day = a[0];
      var month = a[1];
      var year = a[2];
      var hours = '00';
      var minutes = '00';

      if(h.length){
        hours = h[0];
        minutes = h[1];
      }

      return {
        day: day,
        month: month,
        year: year,
        hours: hours,
        minutes: minutes
      }
    } 

    return null;
}


export const date_rs_validate = (d,value) => {
    if(!value.trim()) return false;
    if(d.isValid()){
       var n = value.split(' ');
       if(n.length < 2){
         return false;
       }

       var dt = n[0];
       var hr = n[1];

       var e = dt.split('.');
       if(e.length !== 3) return false;

       if(e[0].length < 1) return false;
       if(e[1].length < 1) return false;
       if(e[2].length < 1) return false;

       
       e = hr.split(':');
       if(e.length < 2) return false;

       if(e[0].length < 1) return false;
       if(e[1].length < 1) return false;

       var h = parseInt(e[0]);
       var m = parseInt(e[1]);

       if(h<0) return false;
       if(h > 23) return false;
       if(m<0) return false;
       if(m>59) return false;

       return true;
    }

    return false;
}


export const SubmitDataPickerValue = (data, list) => {
    
    for(var i = 0; i < list.length; i++){
      var key = list[i];
      var d = data[key];
      if(!d){
        data[key]=null;
        continue;
      }
      if(typeof d === 'object'){
        d = d.currentTarget.value;
        if(!d){
          data[key]=null;
          continue;
        }
      }

      if (d !== null && d.indexOf('-') > -1) {
          d = date_sql_to_rs(d);
          data[key] = date_rs_to_sql(d);
      } else {
        data[key] = date_rs_to_sql( d );
      }
    }

    return data;
}