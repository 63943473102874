import React, {Component} from 'react';
import { Grid, Cell } from "styled-css-grid";

import {templateList} from './configs.modals';


export default class TemplateModal extends Component {


    constructor(props){
        super(props)
        
        this.state={
         
        }

       
        this.onChange = this.onChange.bind(this);
        this.moduleModal = this.moduleModal.bind(this);

        this.range = null;

        this.ref = React.createRef();
    }

    componentDidMount(){
       
    }

    componentDidUpdate(prevProps){
        if(prevProps.element !== this.props.element){
            var selection = document.getSelection();
            this.range = selection.getRangeAt(0);
        }
    }


   

    onChange(e){
        this.setState({[e.currentTarget.name]:e.currentTarget.value});
    }


    async moduleModal(t,e){
        e.stopPropagation();
        e.preventDefault();

        var selection = document.getSelection();
        if(selection){
            //todo
            this.range = selection.getRangeAt(0);

            var Modal = await require(`./../plugins/templates/${t}/${t}Modal`);

            const Mod = <Modal.default editor={this.props.editor} element={this.props.element} insert={true} range={this.range} />


            this.props.editor.setState({modal:Mod});
        }

        return false;
    }


    render(){
       return (<>
        <div className="modal-content" ref={this.ref} style={{paddingBottom: 20}}>
            <h4>Moduli</h4>
            <div className="template-icons">
                <Grid columns={6}>
                    {templateList.map((t,i)=>{
                        var icon = require(`./../plugins/templates/${t.name}/img/icon.svg`);
                        return <Cell key={i}>
                            <button><img src={icon} width="34px" height="34px" onMouseDown={(e)=>this.moduleModal(`${t.name}`,e)} /> {t.title}</button>
                        </Cell>
                    })}
                </Grid>
            </div>
        </div>
        <div className="modal-foot">
                <button onClick={() => this.props.editor.setState({modal:''})}>Zatvori</button>
        </div>
    </>)
    }

}