import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { withRouter } from 'react-router-dom';
import { reduxAsyncValidate, reduxReduceVaues } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";
import AlertyBox from '../libraries/AletyBox';
import { SubmitDataPickerValue } from "./../helpers/dateHelper";

import { setBusy } from '../modules/forms';
import { categoryEditConf } from "./categoryEditConf";

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';

import './../assets/css/form.scss';
import { fetchHelper } from '../helpers/fetchHelper';

class CategoryEditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allow_submit: false,
        }
    }

    componentDidMount() {
        categoryEditConf.index = this;
        this.getCategories();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data) {

            reduxAsyncValidate(this.props.data, this.props);
            this.setInitialValues();

        }

    }

    setInitialValues() {
        if (this.state.categories && !this.state.lastId) {
            let lastId = 0
            this.state.categories.map(category => {
                category.child.map(child => {
                    if(child.id > lastId){
                        lastId = child.id
                    }
                })

                })
            this.setState({lastId})
        }
    }


    async getCategories() {
        var response = await fetchHelper.get('categorization/get_categories');
        if (response.status && response.items.length > 0) {

            let categoryOptions = this.formatData(response.items);
            categoryEditConf.fields.parent_id.options = categoryOptions;
            categoryEditConf.fields.parent_id.disabled = this.props.data.parent_id ? true : false;
            this.setState({ categories: response.items });
        }


    }

    formatData(data) {
        var formatedData = [];
        if (!data || !data.length) return '';

        data.map(obj => {
            var option = {}
            if (obj.position) {
                option.position = obj.position;
            }
            option.text = obj.name;
            option.value = obj.id;
            formatedData.push(option)
        })
        return formatedData.sort((a, b) => {
            return a.position - b.position;
        });
    }

    async getParents(value) {
        
        let groups;
        let parent_id = this.props.data.parent_id;
        if (!this.state.categories) return '';
        this.state.categories.map(category => {
            if (category.id == parent_id) return;
    
            if (category.id === +value) {
                //set groups for subcategory edit
                groups = this.formatData(category.child);
                categoryEditConf.fields.parent_id.options = groups;
                categoryEditConf.fields.parent_id.disabled = parent_id ? true : false;
            }
        })
        return groups;

    }

    setDateModify(){
        let ts = Date.now();

        let date_ob = new Date(ts);
        let date = ( '0' + date_ob.getDate()).slice(-2);
        let month = ('0' +date_ob.getMonth() + 1).slice(-2);
        let year = date_ob.getFullYear();
        let hours = ('0' + date_ob.getHours()).slice(-2);
        let minutes = ('0' + date_ob.getMinutes()).slice(-2);
        let seconds = ('0' + date_ob.getSeconds()).slice(-2);
        
        let dateDisplay = `${year}-${month }-${date} ${hours}:${minutes}:${seconds} `;
        return dateDisplay;
    }


    async submit(data,formType,category_id) {

        this.props.setBusy(true);
        var values = reduxReduceVaues(categoryEditConf.fields, data);
        values.date_modify = this.setDateModify(); //todo proveri razlikovace se od npr.stavljeno na stanje

        var response = await fetchHelper.post('categorization/change', values);

        
        if(!category_id){
            category_id = response.data.data.id
        }

        let url = formType === 'categories' ? `/admin/products/categories/${category_id}` :
        ( formType === 'group' ? `/admin/products/categories/${values.parent_id}` : `/admin/products/categories/${category_id}/${values.parent_id}` ) ;
                                                                
        
        this.props.setBusy(false);

        if (response.status && values && Object.keys(values).length) {
            if (this.props.onSave) {
                this.props.onSave(values);
            }
            AlertyBox.message(response.data);
        } else {
            var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
            AlertyBox.message(m)
        }
        this.props.history.push(url)
    }

    editForm(refID) {
        return (
            <>
                <Cell width={1} className="grid-column">
                    <div className="relative">
                        <div className="separate-right"></div>
                        <Field {...categoryEditConf.fields.id} ref={refID} />
                        <Field {...categoryEditConf.fields.parent_id} />
                        <Field {...categoryEditConf.fields.master_id} />
                        <Field {...categoryEditConf.fields.name} />
                        <Field {...categoryEditConf.fields.alias} />
                        <Field {...categoryEditConf.fields.icon} img_name={this.props.data.icon} master={refID} index={this.props.index} />
                        <Field {...categoryEditConf.fields.position} />
                        <Field {...categoryEditConf.fields.highlight} />
                        <Field {...categoryEditConf.fields.seo_title} />
                        <Field {...categoryEditConf.fields.seo_keywords} />
                        <Field {...categoryEditConf.fields.seo_description} />
                        {/* <Field {...categoryEditConf.fields.stock_status}/>  */}

                        <Field {...categoryEditConf.fields.stock_status_start} />
                        <Field {...categoryEditConf.fields.stock_status_end} />
                    </div>
                </Cell>
                <Cell width={1} className="grid-column">
                    <div className="relative">
                        <div className="separate-right"></div>
                        <Field {...categoryEditConf.fields.slideshow_bg} img_name={this.props.data.slideshow_bg} master={refID} index={this.props.index} />
                        <Field {...categoryEditConf.fields.slideshow_bg_mobile} img_name={this.props.data.slideshow_bg_mobile} master={refID} index={this.props.index} />
                        {/* <Field {...categoryEditConf.fields.discount}/>  */}
                        <Field {...categoryEditConf.fields.status} />
                        <Field {...categoryEditConf.fields.sub_description_title} />
                        <Field {...categoryEditConf.fields.sub_description} />
                        <Field {...categoryEditConf.fields.slug} />
                        {/* <Field {...categoryEditConf.fields.backoffice_slug} /> */}
                        <Field {...categoryEditConf.fields.date_modify} />
                        <Field {...categoryEditConf.fields.free_delivery} />
                        <Field {...categoryEditConf.fields.in_recommended} />
                        {/* <Field {...categoryEditConf.fields.highlight_recommended}/>  */}
                    </div>
                </Cell>
            </>
        )
    }

    addCategoryForm(refID){
        
        return(
        <>
        
            <Cell width={1} className="grid-column">
                    <div className="relative">
                        <div className="separate-right"></div>
                        <Field {...categoryEditConf.fields.name} />
                        <Field {...categoryEditConf.fields.icon} img_name={this.props.data.icon} master={refID} index={this.props.index} />
                        <Field {...categoryEditConf.fields.meta_title} />
                        <Field {...categoryEditConf.fields.meta_keywords} />
                        <Field {...categoryEditConf.fields.meta_description} />
                        <Field {...categoryEditConf.fields.position} />
                        <Field {...categoryEditConf.fields.mposition} />
                    </div>
            </Cell>
            <Cell width={1} className="grid-column">
                    <div className="relative">
                        <div className="separate-right"></div>
                        <Field {...categoryEditConf.fields.status} />
                        <Field {...categoryEditConf.fields.mstatus} />
                        <Field {...categoryEditConf.fields.slug} />
                        {/* <Field {...categoryEditConf.fields.backoffice_slug} /> */}
                        <Field {...categoryEditConf.fields.date_modify} />
                    </div>
            </Cell>
        </>
        )
    }

    getTitle(type){
        let titlePart = this.props.edit ? 'Izmeni ' : 'Dodaj ';
        switch(type){
            case 'category':
                return titlePart + 'kategoriju';
            case 'group':
                return titlePart + 'grupu';
            case 'subcategory':
                return titlePart + 'podkategoriju'
            default: 
             return ''
        }
    }

    render() {
        if (!this.props.data) return '';

        const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

        var type = this.props.formType.split('-');
        var typeOfForm = type.length > 1 ? type[1] : type[0];
        categoryEditConf.initialValues = this.props.data;
        
        categoryEditConf.initialValues.parent_id =   typeOfForm === 'group'   ? this.props.category_id : 
                                                   (this.props.data.parent_id ? this.props.data.parent_id :
                                                   (this.props.group_id ? this.props.group_id : null) );
      
        this.getParents(this.props.category_id);


        var refID = React.createRef();

        return (
            <>
            <h1>{ this.getTitle(typeOfForm)}</h1>
                <form id="categoryEditForm" onSubmit={handleSubmit((data) => {
                    if (this.state.allow_submit) {
                        this.setState({ allow_submit: false })
                        data = SubmitDataPickerValue(data, ['stock_status_start', 'stock_status_end', 'date_modify']);
                        this.submit(data,typeOfForm,this.props.category_id);
                    }
                })} className="redux-form" encType="multipart/form-data" >

                    <GigaTabs tab={0}>
                        {/* GiaTab title, heigth */}
                        <GigaTab title="Edit " /*height={400}*/>

                            {busy ? <div className="form-mask"></div> : ''}
                            <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                                
                                {this.props.category_id || typeOfForm !== 'category' ? this.editForm(refID): this.addCategoryForm(refID)}
                            </Grid>

                        </GigaTab>
                        <GigaTab title="Sadrzaj">
                            <Grid columns={1} gap="30px">
                                <Cell width={1} className="grid-column">
                                    <div className="relative">
                                        {/* <div className="separate-right"></div> */}

                                    </div>
                                </Cell>
                            </Grid>
                        </GigaTab>
                    </GigaTabs>
                    <div className="page-bottom-bar">
                        <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
                    </div>
                </form>
            </>
        );
    }
}


const selector = formValueSelector('categoryEditForm');

const mapStateToProps = state => ({
    initialValues: categoryEditConf.initialValues,
    busy: state.forms.busy,
    id: selector(state, 'id'),
    // category_id: selector(state, 'category_id'),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBusy,
            updateField: (field, data) => change("categoryEditForm", field, data),
        },
        dispatch
    );

CategoryEditForm = reduxForm({
    form: "categoryEditForm",
    reduxAsyncValidate,
    enableReinitialize: true,
})(CategoryEditForm);
CategoryEditForm = connect(
    mapStateToProps, mapDispatchToProps
)(CategoryEditForm);
export default withRouter(CategoryEditForm);