import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "../../../helpers/fetchHelper";
import GigaTable from '../../../libraries/GigaTable';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
const queryString = require('query-string');

class SubCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      table: null,
      active_user:null
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
    this.subcategories = null
  }

  componentDidMount(){
    this.loadCategories()
    // this.subcategories = this.props.location.state.subcategories;
  }

  async loadCategories(){
        
    var response = await fetchHelper.get('/categorization/get_categories');
    if (response.status && response.items.length > 0) {
      response.items.map(category => {
        if(category.id == this.props.match.params.category){
          console.log(category.id)
          category.child.map(child => {
            if(child.id == this.props.match.params.group){

              this.setState({title: `${child.name} : podkategorije`})
            }
          })
        }
      })
    }
}
  
  componentDidUpdate(prevProps, prevState) {
    // console.log(prevProps,'=====' , this.props)
    if(prevProps.match.params.group !== this.props.match.params.group){
    this.loadCategories();
      //   this.subcategories = this.props.location.state.subcategories;
    }
  }
  

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_user:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_user:row,selected:row.id})
  }


  resetActive() {
    //ne bacati na runRow 
    this.setState({active_user:null,selected:null})
  }
  render() {
    const parsed = queryString.parse(this.props.location.search);
    let groupId = this.props.match.params.group;
    let categoryId = this.props.match.params.category;
    let masterId = +parsed.master_id;

    const cells = [
      {name:'Id',cell: 'id',width:90, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Naziv',cell: 'name', width:150,fixed:true, className:''},
      {name:'Proizvodi', cell:'custom1', custom:true, width:70, align:'center', render:(row)=>{
        return  <button><Link target="_blank" to={`/admin/products/${masterId}/${row.master_id}`}><i className="fa fa-table"></i></Link></button>
      }},
      {name:'Edit', cell:'custom2', custom:true, width:60, align:'center', render:(row)=>{
        return <button onClick={()=> this.setState({active_user:row,selected:row.id}) }><Link target="_blank"
        to={{pathname:`/admin/products/subcategory/edit/${row.id}`,search:`?category_id=${categoryId}`}}
        ><i className="fa fa-edit"></i></Link></button>
      }},
    ];
    var fetch=`categorization/get_subcategories/${categoryId}/${groupId}`;
    
    const buttons = [
      {title: 'Nova podkategorija',target:true, type: 'link', link:'/admin/products/add-subcategory',search:`?category_id=${categoryId}&group_id=${groupId}`},
    ];
    return (
      <>
        <div className="admin-home__main-section">
          <h1>{this.state.title}</h1>
        <div className="table-wrapper">
          <GigaTable 
            cells={cells} 
            pk='id' 
            height={300} 
            buttons={buttons} 
            id='subcategories-table' 
            db="app_categories"
            fetch={fetch}
            delete={true} 
            selected={this.state.selected}
            getInstance={this.getTableInstance}
            run={this.runRow}
            resetActive={this.resetActive}
          />
        </div>
      </div>
      </>
    );
  }
}



  export default withRouter((SubCategory));