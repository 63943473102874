import React, {Component} from 'react';
import { rgbToHex } from '../../../helpers/stringHelper';


export default class PaintModal extends Component {


    constructor(props){
        super(props)
        
        this.state={
            backgroundColor: '',
            color: '',
        }

        this.set = this.set.bind(this);
        this.onChange = this.onChange.bind(this);
        this.ref = React.createRef();
    }

    componentDidMount(){
        this.get();
    }

    componentDidUpdate(prevProps){
        if(prevProps.element !== this.props.element){
            this.get();
        }
    }


    set(e){
        e.preventDefault();
        e.stopPropagation();

        
        this.props.editor.ref.current.dataset.color = this.state.color;
        this.props.editor.ref.current.dataset.backgroundColor = this.state.backgroundColor;

        this.props.editor.ref.current.style.color = this.state.color;
        this.props.editor.ref.current.style.backgroundColor = this.state.backgroundColor;

        this.props.editor.registerChange();
        
        return false;
    }




    get(){
       
        var color = this.props.editor.ref.current.style.color;
        var backgroundColor = this.props.editor.ref.current.style.backgroundColor;

        this.setState({
            color: color ? rgbToHex(color) : '#000000',
            backgroundColor: backgroundColor ? rgbToHex(backgroundColor) : '#ffffff'
        })

    }

    onChange(e){
        this.setState({[e.currentTarget.name]:e.currentTarget.value});
    }

    render(){
       return (<>
        <div className="modal-content" ref={this.ref} onMouseDown={this.prevent}>
                    <h4>Podešavanje boja stranice</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width:'50%'}}  >
                                    <div className="ge-form-group">
                                        <label>Background</label>
                                        <input name="backgroundColor" type="color" className="small" onChange={this.onChange} value={this.state.backgroundColor} />
                                    </div>
                                </td>
                                <td style={{width:'50%'}}  >
                                    <div className="ge-form-group">
                                        <label>Color</label>
                                        <input name="color" type="color" className="small" onChange={this.onChange} value={this.state.color} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
        </div>
        <div className="modal-foot">
                <button onClick={() => this.props.editor.setState({modal:''})} >Zatvori</button>
                <button onMouseDown={this.set} className="primary-btn">Primeni</button>
        </div>
    </>)
    }

}