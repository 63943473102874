import { b64EncodeUnicode } from './base64';

class tinyExport {
    constructor(html, editor){
        var container = document.createElement('div');
        container.innerHTML = html;

        this.ref = container;
        this.editor = editor;

        this.nodes = [];
    }

    exportContent(){
        var ref = this.ref;
        if(ref && ref.childNodes && ref.childNodes.length){
            for(var i = 0; i < ref.childNodes.length; i++){
                var node = ref.childNodes[i];
                console.log('export', node)
                this.nodes.push({
                    type: node.tagName,
                    attributes: this.attributes(node),
                    content: this.value(node),
                })

            }

            var data = {
                nodes: this.nodes,
                paint: {
                    color: '#000',
                    backgroundColor: '#FFF'
                },
                id: this.editor.settings.section+'-'+this.editor.id +'-' + this.editor.settings.content_id,
                style: ''
            } 

            return b64EncodeUnicode (JSON.stringify(data));
        }

        return null;
    }


    iterate(nodes){
       
        if(nodes && nodes.length){
            var n = [];
            for(var i = 0; i < nodes.length; i++){
                var node = nodes[i];
                if(node.nodeType !== 3){
                    n.push({
                        type: node.nodeName,
                        attributes: this.attributes(node),
                        content: this.value(node)
                    })
                } else {
                    n.push(node.data);
                }
            }
            if(n.length === 1){
                n = n[0];
            }
            return n;
        }
    }


    value(node){

        switch(node.nodeType){
            case 1:
                return (node.childNodes && node.childNodes.length) ? this.iterate(node.childNodes) : node.src ? node.src : node.value ? node.value : node.innerText ? node.innerText : node.data ? node.data : '';
            break;
            case 2:

            break;
            case 3:
                return node.data;
            break;
            case 4:

            break;
        }

       return '';
    }


    attributes(node){

        var allowed = {
            'style': 'style',
            'className': 'className',
            'width': 'width',
            'height': 'height',
            'disabled': 'disabled',
            'readonly': 'readOnly',
            'src': 'src',
            'href': 'to',
            'dataset':'dataset',
        };


        var attributes = {
            'style': [],
            'className': '',
            'width': '',
            'height': '',
            'disabled': '',
            'readOnly': '',
            'src': '',
            'to': '',
            'dataset':{},
        };

        var removeCss = [
            'active',
            'editor-highlight'
        ];

        var allowedStyle = {
            'margin': 1,
            'padding': 1,
            'color': 1,
            'textDecoration':1,
            'textTransform':1,
            'textAlign':1,
            'verticalAlign':1,
            'horizontalAlign':1,
            'fontSize':1,
            'fontFamily':1,
            'fontWeight':1,
            'backgroundColor':1,
            'fontStyle':1,
            'border':1,
            'borderRadius':1,
            'transform':1,
            'transition':1,
            'overflow':1,
            'overflowY':1,
            'overflowX':1,
            'width':1,
            'height':1,
            'contenteditable':1,
            'float':1
        }

        if(node.style !== undefined){
           
            var style = {};
            Object.keys(node.style).map((k)=>{
                var v = node.style[k];
                if(v && allowedStyle[k] !== undefined){
                    if(v){
                      style[k] = v;
                    }
                }
            });
            attributes.style = style;
        }

        Object.keys(allowed).map(n=>{
            var v = allowed[n];
            if(node[n] !== undefined){
                var cls = node[n];
                if(n === 'className'){
                    
                    removeCss.map((rc)=>{
                        var rgx = new RegExp(rc,'g');
                        cls = cls.replace(rgx,'');
                    });
                }
                if(n !== 'style' && cls){
                    attributes[v] = cls;
                }
            }
           
            
        });

        return attributes;
    }

    convertAttrKeyToReact(key){
        var n = key.split(';');
        var x = {};
        n.map(str=>{
            var e = str.split(':');
            if(e[0]){
                var s = e[0].toString();
                var a = s.split('-');
                var w = a.map((t,b)=>{
                    return b === 0 ? t : t.charAt(0).toUpperCase() + t.slice(1);
                })
                var v = w.join('');
                x[v] = e[1];
            }
        });
        return x;
    }

}

export default tinyExport;