import React, {Component} from 'react';

export default class Outdent extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){

        e.stopPropagation();
        e.preventDefault();

        // this.props.element.current.focus();
        document.execCommand('outdent', false, '');
        this.props.editor.registerChange();

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"Outdent-btn-"+this.props.id} onMouseDown={this.action}><i className="fa fa-outdent" aria-hidden="true" ></i></button>
    }

}