import React, {Component} from 'react';

export default class TextModal extends Component {


    constructor(props){
        super(props)
        
        this.state={
            width: 'auto',
            height: 'auto',
            fontSize: 'inherit',
            fontFamily: 'inherit',
        }

        this.setStyle = this.setStyle.bind(this);
        this.onChange = this.onChange.bind(this);

        this.ref = React.createRef();
    }

    componentDidMount(){
        this.getStyle();
    }

    componentDidUpdate(prevProps){
        if(prevProps.element !== this.props.element){
            this.getStyle();
        }
    }


    setStyle(){
        // var width = this.ref.current.querySelector('input[name="width"]').value;
        this.props.element.style.width = this.state.width;
        this.props.element.style.height = this.state.height;
        this.props.element.style.fontSize = this.state.fontSize;
        this.props.element.style.fontFamily = this.state.fontFamily;

        this.props.editor.setState({modal:''});

        this.props.editor.registerChange();
    }


    getStyle(){
        var width = this.props.element.style.width;
        var height = this.props.element.style.height;
        var fontSize = this.props.element.style.fontSize;
        var fontFamily = this.props.element.style.fontFamily;

        this.setState({
            width: width,
            height: height,
            fontSize: fontSize,
            fontFamily: fontFamily
        })
    }

    onChange(e){
        this.setState({[e.currentTarget.name]:e.currentTarget.value});
    }


    render(){
       return (<>
        <div className="modal-content" ref={this.ref}>
            <table>
                <tbody>
                    <tr>
                        <td style={{width:'50%'}}>
                            <div className="ge-form-group">
                                <label>Width</label>
                                <input name="width" className="small" onChange={this.onChange} value={this.state.width}/>
                            </div>
                        </td>
                        <td style={{width:'50%'}}>
                            <div className="ge-form-group">
                                <label>Height</label>
                                <input name="height" className="small" onChange={this.onChange} value={this.state.height} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width:'50%'}}>
                            <div className="ge-form-group">
                                <label>Font Size</label>
                                <input name="fontSize" className="small" onChange={this.onChange} value={this.state.fontSize} />
                            </div>
                        </td>
                        <td style={{width:'50%'}}>
                        
                        </td>
                    </tr>
                    <tr>
                        <td style={{width:'50%'}} colSpan="2">
                            <div className="ge-form-group">
                                <label>Font Family</label>
                                <input name="fontFamily" className="largest" onChange={this.onChange} value={this.state.fontFamily} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="modal-foot">
                <button onClick={this.setStyle} className="primary-btn">Primeni</button>
                <button onClick={() => this.props.editor.setState({modal:''})}>Zatvori</button>
        </div>
    </>)
    }

}