
import { 
    renderFieldInput,
} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateString,
    ValidateAddress,
    ValidateFileImage
} from '../helpers/validation';



export const loginConf = {
    fields: {
        'username' : {
            name: 'username',
            type: 'text',
            component: renderFieldInput,
            label: 'Korisnik',
            labelHide: true,
            validate:[ValidateString,ValidateRequire],
            size: 'medium',
            value: '',
        },
        'password' : {
            name: 'password',
            type: 'password',
            component: renderFieldInput,
            label: 'Lozinka',
            labelHide: true,
            validate:[ValidateString,ValidateRequire],
            size: 'medium',
            value: '',
            
        },
    },
    initialValues: {}
}