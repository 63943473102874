import React from 'react';
import {SortableContainer} from 'react-sortable-hoc';
import {SortableItem} from './SortableItem';

export const SortableList = SortableContainer((props) => {


    var images = '';
    if(props.items){
        images = props.items.map((value, index) => (
            <SortableItem component={props.cmp}  args={props.args}  key={`item-${value.id}`} index={index} value={value} remove={props.remove} edit={props.edit} active={props.active} />
          ));
    }
    return (
     <ul>
       {images}
      </ul>
    );
  });