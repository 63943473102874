import {fetchHelper} from './../helpers/fetchHelper';

export const SET_BUSY = "orderindex/SET_BUSY";
export const SET_LAST_CHECK = "orderindex/SET_LAST_CHECK";

const orderIndexState = {
    1: 'Not updated',
    2: 'Update in progress',
    3: 'Updated'
}

let orderUpdateInterval = null;

const initialState = {
  busy: false,
  last_check: 'Never',
  status: 'Not updated'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSY:
      return {
        ...state,
        busy: action.busy
      };
    case SET_LAST_CHECK:
    return {
      ...state,
      last_check: action.last_check
    };
    default:
      return state;
  }
};



export const Sync = () => (dispatch,getState) => {

  const { busy } = getState().orderindex;

  return new Promise(resolve => {

        if(busy || orderUpdateInterval){
            resolve(orderIndexState[1]);
            return;
        }

        clearInterval( orderUpdateInterval );

        orderUpdateInterval = setInterval(()=>{
            dispatch(updateOrders());
        },5*60*1000);

        dispatch(updateOrders());
        
    });
}



export const updateOrders = () => (dispatch,getState) => {

    const { busy } = getState().orderindex;

    return new Promise(async (resolve)=>{

        if(busy){
            resolve(orderIndexState[1]);
            return;
        }

        dispatch({
          type: SET_BUSY,
          busy: true
        })

        const limit = 1000;
        var result = await fetchHelper.get('orders/sync/count');

        if(result.status){

          var web = result.data.web;
          var shop = result.data.shop;

          if(web > 0){
            var pages = Math.round(Math.ceil(web/limit));
            for(var i = 0; i < pages; i++){
              var ofs = i * limit;
              var result = await fetchHelper.get('orders/sync/web/'+ofs);
            }
          }


          if(shop > 0){
            var pages = Math.round(Math.ceil(shop/limit));
            for(var i = 0; i < pages; i++){
              var ofs = i * limit;
              var result = await fetchHelper.get('orders/sync/shop/'+ofs);
            }
          }


          dispatch({
            type: SET_BUSY,
            busy: false
          });

          resolve(true);
        }


        
    })

}
