import $ from 'jquery';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderDatePicker,
 

} from "../../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidatePhone, 
    ValidateDate,
    ValidateString,
    
} from '../../helpers/validation';
import AlertyBox from "../../libraries/AletyBox";
import { fetchHelper } from "../../helpers/fetchHelper";
import { reducer , change as changeFieldValue, change } from "redux-form";
import { 
    server_img_host,
    page_icon_path,
    page_icon_mobile_path, page_thumb_path, page_image_path
 } from '../../config/vars';

export const fileTypeConf = {
    fields: {
        'id' : {
            name: 'id',
            type: 'text',
            component: renderFieldInput,
            label: 'ID',
            validate:[ValidateNumeric],
            warn: [],
            size: 'small',
            value: '',
            readonly:true,
        },
        'file_id' : {
            name: 'file_id',
            type: 'hidden',
            component: renderFieldInput,
            validate:[ValidateNumeric],
            warn: [],
            size: 'small',
            value: '',
            readonly:true
        },
        'morph_id' : {
            name: 'morph_id',
            type: 'hidden',
            component: renderFieldInput,
            validate:[ValidateNumeric],
            warn: [],
            size: 'small',
            value: '',
            readonly:true,
        },
        'title' : {
            name: 'title',
            type: 'text',
            component: renderFieldInput,
            validate:[ValidateRequire],
            label: 'Naziv',
            warn: [],
            size: 'medium',
            value: '', 
        },
        'description' : {
            name: 'description',
            type: 'text',
            component: renderFieldTextarea,
            label: 'Opis',
            size: 'large',
            height: 200,
            value: '',
        },
        'file_id_select': {
            name:'file_id_select',
            type: 'select',
            component: renderFieldSelect,
            label: 'Sekcija',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
            trigger: (value, input, instance, controler) => {
                console.log('value',value)
                input.onChange(value);
            },
            options:[
            ]
        },
        'table_select': {
            name:'table_select',
            type: 'select',
            component: renderFieldSelect,
            label: 'Database Table',
            validate:[ValidateRequire],
            warn: [ValidateRequire],
            size: 'medium',
            value: '',
            trigger: (value, input, instance, controler, parentValue) => {
                console.log('instance', instance, value)
                input.onChange(value);

                var items = [];
                var cels = (controler.state.columns[value]);
                if(cels && Object.keys(cels).length){
                    Object.keys(cels).map((k,i)=>{
                        var a = cels[k];
                        items.push({value:a,text:a})
                    })
                }

                instance.fields.table_cell.options = items;
                instance.fields.table_cell.parentValue = value;

                // instance.fields.table_cell.id = value !=='' ? value : '';
                // instance.fields.test3.parentValue = '';
                controler.props.updateField('table_cell' , '');
            },
            options:[
            ]
        },
        'table_cell' : {
            id: 'table_cell',
            name: 'table_cell',
            component: renderFieldSelect,
            label: 'Column',
            validate:[ValidateRequire],
            warn: [ValidateRequire],
            size: 'medium',
            value: '',
            parentValue: '',
            /* setovati display samo kada zelite da se ne vidi kada je parent value = '' */
            display: (parentValue) => {
                console.log(parentValue)
                return parentValue  !== '' ? '':'none'
            },
            trigger: (value, input, instance, controler, parentValue) => {
                console.log(parentValue)
                if(parentValue === '') return '';
                input.onChange(value);
                // instance.fields.test3.parentValue = value !== '' ?  parseInt(value) : '';
                // controler.props.updateField('test3' , '')
            },
            options:[
                
            ],
        },
        'status' : {
            name: 'status',
            component: renderFieldSelect,
            label: 'Status',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [],
            size: 'medium',
            value: '',
            options:[
                {text:'Neaktivan',value:0},
                {text:'Aktivan',value:1},
                
            ], 

        },

    },
    initialValues: {}
}