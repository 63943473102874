import React, {Component} from 'react';
import LinkModal from '../../modals/LinkModal';

export default class Link extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);

        this.editorContent = null;
        this.refInp = React.createRef();

    }

    componentDidMount(){

        this.url = this.props.url;

        this.initialize();
    }

    initialize(){
        this.editorContent = this.props.element.current;
    }

    action(e){
        e.stopPropagation();
        e.preventDefault();
        this.props.editor.setState({modal:<LinkModal editor={this.props.editor} element={this.props.element} range={this.props.range} />});

        return false;
    }

    render(){
        return <button onMouseDown={this.action} className="get-btn selector-ga" id={"Link-btn-"+this.props.id}><i className="fa fa-link" aria-hidden="true" ></i></button>
    }

}