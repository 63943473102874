import React, { Component } from "react";
import { withRouter} from "react-router";
import { fetchHelper } from "../../../helpers/fetchHelper";

import CategoryEditForm from "../../../forms/CategoryEditForm";
const queryString = require('query-string');

class CategorizationEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_page:null,
      selected:null
    }


  }

 componentDidMount(){
    this.getFormData(this.props.match.params.id);
  }

  async getFormData(id){
    var r = await fetchHelper.get(`categorization/edit/get_data/${id}`);
  
    if(r.status){
      let data=r.items
      this.setState({active_page:data,selected:id}) 
    }
  }


  render() {
        const parsed = queryString.parse(this.props.location.search);
        let category_id = +parsed.category_id;
        let group_id = +parsed.group_id;
        let formType;
        let edit;
        if(this.props.location.pathname){

          let pathname = this.props.location.pathname.split('/');
          edit = pathname.indexOf('edit');
          console.log(pathname[pathname.length - 1])
          formType = edit !== -1 ? pathname.find(el => el === 'subcategory' || el === 'group' || el === 'category') : pathname[pathname.length - 1];
          
        }

    return (
      <>
        <div className="admin-home__main-section">
        <CategoryEditForm
          edit={edit > 0 ? true : false }
          data={this.state.active_page} 
          formType={ formType } 
          index={this} 
          category_id={category_id} 
          group_id={group_id}
        />
        </div>
      </>
    );
  }
}


  export default withRouter((CategorizationEdit));