import React, { Component } from 'react';

import './check.scss';

class Check extends Component {
    constructor(props) {
        super(props);
        
        this.state = {

        };

    }

    render() {
        return <div className={`success-checkmark ${this.props.active ? "active" : ""}`}>
            <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
            </div>
        </div>
    }
}


export default (Check);