import React from 'react';

class Form extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        
      };
    }

    render(){

        const { data } = this.props;

        return (<form>
            <div className="clear row-3">
                <div><label>Title</label><input defaultValue={data.title} name="title" /></div>
                <div><label>Size</label><input defaultValue={data.size} name="size" /></div>
                <div><label>Bold</label>
                    <input type="radio" value="1" name="bold" defaultChecked={data.bold==='1'?true:false} data-default="0" />
                    <input type="radio" value="0" name="bold" defaultChecked={data.bold==='0'?true:false} data-default="0" />
                </div>
            </div>
        </form>)
    }
}

export default Form;