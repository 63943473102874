import React from 'react';
import { date_rs_to_sql, date_sql_to_rs } from '../../../../../helpers/dateHelper';
import { b64EncodeUnicode } from '../../helpers/base64';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import './style.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from 'swiper/core';
// import 'swiper/swiper.scss'
import 'swiper/swiper.scss'
SwiperCore.use([Navigation,Pagination]);
class View extends React.Component {
    constructor(props) {
      super(props);
      this.state = {

      };

    }


    componentWillMount(){
       
    }


    componentDidMount(){
        // console.log('VIEW')
    }

    componentWillUnmount(){
        
    }

    showSliderItems(slide,side){
        if(slide.side == side){
            
            return(
                <div className={"desc-container" + (side == 'right' ? ' right-side' : '') }>
                    {slide.logo ? <img src={slide.logo} className="logo" alt='logo' /> : ''}    
                    <p className='slide title' style={{color:`${slide.textColor}`}}>{slide.title}</p>
                    <p className='slide subtitle' style={{color:`${slide.textColor}`}}>{slide.subtitle}</p>
                    <p className='slide desc' style={{color:`${slide.textColor}`}}>{slide.txtarea}</p>
                    <p className='slide oldPrice' style={{color:`${slide.textColor}`}}>{slide.oldPrice ? slide.oldPrice +' RSD' : '' } </p>
                    <p className='slide newPrice' style={{color:`${slide.textColor}`}}>{slide.newPrice ? slide.newPrice +' RSD' : '' } </p>
                    <p className='slide saved' style={{color:`${slide.textColor}`}}>{slide.saved ? 'Ušteda ' + slide.saved : ''}</p>
                    <p className='slide anno' style={{color:`${slide.textColor}`}}>{slide.annotation}</p>
                    <button className="btn"  style={{backgroundColor:`${slide.btnColor}`,color:`${slide.textColor}`}}>Saznaj više</button>
                </div>
            )    
        }

        return(
            slide.imagePreview ? <img src={slide.imagePreview} className='product-image' alt='sdadsa' /> : ''
        )

        
    }

    // css(data,id){
    //     return `<style>
    //     .wrapper{
    //         position: relative;
    //         overflow: hidden;
    //         border: 1px solid black;
    //         text-align: center; height: 376px;
    //     </style>`;
    // }
    

    view(data){
        let settings = JSON.parse(data.allData)

        return (
            <Swiper 
            spaceBetween={0}
            speed={2500}
            slidesPerView={1}
            onSlideChange={(swiper) => {
                console.log(swiper)
                this.setState({currentIndex:swiper.realIndex,changed:true,animationStart:false})                
            } }
            onSwiper={(swiper) => console.log(swiper)}
            navigation
            loop={true} >
                {settings.map((slide,i) => {
                    return (
                        <SwiperSlide ref={this.myRef} key={i} >
                            <div onClick={() => this.showForm(slide.id)} className='wrapper' style={slide.bgimage ? slide.bgimage : null}>
                                 <div className='inner-wrapper'>
                                    <div className='box'>
                                        {this.showSliderItems(slide,'left')}
                                    </div>
                                    <div className='box'>
                                         {this.showSliderItems(slide,'right')}
                                       
                                
                                    </div>
                                </div> 
                            </div>
                            
                         </SwiperSlide>
                    )
                })}
            </Swiper>
        )
    }

    render(){

        const { data, folder } = this.props;
        const json = JSON.stringify(data);
        
        console.log(data)

        return <div
            tab="0" 
            className="mceNonEditable" 
            data-controller="plugin" 
            data-plugin={folder}  
            data-json={b64EncodeUnicode(json)} 
        >
            {this.view(data)}
        </div>
    }
}

export default View;