import React, { useState }  from 'react';
import { addPlugin } from '../../helpers/addPlugin';
import View from './view';
import Form from './form';

import {ReactComponent as Icon} from './icon.svg';
import { renderToString } from 'react-dom/server';

export const previewPlugin = (editor, node) => {

   

    var pluginName      = 'preview_plugin', 
        pluginTitle     = 'Preview', 
        pluginFolder    = 'previewPlugin';

    var initialData = {
     
    }

    var icon = renderToString(<Icon />);

    addPlugin(editor, node, Form, View, initialData, pluginName, pluginTitle, pluginFolder, icon, '99%', '97%');
};