import React, {Component} from 'react';
import { Grid, Cell } from "styled-css-grid";

export default class TemplateProducts extends Component {


    constructor(props){
        super(props)
        
        this.state = {
           products: []
        }
    }

    componentDidMount(){

    }

    title(){
        return this.props.parent.state.title ? <h4>{this.props.parent.state.title}</h4> : ''
    }

    products(){
        return this.state.products.map((product,i)=>{
            return <Cell width={1} key={i}>{product.title}</Cell>
        })
    }
    

    render(){
        return (
            <div className="block-element-editor" contentEditable={false} 
                data-type="template" 
                data-module="TemplateProductsModal" 
                tabIndex={1} 
                data-onload="TemplateProducts" 
                data-title={this.props.parent.state.title?this.props.parent.state.title:''}
                data-cols={this.props.parent.state.cols} 
                data-ids={this.props.parent.state.ids}
                style={{
                    padding: this.props.parent.state.padding,
                    margin: this.props.parent.state.margin
                }}
            
            >
                <div className="giga-products">
                    {this.title()}
                    <div className="product-list">
                        <Grid columns={this.props.parent.state.cols}>
                            {this.products()}
                        </Grid>
                    
                    </div>
                </div>
            </div>)
    }

}