import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "./../../../helpers/fetchHelper";
import GigaTable from './../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import UserForm from "../../../forms/UserForm";
// import GridLayoutEditor from "../../../libraries/GridLayout/GridLayout";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_user:null,
      selected: null,
      table: null,
      user_images:null,
      section:"users",
      detail: null
  
      
      
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
    this.addNew=this.addNew.bind(this);
  }

  componentDidMount(){
    
  }

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_user:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_user:row,selected:row.id})
  }


  async addNew(){
    var r = await fetchHelper.fetchPost('users/change',{});
    if(r.status&&r.data){
      this.setState({active_user:r.data,selected:r.data.id,});
      this.table.loadData();
      //+scroll to left bottom
    }
    else{
      AlertyBox.serverError();
    }
  }

  resetActive() {
    this.setState({active_user:null,selected:null})
  }









  render() {

    const cells = [
      {name:'ID',cell: 'id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Ime',cell: 'first_name',width:100, fixed:true, className:''},
      {name:'Prezime',cell: 'last_name', width:130,fixed:true, className:'giga-td-last-fixed'},
      {name:'Email',cell: 'email', width:200,fixed:false, truncate:true,className:'draggable'},
      {name:'Tel',cell: 'phone', width:150,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Adresa',cell: 'address', width:250,fixed:false,truncate:true,editable:true,className:'draggable'},
      {name:'Pos.br.',cell: 'postal_code', width:80,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Grad',cell: 'city', width:150,fixed:false,editable:true,className:'draggable'},
      {name:'Registrovan',cell: 'registration_date', width:180,fixed:false,editable:true,className:'draggable', onBefore:(value)=>{
        return date_sql_to_rs(value);
      }, onSave:(value)=>{
        return date_rs_to_sql(value);
      }},
      {name:'Detalji', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return row.id !== 1499709 ? <button onClick={()=>{ 
          this.setState({active_user:row,selected:row.id})
      }}><i className="fa fa-folder-open-o" /></button> : ''
      }},
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center', render:(row)=>{
        var value = parseInt(row.status); 
        return row.id !== 1499709 ? <button onClick={()=> this.table.updateRowCell(row,'status',value?0:1)}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button> : ''
      }},
    ];

    const buttons = [
      {title: 'Novi korisnik', type: 'button' , onClick: this.addNew},
    ];
    
    return (<><div>

    </div>
      <div className="admin-home__main-section">
          {/* testing */}
          {/* <GridLayoutEditor/> */}
        <div className="table-wrapper">
          <GigaTable 
            cells={cells} 
            pk='id' 
            height={300} 
            buttons={buttons} 
            id='users-table' 
            db="gigatron_users"
            fetch="users"
            delete={true} 
            selected={this.state.selected}//row.id that we get on click on btn 'Detalji', or on click in GigatableFooter up and down arrows.+key u renderFieldUploadImage
            //In GigaTable>in GigatableFooter 1.if this.props.selected&&this.props.run,it show arrows up and down 
            //2.On click on arrows it calls this.props.run which sets selected,and active_user 3.slected id makes row red in GigaTableRows(adds class selected);
            getInstance={this.getTableInstance}
            run={this.runRow}//called in GigaTableRows(in updateRow) when you edit input onEditCell, and in GigaTableFooter when you click arrows up and down
            resetActive={this.resetActive}//on page change and on delete
          />
        </div>
        <UserForm detail={this.state.detail} data={this.state.active_user} user_images={this.state.user_images} onSave={this.onSave} index={this} />
      </div>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Users));
