import React, { Component } from 'react';
import {fetchHelper} from './../../helpers/fetchHelper';
import SortableComponent from '../../libraries/ReactSortable/SortableComponent';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import GigaSpinner from './../../libraries/GigaSpinner';
import AlertyBox from "./../../libraries/AletyBox";
import GalleryItemForm from '../../forms/GalleryItemForm';
import { setFullscreenLoader } from "../../modules/forms";

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            edit: null,
        };
        this.id = this.props.args.id;
        this.position = 1;
        this.section = this.props.args.section;
        this._sendRequest = this._sendRequest.bind(this);

        this.ref = React.createRef();
    }

    componentDidMount() {
      this.getImages(this.id);
    }
    
    componentDidUpdate(prevProps, prevState){
        if(this.props.args.id !== this.id){
            this.id = this.props.args.id;
            this.getImages(this.id);
        }        
    }
    
    async getImages(id){
        if(!id ) return;
        const data = {
            'section' : this.section
        }
        var response = await fetchHelper.get('gallery/get_images/'+id,data);
        if(response.status && response.imgList){
          this.setState({images:response.imgList})
        } else {
            this.setState({images:[]})
        }
    }

    // Prepares the data and sends the request.
    async _sendRequest(e) {
        e.preventDefault();
        
        var fileList = this.ref.current.files;
        this.props.setFullscreenLoader(true);
        
        const result = await fetchHelper.post('gallery/upload_image',{images:fileList, section: this.section, product_id: this.id});              
        
        if(result.status && result.data && result.data.status && result.data.items && result.data.items.length){
            this.setState({images:result.data.items});
            
            if(this.props.args.callback) this.props.args.callback(result.data.items,this.props.args.onSaveItem,this.props.args.formData);
        }
        this.props.setFullscreenLoader(false);
    }


    remove (index,position, id, e) {

        e.stopPropagation();
        
        AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
            if(result.isConfirmed){
            
                var data = {
                    section: this.section,
                    position: position,
                    name: index,
                    pictureID: id,
                    resizedPath: 'gallery/thumb/'
                }
                this.props.setFullscreenLoader(true);
                var response = await fetchHelper.delete('gallery/remove_picture/'+this.id,data);
                this.props.setFullscreenLoader(false);
                AlertyBox.message(response);
                if(response && response.status && response.data.status){

                    let number = 1
                    let updatedList = response.data.getImages.imgList;
                    
                    updatedList.map(i => {
                        i.position = number;
                        number++;
                    })
                    
                    var data = Object.assign({},updatedList)
                    data.delete = true;
                    data.item_id = this.id;

                    if(this.props.args.callback)this.props.args.callback(data,this.props.args.onSaveItem, this.props.args.formData);
                    
                    fetchHelper.post('gallery/update_order',data);

                    if(this.state.edit && parseInt(id) === parseInt(this.state.edit.id)){
                        this.setState({edit:null});
                    }
                    
                    
                    if(this.position){
                        this.position--;
                    }

                    this.setState({images: updatedList});
                }
        
            }
        })

    }



    render() {
        
        const {img_name,url, remove,master,index,label,input,value, size,readonly,meta,place} = this.props.args;
        
        return (
            <>
                <div className="form-group">
                    <div className="sortable-gallery">
                        <SortableComponent onSaveItem={this.props.args.onSaveItem} component={this} args={this.props.args} items={this.state.images} remove={this.remove.bind(this)} edit={(image)=>{this.setState({edit:image})}} active={this.state.edit ? this.state.edit.id : null} /> 
                    </div>
                    <label>{label}:</label>
                    <input {...input} ref={this.ref}  placeholder={label} type="file" value={value} className={size ? size : ''} readOnly={readonly ? readonly : false}  multiple="multiple" />
                    {meta.touched && ((meta.error && <span className="form-field-error">{meta.error}</span>) || (meta.warning && <span className="form-field-warning">{meta.warning}</span>))}
                    <button style={{display:"block"}} disabled={!meta.valid} onClick={(e) => this._sendRequest(e)} >Sačuvaj</button>
                </div>      
                <GalleryItemForm data={this.state.edit} index={this} id={this.id} />
            </>
        )
    }


}


const mapStateToProps = state => ({
    user: state.auth.user
  });
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        setFullscreenLoader
      },
      dispatch
    );
  
    export default withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
    )(Gallery));
  
// export default Gallery;


