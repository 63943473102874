import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "./../../helpers/fetchHelper";
import {setBusy} from './../../modules/forms';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";

import LoginForm from "../../forms/LoginForm";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    }

  }

  componentDidMount(){
    
  }




  render() {

    
    return (<>
      <div className="login-section">
        <h1>Gigatron prijava</h1>
        <LoginForm onSave={this.onSave} index={this} url={this.props.url} />
      </div>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setBusy
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Login));