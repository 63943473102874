import React, { useState, useEffect } from "react";
import { useNode } from "@craftjs/core";
import { ImageSettings } from "./ImageSettings";

export const Image = ({ src, url , width , height ,margin , padding }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    !selected && setEditable(false);
  }, [selected]);

  const selection = () => {
    if(selected){
        setEditable(true);
    }
  }

  return (
    <div  onClick={(e) => selection()} ref={(ref) => connect(drag(ref)) }>
       <figure>
            <img 
              src={src} 
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
        </figure>
    </div>
  );
};
Image.craft = {
  displayName: "Image",
  props: {
    margin: [0, 0, 0, 0],
    padding: [0, 0, 0, 0],
    width: '100%',
    heigth: 'auto',
  },
  related: {
    toolbar: ImageSettings,
  },
};
