import $ from 'jquery';
import React, { useState }  from 'react';
import ReactDOM from 'react-dom';
import { getFormData } from '../helpers/getFormData';
import Empty from './empty';

export const Dialog = (editor, title, Form, onSuccess, onCancel, node, initialData, width, height) => {

    ReactDOM.render(<DialogComponent editor={editor} title={title} onSuccess={onSuccess} onCancel={onCancel} node={node} data={initialData} width={width} height={height} >
        <Form editor={editor} title={title} onSuccess={onSuccess} onCancel={onCancel} node={node} data={initialData} />
    </DialogComponent>, document.getElementById('dialog_container'));

}


export default class DialogComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
          
        };
  
        this.ref = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
      }


      onChange(){

      }


      onSuccess(){

        var data = getFormData(this.ref);

        if(this.props.onSuccess){
            this.props.onSuccess(this.props.editor, data);
        }

        ReactDOM.render(<Empty />, document.getElementById('dialog_container'))
      }


      onCancel(){
        ReactDOM.render(<Empty />, document.getElementById('dialog_container'))
      }


      render(){

            const {width, height} = this.props;

            var style={
                width: width ? width : null,
                height: height ? height : null,
            }

            return (<div className="tinydialog" style={style}>
                <div className="tinydialogheader">
                    <div className="tinydialogtitle">{this.props.title}</div>
                </div>
                <div className="tinydialogbody" ref={this.ref}>
                    {this.props.children}
                </div>
                <div className="tinydialogfooter">
                        <button onMouseDown={this.onSuccess}>OK</button>
                        <button onMouseDown={this.onCancel}>Cancel</button>
                </div>
            </div>);

      }

}