import React from 'react';
import GigaDatePicker from '../../../../GigaDatePicker';
import ColorPickerField from '../../fields/ColorPickerField';
import TextField from '../../fields/TextField';
import TextArea from '../../fields/TextArea';
import ImageField from '../../fields/ImageField';
import { fetchApiHelper } from '../../../../../helpers/fetchApiHelper';
import { ValidateAlphaNumeric } from '../../../../../../src/helpers/validation'
import InputRangeField from '../../fields/InputRangeField';
import { withRouter, Link } from "react-router-dom";
import Buttons from '../../fields/Buttons'
// import Swiper from "react-id-swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from 'swiper/core';
import 'swiper/swiper.scss'

import { renderFieldInput } from '../../../../../helpers/formHelper';
import SelectFields from '../../fields/SelectFields';
SwiperCore.use([Navigation,Pagination]);

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 376,
            showForm: false,
            settings: [],
            page:null,
            options:[
                {name:'Left to right', value: 'left-to-right'},
                {name:'Right to left', value: 'right-to-left'},
                {name:'Top to bottom', value: 'top-to-bottom'},
                {name:'Bottom to top', value: 'bottom-to-top'},
                {name:'Fade in', value: 'fade-in'},
                {name:'Zoom', value: 'show-up'}
            ],
            link:null,
            changed: false,
        };
        this.myRef = React.createRef();
        this.handleChange = this.handleChange.bind(this)
        this.addSlide = this.addSlide.bind(this)
        this.onColorChange = this.onColorChange.bind(this);
        this.setSide = this.setSide.bind(this);
        this.showForm = this.showForm.bind(this);
    }


    componentDidMount() {
      
        if(this.props.data && this.props.data.allData){
            let settings = JSON.parse(this.props.data.allData);
            this.setState({settings:settings,height: settings[0].bgimage.height})
        }
    }
    

    pictureUpload(file,key) {
        return new Promise((resolve,reject) =>{
            
            const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    
                    var img = new Image();
                    img.src = reader.result;
                    img.onload = async (ee) =>{
                        var width = (ee.path[0].width);
                        var height = (ee.path[0].height);
                        // if(width < 65 && height < 65){
                            var target = key === 'bgimage' ? 'custom_pages/slider' : 'custom_pages/slider_product';
                            var result = await fetchApiHelper.postApi('core/service/upload_base64/JHIOjfda89idaslfjsadoiudfasd',{base64:reader.result,target:target});
                            console.log(result)
                            if(result.status === 200){
                                if(result.data.status){
                                    resolve(result.data.image);
                                    return;
                                }
                            }
                            resolve('');
                    }
    
                    
                }
                reader.onerror = error => reject(error);
        })
    }
  

    setHeight(key,value){
        if(!this.state.settings) this.setState({[key]:value});

           
        let settingsupdated = [...this.state.settings ]
            
        let updatedData;
        this.state.settings.map((slide)=>{
            updatedData = { ...slide};
            let data = {
                height: value + 'px'
            }
            this.updatesStyle(updatedData,data)
            settingsupdated[slide.id] = updatedData
        })
            this.setState({settings:settingsupdated, [key]: value})
            return;
    }

    

    async handleChange(event,id) {
        let fieldtype = event.currentTarget.dataset.group;
        let key = event.currentTarget.getAttribute('name_helper');
        let value  = event.currentTarget.value;
        if(fieldtype != 'main'){      
            
            if (event.target.files) {
                const file = event.target.files[0];
            
                if(file){
                var b64 = await this.pictureUpload(file,key);

                    if(key === 'bgimage'){ 
                        let data = {
                        'backgroundImage': `url(${b64})`,
                        'border' : '1px solid green'
                        }

                        return this.updateSliderInfo(id,key,value,data);
                    }
                 return this.updateSliderInfo(id,key,b64);
                }
            }

            this.updateSliderInfo(id,key,value)
        }
      

       if(key === 'height'){
        this.setHeight(key,value);
       }
       
       this.setState({[key]:value})

    }

    showSliderItems(slide,side){
        if(this.state.id === undefined) return;

        if( this.state.settings[this.state.id] && slide.side == side){
       
            return(
                <div 
                    className={"desc-container " + 
                    (this.state.currentIndex == slide.id ? slide.txtAnim:'')  + 
                    (slide.side == 'right' ? ' right-side' : '') + 
                    (this.state.animationStart ? '' : ' hide')} 
                    onAnimationStart={() => this.setState({ animationStart: true })}
                >
                    {slide.logo ? <img src={slide.logo} className="logo" alt='logo' /> : ''}    
                    <p className='slide subtitle' style={{color:`${slide.textColor}`}}>{slide.title}</p>
                    <p className='slide desc' style={{color:`${slide.textColor}`}}>{slide.txtarea}</p>
                    <p className='slide oldPrice' style={{color:`${slide.textColor}`}}>{slide.oldPrice ? slide.oldPrice +' RSD' : '' } </p>
                    <p className='slide newPrice' style={{color:`${slide.textColor}`}}>{slide.newPrice ? slide.newPrice +' RSD' : '' } </p>
                    <p className='slide saved' style={{color:`${slide.textColor}`}}>{slide.saved ? 'Ušteda ' + slide.saved : ''}</p>
                    <p className='slide anno' style={{color:`${slide.textColor}`}}>{slide.annotation ? '* ' + slide.annotation : ''}</p>
                    {slide.link ? <a to="#" style={{backgroundColor:`${slide.btnColor}`,color:`${slide.textColor}`}} type="button" className="btn">Saznaj više</a> : <button style={{backgroundColor:`${slide.btnColor}`}} className="btn">Saznaj više</button>}
                </div>
            )    
        }

        return(
            slide.imagePreview ? <img src={slide.imagePreview} className={`product-image ${this.state.currentIndex == slide.id ? slide.picAnim:''} ${ (this.state.animationStart? '' : ' hide')} ` } alt='productImage' /> : ''
        )

        
    }

    setSlides( ) {
        if(!this.state.settings.length) return;
         
        return (
            <Swiper
            spaceBetween={0}
            speed={1500}
            allowTouchMove={false}
            slidesPerView={1}
            onSlideChange={(swiper) => {
                this.setState({currentIndex:swiper.realIndex,id:swiper.realIndex,animationStart:false})                
            } }
            onSwiper={(swiper) => console.log(swiper)}
            navigation
            pagination
            loop={true}
            >
            {this.state.settings.map((slide,i) => {  
                             
                    return (
                        <SwiperSlide ref={this.myRef} key={i} onClick={() => this.showForm(slide.id)} >
                            <div  className='wrapper' style={slide.bgimage}>
                                <div className='inner-wrapper'>
                                    <div className='box'>
                                        {this.showSliderItems(slide,'left')}
                                    </div>
                                    <div className='box'>
                                         {this.showSliderItems(slide,'right')}
                                    </div>
                                </div>
                            </div>
                            
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        )
    }

    updateSliderInfo(id,key,value, bgStyleData){
        let settingsupdated = [...this.state.settings ]
        let formdata = this.state.settings.filter(item => item.id == id);
        let updatedData = { ...formdata[0] };

        if(bgStyleData != undefined){
            this.updatesStyle(updatedData,bgStyleData);
            settingsupdated[id] = updatedData;
            this.setState({settings:settingsupdated});
            return;
        }

        if(bgStyleData === undefined && key != 'bgimage'){
            updatedData[key] = value;
            settingsupdated[id] = updatedData;
            this.setState({settings:settingsupdated});
            return;
        }

    }

    updatesStyle(updatedData,data){
        let style = {...updatedData.bgimage};
        Object.keys(data).map(prop => {
            style[prop] = data[prop]
        })
        updatedData.bgimage = style;
    }

    onColorChange(e,id){ 
        let name = Object.keys(e)[0];
        let value = Object.values(e)[0];

        if(name == 'backgroundColor'){
            let data = { backgroundColor:value };
            
            this.updateSliderInfo(id,name,value,data);
        } else {
            this.updateSliderInfo(id,name,value);
        }     
 
    }

    showForm(id) {
        if(id == this.state.id ){
            this.setState({
                showForm: !this.state.showForm,
                id:id
            })
        }
        if(!this.state.showForm || id != this.state.id ){
            this.setState({
                id:id,
                showForm:true
            })
        }
    }

    addSlide(e) { 
        e.preventDefault();
        if(!this.state.settings.length){ 
            this.setState({
                settings:[
                {
                    id: 0,
                    txtarea: null,
                    imagePreview: null,
                    logo:null,
                    bgimage : {
                        position: 'relative', textAlign: 'center',
                        backgroundImage: null,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundColor: '#fff',
                        height: '376px',
                        // position: 'relative', textAlign: 'center', height: '376px'
                    },
                    title: null,
                    oldPrice:null,
                    newPrice:null,
                    saved: null,
                    link:null,
                    annotation:null,
                    side:'left',
                    txtAnim:'left-to-right',
                    picAnim:'left-to-right',
                    button:true,
                    textColor:'',
                    btnColor:''

                }
            ],
            showForm:true,
            id:0
        })
            return;
        }

        let id = this.state.settings[this.state.settings.length -1].id

        this.setState({
            settings: [
                ...this.state.settings,
                {
                    id:id + 1 ,
                    txtarea: null,
                    imagePreview: null,
                    logo:null,
                    bgimage : {
                        position: 'relative', textAlign: 'center',
                        backgroundImage: `url()`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        position: 'relative', 
                        textAlign: 'center',
                        height: '376px', 
                        backgroundColor: '#fff'
                    },
                    title: null,
                    oldPrice:null,
                    newPrice:null,
                    saved: null,
                    link:null,
                    annotation:null,
                    side:'left',
                    txtAnim:'left-to-right',
                    picAnim:'left-to-right',
                    btnColor:'',
                    textColor:''
                }
            ],
        })
       
    }

    setSide(e,id){
        e.preventDefault();
        let side = e.target.dataset.index == 1 ? 'left' : 'right';
        let name = e.target.name;

        this.updateSliderInfo(id,name,side);

    }

    deleteSlider(e,id){
        let settings = [...this.state.settings]
        for(let i = 0; i < settings.length; i++){
            if(settings[i].id == id){
                settings.splice(i,1)
                if(settings[i]) settings[i].id = i;
            }
        }
        this.setState({settings: settings,id:null})
    }

    handleSelectChange(e,id){
        let settings = [...this.state.settings]
        let key = e.currentTarget.getAttribute('name_helper');
        for(let i = 0; i < settings.length; i++){
            if(settings[i].id == id){
                settings[i][key] = e.target.value;
                this.setState({settings: settings})
            }
        }
    }

    render() {
        
        let slider = this.state.settings[this.state.id];
        
        const buttons = [
            {title: 'Levo' ,dataIndex:'1', type: 'button' , name:'side', onClick: (e) => this.setSide(e,this.state.id)},
            {title: 'Desno',dataIndex:'2', type: 'button' , name:'side', onClick: (e) => this.setSide(e,this.state.id)},
        ];


        return (

            <form>
                <div className='slider-form-container'>
                    <div className='form-left'>
                      
                        <TextField block_setting_from_input={true}  label='stranica' name='pageName'data='main' onChange={this.handleChange} value={this.state.pageName} />
                        <TextField block_setting_from_input={true} label='Visina' name='height'  data='main'  onChange={(e) => this.handleChange(e,this.state.id)} value={this.state.height} />
                        <TextField block_setting_from_input={true}  label='Link' name='link'    data='main' onChange={this.handleChange} value={this.state.link} />

                        <button onClick={this.addSlide} className="btn">Dodaj slajd</button>
                    </div>
                    { slider  && this.state.showForm   ? ( 
                    <div className='form-right' >
                        
                        
                        <TextField block_setting_from_input={true}  label='Naslov' name='title'   onChange={(e) => { this.handleChange(e,this.state.id)}}  value={slider.title} />

                        <TextArea  block_setting_from_input={true}  label='Opis' name='txtarea'    onChange={(e) => this.handleChange(e,this.state.id)}  value={slider.txtarea} height='100px' />

                        <TextField block_setting_from_input={true}  label='Stara cena'name='oldPrice' type='number'   onChange={(e) => this.handleChange(e,this.state.id)}  value={slider.oldPrice} />

                        <TextField block_setting_from_input={true}  label='Nova cena' name='newPrice' type='number'   onChange={(e) => this.handleChange(e,this.state.id)}  value={slider.newPrice} />

                        <TextField block_setting_from_input={true}  label='Usteda'    name='saved'    type='number'     onChange={(e) => this.handleChange(e,this.state.id)}  value={slider.saved} />

                        <TextField block_setting_from_input={true}  label='Link'      name='link'     onChange={(e) => this.handleChange(e,this.state.id)}  value={slider.link} />
                        
                        <TextArea label='Napomena' name='annotation' value={slider.annotation} onChange={(e) => this.handleChange(e,this.state.id)} height='100px' />
                        
                        <SelectFields  block_setting_from_input={true} label='Izaberi animaciju za tekst'
                        name="txtAnim" value={slider.txtAnim} onChange={(e)=> this.handleSelectChange(e,this.state.id)} options={this.state.options}/>
                        
                        <SelectFields  block_setting_from_input={true} label='Izaberi animaciju za sliku'
                        name="picAnim" value={slider.picAnim} onChange={(e)=> this.handleSelectChange(e,this.state.id)} options={this.state.options}/>

                        <Buttons className={'txt-position-buttons'} buttons={buttons} title={'Pozicija teksta'}/>
                        
                        <div className="clear row-4">
                            <ColorPickerField color={slider.textColor} label="Boja teksta" name="textColor" enableAlpha={false} onChange={(e) => this.onColorChange(e, this.state.id)} />
                            <ColorPickerField color={slider.bgimage.backgroundColor} label="Boja pozadine" name="backgroundColor" enableAlpha={false} onChange={(e) => this.onColorChange(e, this.state.id)} />
                            <ColorPickerField color={slider.btnColor} label="Boja dugmeta" name="btnColor" enableAlpha={false} onChange={(e) => this.onColorChange(e, this.state.id)} />
                        </div>                      

                        <ImageField  onChange={(e) => this.handleChange(e,this.state.id)} name='imagePreview' ref="file" placeholder={this.props.label} label='Izaberi sliku' />

                        <ImageField onChange={(e) => this.handleChange(e,this.state.id)} name='bgimage' ref="file" placeholder={this.props.label} label='Izaberi sliku za pozadinu' />

                        <ImageField onChange={(e) => this.handleChange(e,this.state.id)} name='logo' ref="file" placeholder={this.props.label} label='Dodaj logo' />

                        <button type="button" onClick={(e) => this.deleteSlider(e,this.state.id)} className="btn">Izbriši</button>
                    </div>) : ''}
                </div>

                
                { this.setSlides() }
                    
                <input type="hidden" name="allData" value={JSON.stringify(this.state.settings)} />
            </form >

        )
    }
}

export default Form;