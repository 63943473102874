
import React from 'react';
import 'rc-slider/assets/index.css'; 
import Slider, {SliderTooltip, createSliderWithTooltip } from 'rc-slider';
const SliderWithTooltip = createSliderWithTooltip(Slider);
// <ResizeRangeField
//     onChange={(holder_width)=>{ 
//         this.setState({ chosen_screen_resolution:holder_width });
//         // +if anything else you want
//     }}  
// /> 
//  <div className="resolution_wrapper_main">
//      <div className="resolution_wrapper" style={{width:this.state.chosen_screen_resolution}}>
//          Your content goes here 
//      </div> 
// </div>


export default class ResizeRangeField extends React.Component {


    
    constructor(props){
        super(props);
        this.state = {
            current_window_size:0,
            fake_resolution_wrapper_width:360,
            use_real_screen_resolution_to_scale:false,
        }
        this.onSliderChange = this.onSliderChange.bind(this);
    }

    componentDidMount() {
        let current_window_size=window.innerWidth; 
        this.setState({current_window_size:current_window_size,fake_resolution_wrapper_width:current_window_size})
        global.window.addEventListener('resize', this.onResize)  
    }
    componentWillUnmount() {
        global.window.removeEventListener('resize', this.onResize);
    }
    onResize = e => {   
        let current_window_size=window.innerWidth; 
        this.setState({current_window_size:current_window_size})
        if(this.props.onChange&&this.state.use_real_screen_resolution_to_scale){
            this.props.onChange(current_window_size)
        }
    };

    onSliderChange(value){
        this.setState({fake_resolution_wrapper_width:value}); 
        if(this.props.onChange){
            this.props.onChange(value);
        }
    } 
    field_range(){ 
        return  <div className="resize_range_field">
            <div>
                <SliderWithTooltip
                    className="slider"
                    marks={{
                        320:320,
                        [this.state.current_window_size]: { label:
                            <div className="slider_mark"> 
                                <div>
                                    <span>Your real inner screen resolution</span> 
                                    <div>  
                                        <i className={`lock_slider_mark  fa fa-${this.state.use_real_screen_resolution_to_scale ? "lock" : "unlock"}`} aria-hidden="true"
                                            onClick={()=>{
                                            this.setState({use_real_screen_resolution_to_scale:!this.state.use_real_screen_resolution_to_scale})
                                        }}/> 
                                        <span>  {this.state.current_window_size} </span>  
                                    </div> 
                                </div>
                            </div>} ,
                        7680: 7680,
                       
                    }}  
                    disabled={this.state.use_real_screen_resolution_to_scale} 
                    value={this.state.use_real_screen_resolution_to_scale ? this.state.current_window_size : this.state.fake_resolution_wrapper_width}
                    min={320}
                    max={7680}
                    tipProps={{visible:true}}
                    tipFormatter={value => <span className="tooltip">{value}</span>}
                    onChange={ value => {this.onSliderChange(value)}}
                    onAfterChange={(value) =>{
                        this.onSliderChange(value);
                    }}
                />
                <br/>
            </div>       
           
        </div>
    }


    render(){ 
        return this.field_range()  
    }
}