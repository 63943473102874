import React from 'react';
import { renderToString } from 'react-dom/server';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ColorPickerField from '../../fields/ColorPickerField';
import TextField from '../../fields/TextField';
import ImageField from '../../fields/ImageField';
import { b64DecodeUnicode, b64EncodeUnicode } from '../../helpers/base64';
import { fetchApiHelper } from '../../../../../helpers/fetchApiHelper';
import SelectField from '../../fields/SelectField';
import PadField from '../../fields/PadField';

class Form extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        tabNums: 2,
        tabActive: 1,
        tabRadius: 0,
        tabColor: '#333333',
        tabBackgroundColor: '#EEEEEE',
        tabBorderColor: '#CCCCCC',
        tabColorActive: '#000000',
        tabBackgroundColorActive: '#CCCCCC',
        tabBorderColorActive: '#000000',
        tabLineColor: '#CCCCCC',
        tabIconPosition: 'left',
        tabFontSize: '13px',
        tabHeadBackground: '#fff',
        tabContentColor: '#000',
        tabContentBackground: '#fff',
        tabContentPadding: '0px',
        tabWidth: 'auto',
        tabs: {},
        tabs_images: {},
        contents: {}
      };

      this.onChange = this.onChange.bind(this);
      this.onColorChange = this.onColorChange.bind(this);
      this.onTabChange = this.onTabChange.bind(this);
      this.onTabIconChange = this.onTabIconChange.bind(this);
      this.onSelectChange = this.onSelectChange.bind(this);

    }


    componentDidMount(){
       
       const { editor, data } = this.props;

       const node = (editor.selection.getNode());

       var _c = node.querySelectorAll('.tab-content');
       var contents = {};

       if(_c){
           var c = {};
           for(var i = 0; i < _c.length; i++){
               var n = _c[i];

               if(n){
                   var html = n.innerHTML;
                    c[i] =  html ? renderToString( ReactHtmlParser(html) ): '';
               } else {
                    c[i] = '';
               }

               
           }
           contents = c;
       } else {
           contents = {};
       }


       var tabs = data.tabs ? JSON.parse(b64DecodeUnicode(data.tabs)) : {};
      
       var icons = b64DecodeUnicode(data.tabs_images) ? JSON.parse(b64DecodeUnicode(data.tabs_images)) : {};

       this.setState({
        tabNums: data.tabNums,
        tabActive: data.tabActive,
        tabRadius: data.tabRadius,
        tabColor: data.tabColor,
        tabBackgroundColor: data.tabBackgroundColor,
        tabBorderColor: data.tabBorderColor,
        tabColorActive: data.tabColorActive,
        tabBackgroundColorActive: data.tabBackgroundColorActive,
        tabBorderColorActive: data.tabBorderColorActive,
        tabLineColor: data.tabLineColor,
        tabIconPosition: data.tabIconPosition,
        tabPaddingLeft: data.tabPaddingLeft,
        tabPaddingRight: data.tabPaddingRight,
        tabPaddingTop: data.tabPaddingTop,
        tabPaddingBottom: data.tabPaddingBottom,
        tabFontSize: data.tabFontSize,
        tabHeadBackground: data.tabHeadBackground,
        tabContentColor: data.tabContentColor,
        tabContentBackground: data.tabContentBackground,
        tabContentPadding: data.tabContentPadding,
        tabWidth: data.tabWidth,
        tabs: tabs,
        tabs_images: icons,
        contents: contents
       });
    }
   

    onChange(e,s){
        var name = e.currentTarget.name;
        var value = e.currentTarget.value;
        this.setState({[name]:value});

        if(name==='tabNums'){

        }

    }

    onSelectChange(e){
        var name = e.currentTarget.name;
        var value = e.currentTarget.value;
        this.setState({[name]:value});
    }

  
    onColorChange(e){ 
        let name=Object.keys(e)[0];
        let value=Object.values(e)[0];
        this.setState({[name]:value});
 
    }


    onTabChange(e){
        var name = e.currentTarget.name, value = e.currentTarget.value, tabs = this.state.tabs;
        tabs[name] = value;
        
        this.setState({tabs:tabs});
    }


    async onTabIconChange(e){

        var file = e.currentTarget.files[0];
        var name = e.currentTarget.name;

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                
                var img = new Image();
                img.src = reader.result;
                img.onload = async (ee) =>{
                    var width = (ee.path[0].width);
                    var height = (ee.path[0].height);
                    if(width < 65 && height < 65){
                        var result = await fetchApiHelper.postApi('core/service/upload_base64/JHIOjfda89idaslfjsadoiudfasd',{base64:reader.result,target:'custom_pages/tabs'});
                        if(result.status === 200){
                            if(result.data.status){
                                resolve(result.data.image);
                                return;
                            }
                        }
                        resolve('');
                    } else {
                        resolve('');
                    }
                }

                
            }
            reader.onerror = error => reject(error);
        });
        
        if(file){
            var images = this.state.tabs_images;
            var b64 = await toBase64(file);
            images[name] = b64;
            this.setState({tabs_images:images});
        }

    }


    tabsfields(){
        var fields = [];
        var images = {};
        
        for(var i = 0; i < parseInt(this.state.tabNums); i++){
            var title = 'Tab:' + (i+1);
            var image = '';
            if(this.state.tabs[i] !== undefined){
                title = this.state.tabs[i];
                image = this.state.tabs_images[i] ? this.state.tabs_images[i] : '';
            }
            fields.push(title);
            images[i] = image;
        }

        return  fields.map((title,i) => {
            return <>
                <TextField key={i} label="Tab" name={i} value={title} onChange={this.onTabChange} />
                {this.state.tabs_images[i] ? (
                    <div style={{width:64,height:64, border:'solid 1px #000', textAlign:'center', backgroundColor:'#333'}}>
                        <img src={this.state.tabs_images[i]} />
                    </div>
                ):''}
                <ImageField key={'img-'+i} label="Icon" name={i} value="" onChange={this.onTabIconChange} />
            </>
        })
    }


    render(){

        const position_list = [
            {value:'left', text:'Left'},
            {value:'right', text:'Right'},
            {value:'top', text:'Top'},
            {value:'bottom', text:'Bottom'},
        ];

        const size_list = [
            {value:'11px', text:'11px'},
            {value:'12px', text:'12px'},
            {value:'13px', text:'13px'},
            {value:'14px', text:'14px'},
            {value:'15px', text:'15px'},
            {value:'16px', text:'16px'},
            {value:'17px', text:'17px'},
            {value:'18px', text:'18px'},
            {value:'19px', text:'19px'},
            {value:'20px', text:'20px'},
            {value:'21px', text:'21px'},
            {value:'22px', text:'22px'},
            {value:'23px', text:'23px'},
            {value:'24px', text:'24px'},
            {value:'25px', text:'25px'},
            {value:'26px', text:'26px'},
        ];

        return (<form>
            <input type="hidden" name="tabs_images" value={b64EncodeUnicode(JSON.stringify(this.state.tabs_images))} />
            <input type="hidden" name="tabs" value={b64EncodeUnicode(JSON.stringify(this.state.tabs))} />
            <input type="hidden" name="contents" value={b64EncodeUnicode(JSON.stringify(this.state.contents))} />
            <div className="row">
                <div className="col col-6">
                    <TextField label="Tab Numbers" name="tabNums" value={this.state.tabNums} onChange={this.onChange} />
                    <TextField label="Tab Active" name="tabActive" value={this.state.tabActive} onChange={this.onChange} />
                    <TextField label="Tab Radius" name="tabRadius" value={this.state.tabRadius} onChange={this.onChange} />
                    <TextField label="Tab Width" name="tabWidth" value={this.state.tabWidth} onChange={this.onChange} />
                    <TextField label="Content Padding" name="tabContentPadding" value={this.state.tabContentPadding} onChange={this.onChange} />
                    <SelectField label="Tab Icon Position" name="tabIconPosition" value={this.state.tabIconPosition} onChange={this.onSelectChange} values={position_list}  />
                    <SelectField label="Tab Font Size" name="tabFontSize" value={this.state.tabFontSize} onChange={this.onSelectChange} values={size_list}  />
                </div>
                <div className="col col-6">
                    <PadField label="Tab Padding" 
                        top={{value:this.state.tabPaddingTop,       name:'tabPaddingTop'}} 
                        left={{value:this.state.tabPaddingLeft,     name:'tabPaddingLeft'}} 
                        right={{value:this.state.tabPaddingRight,   name:'tabPaddingRight'}} 
                        bottom={{value:this.state.tabPaddingBottom, name:'tabPaddingBottom'}} 
                        onChange={this.onChange}
                    />
                </div>
                <div className="clear row-4 col col-12">
                    <ColorPickerField color={this.state.tabColor} label="Tab Color" name="tabColor"  enableAlpha={false} onChange={this.onColorChange} />
                    <ColorPickerField color={this.state.tabBackgroundColor} label="Tab background" name="tabBackgroundColor"  enableAlpha={false} onChange={this.onColorChange} />
                    <ColorPickerField color={this.state.tabBorderColor} label="Tab border color" name="tabBorderColor"  enableAlpha={false} onChange={this.onColorChange} />
                    <ColorPickerField color={this.state.tabColorActive} label="Tab active color" name="tabColorActive"   enableAlpha={false} onChange={this.onColorChange} />
                    <ColorPickerField color={this.state.tabBackgroundColorActive} label="Tab active background" name="tabBackgroundColorActive"   enableAlpha={false} onChange={this.onColorChange} />
                    <ColorPickerField color={this.state.tabBorderColorActive} label="Tab active border color" name="tabBorderColorActive"   enableAlpha={false} onChange={this.onColorChange} />
                    <ColorPickerField color={this.state.tabLineColor} label="Tab line color" name="tabLineColor"  enableAlpha={false} onChange={this.onColorChange} />
                    <ColorPickerField color={this.state.tabHeadBackground} label="Tab Head Background" name="tabHeadBackground"  enableAlpha={false} onChange={this.onColorChange} />
                    <ColorPickerField color={this.state.tabContentColor} label="Content Color" name="tabContentColor"  enableAlpha={false} onChange={this.onColorChange} />
                    <ColorPickerField color={this.state.tabContentBackground} label="Content Background" name="tabContentBackground"  enableAlpha={false} onChange={this.onColorChange} />
                </div>
                <div className="col col-12">
                    {this.tabsfields()}
                </div>
            </div>
        </form>)
    }
}

export default Form;