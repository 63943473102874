 
import React, { Component } from "react";
import ColorPickerField from '../../../fields/ColorPickerField';
import TextField from '../../../fields/TextField';
import $ from "jquery";
import EditorCSS from "./EditorCSS";
const element_list={
    energy:{
        element_name:"energy",
        element_title:"Energy", 
        removable:1,
        can_change_position:1,
        is_link:false,
        is_linkable:false,
        css:{  
            width:"60px",
            height:"30px",
            position:"absolute",
            left:"0px",
            top:"0px", 
            cursor:"pointer"
        }
    },
    favorite:{
        element_name:"favorite",
        element_title:"Favorite",
        removable:1, 
        can_change_position:1,
        is_link:false,
        is_linkable:false,
        css:{
            width:"fit-content",
            position:"absolute", 
            right:"10px",
            top:"20px",
            cursor:"pointer"
        }
    }, 
    compare:{
        element_name:"compare",
        element_title:"Compare",
        removable:1, 
        can_change_position:1,
        is_link:false,
        is_linkable:false,
        css:{
            width:"fit-content",
            position:"absolute", 
            left:"10px",
            top:"20px",
            cursor:"pointer"
        }
    },

    product_image:{
        element_name:"product_image",
        element_title:"Product image",
        removable:1,
        can_change_position:1,
        is_link:false,
        is_linkable:true,
        css:{ 
            width:"auto",
            height: "150px",
            maxWidth:"200px",
            margin:"0px auto",
            padding:"0px", 
        }
    },
    brand_image:{
        element_name:"brand_image",
        element_title:"Brand image",
        removable:1,
        can_change_position:1,
        is_link:false,
        is_linkable:true,
        css:{
            width:"auto",
            height: "auto",
            max_width:"200px",
            margin:"0px auto",
            padding:"0px",
        }
    },


    product_item_name:{
        element_name:"product_item_name",
        element_title:"Product item name",  
        removable:1,
        can_change_position:1,
        is_link:false,
        is_linkable:true,
        css:{  
            height: "48px",
            width:"100%",
            max_width:"200px",

            margin:"0px auto",
            padding:"0px",

            color:"red",
            fontSize: "14px", 
            fontWeight: "400", 
            textAlign: "center",
            overflow: "hidden",
            opacaty: "1",

            background: "transparent",
        }
    },
    product_type:{  
        element_name:"product_type",
        element_title:"Product type", 
        removable:1,
        can_change_position:1,
        is_link:false,
        is_linkable:true,
        // true 
        css:{   
            height: "40px",
            width:"100%",
            maxWidth:"initial",

            margin:"10px auto 5px auto",
            padding:"5px",

            color:"#7a6767",
            fontSize: "initial", 
            fontWeight: "initial", 
            textAlign: "center",
            overflow: "hidden",
            opacaty: "1",

            background: "transparent", 
            cursor:"pointer",
        }
    },                 
    
    show_more_button:{
        element_name:"show_more_button",
        element_title:"Show more button",
        removable:1,
        can_change_position:1,
        is_link:true,
        is_linkable:true,
        css:{
            height: "initial",
            width:"fit-content",
            maxWidth:"initial",

            margin:"0px auto",
            padding:"13px 17px",

            color:"black",
            fontSize: "initial", 
            fontWeight: "initial", 
            textAlign: "center",
            overflow: "hidden",

            opacaty: "1",
            background: "yellow", 
            cursor:"pointer"
        }
    },

    stars:{
        element_name:"stars",
        element_title:"Stars",
        removable:1, 
        can_change_position:1,
        is_link:false,
        is_linkable:false,
        css:{ 
            width:"fit-content",
            height: "auto",
            maxWidth:"200px",
            margin:"0px auto",
            padding:"0px", 
            fontSize: "22px",
            color:"yellow",
        }
    },
    votes:{
        is_link:false,
        is_linkable:false,
        element_name:"votes",
        element_title:"Votes",
        removable:1, 
        can_change_position:1,
        css:{ 
            width:"fit-content",
            height: "auto",
            maxWidth:"200px", 
            margin:"7px 0px 0px 5px", 
            padding:"0px", 
            fontSize: "initial",
            color:"#666",
        }
    },

    wrapper__old_price__saving_price__main_price__price_helper:{   
        element_name:"wrapper__old_price__saving_price__main_price__price_helper",
        element_title:"Wrapper",
        wrapper_direction:"v",
        nowrap:1,
        removable:1,
        can_change_position:1,
        add_data:0,
        is_link:false,
        is_linkable:false,
        css:{
            padding:"0px",
            margin:"0px", 
            height:"130px",
            width:"100%",
        },
        content:[ 
            {
                element_name:"old_price",
                element_title:"Old price", 
                removable:0,
                can_change_position:0,
                is_link:false,
                is_linkable:false,
                css:{ 
                    background: "transparent",
                    margin:"0px auto",
                    padding:"5px",
                    color: "black",
                    textAlign:"center",
                    textDecoration:"line-through",
                    textDecorationColor:"#dc1313",
                    fontSize: "18px",
                    width:"fit-content",
                   
                },
            },
            {
                element_name:"saving_price",
                element_title:"Saving price",
                removable:0,
                can_change_position:0,
                is_link:false,
                is_linkable:false,
                css:{
                    background: "transparent",
                    margin:"0px auto",
                    padding: "3px 6px 4px",
                    color: "black",
                    textAlign:"center", 
                    fontWeight: "400",
                    fontSize: "14px",
                    background: "#dc1313",
                    color: "white",
                    width:"fit-content",
                }
            
            },
            {   
                element_name:"main_price",
                element_title:"Main price", 
                removable:0,
                can_change_position:0,
                is_link:false,
                is_linkable:false,
                css:{
                    background: "transparent",
                    margin:"0px auto", 
                    padding:"5px 10px",
                    color: "black",
                    textAlign:"center",
                    fontSize: "24px",
                    fontWeight: "500",
                }
            },
            {   
                element_name:"price_helper",
                element_title:"Price helper",
                removable:0,
                can_change_position:0,
                is_link:false,
                is_linkable:false,
                css:{
                    background: "transparent",
                    color:"grey",
                    fontSize:"14px",
                }
            
            }
        ]
    },
    wrapper__sticker_top__sticker_bottom:{
        element_name:"wrapper__sticker_top__sticker_bottom",
        element_title:"Wrapper",
        wrapper_direction:"v",
        nowrap:0,
        removable:1,
        can_change_position:1,
        add_data:0,
        is_link:false,
        is_linkable:false,
        css:{
            padding:"0px",
            margin:"0px", 
            height:"50px",
            width:"100%",
        },
        content:[
            {
                element_name: "sticker_top",
                element_title:"Sticker top", 
                removable:0,
                can_change_position:0,
                is_link:false,
                is_linkable:false,
                css:{   
                    height: "initial",
                    width:"fit-content",
                    maxWidth:"initial",

                    padding:"5px",

                    color:"black",
                    fontSize: "14px", 
                    fontWeight: "400", 
                    textAlign: "left",
                    overflow: "hidden",
                    opacaty: "1",

                    background: "transparent", 
                }
            },
            {
                element_name:"sticker_bottom",
                element_title:"Sticker bottom",
                removable:0,
                can_change_position:0,
                is_link:false,
                is_linkable:false,
                css:{   
                    height: "initial",
                    width:"fit-content",
                    maxWidth:"initial", 

                    padding:"5px",

                    color:"black",
                    fontSize: "14px", 
                    fontWeight: "400",  
                    textAlign: "left",
                    overflow: "hidden",
                    opacaty: "1",

                    background: "transparent", 
                }
            
            }
        ]
    },
    
};

class EditorAddNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // adding new wrapper
            new_element_direction:"v",
            new_element_wrap_info:false,
            new_element_is_link:false,

            wrapper_we_clicked_to_add_new_content:"", 
            // 


 
        };
 
    }
    render() {
            let wrapper_that_gets_filled=this.props.wrapper_we_clicked_to_add_new_content;
            if(!wrapper_that_gets_filled){
                return;
            }

            let new_element_direction=this.state.new_element_direction;
            let new_element_wrap_info=this.state.new_element_wrap_info; 
            let new_element_is_link=this.state.new_element_is_link; 
            let blocked=$(".dropzone_active_tree_title").map(function(){return $(this).attr("name");}).get();
            blocked=blocked.join();
            
        
            
            let remaining_elements= Object.keys(element_list).map((name,indx)=>{ 
                if(!blocked.includes(name)){
                    let el=element_list[name];
                    let title=el.element_title;
                    return <div key={name+indx} className="add_items_checkbox_list">
                        <input type="checkbox" value={name} onClick={(e)=>{
                            let user_checked_items=$('.add_items_checkbox_list>:checkbox:checked').length;
                            if(!user_checked_items){
                                $( "#wrapp_elements_in_me" ).prop('checked', false);
                            }
                            $( "#wrapp_elements_in_me" ).prop( "disabled",!user_checked_items );
                        }}/>
                        <p> {title}</p> 
                        {el.content ?  
                            <div className="sub_info">
                                {el.content.map((item,key)=>{
                                    return  <p key={item.element_title+key}>
                                        {item.element_title}
                                    </p>
                                })}
                            </div>
                        :""}
                    </div>
                }
            }).filter( Boolean );
    
            return <div className="option_list">
                {! remaining_elements.length ? 
                <>
                    <p>
                        There are no elements left to add. 
                    </p>  
                    <i  onClick={()=>{   this.props.showAddNewTab(false) }} className="fa fa-times empty_list" aria-hidden="true"></i> 

                </>
                : 
                <> 
                    {remaining_elements}
                    <hr className="devider"/>
                    <div className="wrapper_options">
                        <input type="checkbox" id="wrapp_elements_in_me" value="0" disabled/> 
                        <p>Wrap content</p>
                        <div> 
                            
                            <svg aria-hidden="true" focusable="false"   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                onClick={()=>{   
                                    this.setState({new_element_is_link:!new_element_is_link }) }}
                                className={new_element_is_link ? "fa-link" : "fa-unlink"}  
                                >   {new_element_is_link ? 
                                    <path fill="currentColor" d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z"></path>
                                    :
                                    <path fill="currentColor" d="M304.083 388.936c4.686 4.686 4.686 12.284 0 16.971l-65.057 65.056c-54.709 54.711-143.27 54.721-197.989 0-54.713-54.713-54.719-143.27 0-197.989l65.056-65.057c4.686-4.686 12.284-4.686 16.971 0l22.627 22.627c4.686 4.686 4.686 12.284 0 16.971L81.386 311.82c-34.341 34.341-33.451 88.269.597 120.866 32.577 31.187 84.788 31.337 117.445-1.32l65.057-65.056c4.686-4.686 12.284-4.686 16.971 0l22.627 22.626zm-56.568-243.245l64.304-64.304c34.346-34.346 88.286-33.453 120.882.612 31.18 32.586 31.309 84.785-1.335 117.43l-65.056 65.057c-4.686 4.686-4.686 12.284 0 16.971l22.627 22.627c4.686 4.686 12.284 4.686 16.971 0l65.056-65.057c54.711-54.709 54.721-143.271 0-197.99-54.71-54.711-143.27-54.72-197.989 0l-65.057 65.057c-4.686 4.686-4.686 12.284 0 16.971l22.627 22.627c4.685 4.685 12.283 4.685 16.97-.001zm238.343 362.794l22.627-22.627c4.686-4.686 4.686-12.284 0-16.971L43.112 3.515c-4.686-4.686-12.284-4.686-16.971 0L3.515 26.142c-4.686 4.686-4.686 12.284 0 16.971l465.373 465.373c4.686 4.686 12.284 4.686 16.97-.001z"></path>
                                }
                            </svg> 
                            <i className={`fa fa-arrow-circle-o-${new_element_direction=="v"?"down": "right"}`} aria-hidden="true" 
                            onClick={()=>{
                                let direction=new_element_direction=="v"? "h":"v"; 
                                this.setState({new_element_direction:direction});
                            }}/> 
                            <i className={`fa fa-${new_element_wrap_info ? "lock" : "unlock"}`} aria-hidden="true"
                            onClick={()=>{
                                this.setState({new_element_wrap_info:!new_element_wrap_info})
                            }}/> 
                        </div> 
                    </div>
                    <div className="option_list_btns">
                        <p onClick={()=>{
                            let options={...element_list};
                            let content = [];
                            let content_names="";

                            $('.add_items_checkbox_list>:checkbox:checked').each(function () {
                                let element=options[$(this).val()];
                                content.push(element);
                                content_names=content_names+"__"+$(this).val();
                            });
                            if(!content_names){
                                return;
                            }
                            let put_el_in_wrapper=$('.wrapper_options>:checkbox:checked').val();
                            if(put_el_in_wrapper){
                                content=[
                                    {
                                        element_name:"wrapper"+content_names,
                                        element_title:"Wrapper",
                                        wrapper_direction:new_element_direction,
                                        nowrap:new_element_wrap_info,
                                        is_link:new_element_is_link,
                                        is_linkable:true,
                                        removable:1,
                                        can_change_position:1,
                                        add_data:1,
                                        content:content,
                                        css:{
                                            padding:"0px",
                                            margin:"0px", 
                                            height:"initial",
                                            width:"100%",
                                        },
                                    }
                                ]
                                content_names="wrapper"+content_names;
                            } 
                            let data={
                                insert_in:wrapper_that_gets_filled,
                                content:content,
                                content_names:content_names,
                            } 

                            this.props.itemTreeAddDeleteChange(data,"add");
                            this.props.showAddNewTab(false) 
                        }}>ADD</p>
                        <p  onClick={()=>{ 
                            this.props.showAddNewTab(false) 
                        }}>CLOSE</p>
                    </div>
                </>
                }
            </div>
        
            
        


   
         

     
    }
 
}
 
 

export default (EditorAddNew) ;
