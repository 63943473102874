import React from 'react';

import ProductsSearchField from "../../fields/ProductsSearchField";
import { fetchApiHelper } from "../../../../../helpers/fetchApiHelper";
import "./style.scss";
import ItemListPreview from './components/ItemListPreview';


class Form extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        active_items:[],
        active_ids:[],
        bottom_preview_type:"preview_layout_with_ids",
   
        
      };

      this.onChange = this.onChange.bind(this);
      this.onItemClick = this.onItemClick.bind(this);
      this.onDragAndDropSortElements =this.onDragAndDropSortElements.bind(this);
   
    }

    ///todo na kraju draditi check pre potvrdjivanja forme dal svi imaju status true..
    componentDidMount(){
        document.body.style.overflow = 'hidden';
        // OLD DATA: 
        console.log(this.props.data)
        // za detalje itemsa ide novi zahtev ne koristimo stare podatke iz editora
        let data=this.props.data;
        let i=data.active_ids;
        if(i){
            this.addOldItems(i)
        }
    }

    componentWillUnmount(){
        // document.body.style.overflow = 'unset';
        document.body.style.removeProperty("overflow");
    }

    async addOldItems(i){
        let r= await fetchApiHelper.postApi(`core/product/ids2`,{ids:i})
        if(r&&r.status){
            let arranged_ids=i.split(",");
            let active_items=r.data.items;
 
            //sort+missing
            let new_ids=[];
            let new_array=[];
            arranged_ids.map((id,i)=>{
                active_items.map((item,index)=>{
                    if(item.id==id){
                        new_array.push(item);
                        new_ids.push(item.id);
                    }
                })
            })
            let diff=arranged_ids.filter(x => !new_ids.includes(x));
            if(diff.length){
                let msg=`Trazeni predmeti sa ids:${diff.toString()}, nisu pronadjeni i nece biti prikazani u listi.`
                alert(msg)
            }
            this.setState({active_items:new_array,active_ids:new_ids})
        }
        else{
            //+
        }
    }
 

    onChange(e,s){
        var name = e.currentTarget.name;
        var value = e.currentTarget.value;
        this.setState({[name]:value});
    }
    
    onDragAndDropSortElements(dragged_id,id_under_dragged_id){
        // move array from previous position to index of id_under_dragged_id
        let arr=this.state.active_ids;
        let old_index=arr.indexOf(dragged_id);
        let new_index=arr.indexOf(id_under_dragged_id);
        arr.splice(old_index, 1);
        arr.splice(new_index, 0, dragged_id); 
        let new_items_arr=this.state.active_items.sort((a,b)=>{
            return arr.indexOf(a.id) - arr.indexOf(b.id);
        })
        this.setState({active_ids:arr,active_items:new_items_arr})
    }
 
    onItemClick(x,action){
        if(action=="add"){
            // todo check first gg
            this.setState(prevState => ({
                active_items:[...prevState.active_items,x],
                active_ids:  [...prevState.active_ids, x.id]
            }))
        }
        else{
            let a=this.state.active_items.filter(item => item.id !== x);
            let i=this.state.active_ids.filter(id =>  id!== x);
            this.setState({active_items:a,active_ids:i})
        }
    }

    active_nav(title, type) {
        return <p className={this.state.bottom_preview_type == type ? "active_nav_prev" : ""}
        onClick={()=>{this.setState({ bottom_preview_type:type})}}
        >{title} {type != "choose_layout" ? <i className="fa fa-angle-double-right" aria-hidden="true" /> : ""}</p>
    }
    render(){
        return ( 
        <form className="product-list-wrapper">
            <div className="item_types_breadcrumbs">
                {this.active_nav("Preview and edit layout", "preview_layout_with_ids")}
                {this.active_nav("Choose another layout", "choose_layout")}
            </div>
            <input type="hidden" name="active_ids" value={this.state.active_ids ? this.state.active_ids.toString(): ""}/>               
                {/* todo za search: loading,slike...*/}
                {this.state.bottom_preview_type=="preview_layout_with_ids" ? 
                    <ProductsSearchField 
                        onItemClick={this.onItemClick} 
                        active_items={this.state.active_items} 
                        active_ids={this.state.active_ids} 
                    /> : "" }


                <ItemListPreview 
                    type={this.state.bottom_preview_type}  
                    active_items={this.state.active_items}
                    data={this.props.data}
                    onItemClick={this.onItemClick} 
                    onDragAndDropSortElements={this.onDragAndDropSortElements}
                /> 
                          
        </form>)
    }
}

export default Form;

