  
import React from 'react';
import { useEditor } from '@craftjs/core';
export * from './components/ToolbarSection';
export * from './components/ToolbarItem';
export * from './components/ToolbarTextInput';
export * from './components/ToolbarDropdown';

export const Toolbar = () => {
    const { active, related } = useEditor((state) => ({
      active: state.events.selected,
      related:
        state.events.selected && state.nodes[state.events.selected].related,
    }));
  
    return (
      <div className="py-1 h-full">
        {active && related.toolbar && React.createElement(related.toolbar)}
        {!active && (
          <div
            className="px-5 py-2 flex flex-col items-center h-full justify-center text-center"
            style={{
              color: 'rgba(0, 0, 0, 0.5607843137254902)',
              fontSize: '11px',
            }}
          >
            <h2 className="pb-1">Klikni na komponentu da pocnes da editujes.</h2>
            <h2>
              
            </h2>
          </div>
        )}
      </div>
    );
  };