import $ from 'jquery';
import React from 'react';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderGigaEditor
} from "./../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidateFileImage
} from './../helpers/validation';


console.log(renderFieldInput);
export const galleryItemConf = {
    
    fields: {
        
        'title' : {
            name: 'title',
            type: 'text',
            component: renderFieldInput,
            label: 'Naslov',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'alt' : {
            name: 'alt',
            type: 'text',
            component: renderFieldInput,
            label: 'Alt text',
            validate:[ValidateRequire,ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'description' : {
            name: 'description',
            type: 'text',
            component: renderFieldInput,
            label: 'Opis',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'price' : {
            name: 'price',
            type: 'text',
            component: renderFieldInput,
            label: 'Cena',
            validate:[ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
        },
        'btn_text' : {
            name: 'btn_text',
            component: renderFieldInput,
            label: 'Tekst tastera',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'link' : {
            name: 'link',
            component: renderFieldInput,
            label: 'Link',
            validate:[],
            warn: [],
            size: 'large',
            value: '',
        },
    },
    initialValues: {}
}