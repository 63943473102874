import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';

export default class Unwrap extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(){
        var ins = new InsertNode(this.props.editor);
        
        if(this.props.editor.state.active){
            var wrapper = this.props.editor.state.active.closest('.theme-wrap');
            if(wrapper){
                var docFrag = document.createDocumentFragment();
                while (wrapper.firstChild) {
                    var child = wrapper.removeChild(wrapper.firstChild);
                    docFrag.appendChild(child);
                }

                // replace wrapper with document fragment
                wrapper.parentNode.replaceChild(docFrag, wrapper);
            }
        }

        this.props.editor.registerChange();
    }

    render(){
        return <button title="Unwrap theme-wrap" className="get-btn selector-ga" id={"Wrap-btn-"+this.props.id} onClick={this.action}><i className="fa fa-object-ungroup" aria-hidden="true" ></i></button>
    }

}