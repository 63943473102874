 
import React, { Component } from "react";
 
 
class Price extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    
    }

    render() {
        let price_type=this.props.price_type;
        let product=this.props.product; 
        if (price_type&&product){
            
            if(!product.prices) return "";
            let p=product.prices;
            let oldValue=p.old.value;
            if(oldValue===p.price.value || oldValue == 0){
                oldValue=null;
            }

            switch(price_type) {
                case "old_price":
                    return oldValue&&product.prices.old.label ? 
                    <div 
                    className={`old_price `}  
                    style={this.props.style}
                    >
                        <span>{product.prices.old.label} 
                        {product.prices.old.label ? (product.prices.old.label.includes(":")? "" : ":") : ""}
                        </span>
                        <span>{product.prices.old.formated}</span>
                        <span> {product.prices.old.monet}</span>
                    </div> :"" ; 
                case "saving_price":
                    return oldValue&&product.prices.saving.label ? 
                    <div  
                    className={`saving_price `}  
                    style={this.props.style}
                    >
                        <span>{product.prices.saving.label}
                        {product.prices.saving.label ? (product.prices.saving.label.includes(":")? "" : ":") : ""}
                        </span>
                        <span>{product.prices.saving.formated}</span>
                        <span> {product.prices.saving.monet}</span>
                    </div>:"" ; 
                case "main_price":
                    return <div 
                        className={`main_price `}  
                        style={this.props.style}
                        >
                        <span>{product.prices.price.formated}</span>
                        <span> {product.prices.price.monet}</span>
                    </div>;  
                case "price_helper":
                    return oldValue&&p.old.label === "MP cena" ? <span 
                        className={`price_helper `}  
                        style={this.props.style}
                        >
                        <em>*</em> Za gotovinsko plaćanje i online kupovinu
                    </span> : "" ;
            }
        }
        else{
            return "";
        }
     
    }
 
}
 
 

export default (Price) ;
