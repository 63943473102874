import React from 'react';
{/*  
block_setting_from_input={true} ///when we remove input name(because we don't wanna set data directly from this form field to View),in parent component we can use name_helper,and set the data where we want..
*/}
export default class SelectField extends React.Component {

    constructor(props){
        super(props);
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }


    onChange(e){
        if(this.props.onChange){
            this.props.onChange(e);
        }
    } 

    render(){
        let disabled=this.props.disabled ? disabled : "";
        return <div className={`form-group ${this.props.className ? this.props.className : ""}`}>
            <label>{this.props.label}</label>
            <select className={!this.props.className ? "selectfield": ""}
                value={this.props.value ? this.props.value : ""} 
                name={this.props.block_setting_from_input ? "" : this.props.name}
                name_helper={this.props.name}
                onChange={this.onChange} {...disabled}>
                    {this.props.values.map((o,i)=>(
                        <option value={o.value} key={o+i}>{o.text}</option>
                    ))}
                </select>
        </div>
    }
}