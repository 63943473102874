import React, { Component, useReducer } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { reduxAsyncValidate, reduxReduceVaues, reduxPrepareFormData } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";

import { fetchHelper } from '../helpers/fetchHelper';
import AlertyBox from '../libraries/AletyBox';
import { setBusy } from '../modules/forms';
import { pagesConf } from "./pagesConf";
// import { userConf } from "./userConf";

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';

import './../assets/css/form.scss';
import GigaEditor from '../libraries/GigaEditor';
import { date_sql_to_rs, date_rs_to_sql, SubmitDataPickerValue } from "./../helpers/dateHelper";
import { EditorInstance } from '../libraries/GigaEditor/EditorInstance';

  
class PagesForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            success: null,
            message: null,
            busy: false,
            rnd: 0,
            allow_submit: false,
        };

        this.editors = {};
        this.setEditorInstance = this.setEditorInstance.bind(this);
    }

    componentDidMount() {
        pagesConf.index = this;
    }

    componentDidUpdate(prevProps) {
        if (this.props.data) {
            reduxAsyncValidate(this.props.data, this.props);
        }
    }




    async submit(data) {

        console.log('submit data', data)

        this.props.setBusy(true);
        var values = reduxReduceVaues(pagesConf.fields, data);
    
        values.section = this.props.data.section;

        // COLLECT EDITOR DATA
        var content = this.editors['content'].getContent();

        if(this.editors['content_mobile']){
            var content_mobile = this.editors['content_mobile'].getContent();
            values.content_mobile = content_mobile.html;
            values.content_mobile_data = content_mobile.data;
        }

        

        values.content = content.html;
        values.content_data = JSON.stringify(content.data);

        

        var response = await fetchHelper.post(`pages/change`, values);
   
        this.props.setBusy(false);

        if (response.status && values && Object.keys(values).length) {
            if (this.props.onSave) {
                this.props.onSave(values,response);
            }
            AlertyBox.message(response.data);
        } else {
            var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
            AlertyBox.message(m)
        }
        return true;
    }

    setEditorInstance(e){
        this.editors[e.props.id] = e;
    }


    render() {

        if (!this.props.data) return '';

        const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

      
        // remove null, set empty string
        pagesConf.initialValues = this.props.data;

        pagesConf.fields.content.value = this.props.content;
        pagesConf.fields.content_mobile.value = this.props.content_mobile;

        var refID = React.createRef();


        return (

            <>
                <form id="pagesform" autoComplete="off" onSubmit={handleSubmit((data) => {
                    if (this.state.allow_submit) {
                        this.setState({ allow_submit: false })
                        data = SubmitDataPickerValue(data, ['date_created', 'date_updated', 'start_date', 'end_date']);
                        // console.log(data);
                        this.submit(data);
                    }
                })} className="redux-form" encType="multipart/form-data" >
                    {busy ? <div className="form-mask"></div> : ''}
                    <GigaTabs tab={0}>

                        <GigaTab title="Osnovni podaci" /*height={400}*/>

                            <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                                <Cell className="grid-column">
                                    <div className="relative">
                                        <div className="separate-right"></div>
                                        <Field {...pagesConf.fields.id} ref={refID} />
                                        <Field {...pagesConf.fields.master_id} />
                                        <Field {...pagesConf.fields.section_id} />
                                        <Field {...pagesConf.fields.position} />
                                        <Field {...pagesConf.fields.featured} />
                                        {/* <Field {...pagesConf.fields.section_old_id} /> */}
                                        <Field {...pagesConf.fields.vendor_id} />
                                        <Field {...pagesConf.fields.promocode_id} />
                                        <Field {...pagesConf.fields.category_id} />

                                        
                                        <Field {...pagesConf.fields.name} />
                                        <Field {...pagesConf.fields.title} />
                                        <Field {...pagesConf.fields.description} />
                                        <Field {...pagesConf.fields.meta_title} />
                                        <Field {...pagesConf.fields.meta_keywords} />
                                        <Field {...pagesConf.fields.meta_description} />
                                        
                                        <Field {...pagesConf.fields.icon} img_name={this.props.data.icon} master={refID} index={this.props.index} />
                                        <Field {...pagesConf.fields.icon_mobile} img_name={this.props.data.icon_mobile} master={refID} index={this.props.index} />
                                        <Field {...pagesConf.fields.thumb} img_name={this.props.data.thumb} master={refID} index={this.props.index} />
                                        <Field {...pagesConf.fields.image} img_name={this.props.data.image} master={refID} index={this.props.index} />
                                       
                                    </div>
                                </Cell>
                                <Cell className="grid-column">
                                    <div className="relative">
                                    <div className="separate-right"></div>
                                        <Field {...pagesConf.fields.start_date} content_id={this.props.id} />
                                        <Field {...pagesConf.fields.end_date} content_id={this.props.id} />

                                        <Field {...pagesConf.fields.date_created} content_id={this.props.id}/>
                                        <Field {...pagesConf.fields.date_updated} content_id={this.props.id}/>

                                        <Field {...pagesConf.fields.statistic_visits} />
                                        <Field {...pagesConf.fields.statistic_votes} />
                                        <Field {...pagesConf.fields.statistic_rating} />
                                        <Field {...pagesConf.fields.statistic_comments} />    
                                    </div>                            
                                </Cell>
                                <Cell className="grid-column">
                                    <Field {...pagesConf.fields.status} />
                                </Cell>
                            </Grid>

                        </GigaTab>
                        <GigaTab title="EDITOR">
                            <Grid gap="30px"  columns={this.props.data.master_id ?"repeat(auto-fit, minmax(500px, 1fr))":"minmax(200px, 700px)"}>
                                <Cell className="grid-column">
                                    <div className="relative">
                                        <Field {...pagesConf.fields.content}
                                            instance={pagesConf}
                                            content_id={this.props.id}
                                            content={this.props.content}
                                            id="content"
                                            section="pages" 
                                            index={this} 
                                            url="http://ozone.devgigatron.com/"
                                        />
                                    </div>
                                </Cell>
                                {this.props.data.master_id ?
                                <Cell className="grid-column">
                                    <div className="relative">
                                            <Field {...pagesConf.fields.content_mobile}
                                            instance={pagesConf}
                                            content_id={this.props.id}
                                            content={this.props.content_mobile}
                                            id="content_mobile"
                                            section="pages" 
                                            index={this} 
                                            url="http://ozone.devgigatron.com/"
                                            />
                                    
                                    </div>
                                </Cell>
                                : ""}
                            </Grid>
                        </GigaTab>

                    </GigaTabs>
                    <div className="page-bottom-bar">
                        <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
                    </div>
                </form>
            </>
        );
    }
}

const selector = formValueSelector('pagesform');

const mapStateToProps = state => ({
    initialValues: pagesConf.initialValues,
    busy: state.forms.busy,
    id: selector(state, 'id'),
    content: selector(state, 'content'),
    content_mobile: selector(state, 'content_mobile')
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBusy,
            updateField: (field, data) => change("pagesform", field, data),
        },
        dispatch
    );

PagesForm = reduxForm({
    form: "pagesform",
    reduxAsyncValidate,
    enableReinitialize: true,
})(PagesForm);
PagesForm = connect(
    mapStateToProps, mapDispatchToProps
)(PagesForm);
export default PagesForm;
// export default connect(mapStateToProps, mapDispatchToProps)(PagesForm);