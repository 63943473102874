import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';

export default class ClearStyle extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(){

        var ins = new InsertNode(this.props.editor);
        ins.clear();
        this.props.editor.registerChange();

        // this.props.element.current.focus();
        // var el = this.props.editor.state.active;
        // el.removeAttribute('style');
        // document.execCommand('formatBlock', false, 'p');

        // this.props.editor.registerChange();
    }

    render(){
        return <button className="get-btn selector-ga" id={"ClearStyle-btn-"+this.props.id} onClick={this.action}><i className="fa fa-eraser" aria-hidden="true" ></i></button>
    }

}