import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { reduxAsyncValidate,reduxReduceVaues } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";
import AlertyBox from '../libraries/AletyBox';
import tinyExport from '../libraries/GigaEditor/tinymce/helpers/tinyExport';
import { SubmitDataPickerValue } from "./../helpers/dateHelper";

import { setBusy } from '../modules/forms';
import { productConf } from "./productConf";

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';

import './../assets/css/form.scss';
import { fetchHelper } from '../helpers/fetchHelper';

class ProductForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allow_submit: false,
        }
        this.editors = {};
        this.editorRef = React.createRef();
        this.setEditorInstance = this.setEditorInstance.bind(this);
    }

    componentDidMount() {
        productConf.index = this;
        
        this.getCategories();
        this.getEnergyData();
        this.getWarranties();
        this.getBrands();
    }

    componentDidUpdate(prevProps,prevState) {
        if (this.props.data) {
            
            reduxAsyncValidate(this.props.data, this.props);
            this.setInitialValues();
        }

        if(prevProps.category_id !== this.props.category_id){
            this.getGroups(this.props.category_id) ;
        }

        if(prevProps.data !== this.props.data ){
            this.getSubCategories(this.props.data.group_id)
        }

    }

    async getBrands() {
        var response = await fetchHelper.get('/products/get_brands');
        if (response.status && response.items.length > 0) {

            let brandOptions = this.formatData(response.items);
            productConf.fields.brand_id.options = brandOptions;
            this.setState({ brands: true })

        }

    }

    async getEnergyData() {
        var response = await fetchHelper.get('/products/get_energy_data');
        if (response.status && response.items.length > 0) {

            let energyOptions = this.formatData(response.items);
            productConf.fields.energy.options = energyOptions;
        //     this.setState({ brands: true })

        }

    }

    setInitialValues(){
        if(this.state.categories){
            
            this.state.categories.map(category => {
                if(+category.master_id === this.props.data.category_id){
                    productConf.initialValues.category_id = category.id;
                    
                }
            })
        }
        if(this.props.data.group_id === null ){
            if(this.state.groups ){
                this.state.groups.map(group => {
                    if(+group.master_id === this.props.data.subcategory_id){
                    productConf.initialValues.group_id = group.id;
                    }

                    if(group.child.length){

                        group.child.map(subcategory => {
                            if(+subcategory.master_id === this.props.data.subcategory_id){
                                productConf.initialValues.group_id = group.id;
                                productConf.initialValues.subcategory_id = subcategory.id;
                            }
                        })
                    }
                })
                this.getSubCategories(this.props.data.group_id);
            }
        }
    }


    async getCategories() {
        var response = await fetchHelper.get('/categorization/get_categories');
        if (response.status && response.items.length > 0) {

            let categoryOptions = this.formatData(response.items);
            this.setState({ categories: response.items })
            productConf.fields.category_id.options = categoryOptions; 
            this.getGroups(this.props.category_id) 
        }


    }



    formatData( data) {
        var formatedData = [];
        if (!data || !data.length) return '';

        data.map(obj => {
            var option = {}
            if (obj.position) {
                option.position = obj.position;
            }
            option.text = obj.name;
            option.value = obj.id;
            formatedData.push(option)
        })
        return formatedData.sort((a, b) => {
            return a.position - b.position;
        });
    }

    async getWarranties() {

        var response = await fetchHelper.get(`/products/get_warranties`);

        let warranties = this.formatData(response.items);
        productConf.fields.warranty_id.options = warranties;

    }

    async getGroups(value) {
        let groups;
        
        if(!this.state.categories) return '';
        this.state.categories.map(category => {
            if (category.id === +value) {
                this.setState({ groups: category.child });
                groups = this.formatData(category.child);
                productConf.fields.group_id.options = groups;
            }
        })
        return groups;

    }

    async getSubCategories(value) {
        if(!this.state.groups) return '';
        let subcategories;
        if(!this.state.subcategories){

            this.state.groups.map(group => {
                if (group.id === +value) {
                    subcategories = this.formatData(group.child);
                    
                    
                    productConf.fields.subcategory_id.options = subcategories || [];
                    
                    this.props.updateField('subcategories', subcategories)
                    if(subcategories.length){
                        productConf.fields.subcategory_id.disabled = false
                        this.setState({subcategories})
                    }else{
                        productConf.fields.subcategory_id.disabled = true
                    }

                }
            })
        }

    }

    async submit(data) {

        this.props.setBusy(true);
        var values = reduxReduceVaues(productConf.fields, data);

        // COLLECT EDITOR DATA
        var other = this.editors['other'].getContent();
        var Export = new tinyExport(other, this.editors['other']);
        var other_data = Export.exportContent();

        var content = this.editors['content'].getContent();
        var Export = new tinyExport(content, this.editors['content']);
        var content_data = Export.exportContent();

        var content_mobile = this.editors['content_mobile'].getContent();
        var Export = new tinyExport(content_mobile, this.editors['content_mobile']);
        var content_mobile_data = Export.exportContent();

        values.id = this.props.id;
        values.other = other;
        values.other_data = other_data;

        values.content = content;
        values.content_data = content_data;

        values.content_mobile = content_mobile;
        values.content_mobile_data = content_mobile_data;
        
        if(!data.subcategories.length){
            values.subcategory_id = 0;
        }
        var response = await fetchHelper.post('products/change', values);
        
        this.props.setBusy(false);

        if (response.status && values && Object.keys(values).length) {
            if (this.props.onSave) {
                this.props.onSave(values);
            }
            AlertyBox.message(response.data);
        } else {
            var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
            AlertyBox.message(m)
        }
    }

    setEditorInstance(id, editor) {
        this.editors[id] = editor;
    }

    render() {
        if (!this.props.data) return '';

        
        const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

        productConf.initialValues = this.props.data;

        // setovanje podkategorija u slucaju kada proizvod ima group id
        if(this.props.data.group_id){
            this.getSubCategories(this.props.data.group_id)
        }


        var refID = React.createRef();

        return (
            <>
                <form id="productform" onSubmit={handleSubmit((data) => {
                    if (this.state.allow_submit) {
                        this.setState({ allow_submit: false })
                        data = SubmitDataPickerValue(data, ['date_created']);
                        this.submit(data);
                    }
                })} className="redux-form" encType="multipart/form-data" >

                    <GigaTabs tab={0}>
                        {/* GiaTab title, heigth */}
                        <GigaTab title="Proizvod" /*height={400}*/>

                            {busy ? <div className="form-mask"></div> : ''}
                            <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                                <Cell width={1} className="grid-column">
                                    <div className="relative">
                                        <div className="separate-right"></div>
                                        {/* <Field {...productConf.fields.gallery} master={refID} id={this.props.product_id}/> */}
                                        <Field {...productConf.fields.vendor_id} />
                                        <Field {...productConf.fields.category_id} instance={productConf} controler={this} value={this.props.category_id} />
                                        <Field {...productConf.fields.group_id} instance={productConf} controler={this} value={this.props.groups} />
                                        <Field {...productConf.fields.subcategory_id} instance={productConf} controler={this} value={this.props.subcategories} />
                                        <Field {...productConf.fields.name} />
                                        <Field {...productConf.fields.brand_id}  instance={productConf} controler={this} value={this.props.brand_id} />
                                        <Field {...productConf.fields.energy}  instance={productConf} controler={this} value={this.props.energy_id} />
                                        <Field {...productConf.fields.warranty_id} instance={productConf} controler={this} value={this.props.warranty_id} />
                                        <Field {...productConf.fields.single_user_limitation} />
                                        <Field {...productConf.fields.statistic_sold} />
                                        <Field {...productConf.fields.statistic_visits} />
                                        <Field {...productConf.fields.statistic_reviews} />
                                        <Field {...productConf.fields.statistic_rating} />
                                        <Field {...productConf.fields.date_created} />
                                        <Field {...productConf.fields.promotion} />
                                        <Field {...productConf.fields.sale} />
                                        <Field {...productConf.fields.outlet} />
                                        <Field {...productConf.fields.status} />
                                        <Field {...productConf.fields.gigadrive} />
                                        <Field {...productConf.fields.online_buy} />
                                        <Field {...productConf.fields.recommendation} />
                                        <Field {...productConf.fields.description} />
                                        <Field {...productConf.fields.seo_title} />
                                        <Field {...productConf.fields.seo_description} />
                                    </div>
                                </Cell>
                                <Cell width={1} className="grid-column">
                                    <div className="relative">
                                        <div className="separate-right"></div>
                                        <Field {...productConf.fields.price_list} />
                                        {/* <Field {...productConf.fields.sticker} /> */}
                                        <Field {...productConf.fields.new_product_synonim_for_search} />
                                        <Field {...productConf.fields.search_synonyms} />
                                        <Field {...productConf.fields.other}
                                            instance={productConf}
                                            content_id={this.props.id}
                                            content={this.props.other}
                                            ref={this.editorRef}
                                            id="other"
                                            section="product"
                                            index={this}
                                            url="http://ozone.devgigatron.com/"
                                        />
                                    </div>
                                </Cell>
                            </Grid>

                        </GigaTab>
                        <GigaTab title="Sadrzaj">
                            <Grid columns={1} gap="30px">
                                <Cell width={1} className="grid-column">
                                    <div className="relative">
                                        {/* <div className="separate-right"></div> */}
                                        <Field {...productConf.fields.image} master={refID} index={this.props.index} />
                                        <Field {...productConf.fields.content}
                                            instance={productConf}
                                            content_id={this.props.id}
                                            content={this.props.content}
                                            ref={this.editorRef}
                                            id="content"
                                            section="product"
                                            index={this}
                                            url="http://ozone.devgigatron.com/" />
                                        <Field {...productConf.fields.content_mobile}
                                            instance={productConf}
                                            content_id={this.props.id}
                                            content={this.props.content}
                                            ref={this.editorRef}
                                            id="content_mobile"
                                            section="product"
                                            index={this}
                                            url="http://ozone.devgigatron.com/" />
                                    </div>
                                </Cell>
                            </Grid>
                        </GigaTab>
                        <GigaTab title="Galerija">
                            <Grid columns={1} gap="30px">
                                <Cell width={1} className="grid-column">
                                    <div className="relative">
                                        {/* <div className="separate-right"></div> */}
                                        <Field {...productConf.fields.gallery} master={refID} id={this.props.product_id} />
                                    </div>
                                </Cell>
                            </Grid>
                        </GigaTab>
                    </GigaTabs>
                    <div className="page-bottom-bar">
                        <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
                    </div>
                </form>
            </>
        );
    }
}


const selector = formValueSelector('productform');

const mapStateToProps = state => ({
    initialValues: productConf.initialValues,
    busy: state.forms.busy,
    id: selector(state, 'id'),
    category_id: selector(state, 'category_id'),
    groups: selector(state, 'groups'),
    subcategories: selector(state, 'subcategories'),
    warranty_id: selector(state, 'warranty_id'),
    brand_id: selector(state, 'brand_id')
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBusy,
            updateField: (field, data) => change("productform", field, data),
        },
        dispatch
    );

ProductForm = reduxForm({
    form: "productform",
    reduxAsyncValidate,
    enableReinitialize: true,
})(ProductForm);
ProductForm = connect(
    mapStateToProps, mapDispatchToProps
)(ProductForm);
export default ProductForm;