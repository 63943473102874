import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "./../../../helpers/fetchHelper";
import GigaTable from './../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import VouchersForm from "../../../forms/VouchersForm";
// import GridLayoutEditor from "../../../libraries/GridLayout/GridLayout";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import GigaTabs from "../../../libraries/GigaTabs";
import GigaTab from "../../../libraries/GigaTabs/GigaTab";

class Vouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_item:null,
      selected: null,
      selected2: null,
      active_item2:null,
      table: null,
      user_images:null,
      section:"users",
      detail: null
  
      
      
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
  }

  componentDidMount(){
    
  }

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_item:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_item:row,selected:row.id})
  }

  resetActive() {
    this.setState({active_item:null,selected:null})
  }




  getLog(data , filename){

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets : {'data' : ws} , SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb , {bookType : 'xlsx' , type: 'array'});
    const d = new Blob([excelBuffer] , {type : fileType});
    FileSaver.saveAs(d , filename + fileExtension);
  }
  dateLog() { 
    const cells = [
      
        {name:'Name',cell: 'product_id', width:200,fixed:false, truncate:true,className:'draggable'},
        {name:'Date Start',cell: 'date_created', width:140,fixed:false, truncate:true,editable:true,className:'draggable'},  
        {name:'Excel', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
            return row.id !== 1499709 ? <button onClick={  ()=>{ 
                var f = async()=>{
                    var response =  await   fetchHelper.get("vouchers/excel/" + row.date_created);
                
                    if(response.items){
                        this.getLog(response.items , 'ExcelData');
                    }
                }
              
                f();

          }}><i className="fa fa-file-excel-o" /></button> : ''
          }},
      ];
  
      const buttons = [
       
      ];
      
      return (<><div>
  
      </div>
        <div className="">
            {/* testing */}
            {/* <GridLayoutEditor/> */}
          <div >
            <GigaTable 
              cells={cells} 
              pk='id' 
              height={300} 
              buttons={buttons} 
              id='voucher-log' 
              db="gigatron_vip_vouchers"
              fetch="vouchers/logs"
              delete={true} 
              selected={this.state.selected2}//row.id that we get on click on btn 'Detalji', or on click in GigatableFooter up and down arrows.+key u renderFieldUploadImage
              //In GigaTable>in GigatableFooter 1.if this.props.selected&&this.props.run,it show arrows up and down 
              //2.On click on arrows it calls this.props.run which sets selected,and active_item 3.slected id makes row red in GigaTableRows(adds class selected);
              getInstance={this.getTableInstance}
              run={this.runRow}//called in GigaTableRows(in updateRow) when you edit input onEditCell, and in GigaTableFooter when you click arrows up and down
              resetActive={this.resetActive}//on page change and on delete
            />
          </div>
          {/* <VouchersForm detail={this.state.detail} data={this.state.active_item} onSave={this.onSave} index={this} /> */}
        </div>
      </>);
  }



  render() {

    const cells = [
      {name:'ID',cell: 'id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Barcode',cell: 'barcode',width:160, fixed:true, className:''},
      {name:'Contract ID',cell: 'contract_id', width:160,fixed:true, truncate:true, sort: true},
      {name:'Name',cell: 'product_id', width:200,fixed:false, truncate:true,className:'draggable'},
      {name:'Discount',cell: 'discount', width:100,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Store',cell: 'store', width:100,fixed:false,truncate:true,editable:true,className:'draggable'},
      {name:'Date Start',cell: 'start_date', width:140,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Date End',cell: 'end_date', width:140,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Detalji', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return row.id !== 1499709 ? <button onClick={()=>{ 
          this.setState({active_item:row,selected:row.id})
      }}><i className="fa fa-folder-open-o" /></button> : ''
      }},
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center', render:(row)=>{
        var value = parseInt(row.status); 
        return row.id !== 1499709 ? <button onClick={()=> this.table.updateRowCell(row,'status',value?0:1)}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button> : ''
      }},
    ];

    const buttons = [
      {title: 'Kreiraj nove', type: 'link' , link: '/admin/promotions/new'},
    ];
    
    return (<><div>

    </div>
      <div className="admin-home__main-section">
          {/* testing */}
          {/* <GridLayoutEditor/> */}
        <div className="table-wrapper">
            <GigaTabs>
                <GigaTab title="Lista">
                    <GigaTable 
                        cells={cells} 
                        pk='id' 
                        height={300} 
                        buttons={buttons} 
                        id='voucher-table' 
                        db="gigatron_vip_vouchers"
                        fetch="vouchers/list"
                        delete={true} 
                        selected={this.state.selected}//row.id that we get on click on btn 'Detalji', or on click in GigatableFooter up and down arrows.+key u renderFieldUploadImage
                        //In GigaTable>in GigatableFooter 1.if this.props.selected&&this.props.run,it show arrows up and down 
                        //2.On click on arrows it calls this.props.run which sets selected,and active_item 3.slected id makes row red in GigaTableRows(adds class selected);
                        getInstance={this.getTableInstance}
                        run={this.runRow}//called in GigaTableRows(in updateRow) when you edit input onEditCell, and in GigaTableFooter when you click arrows up and down
                        resetActive={this.resetActive}//on page change and on delete
                    />
                </GigaTab>
                <GigaTab title="Excel">
                    {this.dateLog()}
                </GigaTab>
          </GigaTabs>
        
        </div>
        <VouchersForm detail={this.state.detail} data={this.state.active_item} onSave={this.onSave} index={this} />
      </div>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Vouchers));
