import React, {Component} from 'react';

import GigaTabs from '../../GigaTabs';
import GigaTab from '../../GigaTabs/GigaTab';
import InsertNode from '../base/insertNode';
import Initialize from '../base/initialize';

export default class TableModal extends Component {


    constructor(props){
        super(props)
        
        this.state={
            width: '100%',
            height: 'auto',
            fontSize: 'inherit',
            fontFamily: 'inherit',
            cell: 2,
            row: 2,
            id: null,
        }

        this.set = this.set.bind(this);
        this.onChange = this.onChange.bind(this);

        this.ref = React.createRef();
    }

    componentDidMount(){
        this.get();
    }

    componentDidUpdate(prevProps){
        if(prevProps.element !== this.props.element){
            this.get();
        }
    }


    set(){

        if(this.props.insert === true){
            this.insert();
        } else {
            this.update();
        }

        this.props.editor.setState({modal:''});

        this.props.editor.registerChange();
    }




    tdClick(td){
        var ins = new Initialize();
        ins.initializeTableTd(td);
    }

    insert(){

        if(this.state.cell && this.state.row){

                var tableHolder = document.createElement('div');
                tableHolder.className = 'element-editor';
                tableHolder.dataset.type = 'table';
                tableHolder.dataset.stop = true;

                var table = document.createElement('table');
                table.classname = 'table-editor';

                table.style.width = this.state.width;
                table.style.height = this.state.height;
                table.style.fontSize = this.state.fontSize;
                table.style.fontFamily = this.state.fontFamily;

                var tbody = document.createElement('tbody');

                for(var a = 0; a < this.state.row; a++){
                    var tr = document.createElement('tr');
                   
                    for(var b = 0; b < this.state.cell; b++){
                        var td = document.createElement('td');
                        td.innerText = b;
                        tr.appendChild(td);
                    }

                    tbody.appendChild(tr);
                }
                table.appendChild(tbody);
                tableHolder.appendChild(table);
             
                this.props.editor.setState({modal:'',customModal:''});

                var div =document.createElement('div');
                div.appendChild(tableHolder);

                var range = this.props.editor.state.range;

                if(range){
                    range.insertNode(tableHolder);


                    // document.execCommand('insertHTML',true, div.innerHTML);
                    // console.log('insertHTML', div.innerHTML)


                    var init = new Initialize();
                    init.initializeTables(this.props.editor)
                }
        }
    }


    update(){
        var el = this.props.table.querySelector('tbody');

        el.style.width = this.state.width;
        el.style.height = this.state.height;
        el.style.fontSize = this.state.fontSize;
        el.style.fontFamily = this.state.fontFamily;

        var rows = el.querySelectorAll('tr');
        
        var rowsCount = rows.length;
        var celsCount = rows[0].querySelectorAll('td').length;
        var targetRows = parseInt(this.state.row);
        var targetCels = parseInt(this.state.cell);

        if(rowsCount > targetRows){
            this.removeRows(el,rowsCount,targetRows);
        }

        if(celsCount > targetCels){
            this.removeCels(el, celsCount, targetCels);
        }
        
        if(targetRows >rowsCount){
            this.appendRow(el, targetRows, rowsCount, celsCount);
        }

        if(targetCels > celsCount){
            this.appendCels(el, targetRows, targetCels, celsCount);
        }
    }


    removeRows(body, rowsCount, targetRows){

        var rows = body.querySelectorAll('tr');
        
        for(var i = 0; i < rowsCount; i++){
            if(targetRows <= i){
                rows[i].remove();
            }
        }
    }


    removeCels(body, celsCount, targetCels){

        var rows = body.querySelectorAll('tr');

        for(var i = 0; i < rows.length; i++){
            var row = rows[i];
            var cells = row.querySelectorAll('td');
            for(var y = 0; y < celsCount; y++){
                var cell = cells[y];
                if(y >= targetCels){
                    cell.remove();
                }
            }
        }
    }

    appendRow(body, targetRows, rowsCount, celsCount){
        var initialize = new Initialize();
        for(var i = rowsCount; i < targetRows; i++){
            var row = document.createElement('tr');
            for(var x = 0; x < celsCount; x++){
                var td = document.createElement('td');
                initialize.initializeTableTd(td);
                row.appendChild(td);
            }
            body.appendChild(row);
        }
    }

    appendCels(body, targetRows, targetCels, celsCount){
        var initialize = new Initialize();
        var rows = body.querySelectorAll('tr');
        for(var i = 0; i < targetRows; i++){
            var row = rows[i];
            for(var y = celsCount; y < targetCels; y++){
                var td = document.createElement('td');
                initialize.initializeTableTd(td);
                row.appendChild(td);
            }
        }
    }


    get(){

        var el = this.props.element.current;
   
        if(el){
            var width = el.style.width ? el.style.width : this.state.width,
            height = el.style.height ? el.style.height : this.state.height,
            fontSize = el.style.fontSize ? el.style.fontSize : this.state.fontSize,
            fontFamily = el.style.fontFamily ? el.style.fontFamily : this.state.fontFamily;

            var cell = 0;
            var row = 0;

            var rows = el.querySelectorAll('tr');
            if(rows && rows.length){
                row = rows.length;
                var r = rows[0];

                var cells = r.querySelectorAll('td');
                if(cells && cells.length){
                    cell = cells.length;
                }
            }

            this.setState({
                width: width,
                height: height,
                fontSize: fontSize,
                fontFamily: fontFamily,
                cell: cell,
                row: row,
            })
        }
    }

    onChange(e){
        this.setState({[e.currentTarget.name]:e.currentTarget.value});
    }


    render(){
       return (<>
        <div className="modal-content" ref={this.ref}>
            <GigaTabs tab={0}>
                <GigaTab title="Properties">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Width</label>
                                        <input name="width" className="small" onChange={this.onChange} value={this.state.width}/>
                                    </div>
                                </td>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Height</label>
                                        <input name="height" className="small" onChange={this.onChange} value={this.state.height} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Font Size</label>
                                        <input name="fontSize" className="small" onChange={this.onChange} value={this.state.fontSize} />
                                    </div>
                                </td>
                                <td style={{width:'50%'}}>
                                
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:'50%'}} colSpan="2">
                                    <div className="ge-form-group">
                                        <label>Font Family</label>
                                        <input name="fontFamily" className="largest" onChange={this.onChange} value={this.state.fontFamily} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GigaTab>
                <GigaTab title="Rows">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Cell</label>
                                        <input name="cell" className="small" onChange={this.onChange} value={this.state.cell}/>
                                    </div>
                                </td>
                                <td style={{width:'50%'}}>
                                    <div className="ge-form-group">
                                        <label>Row</label>
                                        <input name="row" className="small" onChange={this.onChange} value={this.state.row} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GigaTab>
            </GigaTabs>
        </div>
        <div className="modal-foot">
                <button onClick={this.set} className="primary-btn">Primeni</button>
                <button onClick={() => this.props.editor.setState({modal:''})} >Zatvori</button>
        </div>
    </>)
    }

}