import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { Link } from 'react-router-dom';

class Buttons extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };


  }

  componentDidMount(){
   
  }


  button(button,i){
      switch(button.type){
        case 'link':
          let link = button.search ? {pathname:button.link,search:button.search} : button.link;
          let target = button.target ? "_blank" : null;
            return <Link target={target} className={"giga-table-button"+(button.className!==undefined?' '+button.className:'')} key={i} to=
            {link}>{button.title}</Link>
        case 'button':
            return <button className={"giga-table-button"+(button.className!==undefined?' '+button.className:'')} key={i} onClick={button.onClick}>{button.title}</button>
        default:
            return '';
          
      }
  }

  render() {

    return (
        <div className="giga-table-buttons">
            {this.props.title?<h4>{this.props.title}</h4>:''}
            {this.props.buttons.map((button,i)=>{
                return this.button(button,i);
            })}
        </div>
    );
  }
  // }
}


const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default 
    withRouter(connect(
      mapStateToProps,
      mapDispatchToProps
  )(Buttons));