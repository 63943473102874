import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';

export default class Bold extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){
        e.stopPropagation();
        e.preventDefault();

        // var ins = new InsertNode(this.props.editor);
        // ins.__FORMAT('b');

        // // var ins = new InsertNode(this.props.editor);
        // // ins.style('fontWeight', 'bold','');
        // this.props.editor.registerChange();

        document.execCommand('bold');
        this.props.editor.registerChange();

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"Bold-btn-"+this.props.id} onMouseDown={this.action}><i className="fa fa-bold" aria-hidden="true" ></i></button>
    }

}