import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';

export default class Paragraph extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){
        // var ins = new InsertNode(this.props.editor);
        // ins.wrap('p');
        // this.props.editor.registerChange();

        e.stopPropagation();
        e.preventDefault();

        document.execCommand('formatBlock',true,'p');
        this.props.editor.registerChange();

        return false;

    }

    render(){
        return <button className="get-btn selector-ga" title="Paragraph" id={"Paragraph-btn-"+this.props.id} onMouseDown={this.action}><i className="fa fa-paragraph" aria-hidden="true" ></i></button>
    }

}