import React, { useState }  from 'react';
import { addPlugin } from '../../helpers/addPlugin';
import View from './view';
import Form from './form';

import {ReactComponent as Icon} from './icon.svg';
import { renderToString } from 'react-dom/server';
import { date_sql_to_rs } from '../../../../../helpers/dateHelper';

export const tabPlugin = (editor, node) => {

   

    var pluginName      = 'tab_plugin', 
        pluginTitle     = 'Tabs', 
        pluginFolder    = 'tabPlugin',
        pluginIcon      = ''

    var initialData = {
      tabNums: 2,
      tabActive: 1,
      tabRadius: 0,
      tabColor: '#333333',
      tabBackgroundColor: '#EEEEEE',
      tabBorderColor: '#CCCCCC',
      tabColorActive: '#000000',
      tabBackgroundColorActive: '#CCCCCC',
      tabBorderColorActive: '#000000',
      tabLineColor: '#CCCCCC',
      tabIconPosition: 'left',
      tabFontSize: '13px',
      tabHeadBackground: '#fff',
      tabPaddingLeft: 5,
      tabPaddingRight: 5,
      tabPaddingBottom: 5,
      tabContentColor: '#000',
      tabContentBackground: '#fff',
      tabContentPadding: '0px',
      tabWidth: 'auto',
      tabPaddingTop: 5,
      tabs: '',
      tabs_images: '',
      contents: '',
    }

    var icon = renderToString(<Icon />);

    addPlugin(editor, node, Form, View, initialData, pluginName, pluginTitle, pluginFolder, icon);
};