import React, { useState, useEffect } from "react";
import { useNode } from "@craftjs/core";
import { TabsSettings } from "./TabsSettings";
import { Text } from '../Text';

export const Tabs = ({ }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    !selected && setEditable(false);
  }, [selected]);

  const selection = () => {
    if(selected){
        setEditable(true);
    }
  }

  return (
    <div  onClick={(e) => selection()} ref={(ref) => connect(drag(ref)) }>
        <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: 'stretch',
            flexShrink: 0,
            marginTop: '20px',
        }}
        >
        <span 
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                overflow: 'auto',
            }}
        >
            <span
            style={{
           
            }}>
              Tab 1
            </span>
            <span
             style={{
              
            }}
            >
              Tab 2
            </span>
        </span>
        </div>
    </div>
  );
};
Tabs.craft = {
  displayName: "Tabs",
  props: {
   
  },
  related: {
    toolbar: TabsSettings,
  },
};
