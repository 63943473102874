import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { Link } from 'react-router-dom';

class Buttons extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };


  }

  componentDidMount(){
   
  }


  button(button,i){
      switch(button.type){
        case 'button':
            return <button name={button.name} data-index={button.dataIndex} className={"txt-position-button"+(button.className!==undefined?' '+button.className:'')} key={i} onClick={button.onClick}>{button.title}</button>
        default:
            return '';
          
      }
  }

  render() {

    return (
        <div className={`form-group ${this.props.className ? this.props.className : ""}`}>
            {this.props.title?<label>{this.props.title}</label>:''}
            {this.props.buttons.map((button,i)=>{
                return this.button(button,i);
            })}
        </div>
    );
  }
  // }
}

  export default Buttons;