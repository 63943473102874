import $ from 'jquery';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderDatePicker,
 

} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidatePhone, 
    ValidateDate,
    ValidateString,
    
} from '../helpers/validation';
import AlertyBox from "../libraries/AletyBox";
import { fetchHelper } from "../helpers/fetchHelper";
import { reducer , change as changeFieldValue, change } from "redux-form";
import { 
    server_img_host,
    page_icon_path,
    page_icon_mobile_path, page_thumb_path, page_image_path
 } from '../config/vars';

export const backofficeUsersConf = {
    fields: {
        'id' : {
            name: 'id',
            type: 'text',
            component: renderFieldInput,
            label: 'ID',
            validate:[ValidateRequire,ValidateAlphaNumeric],
            warn: [],
            size: 'small',
            value: '',
            readonly:true,
        },
        'username' : {
            name: 'username',
            type: 'text',
            component: renderFieldInput,
            label: 'Korisničko ime',
            validate:[ValidateRequire,ValidateString],
            warn: [],
            size: 'medium',
            value: '', 
        }, 
        'password' : {
            name: 'password',
            type: 'password',
            component: renderFieldInput,
            label: 'Lozinka',
            //validate empty
            validate:[],
            size: 'medium',
            value: '',
        },
        'name' : {
            name: 'name',
            type: 'text',
            component: renderFieldInput,
            validate:[ValidateRequire],
            label: 'Ime i prezime',
            validate:[ValidateRequire,ValidateAlphaNumeric],
            warn: [],
            size: 'medium',
            value: '', 
        },
        'company' : {
            name: 'company',
            type: 'text',
            component: renderFieldInput,
            label: 'Kompanija',
            validate:[ValidateAlphaNumeric],
            warn: [],
            size: 'medium', 
            value: '',
        }, 
        'department' : {
            name: 'department',
            type: 'text',
            component: renderFieldInput,
            label: 'Odeljenje',
            validate:[ValidateAlphaNumeric],
            warn: [],
            size: 'medium',
            value: '', 
        }, 
        'city' : {
            name: 'city',
            component: renderFieldInput,
            label: 'Grad',
            validate:[ValidateAddress],
            warn: [],
            size: 'medium',
            value: '',
        },
        'email' : {
            name: 'email',
            type: 'text',
            component: renderFieldInput,
            label: 'E-mail',
            validate:[ValidateRequire,ValidateEmail],
            warn: [],
            size: 'medium',
            value: '',
        },
        'phone_gsm' : {
            name: 'phone_gsm',
            component: renderFieldInput,
            label: 'Tel',
            validate:[ValidatePhone],   
            warn: [],
            size: 'medium',
            value: '',
            
        }, 
        'date_created' : {
            name: 'date_created',
            component: renderDatePicker,
            validate:[ValidateDate], 
            warn: [],
            label: 'Datum registracije',
            size: 'medium',
            value: '', 
            readonly:true,
            disabled:true,    
        },
        'status' : {
            name: 'status',
            component: renderFieldSelect,
            label: 'Status',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [],
            size: 'medium',
            value: '',
            options:[
                {text:'Neaktivan',value:0},
                {text:'Aktivan',value:1},
                
            ], 

        },

    },
    initialValues: {}
}