import React from 'react';
import { Link } from 'react-router-dom'

import View from './../plugins/counterPlugin/view.js';
import {default as TabView} from './../plugins/tabPlugin/view.js';
import {default as ProductView} from './../plugins/productsPlugin/view.js';
import { b64DecodeUnicode } from './base64.js';

export class TinyBilderToReact  {

    constructor(json){

       this.json = JSON.parse(json);
       this.nodes = [];
       if(this.json){
            this.nodes = this.json.nodes;
       }

    }



    generate(){
        const { nodes } = this;
        if(nodes && nodes.length){
            var collections =  nodes.map((node,i)=>{
                return this.nodeTypeRender(node, i);
            });
        }

        return {
            html: collections,
            paint: this.json.paint ? this.json.paint : {},
            style: this.json.style ? this.json.style: {},
            id: this.json.id ? this.json.id : ''
        };
    }


    nodeTypeRender(node, key){
       
            console.log(node)
            var a = node.attributes;

            if(typeof node === 'string'){
                return node;
            }

            // Kreiranje samo dozvoljenih atributa
            var attr = {};
            if(a){
                if(a.className) attr.className  = a.className;
                if(a.disabled)  attr.disabled   = a.disabled;
                if(a.height)    attr.height     = a.height;
                if(a.to)        attr.to         = a.to;
                if(a.readOnly)  attr.readOnly   = a.readOnly;
                if(a.src)       attr.src        = a.src;
                if(a.style)     attr.style      = a.style;
            }

            var dataset = a.dataset ? a.dataset : {}


            var dts = {};
            Object.keys(dataset).map((k)=>{
                var v = dataset[k];
                if(v){
                    dts[k] = v;
                }
            });

            // Kreiranje samo dozvoljenih stilova
            var style = {};
            var allowedStyle = {
                'margin': 1,
                'padding': 1,
                'color': 1,
                'textDecoration':1,
                'textTransform':1,
                'textAlign':1,
                'verticalAlign':1,
                'horizontalAlign':1,
                'fontSize':1,
                'fontFamily':1,
                'fontWeight':1,
                'backgroundColor':1,
                'fontStyle':1,
                'border':1,
                'borderRadius':1,
                'transform':1,
                'transition':1,
                'overflow':1,
                'overflowY':1,
                'overflowX':1,
                'width':1,
                'height':1,
                'float':1
                
            }
            if(attr.style !== undefined)
            Object.keys(attr.style).map((k)=>{
                var v = a.style[k];
                if(v && allowedStyle[k] !== undefined){
                    style[k] = v;
                }
            });

            dataset = dts;
            attr.style = style;

            var isTemplate = false;
            var template = null;

            Object.keys(dataset).map((d,i)=>{

                if(d === 'controller' && dataset[d] === 'plugin'){
                    isTemplate = true;
                    template = dataset.plugin;
                }
            });

            if(isTemplate && template){
                var tt = null;
                var children = this.nodeValues(node.content,key);
                if(!children){
                    children = {};
                }

                var data = dataset && dataset.json !== undefined ? JSON.parse( b64DecodeUnicode(dataset.json) ) : {};
                switch(template){
                    case 'counterPlugin':
                        tt = <View data={data} folder={template} key={key} />
                    break;
                    case 'tabPlugin':
                        tt = <TabView data={data} folder={template} key={key} />
                    break;
                    case 'productsPlugin':
                        tt = <ProductView data={data} folder={template} key={key} />
                    break;
                }

                if(tt){
                    
                    return tt;
                }
            }

           

            if(typeof node !== 'string'){
                switch(node.type){
                    case 'P':
                        return <p key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </p>
                    break;
                    case 'style':
                    case 'STYLE':
                        console.log('style',node)
                    break;
                    case 'FIGURE':
                        return <figure key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </figure>
                    break;
                    case 'IMG':
                        return <img key={key} {...attr} {...dataset} src={node.content} />
                    break;
                    case 'FIGCAPTION':
                        return <figcaption key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </figcaption>
                    break;
                    case 'SPAN':
                        return <span key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </span>
                    break;
                    case 'SUB':
                        return <sub key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </sub>
                    break;
                    case 'SUP':
                        return <sup key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </sup>
                    break;
                    case 'OL':
                        return <ol key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </ol>
                    break;
                    case 'UL':
                        return <ul key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </ul>
                    break;
                    case 'LI':
                        return <li key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </li>
                    break;
                    case 'TABLE':
                        return <table key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </table>
                    break;
                    case 'THEAD':
                        return <thead key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </thead>
                    break;
                    case 'TBODY':
                        return <tbody key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </tbody>
                    break;
                    case 'TR':
                        return <tr key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </tr>
                    break;
                    case 'TD':
                        return <td key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </td>
                    break;
                    case 'H1':
                        return <h1 key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </h1>
                    break;
                    case 'H2':
                        return <h2 key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </h2>
                    break;
                    case 'H3':
                        return <h3 key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </h3>
                    break;
                    case 'H4':
                        return <h4 key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </h4>
                    break;
                    case 'H5':
                        return <h5 key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </h5>
                    break;
                    case 'H6':
                        return <h6 key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </h6>
                    break;
                    case 'B':
                        return <b key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </b>
                    break;
                    case 'U':
                        return <u key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </u>
                    break;
                    case 'I':
                        return <i key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </i>
                    break;
                    case 'BR':
                        return <br key={key} />
                    break;
                    case 'DIV':
                        return <div key={key} {...attr} {...dataset}>
                            {this.nodeValues(node.content,key)}
                        </div>
                    break;
                    case 'A':
                        if(attr.to && attr.to.indexOf('/')=== 0){
                            return <Link key={key} {...attr} {...dataset} to={attr.to}>
                                {this.nodeValues(node.content,key)}
                            </Link>
                        } 
                        else {
                            return <a key={key} {...attr} {...dataset} href={attr.to} target="_blank">
                                {this.nodeValues(node.content,key)}
                            </a>
                        }
                    break;
                }
            } else {
                return node;
            }

        
    }

    nodeValues(content,key){
        this.keyIter++;
        if(Array.isArray(content)){
            return content.map((c,i)=>{
                return this.nodeTypeRender(c,this.keyIter+'-'+i);
            })
        } else {
            if(typeof content === 'object'){
                return this.nodeTypeRender(content,this.keyIter+'-'+key+'-'+content.type)
            } else {
                return content ? content : <>&nbsp;</>;
            }
            
        }
    }



}