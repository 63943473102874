import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";

class GigaTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
        
    };

   
  }

  componentDidMount(){
   
  }

  render() {

    return (
        <div className={"giga-tab-view-body"+(this.props.height?' view-body-height':'')} style={{height:(this.props.height?this.props.height:'auto')}}>
            {this.props.children}
        </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user,
  busy: state.forms.busy
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default 
    withRouter(connect(
      mapStateToProps,
      mapDispatchToProps
  )(GigaTab));