import React, { Component, useReducer } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { reduxAsyncValidate, reduxReduceVaues } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";

import { fetchHelper } from '../helpers/fetchHelper';
import AlertyBox from '../libraries/AletyBox';
import { setBusy } from '../modules/forms';
import { slidersConf } from "./slidersConf";

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';

import './../assets/css/form.scss';
import { SubmitDataPickerValue } from "../helpers/dateHelper";

  
class SlidersForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            success: null,
            message: null,
            busy: false,
            rnd: 0,
            allow_submit: false,
        };

    }

    componentDidMount() {
        slidersConf.index = this;
    }

    componentDidUpdate(prevProps) {
        if (this.props.data) {
            reduxAsyncValidate(this.props.data, this.props);
        }
    }




    async submit(data) {

        this.props.setBusy(true);
        var values = reduxReduceVaues(slidersConf.fields, data);
    
        values.section = this.props.data.section;  
        var response = await fetchHelper.post(`sliders/change`, values);
   
        this.props.setBusy(false);

        if (response.status && values && Object.keys(values).length) {
            if (this.props.onSave) {
                this.props.onSave(values,response);
            }
            AlertyBox.message(response.data);
        } else {
            var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
            AlertyBox.message(m)
        }
        return true;
    }



    render() {

        if (!this.props.data) return '';

        const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

      
        // remove null, set empty string
        slidersConf.initialValues = this.props.data;

        var refID = React.createRef();


        return (

            <>
                <form id="slidersform" autoComplete="off" onSubmit={handleSubmit((data) => {
                    if (this.state.allow_submit) {
                        this.setState({ allow_submit: false })
                        data = SubmitDataPickerValue(data, ['start_date', 'end_date']);
                        // console.log(data);
                        this.submit(data);
                    }
                })} className="redux-form" encType="multipart/form-data" >
                    {busy ? <div className="form-mask"></div> : ''}
                    <GigaTabs tab={0}>

                        <GigaTab title="Osnovni podaci" /*height={400}*/>

                            <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                                <Cell className="grid-column">
                                    <div className="relative">
                                        <div className="separate-right"></div>
                                        <Field {...slidersConf.fields.id} ref={refID} />
                                        <Field {...slidersConf.fields.title} /> 
                                        <Field {...slidersConf.fields.description} />
                                        <Field {...slidersConf.fields.start_date} content_id={this.props.id} />
                                        <Field {...slidersConf.fields.end_date} content_id={this.props.id} />
                                        <Field {...slidersConf.fields.img_name} /> 
                                    </div>
                                </Cell>
                                <Cell className="grid-column">
                                    <Field {...slidersConf.fields.status} />
                                </Cell>
                            </Grid>

                        </GigaTab>
                        <GigaTab title="Galerija">
                            <Grid gap="30px"  columns={this.props.data.master_id ?"repeat(auto-fit, minmax(500px, 1fr))":"minmax(200px, 700px)"}>
                                <Cell className="grid-column">
                                    <div className="relative">
                                    
                                    <Field {...slidersConf.fields.gallery} formData={this.props.formData} onSaveItem={this.props.onSave} master={refID} id={this.props.id}/>
                                    </div>
                                </Cell>
                            </Grid>
                        </GigaTab>

                    </GigaTabs>
                    <div className="page-bottom-bar">
                        <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
                    </div>
                </form>
            </>
        );
    }
}

const selector = formValueSelector('slidersform');

const mapStateToProps = state => ({
    initialValues: slidersConf.initialValues,
    busy: state.forms.busy,
    id: selector(state, 'id'),
    formData: selector(state,'title','description','start_date','end_date','img_name','status')
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBusy,
            updateField: (field, data) => change("slidersform", field, data),
        },
        dispatch
    );

    SlidersForm = reduxForm({
    form: "slidersform",
    reduxAsyncValidate,
    enableReinitialize: true,
})(SlidersForm);
SlidersForm = connect(
    mapStateToProps, mapDispatchToProps
)(SlidersForm);
export default SlidersForm;
// export default connect(mapStateToProps, mapDispatchToProps)(PagesForm);