import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "../../../helpers/fetchHelper";
import GigaTable from '../../../libraries/GigaTable';
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import BackofficePagesForm from "../../../forms/BackofficePagesForm"; 

class BackofficePages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_user:null,
      selected: null,
      table: null,
      section:"backoffice_pages",

      create_new_page:false,
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);
    this.addNew=this.addNew.bind(this);
  }
 
  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setRowData(row);
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_user:row,selected:row.id})
  }
 



  addNew(){
    // console.log("otvori formu")
    this.setState({ 
      active_user: null, 
      selected: null,
      create_new_page:true, 
    });
  }

  resetActive() {
    // console.log("resetuje sve")
    this.setState({
      active_user:null,
      selected:null,
      create_new_page:false,
    })
  }
  setRowData(row){
    // console.log("Setuje aktivan row")
    this.setState({
      active_user:row,
      selected:row.id,
      create_new_page:false,
    })
  }
  
  
  render() {

    const cells = [
      {name:'ID',cell: 'id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true}, 
      {name:'Parent id',cell: 'parent_id', width:100,fixed:true,editable:true,truncate:true,sort:true},
      {name:'Positon',cell: 'position', width:100,fixed:true,editable:true,truncate:true,sort:true},
      {name:'Naziv stranice',cell: 'title', width:200,fixed:true,editable:true,truncate:true,className:'giga-td-last-fixed',sort:true},
      {name:'Slug',cell: 'slug', width:300,editable:true,truncate:true,sort:true},
      {name:'Icon',cell: 'icon', width:150,fixed:false,editable:true,truncate:true,sort:true},
      {name:'View(nav helper)',cell: 'view', width:150,fixed:false,editable:true,truncate:true,sort:true},
      {name:'Nav',cell: 'nav', width:100,fixed:false,editable:true,truncate:true,sort:true},
      {name:'Opis',cell: 'description', width:100,fixed:false,editable:true,truncate:true,sort:true},
      {name:'Detalji', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return  <button onClick={()=>{ 
          this.setRowData(row);
      }}><i className="fa fa-folder-open-o" /></button> 
      }},
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center', render:(row)=>{ 
        var value = parseInt(row.status); 
        return  <button onClick={()=> this.table.updateRowCell(row,'status',value?0:1)}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button>  
      }},
    ];

    const buttons = [
      {title: 'Nova stranica', type: 'button' , onClick: this.addNew},
    ];
    // {console.log(this.state.create_new_page)}
    return (<><div>

    </div>
      <div className="admin-home__main-section"> 
        <div className="table-wrapper">
          <GigaTable 
            cells={cells} 
            pk='id' 
            height={300} 
            buttons={buttons}
            id='backoffice-pages' 
            db="backoffice_navigation" 
            fetch="backoffice_pages"
            delete={true} 
            selected={this.state.selected}
            getInstance={this.getTableInstance}
            run={this.runRow}
            resetActive={this.resetActive}
          />
        </div>
        <BackofficePagesForm create_new_page={this.state.create_new_page}  data={this.state.active_user}  onSave={this.onSave} index={this} />
      </div>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(BackofficePages));
