import React from 'react';
import { b64DecodeUnicode } from '../../helpers/base64';
import { TinyBilderToReact } from '../../helpers/TinyBilderToReact';
import tinyExport  from '../../helpers/tinyExport';


class Form extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        view: <div className="preview-loading">Loading...</div>,
      };

     
    }


    componentDidMount(){
        const html = (this.props.editor.getContent());
        const exp = new tinyExport(html, this.props.editor);
        const data = exp.exportContent();
        if(data){
            const json = b64DecodeUnicode(data);
            var builder = new TinyBilderToReact(json);
            const result =  builder.generate();
      
            if(result && result.html){
                var children = (result.html);
            
                this.setState({view:children})
            }
        }
    }
   

    onChange(e,s){
       
    }


    render(){

        const { data } = this.props;

        return (<div className="preview">
            {this.state.view}
        </div>)
    }
}

export default Form;