import $ from 'jquery';

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderGigaEditor,
    renderDatePicker,
    renderFieldColorPicker,
 

} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidateFileImage,
    ValidateDate,
    ValidateString,
    
} from '../helpers/validation';
import AlertyBox from "../libraries/AletyBox";
import { fetchHelper } from "../helpers/fetchHelper";
import { reducer , change as changeFieldValue, change } from "redux-form";
import { 
    server_img_host,
    page_icon_path,
    page_icon_mobile_path, page_thumb_path, page_image_path
 } from './../config/vars';


export const stickersConf = {
   fields:{
    'id' : {
        name: 'id',
        type: 'text',
        component: renderFieldInput,
        label: 'ID',
        size: 'small',
        value: '1',
        readonly:true,
    },
    'promocode_id' : {
        name: 'promocode_id',
        type: 'text',
        component: renderFieldInput,
        label: 'Promokod ID',
        size: 'medium',
        value: '',
        readonly:false,
    },
    'name' : {
        name: 'name',
        type: 'text',
        component: renderFieldInput,
        validate:[ValidateRequire],
        label: 'Naziv',
        size: 'large',
        value: '',
        maxLength:"60",
    },
    'status' : {
        name: 'status',
        component: renderFieldSelect,
        label: 'Status',
        validate:[ValidateRequire,ValidateNumeric],
        warn: WarnNumeric,
        size: 'medium',
        value: '',
        options:[
            {text:'Neaktivan',value:0},
            {text:'Aktivan',value:1},
            
        ]
    },
    'alias' : {
        name: 'alias',
        type: 'text',
        component: renderFieldInput,
        validate:[ValidateRequire],
        label: 'Alias',
        size: 'large',
        value: '',
        maxLength:"400",
    },
    'discount' : {
        name: 'discount',
        type: 'text',
        component: renderFieldInput,
        validate:[ValidateRequire],
        label: 'Popust',
        size: 'medium',
        value: 0.00,
        maxLength:"6",
    },
    'start_date' : {
        name: 'start_date',
        component: renderDatePicker,
        label: 'Početak prikazivanja',
        // validate:[ValidateDate],
        size: 'medium',
        value: '', 
        readonly:true,                 
    },
    'end_date' : {
        name: 'end_date',
        component: renderDatePicker,
        label: 'Kraj prikazivanja',
        // validate:[ValidateDate],
        size: 'medium',
        value: '',  
        readonly:true,
              
    },
    'url' : {
        name: 'url',
        type: 'text',
        component: renderFieldInput,
        validate:[],
        label: 'Link',
        size: 'large',
        value: '',
        maxLength:"800",
    },
    'published' : {
        name: 'published',
        component: renderFieldSelect,
        label: 'Objavljeno',
        validate:[ValidateRequire,ValidateNumeric],
        warn: WarnNumeric,
        size: 'medium',
        value: '',
        options:[
            {text:'Ne',value:0},
            {text:'Da',value:1},
            
        ]
    },
    'type_id' : {
        name: 'type_id',
        component: renderFieldSelect,
        label: 'Tip',
        validate:[ValidateRequire,ValidateNumeric],
        warn: WarnNumeric,
        size: 'medium',
        value: '',
        options:[
            {text:'Običan',value:1},
            {text:'Popust',value:2},
            {text:'Slika',value:3},
            
        ]
    },
    'color_txt' : {
        name: 'color_txt',
        component: renderFieldColorPicker,
        label: 'Boja teksta',
        enableAlpha: false,
        validate:[],
        warn: null,
        value: '',
    },
    'color_bg' : {
        name: 'color_bg',
        component: renderFieldColorPicker,
        label: 'Boja pozadine',
        enableAlpha: false,
        validate:[],
        warn: null,
        value: '',
    },
    'priority' : {
        name: 'priority',
        component: renderFieldSelect,
        label: 'Prioritet',
        validate:[ValidateRequire,ValidateNumeric],
        warn: WarnNumeric,
        size: 'medium',
        value: '',
        options:[
            {text:'Nizak',value:0},
            {text:'Visok',value:1},
            
        ]
    },
    'image' : {
        name: 'image',
        component: renderFieldUploadImage,
        label: 'Sticker',
        validate:[(file)=>ValidateFileImage(file,{
            size:1,
            // width: 300,
            // height: 300,
            exactWidth: false,
            exactHeight: false,
            accept: ['jpg','jpeg','png'],
            files: 1
        })],
        warn: [],
        value: '',
        url: 'https://img.gigatron.rs/img/stickers/',
        img_name: '',
        place: {
            width: 180,
            height: 180
        },
        maxLength:"100", 
        remove: (e,index,name) => {
            if(e.current){
                AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                    if(result.isConfirmed){
                        let id=e.current.value;
                        var response = await index.removeImage(id);
                        AlertyBox.message({status:true,message:'Slika je uklonjena'});
                    }
                })
            }
        } 
    },
    'icon' : {
        name: 'icon',
        component: renderFieldUploadImage,
        label: 'Widget',
        validate:[(file)=>ValidateFileImage(file,{
            size:1,
            // width: 300,
            // height: 300,
            exactWidth: false,
            exactHeight: false,
            accept: ['jpg','jpeg','png'],
            files: 1
        })],
        warn: [],
        value: '',
        url: 'https://img.gigatron.rs/img/stickers/',
        img_name: '',
        place: {
            width: 180,
            height: 180
        },
        maxLength:"100", 
        remove: (e,index,name) => {
            if(e.current){
                AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                    if(result.isConfirmed){
                        let id=e.current.value;
                        var response = await index.removeIcon(id);
                        AlertyBox.message({status:true,message:'Slika je uklonjena'});
                    }
                })
            }
        } 
    },
    'image_mobile' : {
        name: 'image_mobile',
        component: renderFieldUploadImage,
        label: 'Slika za MOB',
        validate:[(file)=>ValidateFileImage(file,{
            size:1,
            width: 300,
            height: 300,
            exactWidth: false,
            exactHeight: false,
            accept: ['jpg','jpeg','png'],
            files: 1
        })],
        value: '',
        url: server_img_host + page_thumb_path,
        img_name: '',
        place: {
            width: 180,
            height: 180
        },
        maxLength:"100", 
        remove: (e,index,name) => {
            if(e.current){
                AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                    if(result.isConfirmed){
                        let id=e.current.value;
                        var response = await fetchHelper.delete('pages/remove_image/'+id+"/"+name);
                        AlertyBox.message(response.data);
                        if(response.status && response.data.status){
                            index.setState({active_page:response.data.data});
                        }
                    }
                })
            }
        } 
    },

    },

    initialValues: {}
}