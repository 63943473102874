import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { fetchHelper } from "./../../helpers/fetchHelper";

class GigaTableRows extends Component {
  constructor(props) {
    super(props);

    this.state = {
       active: {},
       select_all: false,
       edit: null,
       edit_cell: null,
       loading: false,
    };

    this.rowRef = React.createRef();

    this.editCell = this.editCell.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }


  componentDidMount(){
      this.props.index.setState({rowsObj:this});
  }
  componentDidUpdate(prevProps){
    if(this.props.uncheckAndClearState === true){ 
        // console.log("ROWS")
        // console.log(this.props.uncheckAndClearState,"!=",prevProps.uncheckAndClearState)
        this.setState({active:{},edit:null,edit_cell:null})
        this.props.index.setState({uncheckAndClearState:false})
    }


      if(this.props.selectAll !== this.state.select_all){
          this.setState({select_all: this.props.selectAll},()=>{
                var active = {};
                if(this.state.select_all){
                    this.props.rows.map(row=>{
                        active[row[this.props.pk]] = row;
                    })
                } 
                this.setState({active:active});
          })
      }
  }

  rowClick(e, row){
      var pk = row[this.props.pk];
      var active = this.state.active;
      if(active[pk]===undefined){
          active[pk] = row;
      } else {
          delete active[pk];
      }

      this.setState({active:active},()=>{
          
      });
  }


  editCell(e,c,id){
    if(c.cell=="custom1" || c.cell=="custom2"){
        return false;
    }

    if(this.state.edit === null){
        this.setState({edit:id,edit_cell:c});
    }
  }

  onValueChange(e,value_old){
      var id = this.state.edit;
      var cell = this.state.edit_cell;
      var value = e.currentTarget.value;
      var code = e.keyCode;

      if(code === 13){
          this.setState({edit:null,edit_cell:null},()=>{
              if(value_old !== value)
              this.updateCellValue(id,cell,value);
          });
      }
  }

  cellValue(c,id,value){
    if(c.onBefore !== undefined){
        value = c.onBefore(value);
    }
    var cell = c.cell;
    if(this.state.edit === id && this.state.edit_cell && this.state.edit_cell.cell === cell){
        this.editRef = React.createRef();
        return <input ref={this.editRef} defaultValue={value} onKeyDown={(e)=>this.onValueChange(e,value)} />
    }

    return value;
  }

  updateCellValue(id,c,value){

    if(c.onSave !== undefined){
        value = c.onSave(value);
    }

    var ac = {
        id: id,
        value: value,
        prop: c
    };

    if(ac && c.editable === true){
        this.onEditCell(ac);
    }
  }

  getRows(){

        var getValue = (row,cell) =>{
            if(cell.includes('.')){
                var c = cell.split('.');
                if(c && c.length){
                    var value = null;
                    var d = row;
                    c.map(k=>{
                        d = d[k];
                    });
                    return d;
                }
            }

            return row[cell];
        }

        if(this.props.cells){

            var rows = this.props.rows;
            rows.map((r,i)=>{
                this.props.cells.map(c=>{
                    if(c.custom === true){
                        rows[i][c.cell] = c.render(r);
                    }
                });
            })

            return rows.map((row,i)=>{
                var pk = row[this.props.pk];
                return <tr key={i} className={(this.state.active[pk]!==undefined ? 'active':'')+(this.props.selected && this.props.selected.toString()===pk.toString()?' selected':'')}>
                    <td width="25" className="giga-fixed-td"><input type="checkbox" onChange={(e)=>this.rowClick(e,row)} checked={this.state.active[pk]!==undefined ? true:false} /></td>
                    {this.props.cells.map((cell,b)=>{
                        var value = getValue(row,cell.cell);
                        return <td key={b} align={cell.align?cell.align:'left'} width={cell.width!==undefined?cell.width:'auto'} className={(cell.fixed?'giga-fixed-td ':'')+(cell.className!==undefined?cell.className:'')} >
                            <span className={cell.truncate===true?'truncate':''}
                            style={{width:(cell.truncate?(cell.width-11):'100%'),minHeight:"16px"}}
                            onDoubleClick={((e)=>this.editCell(e,cell,pk))}
                        >{this.cellValue(cell,pk,value)}</span></td>
                    })}
                    <td></td>
                </tr>
            })
        } else {
            return this.props.rows.map((row,i)=>{
                var pk = row[this.props.pk];
                return <tr key={i} onClick={(e)=>this.rowClick(e,row)}>
                    <td width="25"><input type="checkbox" onChange={(e)=>this.rowClick(e,row)} checked={this.state.active[pk]!==undefined ? true:false} /></td>
                    {Object.keys(row).map((a,b)=>{
                        var value = row[a];
                        return <td key={b}>{value}</td>
                    })}
                    <td> </td>
                </tr>
            })
        }
  }


  loadingView(){

    if(!this.props.loading && !this.state.loading) return '';

    return <div className="giga-table-loading">
        <div className="loadingspinner"></div>
    </div>
  }


  async onEditCell(data){
    data.table = this.props.db;
    this.setState({loading:true},async ()=>{
      data.cell = data.prop.cell;
      data.table = this.props.db;
      this.props.updateRow(data);
      var result = await fetchHelper.post('row/update',data);
      this.setState({loading:false})
      this.props.onEditCell(result);
    })
  }

  render() {

   if(!this.props.rows) return '';

    return (
        <>
            {this.loadingView()}
            <div className="giga-table-scroller" ref={this.rowRef}>
                <div className="giga-table-rows" >
                    
                    <table width={this.props.width}>
                        <tbody>
                            {this.getRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
  }
  
}


const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default 
    withRouter(connect(
      mapStateToProps,
      mapDispatchToProps
  )(GigaTableRows));