import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';

export default class StrikeThrough extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){
        e.preventDefault();
        e.stopPropagation();
        // var ins = new InsertNode(this.props.editor);
        // ins.__STYLE('text-decoration', 'line-through');
        // this.props.editor.registerChange();

        document.execCommand('strikethrough');
        this.props.editor.registerChange();

        return false;
    }

    render(){
        return <button className="get-btn selector-ga" id={"StrikeThrough-btn-"+this.props.id}  onMouseDown={this.action}><i className="fa fa-strikethrough" aria-hidden="true" ></i></button>
    }

}