import React, { Component } from "react";
import { Link } from "react-router-dom";

import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";
import { fetchHelper } from "../../../helpers/fetchHelper";
import AlertyBox from '../../../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import GalleryItemForm from "../../../forms/GalleryItemForm";

export class GalleryItemDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data:null,
    }

  }

  async componentDidMount(){
    console.log(this.props.match.params.position);
    var id = this.props.match.params.id;
    var r = await fetchHelper.get('gallery/get_image/'+id);
    if(r.data && r.data.status){
      this.setState({data: r.data.imgData[0] })
    }

  }


  render() {
    
    if(!this.state.data) return '';

    return (
    <>
       <div className="admin-home__main-section">
        
         <GalleryItemForm data={this.state.data} index={this} />  
      
      </div>
    </>);
  }
}

const mapStateToProps = state => ({

  user: state.auth.user

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
   
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(GalleryItemDetails));