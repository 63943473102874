import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { fetchHelper } from "../../../helpers/fetchHelper";
import  PlainTable from "../../../libraries/PlainTable/PlainTable";
import { date_sql_to_rs } from '../../../helpers/dateHelper';
import "../../index.scss";

class Points extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_id: "",
      email: "",
      user_id: "",
      busy: false,
      message: '',
      data: null
    }

    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);

  }

  componentDidMount(){
    
  }


  async get(){

    if(this.state.busy) return;

    this.setState({busy:true,message: ''});

    var params = {};
    if(this.state.email) params.email = this.state.email;
    if(this.state.user_id) params.user_id = this.state.user_id;
    if(this.state.order_id) params.id = this.state.order_id;

    var response = await fetchHelper.get('points/check',params);
    this.setState({busy:false});

    if(response.status){
        this.setState({data:response.data,message:''})
    } else {
        this.setState({message:response.message,data:null});
    }

    // console.log(response);
  }

  onChange(e){
    this.setState({[e.currentTarget.name]:e.currentTarget.value});
  }
  onKeyPress(event){event.key === 'Enter' &&  this.get()}


  order_data_tables(){
    let data=this.state.data;
    if (!data) return;
    let {orders,points_assigned,web_transactions,points_history,shop_orders,shop_transactions,user} = data;
    // console.log(orders,points_assigned,web_transactions,points_history,shop_orders,shop_transactions);



    const cells_web_orders = [
      {name:'ID',cell: 'id',width:100,  className:''},
      // {name:'User id',cell: 'user_id',width:100,  className:''}, 
      {name:'Transaction date',cell: 'transaction_date',width:150,  className:'',
      onBefore:(value)=>{
          return date_sql_to_rs(value); }},
      {name:'Discount',cell: 'discount',width:130,  className:''},
      {name:'Discount value',cell: 'discount_value',width:130,  className:''},
      {name:'Final discount',cell: 'final_discount',width:130,  className:''},
      {name:'Final discount value',cell: 'final_discount_value',width:140,  className:''},
      {name:'Total price',cell: 'total_price',width:130,  className:''},        
      {name:'Final total price',cell: 'final_total_price',width:130,  className:''},
      {name:'Earn points',cell: 'earn_points',width:100,  className:''},
      {name:'Points',cell: 'points',width:100,  className:''},
    
      {name:'Final status',cell: 'final_status',width:200,  className:'', onBefore: (value)=>{
        switch (parseInt(value)) {
          case 1:
            return 'Neispravna';
          case 2:
            return 'Primljena';
          case 3:
            return 'Obradjena';
          case 4:
            return 'Završena';
          case 5:
            return 'Dobijeni bodovi';
          case 6:
            return 'Period registracije istekao';
          case 7:
            return 'Greška';
          case 8:
            return 'Poništena';
          default:
            return 'Upsss'
        }

      }},
    ]
    const cells_web_transaction = [
      {name:'ID',cell: 'id',width:100,  className:''}, 
      {name:'Order id',cell: 'order_id',width:100,  className:''},
      {name:'Date created',cell: 'date_created',width:150,  className:'',
      onBefore:(value)=>{
          return date_sql_to_rs(value); }},
      {name:'Points',cell: 'points',width:100,  className:''},
      // {name:'User id',cell: 'user_id',width:100,  className:''},
    ]

    const cells_points_assigned=[
      {name:'ID',cell: 'id',width:100,  className:''}, 
      {name:'Product id',cell: 'product_id',width:100,  className:''},
      {name:'Order id',cell: 'order_id',width:150,  className:''},
      {name:'Loyalty id',cell: 'loyalty_id',width:100,  className:''},
      {name:'Loyalty definition id',cell: 'loyalty_definition_id',width:150,  className:''},
      {name:'Date created',cell: 'date_created',width:150,  className:'',
      onBefore:(value)=>{
        return date_sql_to_rs(value); }},
      {name:'Registration date',cell: 'registered_date',width:150,  className:'',
      onBefore:(value)=>{
        return date_sql_to_rs(value); }},
      // {name:'User id',cell: 'user_id',width:100,  className:''}, 
      {name:'Registered',cell: 'registered',width:100,  className:''},  
      {name:'Alerted',cell: 'alerted',width:100,  className:''},
      {name:'Shop type',cell: 'shop_type',width:100,  className:''},
      {name:'Points',cell: 'points',width:100,  className:''},
      
    ]
    const cells_points_history = [
      {name:'ID',cell: 'id',width:100,  className:''}, 
      // {name:'User id',cell: 'user_id',width:100,  className:''},
      {name:'Before points',cell: 'before_points',width:100,  className:''},
      {name:'After points',cell: 'after_points',width:100,  className:''},
      {name:'Changed at',cell: 'changed_at',width:150,  className:'',
      onBefore:(value)=>{
        return date_sql_to_rs(value); }},
    ]
    const cells_shop_orders = [
      {name:'ID',cell: 'id',width:150,  className:''}, 
      {name:'Invoice',cell: 'invoice',width:150,  className:''}, 
      {name:'Location id',cell: 'location_id',width:100,  className:''}, 
      {name:'Delivery id',cell: 'delivery_id',width:100,  className:''}, 
      {name:'Type id',cell: 'type_id',width:100,  className:''}, 
      {name:'Email',cell: 'email',width:250,  className:''}, 
      {name:'Barcode',cell: 'barcode',width:200,  className:''}, 
      {name:'Hash',cell: 'hash',width:200,  className:''}, 
      {name:'Card type',cell: 'card_type',width:100,  className:''},
      {name:'Nis card',cell: 'nis_card',width:100,  className:''}, 
      {name:'Payment type',cell: 'payment_type',width:100,  className:''}, 
      {name:'Discount value',cell: 'discount_value',width:100,  className:''}, 
      {name:'Total price',cell: 'total_price',width:100,  className:''}, 
      {name:'Date',cell: 'date',width:150,  className:'',
      onBefore:(value)=>{
        return date_sql_to_rs(value); }},
      {name:'Transaction date',cell: 'transaction_date',width:150,  className:'',
      onBefore:(value)=>{
        return date_sql_to_rs(value); }},
      {name:'Date updated',cell: 'date_updated',width:150,  className:'',
        onBefore:(value)=>{
          return date_sql_to_rs(value); }},
      {name:'Status',cell: 'status',width:200,  className:'', onBefore: (value)=>{
        switch (parseInt(value)) {
          case 1:
            return 'Neispravna';
          case 2:
            return 'Primljena';
          case 3:
            return 'Obradjena';
          case 4:
            return 'Završena';
          case 5:
            return 'Dobijeni bodovi';
          case 6:
            return 'Period registracije istekao';
          case 7:
            return 'Greška';
          case 8:
            return 'Poništena';
          default:
            return 'Upsss'
        }

      }}, 
    ]
    const cells_shop_transaction= [
      {name:'ID',cell: 'id',width:150,  className:''}, 
      {name:'Order id',cell: 'order_id',width:150,  className:''},  
      {name:'Location id',cell: 'location_id',width:100,  className:''}, 
      {name:'Type',cell: 'type',width:100,  className:''},
      {name:'Discount type',cell: 'discount_type',width:100,  className:''},
      // {name:'User id',cell: 'user_id',width:100,  className:''},
      {name:'Discount',cell: 'discount',width:100,  className:''}, 
      {name:'Total',cell: 'total',width:100,  className:''}, 
      {name:'Value',cell: 'value',width:100,  className:''}, 
      {name:'Transaction date',cell: 'transaction_date',width:150,  className:'',
      onBefore:(value)=>{
        return date_sql_to_rs(value); }},
      {name:'Description',cell: 'description',width:400,  className:''}, 
    ]

    const cells_user= [
      {name:'ID',cell: 'id',width:100,  className:''}, 
      {name:'First name',cell: 'first_name',width:100,  className:''}, 
      {name:'Last name',cell: 'last_name',width:150,  className:''}, 
      {name:'Company name',cell: 'company_name',width:150,  className:''},
      {name:'Email',cell: 'email',width:250,  className:''}, 
      {name:'Registration date',cell: 'registration_date',width:150,  className:'',
      onBefore:(value)=>{
        return date_sql_to_rs(value); }},
      {name:'Type id',cell: 'type_id',width:100,  className:''}, 
      {name:'Points',cell: 'points',width:100,  className:''},
      
    ]
 

    return  <>
    <PlainTable itemsPerPage={10} height={"130px"} cells={cells_user} rows={[user]} title="Podaci o korisniku" className="user_data_table" removeFooter={true}/> 
    <PlainTable itemsPerPage={10} height={"250px"} cells={cells_points_assigned} rows={points_assigned} title="Proces dobijanja bodova"/> 
    <PlainTable itemsPerPage={10} height={"250px"} cells={cells_points_history} rows={points_history} title="Log promene bodova"/> 
    <PlainTable itemsPerPage={10} height={"250px"} cells={cells_web_orders} rows={orders} title="Web kupovine"/> 
    <PlainTable itemsPerPage={10} height={"250px"} cells={cells_web_transaction} rows={web_transactions} title="Osvojeni bodovi na webu"/> 
    <PlainTable itemsPerPage={10} height={"250px"} cells={cells_shop_orders} rows={shop_orders} title="Kupovine u prodavnici"/> 
    <PlainTable itemsPerPage={10} height={"250px"} cells={cells_shop_transaction} rows={shop_transactions} title="Potrošeni bodovi u prodavnici"/> 
    </>
   
  }


  sum(){
    /*
    *  1 - RECEIVED
    *  2 - INFORMED
    *  3 - IDLE
    *  4 - PROCESSED
    *  5 - FINALIZED
    *  6 - EXPIRED
    *  7 - ERROR
    *  8 - CANCELED
    */

  }
 

  render() {
   
    return (
        <>
            <div className="admin-home__main-section points_page_wrapper">
                <div className="table-wrapper">
                    <h1>Points Check</h1>

                    <div className="form" id="form-search">
                        <span className="search-group">
                            <label>Order ID</label>
                            <input type="text" maxLength="20" value={this.state.order_id} name="order_id" onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                        </span>
                        <span className="search-group">
                            <label>User ID</label>
                            <input type="number" maxLength="10" value={this.state.user_id} name="user_id" onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                        </span>
                        <span className="search-group">
                            <label>Email</label>
                            <input type="email" maxLength="80" value={this.state.email} name="email" onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                        </span>
                        <span className="search-group">
                            <button className={this.state.busy ? 'busy' : ''} onClick={this.get.bind(this)}><i className="fa fa-search" /></button>
                        </span>
                        {this.state.message ? (
                            <div className="page-message error-message">{this.state.message}</div>
                        ) : ''}
                    </div>

                    <div className="result-page"  style={{padding:"0px 10px"}}>
                        {!this.state.data? this.state.busy ? 'Loading...' : 'Nema rezultata':''}
                        {this.order_data_tables()}
                        {this.sum()}
                    </div>

                </div>

            </div>
        </>);
  }
}

const mapStateToProps = state => ({

  user: state.auth.user

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
   
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(Points));