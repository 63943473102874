import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import { withRouter } from "react-router-dom";
import { reduxAsyncValidate, reduxReduceVaues } from "../helpers/formHelper";
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";
import AlertyBox from "../libraries/AletyBox";
import { SubmitDataPickerValue } from "../helpers/dateHelper";

import { setBusy } from "../modules/forms";
import { vouchersConf } from "./vouchersConf";

import "./../assets/css/form.scss";
import { fetchHelper } from "../helpers/fetchHelper";
import { stringTrim } from "../helpers/stringHelper";

import GigaSpinner from "../libraries/GigaSpinner";
import GigaTabs from "../libraries/GigaTabs";
import GigaTab from "../libraries/GigaTabs/GigaTab";

class VouchersCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allow_submit: false,
      files: [],
      lastId: null,
    };

    this.addDepedency = this.addDepedency.bind(this);
  }

  componentDidMount() {
    vouchersConf.index = this;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data) {
      reduxAsyncValidate(this.props.data, this.props);
    }
  }

  async submit(data, formType) {
    this.props.setBusy(true);
    var values = reduxReduceVaues(vouchersConf.fields, data);
    console.log(values);
    var response = await fetchHelper.post("vouchers/create", values);

    this.props.setBusy(false);

    if (response.status && values && Object.keys(values).length) {
      if (this.props.onSave) {
        this.props.onSave(values);
      }
      AlertyBox.message(response.data);
    } else {
      var m = {
        message: "Došlo je do greške prilikom snimanja",
        status: false,
      };
      AlertyBox.message(m);
    }
  }

  addDepedency(instance, e) {
    var data = {
      title: this.state.master_title,
      master: {
        table: this.state.master_table,
        cell: this.state.master_table_cell,
      },
      origin: {
        table: this.state.origin_table,
        cell: this.state.origin_table_cell,
      },
      depedency: {
        table: this.state.depedency_table,
        cell: this.state.depedency_table_cell,
      },
    };

    this.setState({ data: data });
  }

  addTables() {
    var d = this.state.data;
    var status = false;
    if (d) {
      if (d.master.table && d.master.cell) {
        if (d.origin.table && d.origin.cell) {
          status = true;
          if (d.depedency.table && !d.depedency.cell) {
            status = false;
          }
        }
      }
    }

    if (!status) {
      var m = { message: "Opcije ne ispunjavaju uslov", status: false };
      AlertyBox.message(m);
      return false;
    }

    if (!stringTrim(d.title)) {
      var m = { message: "Uslov mora da ima naslov", status: false };
      AlertyBox.message(m);
      return false;
    }
  }

  form(refID) {
    const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

    return (
      <>
        <form id="slidersform" autoComplete="off" onSubmit={handleSubmit((data) => {
                if (this.state.allow_submit) {
                    this.setState({ allow_submit: false })
                    data = SubmitDataPickerValue(data, ['start_date', 'end_date']);
                    // console.log(data);
                    this.submit(data);
                }
            })} className="redux-form" encType="multipart/form-data" >
          <GigaTabs tab={0}>
            {/* GiaTab title, heigth */}
            <GigaTab title="Detalji " /*height={400}*/>
              {busy ? <div className="form-mask"></div> : ""}
              <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                <Cell width={1} className="grid-column">
                <div className="relative">
                       <Field {...vouchersConf.fields.product_id}  instance={vouchersConf} controler={this} />
                       <Field {...vouchersConf.fields.start_date}  instance={vouchersConf} controler={this} />
                       <Field {...vouchersConf.fields.end_date} instance={vouchersConf} controler={this} />
                       <Field {...vouchersConf.fields.discount}  instance={vouchersConf} controler={this} />
                       <Field {...vouchersConf.fields.count}  instance={vouchersConf} controler={this} />
                       <Field {...vouchersConf.fields.prefix}  instance={vouchersConf} controler={this} />
                       <Field {...vouchersConf.fields.sufix}  instance={vouchersConf} controler={this} />
                       <Field {...vouchersConf.fields.length}  instance={vouchersConf} controler={this} />
                       <Field {...vouchersConf.fields.type}  instance={vouchersConf} controler={this} />
                   </div>
                   <div className="page-bottom-bar">
                        <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
                    </div>
                </Cell>
                <Cell>

                </Cell>
              </Grid>
            </GigaTab>
          </GigaTabs>
        </form>
      </>
    );
  }


  render() {

    const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

    console.log("data", this.props.data);
    vouchersConf.initialValues = this.props.data;

    var refID = React.createRef();

    return <>{this.form(refID)}</>;
  }
}

const selector = formValueSelector("VouchersCreateForm");

const mapStateToProps = (state) => ({
  initialValues: vouchersConf.initialValues,
  busy: state.forms.busy,
  id: selector(state, "id"),
  // category_id: selector(state, 'category_id'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBusy,
      updateField: (field, data) => change("VouchersCreateForm", field, data),
    },
    dispatch
  );

VouchersCreateForm = reduxForm({
  form: "VouchersCreateForm",
  reduxAsyncValidate,
  enableReinitialize: true,
})(VouchersCreateForm);
VouchersCreateForm = connect(mapStateToProps, mapDispatchToProps)(VouchersCreateForm);
export default withRouter(VouchersCreateForm);
