import React, {Component} from 'react';

export default class Modal extends Component {


    constructor(props){
        super(props)
        
        
    }

    componentDidMount(){

    }

    initialize(){

    }

    onClick(e){
        e.stopPropagation();

        return false;
    }


    render(){
        if(this.props.children){
            return <div className="GigaEditorModal" onMouseDown={this.onClick}>
                {this.props.children}
            </div>
        } else {
            return '';
        }
    }

}