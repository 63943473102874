import React from 'react';
import { b64DecodeUnicode, b64EncodeUnicode } from '../../helpers/base64';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import './style.scss';
import { uniqString } from '../../../../../helpers/stringHelper';

class View extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          active: 0
      };

      this.buttonClick = this.buttonClick.bind(this);
    }


    componentWillMount(){
        var a = parseInt(this.props.data.tabActive);
        a = a ? a : 1;
        var active = a && !isNaN(a) ? a : 1;
        this.setState({active:active})
    }


    componentDidMount(){
        
    }


    buttonClick(e){
        this.setState({active:parseInt(e.currentTarget.dataset.index)})
    }


    formateNode(el){
        var isHTML = RegExp.prototype.test.bind(/^(<([^>]+)>)$/i);
        if(isHTML){
            return ReactHtmlParser(el);
        }
        return el;
    }

    buttonIcon(i){
        var images = this.props.data.tabs_images;
        if(images){
            images = JSON.parse(b64DecodeUnicode(images));
            console.log(images);
            if(images){
                var img = images[i];
                if(img){
                    return <img src={img} />
                }
            }
        }
        return '';
    }

    tabIconClass(i){

        if(this.buttonIcon(i)){
            return ' icon-' + this.props.data.tabIconPosition;
        }

        return '';
    }

    tabStyle(){
        var left = 5;
        var right = 5;
        var top = 5;
        var bottom = 5;

        if(this.props.data.tabPaddingLeft) left = this.props.data.tabPaddingLeft;
        if(this.props.data.tabPaddingRight) right = this.props.data.tabPaddingRight;
        if(this.props.data.tabPaddingTop) top = this.props.data.tabPaddingTop;
        if(this.props.data.tabPaddingBottom) bottom = this.props.data.tabPaddingBottom;

        return {
            paddingLeft: left+'px', paddingRight: right+'px', paddingTop: top+'px', paddingBottom: bottom+'px', fontSize: this.props.data.tabFontSize, width: this.props.data.tabWidth
        }
    }

    buttons(count){
        var active = this.state.active;
        var n = this.props.data.tabs;
        var _tabs = n ?  JSON.parse(b64DecodeUnicode(n)) : {}; 
        var tabs = [];
        for(var i = 0; i < count; i++){
            var x = i+1;
            var title = _tabs[i] !== undefined ? _tabs[i] : _tabs[i.toString()] !== undefined ?  _tabs[i.toString()] : 'Tab: ' + x;
            tabs.push(<button key={i} style={this.tabStyle()} className={"tab-button" + (active === x ? ' tab-button-active':'')+(this.tabIconClass(i))} data-index={x} onClick={(e)=>{this.buttonClick(e)}}>{this.buttonIcon(i)}<b>{this.formateNode(title)}</b></button>);
        }
        return tabs;
    }


    contents(count,contents64){
        var active = this.state.active;
        var c = JSON.parse(b64DecodeUnicode(contents64));
        var contents = [];
        for(var i = 0; i < count; i++){
            var x = i+1;
            var content = c[i] ? c[i] : 'Content '+x;
            contents.push(<div className={"mceEditable tab-content" + (active === x ? ' tab-content-active':'')} key={i}>{this.formateNode(content)}</div>);
        }

        return contents;
    }


    css(data,id){
        return `<style>
            #${id} .mce-plugins-tabs-buttons .tab-button { 
                color: ${data.tabColor};
                background-color: ${data.tabBackgroundColor};
                border-color: ${data.tabBorderColor};
                border-top-left-radius: ${data.tabRadius}px;
                border-top-right-radius: ${data.tabRadius}px;
            }
            #${id} .mce-plugins-tabs-buttons .tab-button.tab-button-active { 
                color: ${data.tabColorActive};
                background-color: ${data.tabBackgroundColorActive};
                border-color: ${data.tabBorderColorActive};
            }
            #${id} .mce-plugins-tabs-buttons {
                border-color: ${data.tabLineColor};
            }
        </style>`;
    }


    view( data ){

        var id = 'tab-'+(uniqString(5));

        console.log(data);

        return <div className="mce-plugin-tabs" id={id}>
            {ReactHtmlParser(this.css(data,id))}
            <div className="mce-plugins-tabs-buttons" style={{backgroundColor:data.tabHeadBackground}}>
                {this.buttons(parseInt(data.tabNums),)}
            </div>
            <div className="mce-plugins-tabs-content" style={{color:data.tabContentColor, backgroundColor: data.tabContentBackground, padding: data.tabContentPadding}}>
                {this.contents(parseInt(data.tabNums), data.contents, parseInt(data.tabActive))}
            </div>
        </div>
    }

   

    render(){

        const { data, folder } = this.props;
        const json = JSON.stringify(data);
        

         /* !!! obavezno:
             tab="0" 
             className="mceNonEditable"
              data-controller="plugin" 
              data-plugin={folder}  
              data-json={json} 
        */
        return <div 
            tab="0" 
            className="mceNonEditable" 
            data-controller="plugin" 
            data-plugin={folder}  
            data-json={b64EncodeUnicode(json)} 
        >{this.view(data)}</div>
    }
}

export default View;