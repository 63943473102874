import React, {Component} from 'react';
import History from './../../base/History';

export default class UndoRedo extends Component {


    constructor(props){
        super(props)

        this.state = {
            undo: false,
            redo: false,
        }
        
        this.action = this.action.bind(this);
        this.action2 = this.action2.bind(this);
    }

    componentDidMount(){
        this.props.editor.setState({UndoRedo:this});
    }

    componentDidUpdate(){
       
    }

    initialize(){

    }

    action(){
        // this.props.editor.History.Undo(this.props.editor);
        document.execCommand('undo');
    }

    action2(){
        // this.props.editor.History.Redo(this.props.editor);
        document.execCommand('redo');
    }

    isDisabled(type){


        // var h = this.props.editor.History;
        // if(!h){
        //     h = new History();
        //     this.props.editor.History = h;
        // }
        // console.log(type, this.props.editor.History.index)
        // this.setState({[type]:h.isDisabled(type)});

    }

    render(){
        return <span>
            <button className="get-btn" onClick={this.action} disabled={this.state.undo}><i className="fa fa-undo" aria-hidden="true" ></i></button>
            <button className="get-btn" onClick={this.action2} disabled={this.state.redo}><i className="fa fa-rotate-right" aria-hidden="true" ></i></button></span>
    }

}