import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";

import './style.scss';

class GigaTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
        buttons: {},
        active: 0
    };

    this.ref = React.createRef();
    this.ref2 = React.createRef();
   
    this.tabClick = this.tabClick.bind(this);
  }

  componentDidMount(){
    this.setBtnsAndActive();
  }
  componentDidUpdate(prevProp){
    if(this.props.children&&this.props.children.props&&this.props.children.props.title&&this.props.children.props.title!=prevProp.children.props.title){
      this.setBtnsAndActive();
    }
  }
  
  setBtnsAndActive(){
    var buttons = {};
    var active = this.props.active ? parseInt(this.props.active) : 0;
    let children=this.props.children;
    if(!Array.isArray(this.props.children)){
      children=[children];
    }
    children.map((c,i)=>{
        buttons[i] = c.props.title ? c.props.title : 'Tab ' + i;
    });

    this.setState({buttons:buttons,active:active},()=>{
      this.sibling(this.state.active);
    });
  }


  tabClick(key,e){
    e.stopPropagation();
    e.preventDefault();
    this.setState({active:key},()=>{
      this.sibling(key);
    });
    return false;
  }

  sibling(key){
    var children = this.ref.current.children;
    var left = 0;
    var size = 100;
    for(var i = 0; i < children.length; i++){
      if(parseInt(i) === parseInt(key)){
        var child = children[i];
        left = child.offsetLeft;
        size = child.offsetWidth;
        i = 99;
      }
    }
    this.ref2.current.style.width = size + 'px';
    this.ref2.current.style.left = left + 'px';
  }


  Buttons(){
      const {buttons} = this.state;
      return <div className="giga-tab-buttons" ref={this.ref}>
          {Object.keys(buttons).map((key,i)=>{
            return <span className={"giga-tab-btn" + (parseInt(this.state.active)===parseInt(key)?' active':'')} key={i} onMouseDown={(e)=>this.tabClick(key,e)} >{buttons[key]}</span>
          })}
          <span className="blue-bar" ref={this.ref2}></span>
      </div>
  }


  Tabs(){
      let children=this.props.children; 
      if(!Array.isArray(this.props.children)){
        children=[children];
      }
      return <div className="giga-tab-container">
          {children.map((tab,key)=>{
                return <div className={"giga-tab-view"+(parseInt(this.state.active)===parseInt(key)?' active':'')} key={key}>{tab}</div>
          })}
      </div>
  }


  render() {

    return (
        <div className="giga-tabs-wrap">
            {this.Buttons()}
            {this.Tabs()}
        </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user,
  busy: state.forms.busy
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default 
    withRouter(connect(
      mapStateToProps,
      mapDispatchToProps
  )(GigaTabs));