import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Link,NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";

import './SideMenu.scss';
import { startWith } from '../../../helpers/stringHelper';
import { setMasterActive,setSideNavMobile,setBreadcrumbs } from './../../../modules/navigation';


class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      components: {},
    };

    this.is_loaded_components = false;
  }

  componentDidMount() {

  }

  componentDidUpdate(){
    this.loadComponents();
  }

  componentWillUnmount() {
   
  }





  loadComponents(){
    if(!this.props.navigation || !Object.keys(this.props.navigation).length || this.is_loaded_components) return;
    this.is_loaded_components = true;
    Object.keys(this.props.navigation).map(async (key)=>{
      var item = this.props.navigation[key];
      if(item.view){
        await this.loadComponent(item);
      }
      if(item.child){
        item.child.map(async (it)=>{
          if(it.view){
            await this.loadComponent(it);
          }
        })
      }
    })
  }

  loadComponent(item){
    return new Promise(async (resolve)=>{
      const Component = await import("./Helpers/" + item.view);
      const cmp = <Component.default parent={this} />;
      const components = this.state.components;
      components[item.id] = cmp;
      resolve(1);
    })
  }


  isActive(slug, gradient, exact){
   
    var to = this.props.location.pathname;
    var rg = new RegExp(slug, 'i');

    if(exact){
      return to === slug ? ' active':'';
    }

    if(to === '/admin' || slug === '/admin') return to === slug ? ' active':'';

    if(to.match(rg)){

      let toarr= to.split('/');
      let slugarr= slug.split('/');

      if(this.diff(toarr,slugarr)){

        return ' active' + (gradient === 1 ? ' border-gradient border-gradient-purple':'');
      }
    }else{
      return ''
    }
  }

  diff(arr,arr2) {
    var ret = true ;
    arr2.sort();
    arr.sort();
    for(var i = 0; i < arr2.length; i += 1) {
        if(arr.indexOf(arr2[i]) == -1){
            ret = false;
        }
    }
    return ret;
};

  render() {
    if(!this.props.user){
      return '';
    }

    if(!this.props.navigation.length) return '';

    return (
      <>
         <nav className={`side-menu ${this.props.mob_nav_open ? "" : "mobile-side-nav-hide"}`} >
          {this.props.navigation.map((item,i)=>{
            return <div className={"item-holder"+(this.isActive(item.slug, 1))} key={i}>
              <Link to={item.slug} className={this.isActive(item.slug, 0, true)}><i className={item.icon} /> <span>{item.title}</span></Link>
              {this.state.components[item.id]? this.state.components[item.id]:''}
              {item.child && item.child.length?(
                <div className={"child-hold"+(this.isActive(item.slug))}>{
                item.child.map((child,a)=>(
                  <div key={a}>
                  <Link  to={child.slug} className={this.isActive(child.slug, 0, true)}><i className={child.icon} /> {child.title}</Link>
                  {this.state.components[child.id]? this.state.components[child.id]:''}
                  {child.child && child.child.length?(
                    <div className={"child-hold" + (this.isActive(child.slug))}>
                      {child.child.map((c,b)=>(
                        <div key={b}>
                        <Link to={c.slug} className={this.isActive(c.slug, 0, true)}><i className={c.icon} /> <span>{c.title}</span></Link> 
                        {this.state.components[c.id]? this.state.components[c.id]:''}
                        </div>
                      ))}
                    </div>
                  ):''}
                  </div>
                ))}
                </div>
              ):''}
            </div>
          })}
        </nav>
      </>
    );
  }
}


const mapStateToProps = state => ({
  navigation: state.navigation.navigation,
  master_active: state.navigation.master_active,
  user: state.auth.user,
  mob_nav_open:state.navigation.mob_nav_open,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setMasterActive,
      setSideNavMobile,
      setBreadcrumbs,
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(SideMenu));
