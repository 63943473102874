import React, { Component } from 'react';
import Draggable from './Draggable';

class GigaTableTh extends React.Component {


    constructor(props){
        super(props);
        this.state = {
            sort: null,
            order: null,
        }

        this.sortClick = this.sortClick.bind(this);
    }

    componentDidMount() {
        // Every React component has a function that exposes the
        // underlying DOM node that it is wrapping. We can use that
        // DOM node, pass it to jQuery and initialize the plugin.

        // You'll find that many jQuery plugins follow this same pattern
        // and you'll be able to pass the component DOM node to jQuery
        // and call the plugin function.

        // Get the DOM node and store the jQuery element reference
        this.$node = (this.refs.sortable);

        // // Initialize the jQuery UI functionality you need
        // // in this case, the Sortable: https://jqueryui.com/sortable/
        // this.$node.sortable({
        //     // Get the incoming `opacity` prop and use it in the plugin configuration
        //     opacity: this.props.opacity,
        //     // Get the incoming onChange func and we invoke it on the Sortable `change` event
        //     change: (event, ui) => this.props.onChange(event, ui)
        // });
    }
    
    // Force a single-render of the component,
    // by returning false from shouldComponentUpdate ReactJS lifecycle hook.
    // Right after ReactJS adds the element in the actual DOM,
    // we need to pass the future control to jQuery.
    // This way, ReactJS will never re-render our component,
    // and jQuery will be responsible for all updates.
    shouldComponentUpdate() {
        return true;
    }

    componentWillReceiveProps(nextProps) {
        // Each time when component receives new props,
        // we should trigger refresh or perform anything else we need.
        // For this example, we'll update only the enable/disable option,
        // as soon as we receive a different value for this.props.enable
        if (nextProps.enable !== this.props.enable) {
            this.$node.sortable(nextProps.enable ? 'enable' : 'disable');
        }
    }


    sortCellIcon(c){
        if(c.sort === true){
            if(!this.state.sort){
                return 'sort-icon sort-hidden';
            } else {
                if(c.cell === this.state.sort){
                    switch(this.state.order){
                        case 'ASC':
                            return 'sort-icon sort-asc';
                        case 'DESC':
                            return 'sort-icon sort-desc';
                        default:
                            return 'sort-icon sort-hidden';
                    }
                } else {
                    return 'sort-icon sort-hidden';
                }
            }
        }

        return '';
    }


    sortClick(e,c){
        e.stopPropagation();

        var name = c.cell;

        var sort = null;
        var order = null;

        if(this.state.sort === null){
            sort = name;
            order = 'ASC';
            
        } else {
            if(this.state.sort !== name){
                sort = name;
                order = 'ASC';
            } else {
                if(this.state.order === 'ASC'){
                    sort = name;
                    order = 'DESC';
                } else {
                    sort = name;
                    order = 'ASC';
                }
            }
        }

        this.setState({sort:sort,order:order});
        this.props.index.setState({
            sort_order: order,
            sort_cell: sort
        },()=>{
            this.props.index.loadData();
        })

    }

    render() {
        const c = this.props.cell;
        return (
            <Draggable 
                ref="sortable" 
                data-id={c.cell} 
                width={c.width!==undefined?c.width:'auto'} 
                align="left"
                className={(c.fixed?'giga-fixed-td ':'')+(c.className!==undefined?c.className:'')}
                node={this.props.node} 
                cells={this.props.cells}
                index={this.props.index}
                id={this.props.id}
            ><span>{c.name}{c.editable===true?<sup><i className="fa fa-pencil"/></sup>:''}</span><span className={this.sortCellIcon(c)} onClick={(e)=>{this.sortClick(e,c)}}></span></Draggable>
        );
    }

    componentWillUnmount() {
        // Clean up the mess when the component unmounts
        //this.$node.sortable('destroy');
    }
};


export default GigaTableTh;