var FormData = require('form-data');

// import FormData from "form-data";
const axios = require('axios').default;

export const API_URL = 'https://api-v2.gigatron.rs/'


export const fetchApiHelper = {
    uniqid: function(a = "", b = false) {
        const c = Date.now()/1000;
        let d = c.toString(16).split(".").join("");
        while(d.length < 14) d += "0";
        let e = "";
        if(b){
            e = ".";
            e += Math.round(Math.random()*100000000);
        }
        return a + d + e;
    },

    cart_session: function(force) {
        try {
          if (localStorage !== undefined) {
            var uid = localStorage.getItem("UID3");
    
            if (uid !== undefined && uid !== null && force === undefined) {
              this.uid = uid;
            } else {
              this.uid = fetchApiHelper.uniqid('giga',true);
    
              localStorage.setItem("UID3", this.uid);
            }
    
            return this.uid;
          }
        } catch (e) {}
    },

    api_url: process.env.NODE_ENV == 'development' ? API_URL  : API_URL,

    _uuid(){
        var user = {uuid:null};
        if (localStorage.getItem('user')) {
            
            var u = localStorage.getItem('user');
            if(u === '[object Object]' ){
                u = null;
            } else {
            
            }

            user = u ? JSON.parse(u)  : null;    
        }

        return user ?  user.uuid : null;
    },

    _queryBuild(params){
        if(params !== undefined)
            return  '?'+Object.keys(params).map(key => key + '=' + params[key]).join('&');
        else 
            return '';
    },

    _postBuild(params){
        let formData = new FormData();
        if (params != undefined && params != null) {
            Object.keys(params).map(function(a, e) {
                formData.append(a, params[a]);
            });
      
            if(params.files){
                Object.keys(params.files).map(function(a,e){
                    formData.append('file_' + a , params.files[a]);
                })
                formData.delete('files');
            }
        }
        return formData;
    },

    getApi: async function(path, query_data){
        
        axios.defaults.withCredentials = true;
        axios.defaults.headers.get['Identify'] = fetchApiHelper._uuid();
        axios.defaults.headers.put['withCredentials'] = true;
        

        var url = fetchApiHelper.api_url + path + fetchApiHelper._queryBuild(query_data);

        if (url.includes("?")) {
            url += "&uid=" + this.cart_session();
          } else {
            url += "?uid=" + this.cart_session();
        }

        const response = await axios.get(url).catch(err=>{return false;});
        return fetchApiHelper.resolve(response);
    },

    //https://search.gigatron.rs/v1/search/get?ids=346701+362252
    getQuery: async function(query, query_data){
        

        axios.defaults.headers.get['Identify'] = fetchApiHelper._uuid();
        axios.defaults.headers.put['withCredentials'] = true;

        var url = query + fetchApiHelper._queryBuild(query_data);

        if (url.includes("?")) {
            url += "&uid=" + this.cart_session();
          } else {
            url += "?uid=" + this.cart_session();
        }

        const response = await axios.get(url).catch(err=>{return false;});
        return fetchApiHelper.resolve(response);
    },


    postApi: async function(path, query_data, customUrl, files){
        var formData = new FormData();
        Object.keys(query_data).map((k)=>{
            var t = (query_data[k]);
            if(typeof t === 'object' && t !== undefined && t){
                Object.keys(t).map((a,i)=>{
                    var file = t[a];
                    formData.append(`${k}`,file);
                });
                
            } else {
                if(t === undefined) t = null;
                formData.append(k,t);
                }
        })
        let response = null;

        if(files){
            Object.keys(files).map((name)=>{
                formData.append(name,files[name]);
            })
        }

        axios.defaults.withCredentials = true;
        axios.defaults.headers.put['Identify'] = fetchApiHelper._uuid();
        axios.defaults.headers.put['withCredentials'] = true;
        var url = (!customUrl ? fetchApiHelper.api_url : '') + path;

        if (url.includes("?")) {
            url += "&uid=" + this.cart_session();
          } else {
            url += "?uid=" + this.cart_session();
        }

        try {
            response = await axios.post(url, formData, {
                headers: {
                'Content-Type': 'multipart/form-data;',
                }
            });

            
        } catch (err){
            console.log(err);
        } finally {
            return fetchApiHelper.resolve(response);
        }
        
    },


    putApi:  async function(path, query_data){

        axios.defaults.withCredentials = true;
        axios.defaults.headers.put['Identify'] = fetchApiHelper._uuid();
        axios.defaults.headers.put['Content-type'] = 'application/json';
        var url = fetchApiHelper.api_url + path;
        if (url.includes("?")) {
            url += "&uid=" + this.cart_session();
          } else {
            url += "?uid=" + this.cart_session();
        }
        const response = await axios.put(url, JSON.stringify(query_data));
        return fetchApiHelper.resolve(response);
    },

    deleteApi:  async function(path, query_data){

        axios.defaults.withCredentials = true;
        axios.defaults.headers.delete['Identify'] = fetchApiHelper._uuid();
        axios.defaults.headers.put['Content-type'] = 'application/json';
        var url = fetchApiHelper.api_url + path;
        if (url.includes("?")) {
            url += "&uid=" + this.cart_session();
          } else {
            url += "?uid=" + this.cart_session();
        }
        const response = await axios.delete(url, {data:query_data});
        return fetchApiHelper.resolve(response);
    },

    resolve(response){
       
        return response;
        
    }
};