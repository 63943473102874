

import { 
    renderFieldInput,
    renderFieldSelect,
    renderFieldCheckbox,
    renderRadioButtons,
    renderFieldTextarea,
    renderFieldEditor,
    renderFieldUploadImage,
    renderGigaEditor, renderEditor
} from "../helpers/formHelper";

import {
    WarnAlphaNumeric, 
    WarnNumeric, 
    ValidateRequire, 
    ValidateAlphaNumeric,
    ValidateNumeric,
    ValidateEmail,
    ValidateAddress,
    ValidateFileImage
} from '../helpers/validation';

import AlertyBox from "../libraries/AletyBox";
import { fetchHelper } from "../helpers/fetchHelper";
import { reducer , change as changeFieldValue, change } from "redux-form";
import { server_img_host, user_avatar_path } from "../config/vars";



export const userConf = {
    fields: {
        'id' : {
            name: 'id',
            type: 'text',
            component: renderFieldInput,
            label: 'ID',
            validate:[ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'small',
            value: '1',
            readonly:true,
        },
        'first_name' : {
            name: 'first_name',
            type: 'text',
            component: renderFieldInput,
            label: 'Ime',
            validate:[ValidateRequire,ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'last_name' : {
            name: 'last_name',
            type: 'text',
            component: renderFieldInput,
            label: 'Prezime',
            validate:[ValidateRequire,ValidateAlphaNumeric],
            warn: WarnAlphaNumeric,
            size: 'medium',
            value: '',
        },
        'status' : {
            name: 'status',
            component: renderFieldSelect,
            label: 'Status',
            validate:[ValidateRequire,ValidateNumeric],
            warn: WarnNumeric,
            size: 'medium',
            value: '',
            options:[
                {text:'Neaktivan',value:0},
                {text:'Aktivan',value:1},
                
            ]
        },
        'newsletter' : {
            name: 'newsletter',
            component: renderFieldCheckbox,
            label: 'Newsletter',
            validate:[],
            warn: [],
            value: '',
        },
        'profile_public' : {
            name: 'profile_public',
            label: 'Profil',
            component: renderRadioButtons,
            label: 'Profil',
            validate:[ValidateRequire],
            warn: [],
            value: '',
            options: [
                {label:'Javan',value:1,name:'profile_public'},
                {label:'Sakriven',value:0,name:'profile_public'}
            ]
        },
        'email' : {
            name: 'email',
            type: 'email',
            component: renderFieldInput,
            label: 'E-mail',
            validate:[ValidateRequire,ValidateEmail],
            warn: [],
            size: 'medium',
            value: '',
        },
        'address' : {
            name: 'address',
            component: renderFieldTextarea,
            label: 'Adresa',
            validate:[ValidateRequire,ValidateAddress],
            warn: [],
            size: 'large',
            height: 100,
            value: '',
            resizable: false,
        },
        'postal_code' : {
            name: 'postal_code',
            component: renderFieldInput,
            label: 'Poš. broj',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [],
            size: 'small',
            value: '',
        },
        'city' : {
            name: 'city',
            component: renderFieldInput,
            label: 'Grad',
            validate:[ValidateRequire,ValidateAddress],
            warn: [],
            size: 'medium',
            value: '',
        },
        /*
        basic = basic buttons for wordprocessing
        formatting = most tools used for formatting - This is the default option
        complex = contains most of the buttons
        */
        'about_me' : {
            name: 'about_me',
            component: renderEditor,
            label: 'O meni',
            validate:[],
            warn: [],
            height: 3000,
            contentid:'',
            ref: null,
            url: 'http://ozone.devgigatron.com/',
            img_list_url:'http://ozone.devgigatron.com/img/gigaapi/',
        },

        'uploadavatar' : {
            name: 'uploadavatar',
            component: renderFieldUploadImage,
            label: 'Avatar',
            validate:[(file)=>ValidateFileImage(file,{
                size:1,
                width: 300,
                height: 300,
                exactWidth: false,
                exactHeight: false,
                accept: ['jpg','jpeg','png'],
                files: 2
            })],
            warn: [],
            value: '',
            url: server_img_host + user_avatar_path,
            img_name: '',
            place: {
                width: 180,
                height: 180
            },
            remove: (e, index) => {
                if(e.current){
                    AlertyBox.confirm('Da li ste sigurni da želite da obrišete sliku?').then(async (result)=>{
                        if(result.isConfirmed){
                            var response = await fetchHelper.delete('users/remove_avatar/'+e.current.value);
                            AlertyBox.message(response.data);
                            if(response.status && response.data.status){
                                    index.setState({active_user:response.data.data});
                            }
                        }
                    })
                }
            }
        },


        'test1' : {
            name: 'test1',
            component: renderFieldSelect,
            label: 'Test1',
            validate:[ValidateRequire,ValidateNumeric],
            size: 'medium',
            value: '',
            trigger: (value, input, instance, controler, parentValue) => {
                
                input.onChange(value);
                instance.fields.test2.parentValue = value !=='' ? parseInt(value) : '';
                instance.fields.test3.parentValue = '';
                controler.props.updateField('test2' , '')
                controler.props.updateField('test3' , '')
            },
            options:[
                {text:'Value 1',value:0},
                {text:'Value 2',value:1},
                
            ]
        },

        'test2' : {
            id: 'test2',
            name: 'test2',
            component: renderFieldSelect,
            label: 'Test2',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [ValidateRequire,ValidateNumeric],
            size: 'medium',
            value: '',
            parentValue: '',
            /* setovati display samo kada zelite da se ne vidi kada je parent value = '' */
            display: (parentValue) => {
                return parentValue  !== '' ? '':'none'
            },
            trigger: (value, input, instance, controler, parentValue) => {
                if(parentValue === '') return '';
                input.onChange(value);
                instance.fields.test3.parentValue = value !== '' ?  parseInt(value) : '';
                controler.props.updateField('test3' , '')
            },
            options:[
                {text:'A Value 1',value:0, parent: 0},
                {text:'A Value 2',value:1, parent: 1},
                
            ],
        },

        'test3' : {
            id: 'test3',
            name: 'test3',
            component: renderFieldSelect,
            label: 'Test3',
            validate:[ValidateRequire,ValidateNumeric],
            warn: [ValidateRequire,ValidateNumeric],
            size: 'medium',
            value: '',
            parentValue: '',
            /* setovati display samo kada zelite da se ne vidi kada je parent value = '' */
            display: (parentValue) => {
                return parentValue !== ''? '':'none'
            },
            trigger: (value, input, instance, controler, parentValue) => {
                if(parentValue === '') return false;
                input.onChange(value);
            },
            options:[
                {text:'B Value 1',value:0, parent: 0},
                {text:'B Value 2',value:1, parent: 1},
                {text:'B Value 3',value:2, parent: 1},
                
            ],

        }

    },
    initialValues: {}
}