import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "../helpers/fetchHelper";
import GigaTable from '../libraries/GigaTable';
import AlertyBox from '../libraries/AletyBox';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { backofficeUsersConf } from "./backofficeUsersConf";

import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';  

class BUGroupsAndPermissionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // active_user:null,
      // selected: null,
      table: null,
      user_group_id:null,  
      
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    // this.onSave = this.onSave.bind(this);
    this.resetActive=this.resetActive.bind(this); 
    // this.runRow = this.runRow.bind(this);
  }

  componentDidMount(){
    this.setState({user_group_id:this.props.user_group_id})
  }
  componentDidUpdate(prevProps){
    if(this.props.user_group_id!=prevProps.user_group_id){
        this.setState({user_group_id:this.props.user_group_id})
    }
  }
  
  getTableInstance(table){
    this.table = table;
  }

  // onSave(data){
  //   if(this.table && data.id){
  //     this.table.loadData(false,()=>{
  //       var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
  //       if(row){
  //         this.setState({active_user:row});
  //       }
  //     });
  //   }
  // }

  // runRow(row){
  //   if(row)
  //   this.setState({active_user:row,selected:row.id})
  // }
 
  resetActive() {
    // this.setState({active_user:null,selected:null})
  }

  
  render() {
    const cells = [
      {name:'ID',cell: 'id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true}, 
      {name:'Nav ID',cell: 'nav_id',width:100, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Nav Title',cell: 'title',width:300, fixed:true,sort:true}, 
      {name:'Slug',cell: 'slug', width:300,fixed:true,className:'giga-td-last-fixed',sort:true},
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center',fixed:true,render:(row)=>{ 
        var value = parseInt(row.status); 
        return  <button 
        onClick={()=> { 
          if(!value){ 
            let additional_data=`{"user_group_id":${this.props.user_group_id},"nav_id":${row.nav_id}}`
            this.table.insertRowCell("BackofficeNavigationGroups",additional_data).then(()=>{ 
              this.props.index.table.loadData();
            });
          }
          else{   
            this.table.removeRowCell({ids:[row.id],table:"backoffice_navigation_groups"}).then(()=>{ 
              this.props.index.table.loadData();
            });
          } 
      }}
      >{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button>  
      }},
    ];
   
    return (<> 
        {this.state.user_group_id ?
        <GigaTabs tab={0}> 
            <GigaTab title={`Dozvole za pristup stranicama`}> 
              <div className="table-det-wrapper">
                <GigaTable
                  cells={cells}
                  pk='id'
                  id={`backoffice_navigation_groups`}
                  height={600}
                  db="backoffice_navigation_groups" //id i status +title and slug from bck navigation
                  fetch={`backoffice_users_groups_and_permissions/permissions/${this.state.user_group_id}`}
                  buttons={false}
                  delete={false}
                  // selected={this.state.selected}//no details btns no need for this
                  getInstance={this.getTableInstance}
                  // run={this.runRow}//no need because we have no up and down arrows and we do not edit fields
                  resetActive={this.resetActive}//left only because table calls it in update
                /> 
              </div>
            </GigaTab> 
        </GigaTabs> : "" } 
    </>);
  }
}

const mapStateToProps = state => ({
  // user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(BUGroupsAndPermissionsForm));
