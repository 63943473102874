import React, {Component} from 'react';
import InsertNode from '../../base/insertNode';

export default class Wrap extends Component {


    constructor(props){
        super(props)
        
        this.action = this.action.bind(this);
    }

    componentDidMount(){

    }

    initialize(){

    }

    action(e){
        e.stopPropagation();
        e.preventDefault();
        // var ins = new InsertNode(this.props.editor);
        // ins.wrap('div', false, 'theme-wrap');

        let selection = window.getSelection().getRangeAt(0).cloneContents();
        let span = document.createElement('div');
        span.appendChild(selection);
        let wrappedselection = '<div class="theme-wrap block-element-editor">'+span.innerHTML+'</div>';
        document.execCommand('insertHTML', false, wrappedselection);

        // document.execCommand('formatBlock', false, 'div');
        // let selectedElement = document.getSelection().focusNode.parentNode;
        // selectedElement.className = 'theme-wrap';

        this.props.editor.registerChange();
    }

    render(){
        return <button title="Wrap theme-wrap" className="get-btn selector-ga" id={"Wrap-btn-"+this.props.id} onMouseDown={this.action}><i className="fa fa-object-group" aria-hidden="true" ></i></button>
    }

}