import React, { Component, useReducer } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { reduxAsyncValidate, reduxReduceVaues, reduxPrepareFormData } from '../helpers/formHelper';
import { bindActionCreators } from "redux";
import { Grid, Cell } from "styled-css-grid";

import { fetchHelper } from '../helpers/fetchHelper';
import { date_sql_to_rs, date_rs_to_sql } from "../helpers/dateHelper";
import AlertyBox from '../libraries/AletyBox';
import { setBusy } from '../modules/forms';
import { userConf } from "./userConf";

import GigaSpinner from '../libraries/GigaSpinner';
import GigaTabs from '../libraries/GigaTabs';
import GigaTab from '../libraries/GigaTabs/GigaTab';

import './../assets/css/form.scss';
import GigaEditor from '../libraries/GigaEditor';
import { mutate } from 'array-move';
import GigaTable from '../libraries/GigaTable';
import tinyExport from '../libraries/GigaEditor/tinymce/helpers/tinyExport';
import PlainTable from "../libraries/PlainTable/PlainTable";
import {website } from '../config/vars';

class UserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      success: null,
      message: null,
      busy: false,
      rnd: 0,
      allow_submit: false,


      table: null,
      selected: null,
      active_order: null,
      show_account: false,
      products_by_order: null
    };

    this.editors = {};

    this.setEditorInstance = this.setEditorInstance.bind(this);
    this.editorRef = React.createRef();


    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    // this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive = this.resetActive.bind(this);
    this.addNew = this.addNew.bind(this);
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    if (this.props.data) {
      reduxAsyncValidate(this.props.data, this.props);
    }
  }


  setEditorInstance(id, editor) {
    this.editors[id] = editor;
  }


  getTableInstance(table) {
    this.table = table;
  }

  runRow(row) {
    if (row)
      this.setState({ active_user: row, selected: row.id })
  }

  resetActive() {
    this.setState({ active_user: null, selected: null })
  }


  async addNew() {
    var r = await fetchHelper.post('users/change', {});
    if (r.status && r.data) {
      this.setState({ active_user: r.data.data, selected: r.data.data.id, });
      this.table.loadData();
      //+scroll to left bottom
    }
    else {
      AlertyBox.serverError();
    }
  }






  async submit(data) {

    this.props.setBusy(true);
    var values = reduxReduceVaues(userConf.fields, data);
    console.log(values)

    // COLLECT EDITOR DATA
    var content = this.editors['about_me'].getContent();
    content = content.replace(/ +(?= )/g,'');
    content = content.replace(/(\r\n|\n|\r)/gm, "");

    var Export = new tinyExport(content, this.editors['about_me']);
    var content_data = Export.exportContent();


    values.about_me = content;
    values.content_data = content_data;


    var response = await fetchHelper.fetchPost('users/change', values);

    this.props.setBusy(false);

    if (response.status && values && Object.keys(values).length) {
      if (this.props.onSave) {
        this.props.onSave(values);
        userConf.fields.uploadavatar.value = response.data.avatar;
      }
      AlertyBox.message(response);
    } else {
      var m = { message: 'Došlo je do greške prilikom snimanja', status: false };
      AlertyBox.message(m)
    }
    return true;
  }


  async getProductsByOrderId(order_id,shop){
    var r = await fetchHelper.get(`orders/get_products_by_order_id/${shop}/${order_id}`);
    console.log("ORDER DETAILS",r);
    //todo kr:map bottom,check empty...
    if(r.status) {
      this.setState({products_by_order: r.data});
    } else {
      console.log("Bedak")
    }
   
  }



  productsByOrder() {

    const cells = [
    {name:'Ean',cell: 'ean',width:100,  className:''},        
    {name:'Model',cell: 'model',width:100,  className:''},   
    {name:'Naziv',cell: 'name',width:100,  className:''},   
    {name:'Količina',cell: 'quantity',width:100,  className:''},   
    {name:'Pojedinačna cena',cell: 'price',width:100,  className:''},  
    {name:'Popust',cell: 'discount',width:100,  className:''},  
    {name:'Ukupna cena',cell: 'total_price',width:100,  className:''},  
    ]



    let data = [];

    if(this.state.products_by_order) {
      this.state.products_by_order.map((item, index) => {
        console.log(item)
        let c = item.current_product_data;
        let d = item.order_data;
        data.push({ean: c.ean, model: c.model, name: c.name, order_id: d.order_id, quantity: d.quantity, price: d.price, total_price: this.state.active_order.total_price, discount: d.discount})
        
      })
    }

 return <PlainTable itemsPerPage={10} height={"200px"} cells={cells} rows={data} /> 
}

detailFunc = (obj,subobj) => {
  return (
  <div className="wrapper_account_det">   
        {obj[subobj].map((item, index) => {
            if(item.value !== '') {
              return (
                <p key={index} className={item.className}><span>{item.name}</span> {item.value}</p>
              );
            } else {
              return ''
            }
        })}
    </div>
  )
}



  userDetailsInfo() {
    var detailsInfo = {
       part_fs: [
        { name: "ID porudžbine:", value: this.state.active_order.order_id },
        { name: "Vrsta porudžbine:", value: this.state.active_order.shop },
        // { name: "Tip porudžbine:", value: this.state.active_order.type_id},
      ],
      part_sc: [
        { name: "Datum porudžbenice:", value:date_sql_to_rs(this.state.active_order.date)},
        { name: "Datum transakcije:", value: date_sql_to_rs(this.state.active_order.transaction_date)},
        { name: "ID lokacije:", value: this.state.active_order.location_id},
        { name: "Način dostave:", value: this.state.active_order.shipping_option},
        { name: "Vrsta kartice:", value: this.state.active_order.card_type},
        { name: "Nis kartica:", value: this.state.active_order.nis_card},
      ],
      part_th: [
        { name: "Način plaćanja:", value: this.state.active_order.payment_type},
        { name: "Vrednost popusta:", value: this.state.active_order.discount_value + " RSD"},
        { name: "Faktura:", value: this.state.active_order.invoice},
        { name: "Popust:", value: this.state.active_order.discount > 0 ? this.state.active_order.discount + " RSD" :'/', className: 'discount-order'},
        { name: "Ukupna cena:", value: this.state.active_order.total_price + " RSD", className:'order-price'},
      ],
      part_ft: [
        { name: "Status:", value: this.state.active_order.status},
        // { name: "App id:", value: this.state.active_order.app_id},
        { name: "ID reference:", value: this.state.active_order.reference_id},
        { name: "ID plaćanja:", value: this.state.active_order.payment_id},
        { name: "ID transakcije:", value: this.state.active_order.transaction_id},
        { name: "Informacije:", value: this.state.active_order.info},
        { name: "Poštanski kod:", value: this.state.active_order.postal_code},
        { name: "Barkod:", value: this.state.active_order.barcode},
        { name: "Email:", value: this.state.active_order.email},
      ],
    };
   

    return (

      <>
        <h4 className="account-title">Detalji porudžbine</h4>
        {this.detailFunc(detailsInfo,'part_fs')}
        {this.detailFunc(detailsInfo,'part_sc')}
        {this.detailFunc(detailsInfo,'part_th')}
        {this.detailFunc(detailsInfo,'part_ft')}
      </>
    );
  }



  render() {
    console.log("pr",this.state.products_by_order)

    if (!this.props.data) return '';

    const { handleSubmit, invalid, submitting, pristine, busy } = this.props;

    // remove null, set empty string
    userConf.initialValues = this.props.data;

    /* situacija kada se ucitava sa vec postojecim podacina ------------------------  */
    // userConf.initialValues.test1 = 1; // radi testiranja triggera
    // userConf.initialValues.test2 = 1; // radi testiranja triggera
    // userConf.initialValues.test3 = 2; // radi testiranja triggera

    // userConf.fields.test2.parentValue = 1; // radi testiranja triggera
    // userConf.fields.test3.parentValue = 1; // radi testiranja triggera

    /* situacija kada se ucitava prazna forma -------------------------------------- */
    // userConf.initialValues.test1 = ''; // radi testiranja triggera
    // userConf.initialValues.test2 = ''; // radi testiranja triggera
    // userConf.initialValues.test3 = ''; // radi testiranja triggera

    userConf.fields.about_me.value = this.props.about_me;

    var refID = React.createRef();

    // var testconten = '<div class="gigaEditorContent active" contenteditable="true" spellcheck="false"><p class=""><div class="block-element-editor get-img-wrap" data-type="image" contenteditable="false" tabindex="0" style="display: inline-block;"><div class="img-wraper" style="border: none; overflow: initial;"><img src="http://ozone.devgigatron.com/img/gigaapi/uploadavatar-1596182491680-751714920.jpeg"></div><span style="display: inline-block; text-align: center;"></span></div>Nije za zezanje</p><p class="">Idemoooo</p></div>'


    const cells = [
        {name:'ID', cell: 'order_id', width:200,fixed:true, truncate:true, className:'giga-td-first-fixed',sort:true},
        {name:'Mesto porudžbine', cell: 'shop', width:200,fixed:true, truncate:true, className:'giga-td-last-fixed',sort:true},
        {name:'Porudžbina uneta',cell: 'date', width:180,fixed:false,className:'draggable', onBefore:(value)=>{
          return date_sql_to_rs(value);
        }},
        {name:'Porudžbina prošla',cell: 'transaction_date', width:180,fixed:false,className:'draggable',sort:true, onBefore:(value)=>{
          //todo regulisati kad vrati "",ili 00- pa bude error polje npr 1449905,proveriti koji su sve pogresni formati mozda uneti u tabelu...
          return date_sql_to_rs(value);
        }},
        {name:'Ukupna cena', cell: 'total_price', width:200,fixed:false,truncate:true, className:'draggable', onBefore:(value)=>{
          return value+" RSD";
        }},
        {name:'Status', cell: 'status', width:200,fixed:false,truncate:true, className:'draggable'},
        {name:'Web porudžbina obradjena', cell: 'web_order_activated', width:200,fixed:false,truncate:true, className:'draggable',sort:true, 
        onBefore:(value)=>{
          //null je za shop
          return  value ? <i class="fa fa-check"></i> : value==0 ?  <i class="fa fa-times"></i> : ""
        }},
        
        {name:'Detalji', cell:'custom1', custom:true, width:55, fixed:false, align:'center', render:(row)=>{
          return   <button onClick={()=>{
            this.getProductsByOrderId(row.order_id,row.shop)
            this.setState({active_order:row,selected:row.order_id, show_account: true})
        }}><i className="fa fa-folder-open-o" /></button>  
        }},
        

    ]; 

    
    console.log(this.state.active_order)

    
    return (
      <>
        <GigaTabs tab={0}>
          {/* GiaTab title, heigth */}
          <GigaTab title="Osnovni podaci" /*height={400}*/>
            <form id="userform" onSubmit={handleSubmit((data) => {
              if (this.state.allow_submit) {
                this.setState({ allow_submit: false })
                this.submit(data);
              }
            })} className="redux-form" encType="multipart/form-data" >
              {busy ? <div className="form-mask"></div> : ''}
              <Grid columns="repeat(auto-fit,minmax(260px,1fr))" gap="30px">
                <Cell width={1} className="grid-column">
                  <div className="relative">
                    <div className="separate-right"></div>
                    <Field {...userConf.fields.uploadavatar} img_name={this.props.data.avatar} master={refID} index={this.props.index} />
                    <Field {...userConf.fields.id} ref={refID} />
                    <Field {...userConf.fields.first_name} />
                    <Field {...userConf.fields.last_name} />
                    <Field {...userConf.fields.email} />
                    <Field {...userConf.fields.address} />
                    <Field {...userConf.fields.postal_code} />
                    <Field {...userConf.fields.city} />
{/* 
                    <Field {...userConf.fields.test1} instance={userConf} controler={this} value={this.props.test1} />
                    <Field {...userConf.fields.test2} instance={userConf} controler={this} />
                    <Field {...userConf.fields.test3} controler={this} /> */}

                  </div>
                </Cell>
                <Cell className="grid-column">
                  <div className="relative">
                    <div className="separate-right"></div>
                    <Field {...userConf.fields.newsletter} />
                    <Field {...userConf.fields.profile_public} />
                  </div>
                </Cell>
                <Cell className="grid-column">
                  <Field {...userConf.fields.status} />
                  {/* <GigaEditor id="test" contentid={this.props.data.id} content={this.props.data.about_me} config={{
                                        toolbar: [
                                            'Underline', 'Bold', 'Italic', 'StrikeThrough', '|', 'AlignLeft','AlignCenter','AlignRight', '|' ,'Image'
                                        ]
                                    }}  /> */}
                </Cell>
              </Grid>
              <Field {...userConf.fields.about_me}
                      instance={userConf}
                      content_id={this.props.id}
                      ref={this.editorRef}
                      content={this.props.about_me}
                      id="about_me" 
                      height={600}
                      section="users"
                      index={this}
                      url="http://ozone.devgigatron.com/" />
              <div className="field-options">
                {this.props.id?(<a className="field-opt-item" href={website+`preview?id=${this.props.id}&route=gigatron_users&path=about_me`} target="_blank">Preivew</a>):<span className="field-opt-item">Preview will be awailable when page is saved</span>}
              </div>
               <div className="page-bottom-bar">
                <button className="save-changes-form-btn" data-busy={busy} type="submit" disabled={invalid || submitting || busy} onClick={() => { this.setState({ allow_submit: true }) }}>{busy ? (<GigaSpinner />) : 'Save'}</button>
              </div>
            </form>
          </GigaTab>
          <GigaTab title="Kupovine">
            <div className="table-det-wrapper">
              <GigaTable

                cells={cells}
                pk='order_id'
                id={`all_user_orders` + this.props.data.id}
                height={300}
                db="gigatron_orders+gigatron_orders_shop_final+gigatron_orders_final"
                //db-za update i delete,u svakom slucaju blokirati 
                fetch={`orders/all_user_orders/${this.props.data.id}`}

                buttons={false}
                delete={false}
                selected={this.state.selected}
                getInstance={this.getTableInstance}
                resetActive={this.resetActive}
              />

            </div>



            {this.state.show_account ? ( 
              <div className="account-overlay">
                <div className="account-wrapper">
                  {this.userDetailsInfo()}
                  <div className="product-content">
                    <h4 className="account-content">Sadržina porudžbine</h4>
                    {this.productsByOrder()}
                  </div>
                  <button className="close-account" onClick={() => { this.setState({ show_account: false }) }}><i className="fa fa-times"></i></button>
                </div>
              
              </div>)
              :
              ""
            }
          </GigaTab>
        </GigaTabs>
      </>
    );
  }
}

const selector = formValueSelector('userform');

const mapStateToProps = state => ({
  initialValues: userConf.initialValues,
  busy: state.forms.busy,
  id: selector(state, 'id'),
  // test1: selector(state, 'test1'),
  // test2: selector(state, 'test2'),
  // test3: selector(state, 'test3'),
  about_me: selector(state, 'about_me')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setBusy,
      updateField: (field, data) => change("userform", field, data),
    },
    dispatch
  );

UserForm = reduxForm({
  form: "userform",
  reduxAsyncValidate,
  enableReinitialize: true,
})(UserForm);
UserForm = connect(
  mapStateToProps, mapDispatchToProps
)(UserForm);
export default UserForm;
// export default connect(mapStateToProps, mapDispatchToProps)(UserForm);