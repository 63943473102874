import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import $ from 'jquery';

class GigaTableFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_search: false,
    };

    this.id = null;
    this.ref = React.createRef();
    this.refSearch = React.createRef();
    
    this.pageMinus = this.pageMinus.bind(this);
    this.pagePlus = this.pagePlus.bind(this);
    this.pageCustom = this.pageCustom.bind(this);
    this.showHideSearch = this.showHideSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    
    this.page = null;
    this.tm = null;

  }

  componentDidMount(){
    this.ref.current.value = this.props.page;
    this.page = this.props.page;
  }

  componentDidUpdate(){
    if(this.page !== this.props.page){
        this.page = this.props.page;
        this.ref.current.value = this.props.page;
    }

    if(this.id !== this.props.id){
      this.id = this.props.id;

      if(this.refSearch.current){
        this.refSearch.current.value = '';
        this.setState({show_search:false});
      }
    }
  }


  pageMinus(){
    var p = parseInt(this.props.page);
    if(p > 1){
        p = p -1;
        this.props.index.setState({page:p},()=>{
            this.props.index.loadData();
        });
    }
    
  }

  pagePlus(){
    var p = parseInt(this.props.page);
    p = p + 1;
    this.props.index.setState({page:p},()=>{
        this.props.index.loadData();
    });
  }

  pageCustom(e){
    var value = parseInt(e.currentTarget.value);
    if(value < 1){
        value = 1;
    }
    if(isNaN(value) && value){
        value = 1;
        this.ref.current.value = value;
    }

    if(value < 1){
        this.ref.current.value = 1;
        value = 1;
    }

    if(parseInt(e.currentTarget.value) === 0){
        this.ref.current.value = 1;
        value = 1;
    }

    if(this.page !== value && !isNaN(value)){
        clearTimeout(this.tm);
        this.tm = setTimeout(()=>{
            this.ref.current.blur();
            this.props.index.setState({page:value},()=>{
                this.props.index.loadData();
            });
        },600)

    }
  }

  showHideSearch(){
     this.setState({show_search: this.state.show_search ? false :true},()=>{
         if(this.state.show_search){
             this.refSearch.current.focus();
         }
     });
  }

  onSearch(e){
      if(e.keyCode === 13){
        var value = e.currentTarget.value;
        this.props.index.setState({search:value},()=>{
            this.props.index.setState({uncheckAndClearState:true});
            this.props.index.resetPage(()=>{
              this.props.index.loadData();
            })
            
        })
    }
  }

  findUp(){ 
    let ind=this.props.rows.findIndex(row => parseInt(row[this.props.pk]) === parseInt(this.props.selected));
    let last=this.props.rows[ind-1];

    let topFromContainer=$('.giga-table-scroller').offset().top;
    let topFromSelected= $('.giga-table-rows .selected').offset().top;
    let rowHeight=$('.giga-table-rows .selected').outerHeight();

    if(topFromSelected < topFromContainer+rowHeight-3){
      var m = $(".giga-table-scroller").scrollTop();  
      $(".giga-table-scroller").scrollTop(m-rowHeight);
    }
    return last;
  }

  findDown(){
    let ind=this.props.rows.findIndex(row => parseInt(row[this.props.pk]) === parseInt(this.props.selected));
    let last=this.props.rows[ind+1];
  
    let topFromContainer=$('.giga-table-scroller').offset().top;
    let topFromSelected= $('.giga-table-rows .selected').offset().top;
    let contHeight= $('.giga-table-scroller').outerHeight();
    let rowHeight=$('.giga-table-rows .selected').outerHeight();

    let selected= topFromSelected+rowHeight*2-1;
    let bottomLine=topFromContainer+contHeight; 

    if(selected > bottomLine){
      var m = $(".giga-table-scroller").scrollTop();  
      $(".giga-table-scroller").scrollTop(m+rowHeight);
    }
    return last;
  }

  render() {
    return (
      <div className="giga-table-footer">
          {this.props.loading?(<div className="gtf-loading-mask"></div>):''}
         <div className="gtf-section">
             <span><i className="fa fa-angle-left icon-btn" onClick={this.pageMinus} /></span>
             <input ref={this.ref}  style={{width:50}} onChange={this.pageCustom} />
             <span><i className="fa fa-angle-right icon-btn" onClick={this.pagePlus} /></span>
         </div>
         {this.props.run && this.props.selected ? (
           <div className="gtf-section float-right noselect">
             <i className="fa fa-angle-up icon-btn lg-icon-btn" onClick={()=>{this.props.run(this.findUp())}} /> 
             <i className="fa fa-angle-down icon-btn lg-icon-btn"  onClick={()=>{this.props.run(this.findDown())}} />
           </div>
         ):''}
         <div className="gtf-section float-right">
             {this.state.show_search?<input ref={this.refSearch} className="giga-table-search" onKeyDown={this.onSearch}  />:''}
            <i className="fa fa-search icon-btn" aria-hidden="true" onClick={this.showHideSearch}></i>
         </div>
      </div>
    );
  }
  
}


const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default 
    withRouter(connect(
      mapStateToProps,
      mapDispatchToProps
  )(GigaTableFooter));