import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import GigaTableHeader  from './GigaTableHeader'
import GigaTableRows from './GigaTableRows';
import Buttons from './Buttons';
import AlertMessage from '../../components/components/AlertMessage';
import {fetchHelper} from './../../helpers/fetchHelper';
import $ from 'jquery';
import AlertyBox from "../../libraries/AletyBox/index";
import PlainTable from "../../libraries/PlainTable/PlainTable";

import './style.scss';
import GigatableFooter from './GigatableFooter';

class GigaTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
        rowsObj: null,
        width: 'auto',
        select_all: false,
        loading: true,
        rows: [],
        cells: [],
        error: '',
        success: '',
        page:1,
        sort_order: null,
        sort_cell: null,
        search: '',
        id: '',
        uncheckAndClearState:false,
    };

    this.isDeleteAppended = false;
    this.tableId          = null;

    this.onScroll       = this.onScroll.bind(this);
    this.onSelectAll    = this.onSelectAll.bind(this);
    this.onEditCell     = this.onEditCell.bind(this);
    this.updateRow      = this.updateRow.bind(this);
    this.deleteRows     = this.deleteRows.bind(this);
  }

  componentDidMount(){
    if(this.props.cells !== undefined && this.props.cells){
        var width = 0;
        if(this.props.cells){
             this.props.cells.map(cell=>{
                 width = width + cell.width + 20;
             });
         }

         var cells = this.props.cells;

         var sessionCells = localStorage.getItem('SORTCELL_'+this.props.id);
         if(sessionCells && sessionCells.length){
             var c = [];
             sessionCells = sessionCells.split(',');
             if(Array.isArray(sessionCells)){
                sessionCells.map(key=>{
                    cells.map((cl)=>{
                        if(cl.cell === key){
                            c.push(cl);
                        }
                    })
                });
                cells = c;
            }
         }

         this.props.cells.map(oric=>{
           var h = false;
           cells.map((cl,i)=>{
             if(oric.name === cl.name){
               h = true;
             }
           });
           if(!h) cells.push(oric);
         })

         this.setState({width:width,cells:cells});
      }

      if(this.props.getInstance) this.props.getInstance(this);

      this.loadData();
  }
  
  componentDidUpdate(prevProps,prevState){
    //todo reset check load
    if(this.state.page!=prevState.page){
      this.setState({uncheckAndClearState:true})
      this.props.resetActive();
    }
    if(this.tableId !== this.props.id){
      this.tableId = this.props.id;
      this.setState({uncheckAndClearState:true})
      this.setState({search:'',id:this.props.id, page:1},()=>{
        this.loadData(true);
      })
    } else {

      let f=this.props.fetch;
      if(f && f!=prevProps.fetch){
        this.loadData(true);
      }

    }


  }


  resetPage(callback){
    this.setState({page:1},()=>{
      callback();
    });
  }


  async loadData(scrolltotop,callback){
    this.setState({loading:true});
    let result = await fetchHelper.get(this.props.fetch+'/'+this.state.page+this.queryUrl());
    this.setState({loading:false,rows:result.status?result.items:[]},()=>{
      if(callback){
        callback();
      }
    });
    
    /* scroll to top rows */
    if(scrolltotop !== false){
      var el = this.state.rowsObj.rowRef.current;
      if(el){
        el.scrollTop = 0;
      }
    }
  }

  queryUrl(){
    var query = null;
    if(this.state.sort_cell){
        if(!query) query = '?';
        query+='sortby='+this.state.sort_cell+'&sort='+this.state.sort_order;

    }

    if(this.state.search){
        if(!query) query = '?'; else query = query+'&';
        query+='search='+this.state.search;
    }

    if(query) return query; else return '';
  }

  onScroll(event){
    var el = event.currentTarget;
    var right = -(el.scrollLeft);
    if(this.state.rowsObj){
      var child = this.state.rowsObj.rowRef.current;
      if(child){
        child.style.right = right+'px';
      }
    }
    
    if(!this.props.is_mobile){
        var fix = $(`#${el.id} .giga-fixed-td`);
        if(fix.length){
            fix.css({'left':Math.abs(right)+'px'});
        }
      }
  }

  onSelectAll(sa){
    this.setState({select_all:sa});
  }

  hasActiveRows(){

    if(this.state.rowsObj){
        if(this.state.rowsObj.state.active){
            if(Object.keys(this.state.rowsObj.state.active).length){
                return true;
            }
        }
    }

    return false;
  }

  buttonsView(){
    let b =this.props.buttons;
    let d =this.props.delete;
    let buttons=[];
    if(!b&&!d){
    return <div className="giga-table-buttons" style={{height:"24px"}}>{this.props.title?<h4>{this.props.title}</h4>:''}</div>
    }
    if(b){
      buttons=b;
    }
    if(d && this.isDeleteAppended === false){
      buttons.push({title: 'Obriši', type: 'button', onClick:this.deleteRows})
    }
    return <Buttons buttons={buttons} title={this.props.title} />
  }

  deleteRows(){  
    if(this.hasActiveRows()){
      this.confirmMSG();
    }
    return false;
  }

  confirmMSG(){
    AlertyBox.confirm('Da li ste sigurni da želite da obrišete izabrano?').then(async (result)=>{
      if(result.isConfirmed){
        var active = this.state.rowsObj.state.active;
        var ids = Object.keys(active).map(id=>{return id});
        this.setState({loading:true});
        
        var data = {ids:ids,table:this.props.db};

        if(this.props.delete_trigger){
          data.delete_trigger = this.props.delete_trigger;
        }

        var result = await fetchHelper.delete('row/delete',data);
        
        this.setState({loading:false,error:result.status && result.data.status?null:result.data.message.message,success:result.status&&result.data.status?result.data.message:null});
        if(result.status){
          this.removeBottomFormAndRemoveCheckersFromTable()
        }
      }
    })
  }

  removeBottomFormAndRemoveCheckersFromTable(){
      //1.Uncheck&&clear state of table components
      this.setState({uncheckAndClearState:true})
      //2.Remove  name*Form(PagesForm..) from view, by calling this.props.resetActive().
      //That sets : active_page:null,selected:null in parent component;
      this.props.resetActive();
      //3.Reload data for table  >check 
      this.loadData();
  }





  message(){
      return <AlertMessage error={this.state.error} success={this.state.success} resetMessage={()=>this.setState({errro:'',success:''})} />
  }

  updateRow(data){
    var rows = this.state.rows;
    rows.map((row,i)=>{
      if(row[this.props.pk] == data[this.props.pk]){
        rows[i][data.prop.cell] = data.value;
        //+update bottom form
        this.props.run(rows[i])
      }
    });
    this.setState({rows:rows})
  }
 
  async insertRowCell(model,additional_data){
    // use case:when you wanna update a db table that doesn't have a status column,but represents a connection between 2 other tables;
    // where in front table fake status represents options,and status true existence of actual data in db;
    // eg: backoffice_user_groups
    this.setState({loading:true});
    var data = {
      model: model,
      additional_data:additional_data
    }
    const result = await fetchHelper.post('row/insert_fake',data);
    if(!result.status){
       this.setState({error:result.message});
    } else {
      this.loadData();
    }

    this.setState({loading:false});
    return true;
  }

  async removeRowCell(data){
    this.setState({loading:true});  
    const result = await fetchHelper.delete('row/delete',data);
    if(!result.status){
       this.setState({error:result.message});
    } else {
      this.loadData();
    }

    this.setState({loading:false});
    return true;
  }
  
  async updateRowCell(row2,cell,value){
     this.setState({loading:true});
     var data = {
       table: this.props.db,
       cell: cell,
       value: value,
       id: row2[this.props.pk]
     }
     const result = await fetchHelper.post('row/update',data);
     if(!result.status){
        this.setState({error:result.message});
     } else {
       this.loadData();
     }

     this.setState({loading:false});
     return true;
  }

  onEditCell(result){
    
    this.setState({loading:false,error:result.status && result.data.status?null:result.data.message.message,success:result.status&&result.data.status?result.data.message:null});
  }

  title(){
    return this.props.title ? <h4>{this.props.title}</h4>:''
  }


  render() {
    return (
        <>
        
        {/*zakomentarisati detalje i status u cells */}
        {/* <div>
            {this.state.rows ? <PlainTable itemsPerPage={30} height={"500px"} cells={this.state.cells} rows={this.state.rows}/> :""}
        </div>  */}

            {this.message()}
            {this.buttonsView()}
            <div className="giga-table-wrapper" onScroll={this.onScroll} id={this.props.id}>
                <div className="giga-table" style={{width:this.state.width,height:this.props.height}}>
                    <GigaTableHeader {...this.props} 
                      cells={this.state.cells}
                      width={this.state.width} 
                      index={this} 
                      onSelectAll={this.onSelectAll} 
                      title={this.props.title}
                      uncheckAndClearState={this.state.uncheckAndClearState}
                      />
                    <GigaTableRows {...this.props} 
                      cells={this.state.cells} 
                      height={this.props.height} 
                      width={this.state.width} 
                      index={this} 
                      selectAll={this.state.select_all} 
                      rows={this.state.rows} 
                      loading={this.state.loading} 
                      onEditCell={this.onEditCell} 
                      updateRow={this.updateRow} 
                      uncheckAndClearState={this.state.uncheckAndClearState}
                    />
                </div>
            </div>
            <GigatableFooter {...this.props} 
              index={this} 
              page={this.state.page} 
              loading={this.state.loading} 
              rows={this.state.rows} 
              id={this.state.id}
            />
      </>
    );
  }

}


const mapStateToProps = state => ({
  user: state.auth.user,
  is_mobile:state.auth.is_mobile,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default 
    withRouter(connect(
      mapStateToProps,
      mapDispatchToProps
  )(GigaTable));