import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouting from './AppRouting';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import Header from './components/common/Header';
import Routes from './routes';
import Title from './components/components/Title';
import SideMenu from './components/components/SideMenu';
import Breadcrumbs from "./components/components/Breadcrumbs/Breadcrumbs";
import GigaSpinner from './libraries/GigaSpinner';

class App extends Component {
  constructor(props) {
    super(props);

    
  }


  render() {
    return (
      <BrowserRouter>
        <AppRouting>
          {this.props.fullscreenLoader ? 
          <div className="full-page-loader">
                <GigaSpinner />
          </div> 
          :""}
          <Header />
          {/* <Title/> */}
          <SideMenu />
          {this.props.is_mobile ? <Breadcrumbs/> :""}
          <div id="dialog_container">{this.props.preview}</div>
          <Routes/>
        </AppRouting>
      </BrowserRouter>
    );
  }
  // }
}


const mapStateToProps = state => ({
  is_mobile: state.auth.is_mobile,
  fullscreenLoader: state.forms.fullscreenLoader,
  preview: state.forms.preview
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

  export default 
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(App);
