import React,{ useState } from 'react';
import GigaEditor from './../libraries/GigaEditor';
import Gallery from '../components/gallery/Gallery';
import GigaDatePicker from "./../libraries/GigaDatePicker/index";
import $ from 'jquery';

import { Editor } from '@tinymce/tinymce-react';

import { date_sql_to_rs, date_rs_to_sql } from "./../helpers/dateHelper";

import { pluginController } from '../libraries/GigaEditor/tinymce/plugins/plugincontroller';
import { customPlugin } from '../libraries/GigaEditor/tinymce/plugins/customPlugin/index.js';
import { counterPlugin } from '../libraries/GigaEditor/tinymce/plugins/counterPlugin';
import { previewPlugin } from '../libraries/GigaEditor/tinymce/plugins/previewPlugin';
import { tabPlugin } from '../libraries/GigaEditor/tinymce/plugins/tabPlugin';
import { productsPlugin } from '../libraries/GigaEditor/tinymce/plugins/productsPlugin';
import ColorPickerField from '../libraries/GigaEditor/tinymce/fields/ColorPickerField';
import { sliderPlugin } from '../libraries/GigaEditor/tinymce/plugins/sliderPlugin';

export const reduxAsyncValidate = (values,props) => {
  
  Object.keys(values).map(key=>{
    props.touch(key);
  })
  props.asyncValidate();
}


export const reduxReduceVaues = (fields, values) => {
  var v = {};
  Object.keys(fields).map(key=>{
    if(values[key] === null || values['key']==='null') values['key'] = '';
    v[key] = values[key];
  });
  return v;
}


export const reduxPrepareFormData = (fields,values) =>{
  var v = {};
  Object.keys(fields).map(key=>{
    v[key] = values[key] === null || values[key] === 'null' || values[key] === '<p>null</p>' || !values[key] ? '' : values[key];
  });
  return v;
}


export function renderFieldInput({
    input,
    label,
    labelHide,
    type,
    size,
    readonly,
    maxLength,
    style,
    trigger,
    instance,
    controler,
    parentValue,
    info,
    meta: { touched, error, warning } 
  }) {
    var ref = React.createRef();
    var changeType = (ref, event)=>{
      var type = ref.current.type;
      ref.current.type = type==='password'?'text':'password';
      var t = ref.current.type;
     if(t==='password'){
        event.currentTarget.classList.remove('fa-eye');
        event.currentTarget.classList.add('fa-eye-slash'); 
      } else {
        event.currentTarget.classList.remove('fa-eye-slash');
        event.currentTarget.classList.add('fa-eye');
      }
    }
    return (
    <div className="form-group" style={{display:type==='hidden'?'none':''}}>
        {labelHide ? "": <label>{label}:</label>}
        <span style={{position: 'relative'}}>
          <input ref={ref} {...input} placeholder={label} type={type} className={(size ? size : '')+(type==="password"?' password-inp':'')} readOnly={readonly ? readonly : false} maxLength={maxLength} 
          onChange={(e)=>trigger ? trigger(e.target.value,input, instance, controler, parentValue) : input.onChange(e.target.value)} 
          />
        {(type === 'password' ? <i className={"fa fa-eye-slash icon-password"} onClick={(event)=>changeType(ref, event)}></i>:"")}
      </span>
      {info?<small>{info}</small>:''}
        {touched && ((error && <span className="form-field-error">{error}</span>) || (warning && <span className="form-field-warning">{warning}</span>))}
     
    </div>
)}


//todo 
export function renderDatePicker({
  size,
  content_id,
  input,
  label,
  readonly,
  disabled,
  meta: { touched, error, warning },
}){ 
 

  if(input.value && input.value.currentTarget){
    input.value = input.value.currentTarget.value;
  }

  if(input.value.indexOf('-') > -1){
    input.value = date_sql_to_rs(input.value);
  }

  if(input.value === 'error'){
    input.value = '';
  }

  if(input.value.indexOf('Invalid date') > -1){
    input.value = '';
  }

 
  return <div className="form-group"> 
  <label>{label}:</label>
        <GigaDatePicker
        size={size}
        content_id={content_id} 
        input={input}
        formDatePicker={true}
        readOnly={readonly}
        disabled={disabled}
        placeholder={label}
        name={input.name} 
        value={input.value}
        onChange={input.onChange}
        required={false}
        />
        {touched && error && <span>{error}</span>}
  </div>
};


export function renderFieldSelect({
  input,
  label,
  size,
  readonly,
  options,
  value,
  trigger,
  id,
  disabled,
  parentValue,
  instance,
  controler,
  display,
  button,
  meta: { touched, error, warning }
}){ 
  return (<div className="form-group" style={{display:display?display(parentValue):''}}>
      <label>{label}:</label>
      <select id={id?id:input.name} 
        {...input} 
        className={size?size:''} 
        readOnly={readonly? readonly : false} 
        data-value={value} 
        disabled={disabled || ''}
        onChange={(e)=>trigger ? trigger(e.target.value,input, instance, controler, parentValue) : input.onChange(e.target.value)} 
      >
        <option></option>
        {options?(
          options.map((opt,i)=>{
              if(opt.parent !== undefined && opt.parent==parentValue && (parentValue !== '' && parentValue !== null)){ 
                return (<option data-i="opt" key={i} value={opt.value}>{opt.text}</option>)
              }
              if(opt.parent === undefined){
                return (<option data-i="self"  key={i} value={opt.value}>{opt.text}</option>)
              }
            }
          )
        ):''}
      </select> {button?button(instance,value):''}
      {touched && ((error && <span className="form-field-error">{error}</span>) || (warning && <span className="form-field-warning">{warning}</span>))}
  </div>
)}


export function renderFieldCheckbox({
  input,
  label,
  readonly,
  meta: { touched, error, warning }
}){ return (
  <div className="form-group">
      <label>{label}:</label>
      <input {...input} type="checkbox" readOnly={readonly ? readonly : false} checked={Boolean(input.value)} />
      {touched && ((error && <span className="form-field-error">{error}</span>) || (warning && <span className="form-field-warning">{warning}</span>))}
  </div>
)}


export function renderRadioButtons ({
  input,
  readonly,
  options,
  label,
  meta: { touched, error, warning }
}){ return (
  <div className="form-group">
      <div className="radio-group" >
        <label>{label}:</label>
        {options.map((opt,i)=>(
            <span key={i}>
             
              <input id={opt.name+'-'+i} {...input} type="radio" readOnly={readonly ? readonly : false} value={opt.value} checked={input.value.toString() === opt.value.toString()} />
              <label htmlFor={opt.name+'-'+i}>{opt.label}</label>
            </span>
        ))}
      </div>
      {touched && ((error && <span className="form-field-error">{error}</span>) || (warning && <span className="form-field-warning">{warning}</span>))}
  </div>
)}


export function renderFieldTextarea ({
  input,
  label,
  readonly,
  size,
  height,
  resizable,
  meta: { touched, error, warning }
}){ return (
  <div className="form-group">
      <label>{label}:</label>
      <textarea {...input} readOnly={readonly ? readonly : false} className={size ? size : ''} placeholder={label} style={{height:height?height:'auto',resize:resizable?'vertical':'none'}} ></textarea>
      {touched && ((error && <span className="form-field-error">{error}</span>) || (warning && <span className="form-field-warning">{warning}</span>))}
  </div>
)}


export function renderFieldColorPicker ({
  input,
  label,
  readonly,
  enableAlpha,
  color,
  meta: { touched, error, warning }
}){ return (
  <div className="form-group">
      <span style={{display:'inline-block',width:120,height:60,position:'relative'}}>
        <ColorPickerField {...input} color={color?color:'rgb(255,255,255)'} label={label} enableAlpha={enableAlpha} readOnly={readonly ? readonly : false} /> 
        <i className="fa fa-times" onClick={()=>{input.onChange({[input.name]:null})}} style={{position:'absolute',top:35,right:10,cursor:'pointer'}} /> 
      </span>
      {touched && ((error && <span className="form-field-error">{error}</span>) || (warning && <span className="form-field-warning">{warning}</span>))}
  </div>
)}


export function renderGigaEditor ({
  input,
  id,
  label,
  content_id,
  url,
  img_list_url,
  section,
  height,
  index,
  meta: { touched, error, warning },
}){ return (
  <div className="form-group">
      <label>{label}:</label>
      <GigaEditor id={id} contentid={content_id} content={input.value} input={input} index={index} config={{
          toolbar: [
            'Underline', 'Bold', 'Italic', 'StrikeThrough', '|', 'AlignLeft','AlignCenter','AlignRight','AlignJustify','Link','Paragraph','|','Preview'
          ],
          extend_toolbar: [
            'Template','Images','|','Indent','Outdent','|','Bullets','List','|' ,'Image','Table','|','ClearStyle','Css','Wrap','Unwrap','Paint','BR','FontSize','Block'
          ],
      }} onChange={input.onChange} 
      url={url}
      img_list_url={img_list_url}
      section={section}
      height={height}
      />
      {touched && ((error && <span className="form-field-error">{error}</span>) || (warning && <span className="form-field-warning">{warning}</span>))}
  </div>
)}

const ckeditorValues = {};

export function renderEditor ({
  input,
  id,
  label,
  ref,
  content_id,
  url,
  img_list_url,
  section,
  height,
  index,
  meta: { touched, error, warning },
}){
  
  if(ckeditorValues[id] === undefined){
    ckeditorValues[id] = input.value;
  }

  let loadPlugins = async (editor, list) => {

  }
  
  return (
  <div className="form-group" id="tinyMceEditor">
      <label>{label}:</label>
      <Editor 
        ref={ref}
        apiKey="8tck7hwyzkzu4wxeo0wkkre3n0jkzqwt3ol9r6kn6a2et2vp"
        id={id}
        value={input.value}
        onEditorChange={input.onChange}
        init={{
           valid_children : '+div[style]',
           content_css: ['/assets/css/plugins.css', 'swiper/swiper.scss'] ,
           valid_elements: '*[*]',
          // verify_html: false,
          //  content_css: '/assets/css/plugins.css',
           content_style: '.row:after,.col:after,.clear:after {content: "";}',//convert "" editor fix > (col:after {content: &quot;&quot: ;)
           height: height,
           noneditable_editable_class: 'mceEditable',
           menu: {
            file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
            edit: { title: 'Edit', items: 'undo redo | cut copy paste  | selectall | searchreplace' },
            view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
            insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat' },
            tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
            table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
            help: { title: 'Help', items: 'help' },    
          },
          //todo za mob 
          //todo remove hlp., add  perm.res. wrapp...templ.pick..
          menubar: 'file edit view insert format tools table help',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            //nasi od ranije reci dal bi da brisemo prewiew il nesto dr:
            'hidetoolbar noneditable preview_plugin plugincontroller counter_plugin tab_plugin products_plugin slider_plugin',
          ],
          toolbar1:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | \
            help  hidetoolbar | ',
            //nasi jedino je hidetoolbar curnut gore:
          toolbar2 : 
            'preview_plugin plugincontroller counter_plugin tab_plugin products_plugin slider_plugin',
       
          formats: {
            'plugincontroller' : { block: 'div', 'data-controller': 'plugin' },
          },
          //more-drawer moze da se autogenerise al za to treba da ceo prvi red bude popunjen a to rece.....
          setup: editor => {
            pluginController(editor);
            counterPlugin(editor);
            previewPlugin(editor);
            tabPlugin(editor);
            productsPlugin(editor);
            sliderPlugin(editor);
            editor.ui.registry.addButton('hidetoolbar', {
              icon: 'more-drawer',
              onAction: function (_) {
                $(".tox-toolbar-overlord").toggleClass( "showMe" );
              },
            });
       
            
          },

        }}
         
        onInit={ (event,editor) => {
          // console.log(editor)
          index.setEditorInstance(id,editor);
          editor.settings.section = section;
          editor.settings.content_id = content_id;
          
        }}
        onSelectionChange={(event, editor)=>{
           var node = editor.selection.getNode();
           
        }}
      />
      {touched && ((error && <span className="form-field-error">{error}</span>) || (warning && <span className="form-field-warning">{warning}</span>))}
  </div>
)}

export function renderFieldUploadImage ({
  input,
  label,
  size,
  readonly,
  url,
  img_name,
  value,
  place,
  remove,
  master,
  index,
  meta: { touched, error, warning },
}){ return (
  <div className="form-group file-wrapper">
    <div className="placeholder-wrapper">
      <div className="image-placeholder" style={{width:place.width,height:place.height}}>
        {img_name? <img src={url+img_name} /> : ''}
        {remove && img_name ?(
          <div className="remove-image-placeholder" onClick={()=>remove(master,index,input.name)}>
            <i className="fa fa-times"></i>
          </div>
        ):''}
      </div>
      </div>
      <div className="placeholder-content">
      <label>{label}:</label>
        <input {...input} placeholder={label} type="file" value={value}  className={size ? size : ''} readOnly={readonly ? readonly : false}  multiple="multiple" key={label+index.state.selected} />
        {touched && ((error && <span className="form-field-error">{error}</span>) || (warning && <span className="form-field-warning">{warning}</span>))}
      </div>
  </div>
)}


export function renderGallery(args){ return (
   
     <Gallery args={args}/> 
 
)}