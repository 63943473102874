import React from 'react';
import ImageModal from './../modals/ImageModal';
import TableModal from './../modals/TableModal';
import LinkModal from './../modals/LinkModal';
import $ from 'jquery';

class Initialize {
    constructor(){
        
    }


    initializeAll(editor){

        this.initializeTables(editor);
        this.initializeTemplates(editor);
        this.initializePaint(editor);
        this.initializeImages(editor);
        this.initializeLink(editor);

        if(editor.state.UndoRedo){
            editor.state.UndoRedo.isDisabled('undo');
            editor.state.UndoRedo.isDisabled('redo');
        }
    }

    initializePaint(editor){

    }


    initializeLink(editor){
        var holder = editor.ref.current;
        var links = holder.querySelectorAll('a');
        if(links && links.length){
            for(var i = 0; i < links.length; i++){
                var a = links[i];
                $(a).unbind('click');
                $(a).on('click',(e)=>{
                    var link = e.currentTarget;
                    editor.setState({clickedElement:link, activeType:'text', customModal: <LinkModal editor={editor} element={{current:link}} />});
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                })
            }
        }
    }


    initializeImages(editor){
        var blocked = editor.ref.current.querySelectorAll('.block-element-editor');
        if(blocked && blocked.length){
            for(var i = 0; i < blocked.length; i++){
                var b = blocked[i];
                b.ondragstart = function() { return false; };
            }
        }

        var items = editor.ref.current.querySelectorAll('figure');
        if(items && items.length){
            for(var i = 0; i < items.length; i++){
                var obj = items[i];
                obj.onclick = ((e)=>{
                    
                    e.stopPropagation();
                    e.preventDefault();
                    editor.setState({
                        customModal: <ImageModal editor={editor} element={e.currentTarget} />,
                        activeType: 'image',
                        active: obj
                    });
                    return false;
                })
            }
        }
    }

    initializeImage(blocked,editor){
        
        
        blocked.ondragstart = function() { return false; };
        
        blocked.onclick = ((e)=>{
            e.stopPropagation();
            e.preventDefault();
            editor.setState({
                customModal: <ImageModal editor={editor} element={e.currentTarget} />,
                activeType: 'image',
                active: e.currentTarget
            });
            return false;
        })
        
    }


    initializeTable(el,editor){
        
    }


    getOffset( el ) {
        var rect=el.getBoundingClientRect();
        return {left:rect.left,top:rect.top};
    }


    initializeTables(editor){
        var ref = editor.ref.current;
        var tds = ref.querySelectorAll('td');
        if(tds && tds.length){
            for(var i = 0; i < tds.length; i++){
                var td = tds[i];
                var table = td.closest('table');
                this.initializeTable(table,editor)
                this.initializeTableTd(td, editor);
            }
        }
    }


    initializeTableTd(td, editor){
        td.ondblclick = (e)=>{
            var z = e.currentTarget;
            var ex = document.body.querySelector('.editor-element-help');
            if(ex) ex.remove();
            var help = document.createElement('div');
            help.classList.add('editor-element-help');
            help.classList.add('noselect');
            help.innerHTML = `<span class="ge-form-group">
                    <label>Width</label>
                    <input class="small" name="width" />
            </span>
            <span class="ge-form-group">
                    <label>Padding</label>
                    <input class="small" name="padding" />
            </span>
            <div class="editor-td-align">
                <button value="top" class="valign" title="Vertical Top"><i class="fa fa-align-center" ></i></button>
                <button value="middle" class="valign" title="Vertical Center"><i class="fa fa-align-justify" ></i></button>
                <button value="bottom" class="valign" title="Vertical Bottom"><i class="fa fa-align-center" style="transform:rotate(180deg)" ></i></button>
            </div>`;
            document.body.appendChild(help);

            var pos = this.getOffset(z);

            help.style.top = pos.top+'px';
            help.style.left = pos.left +'px';

            help.querySelector('input[name="width"]').focus();

            help.querySelector('input[name="width"]').value = z.style.width;
            help.querySelector('input[name="padding"]').value = z.style.padding;
            
            help.contentEditable = false;
            
            var inputs = help.querySelectorAll('input');
            for(var i = 0; i < inputs.length; i++){
                var input = inputs[i];
                input.onclick = (e2) => e2.stopPropagation();
                input.onkeypress = (e3=>{
                    if(e3.keyCode === 13){
                        var value = {a:e3.target.value};
                        var name = e3.target.name;
                        z.style[name] = value.a;
                        help.remove();
                    }
                });
            }

            var vbs = help.querySelectorAll('.valign');
            for(var i2 = 0; i2 < vbs.length; i2++){
                var vbt = vbs[i2];
                vbt.onclick = (e4 => {
                    var value = e4.currentTarget.value;
                    z.style.verticalAlign = value;
                })
            }
        }
        td.onclick = (e)=>{
           
          
            var ex = document.body.querySelector('.editor-element-help');
            e.currentTarget.focus();
            if(ex) ex.remove();
        }
    }


    normalizeContent(_html){

        if(typeof _html === 'object'){
            var html = _html.html;
        } else {
            var html = _html;
        }

  
        // var boldRgx = new RegExp('<\/?bold','g');
        // html = html.replace(/<b>/g,'<span style="font-weight:bold;">');
        // html = html.replace(/<\/b>/g,'</span>');

        // html = html.replace(/<i>/g,'<span style="font-style:italic;">');
        // html = html.replace(/<\/i>/g,'</span>');

        // html = html.replace(/<u>/g,'<span style="text-decoration:underline;">');
        // html = html.replace(/<\/u>/g,'</span>');

        var bl = new RegExp('<span style="font-weight:bold;"></span>','g');
        html = html.replace(bl,'');

        var fis = new RegExp('<span style="font-weight:bold;"><img','g');
        html = html.replace(fis,'<img');

        html = html.replace(/<\/span><\/figure>/g,'</figure>');
        html = html.replace(/<span style="font-weight:bold;"><br><\/span>/g,'');
        html = html.replace(/<p class=""><\/p>/g,'<p></p>');
        html = html.replace(/<p class=""><br><\/p>/g,'<p></p>');
        html = html.replace(/<br>/g,'');

        return html;
    }


    async initializeTemplate(el, editor){

        var t = el.querySelector('[data-type="template"]');
        if(!t){
            t = el;
        }

        // t.onclick = (e) => {
        //     //e.stopPropagation();
        //     editor.templateClick(e);
        // }
        if(t.dataset.onload){
            const f = t.dataset.onload;
            const onLoad =  await import(`./../plugins/templates/${f}/onload.js`);
            var script = new onLoad.onLoad(t, editor);
            script.init();
        }
    }

    async initializeTemplates(editor){
        var templates = editor.ref.current.querySelectorAll('[data-type="template"]');
        if(templates && templates.length){
            for(var i = 0; i < templates.length; i++){
                var t = templates[i];
                //$(t).unbind('click');
                // $(t).on('click', (e) => {
                    
                //     editor.templateClick(e);
                // });
                if(t.dataset.onload){
                   
                        const f = t.dataset.onload;
                        const onLoad =  await import(`./../plugins/templates/${f}/onload.js`);
                        var script = new onLoad.onLoad(t, editor);
                        script.init();
                    
                }
            }
        }
    }
}


export default Initialize;