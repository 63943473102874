import React, { Component } from "react";
import $ from "jquery";
import Modal from "./base/modal";
import { fetchHelper } from "./../../helpers/fetchHelper";
import AlertyBox from "../../libraries/AletyBox";

import Underline from "./plugins/editor/Underline";
import Bold from "./plugins/editor/Bold";
import Italic from "./plugins/editor/Italic";
import UndoRedo from "./plugins/editor/UndoRedo";
import AlignCenter from "./plugins/editor/AlignCenter";
import AlignLeft from "./plugins/editor/AlignLeft";
import AlignRight from "./plugins/editor/AlignRight";
import AlignJustify from "./plugins/editor/AlignJustify";
import Image from "./plugins/editor/Image";
import StrikeThrough from "./plugins/editor/StrikeThrough";
import Bullets from "./plugins/editor/Bullets";
import List from "./plugins/editor/List";
import Indent from "./plugins/editor/Indent";
import Outdent from "./plugins/editor/Outdent";
import Table from "./plugins/editor/Table";
import Link from "./plugins/editor/Link";
import Preview from "./plugins/editor/Preview";
import Wrap from "./plugins/editor/Wrap";
import Unwrap from "./plugins/editor/Unwrap";
import Paint from "./plugins/editor/Paint";
import ActiveNode from "./base/activeNode";
import TextModal from "./modals/TextModal";
import Initialize from "./base/initialize";
import Export from "./base/Export";
import TableModal from "./modals/TableModal";
import ClearStyle from "./plugins/editor/ClearStyle";
import Css from "./plugins/editor/Css";
import FontSize from "./plugins/editor/FontSize";
import Block from "./plugins/editor/Block";
import Template from "./plugins/editor/Template";
import Images from "./plugins/editor/Images";
import Paragraph from "./plugins/editor/Paragraph";
import InsertNode from "./base/insertNode";
//import History from "./base/History";

import "./style.scss";


class GigaEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: "",
      plugins: [],
      toolbars: [],
      extended_toolbars: [],
      UndoRedo: null,
      on: {
        active: [
          "AlignLeft",
          "AlignCenter",
          "AlignRight",
          "AlignJustify",
          "Preview",
          "Wrap",
          "Unwrap",
          "Paint",
          "Css",
          "Paragraph",
          "Images",
          "Image",
          "Template"
        ],
        empty: [
          "Image",
          "Outdent",
          "Indent",
          "BR",
          "Preview",
          "Wrap",
          "Unwrap",
          "Paint",
          "Css",
          "Table",
          "Paragraph",
          "Images",
          "Image",
          "Template"
        ],
        text: [
          "Underline",
          "Italic",
          "StrikeThrough",
          "Bold",
          "AlignLeft",
          "AlignCenter",
          "AlignRight",
          "AlignJustify",
          "Outdent",
          "Indent",
          "Image",
          "Code",
          "Table",
          "Bullets",
          "List",
          "ClearStyle",
          "FontSize",
          "BR",
          "Block",
          "Link",
          "Template",
          "Images",
          "Preview",
          "Wrap",
          "Unwrap",
          "Paint",
          "Css",
          "Paragraph"
        ],
        table: [
          "Underline",
          "Italic",
          "StrikeThrough",
          "Bold",
          "AlignLeft",
          "AlignCenter",
          "AlignRight",
          "AlignJustify",
          "Outdent",
          "Indent",
          "Image",
          "Table",
          "Code",
          "Bullets",
          "List",
          "FontSize",
          "BR",
          "Block",
          "Link",
          "Template",
          "Images",
          "Preview",
          "Wrap",
          "Unwrap",
          "Paint",
          "Css",
          "Paragraph"
        ],
        image: ["Image", "Code", "BR", "Preview", "Wrap", "Unwrap","Css"],
        template: [
          "Underline",
          "Italic",
          "StrikeThrough",
          "Bold",
          "AlignLeft",
          "AlignCenter",
          "AlignRight",
          "AlignJustify",
          "Outdent",
          "Indent",
          "Image",
          "Table",
          "Code",
          "Bullets",
          "List",
          "FontSize",
          "BR",
          "Block",
          "Link",
          "Template",
          "Images",
          "Preview",
          "Wrap",
          "Unwrap",
          "Paint",
          "Css",
          "Paragraph"
          
        ],
      },
      activeOptions: false,
      active: null,
      activeType: null,
      modal: "",
      customModal: "",
      clickedElement: "",
      range: "",
      files: [],
      paint: {
        color: "",
        backgroundColor: "",
      },
      styleCssEditor: {
        color: 'tomato',
        padding: 16
      },
      style: '',
      showCssEditor: false,
      sass: null,
    };

    this.ref = React.createRef();
    this.ref2 = React.createRef();

    this.lastActive = null;
    this.contentid = null;
    this.prevEl = null;
    this.updateTimeout = null;
    this.mouseY = null;
    this.directionY = null;

    this.History = null;

    this.action = this.action.bind(this);
    this.onSelection = this.onSelection.bind(this);
    this.clickOptions = this.clickOptions.bind(this);
    this.code = this.code.bind(this);
    this.registerChange = this.registerChange.bind(this);
    this.updateStyle = this.updateStyle.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }

  componentDidMount() {
    this.__initijalize();
    // console.log(this.props.index)
    this.props.index.setEditorInstance(this);
  }



  componentDidUpdate() {



    if (this.ref) {
      if (this.ref.current) {

        var active = this.ref.current.querySelector(".active");
        if (active) {
          active.classList.remove("active");
        }
        if (this.state.active) {
          this.state.active.classList.add("active");
        }
      }
    }

    if (this.lastActive !== this.state.activeType) {
      this.__activeToolbarButtons();
      this.lastActive = this.state.activeType;
    }

    if (
      this.contentid !== this.props.contentid &&
      this.props.contentid !== undefined
    ) {
   
      if (this.ref && this.ref.current) {
        this.contentid = this.props.contentid;
        var init = new Initialize();
        var html = init.normalizeContent(this.props.content);
        this.ref.current.innerHTML = html;

        this.registerChange();
        
        this.setState({ activeType: "empty" }, () => {
          //this.History = null;
         
            this.__activeToolbarButtons();
            this.loadContentData();
            // if(!this.History){
            //   this.History = new History();
            // }
            // this.History.Save(this,this.state);

            this.__initEditorContent();
            this._getImages();
            this.registerChange();
          
        });
      }
      
    }
  }

  async loadContentData() {
    var key = this.props.section + "-" + this.props.id;
    var result = await fetchHelper.get(`content/get`, {
      section: key,
      master_id: this.props.contentid,
    });

    var style = '#'+this.props.section+'-'+this.props.id +'-' + this.props.contentid + ` {

    }`;

    if (result.status && result.data) {
      if (result.data.data) {
        var data = JSON.parse(result.data.data);
        if(data){
          if(data.style){
            style = data.style;
          }
          this.setState({ paint: data.paint, style: style },()=>{
            this.registerChange();
          });
        }
       
      }
    } else {
      this.setState({
        paint: {
          color: "rgb(0,0,0)",
          backgroundColor: "rgb(255,255,255)",
        },
        style: style
      },()=>{
        //this.History = new History();
      });
    }
  }

  async _getImages(s) {
    this.setState({ images: null });
    let data = { id: this.props.contentid, section: this.props.section };
    var response = await fetchHelper
      .get("upload/get_images", data)
      .catch(function(error) {
        AlertyBox.serverError(error);
      });
    if (response) {
      this.setState({ files: response.data });
    }
  }

  __initijalize() {
    if (this.props.config) {
      if (this.props.config.toolbar) {
        if (Array.isArray(this.props.config.toolbar)) {
          this.__initializeToolbar();
        }
      }
    }

    if (this.props.config) {
      if (this.props.config.extend_toolbar) {
        if (Array.isArray(this.props.config.extend_toolbar)) {
          this.__initializeExtendedToolbar();
        }
      }
    }

    if (this.ref && this.ref.current) {
      var init = new Initialize();
      var html = init.normalizeContent(this.props.content);
      this.ref.current.innerHTML = html;
      this.registerChange();
      this.ref.current.onpaste = (e) => {
        e.preventDefault();
        document.execCommand('inserttext', false, prompt('Paste here.'));
      }
    }

    document.addEventListener("selectionchange", (e) => {
      var ins = new InsertNode(this);
      ins.onSelectionChange(e);
    });

    setTimeout(() => {
      this.registerChange();
      this.__activeToolbarButtons();
      // if(!this.History){
      //   this.History = new History();
      // }
      //this.History.Save(this, this.state);
      // this.__initEditorContent();
      // this._getImages();
    }, 600);

    document.execCommand('defaultParagraphSeparator', false, 'p');
  }

  __initEditorContent() {
    var initialize = new Initialize();
    initialize.initializeAll(this);
  }

  __activeToolbarButtons() {
  if(this.ref2.current){
    var selectors = this.ref2.current.querySelectorAll(".selector-ga");
    var activeType = this.state.activeType ? this.state.activeType : "empty";
    var list = this.state.on[activeType];

    if (selectors && selectors.length && list && list.length) {
      for (var i = 0; i < selectors.length; i++) {
        var x = "-btn-" + this.props.id;
        var id = selectors[i].id;

        var r = list.find((e) => e + x === id);
        if (r !== undefined) r = r ? false : true;
        if (r === undefined) r = true;
        selectors[i].disabled = r;
      }
    }
  }
  }

  __initializeToolbar() {

    var _toolbars = this.state.toolbars;
    _toolbars.push(
      <UndoRedo element={this.ref} {...this.props} editor={this} />
    );
    _toolbars.push(<span className="vertical-spliter"></span>);

    this.props.config.toolbar.map((plugin, i) => {
      if (typeof plugin === "string") {
        switch (plugin) {
          case "|":
            _toolbars.push(<span className="vertical-spliter"></span>);
            break;
          case "Underline":
            _toolbars.push(
              <Underline element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Bold":
            _toolbars.push(
              <Bold element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Italic":
            _toolbars.push(
              <Italic element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "AlignLeft":
            _toolbars.push(
              <AlignLeft element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "AlignCenter":
            _toolbars.push(
              <AlignCenter element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "AlignRight":
            _toolbars.push(
              <AlignRight element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Image":
            _toolbars.push(
              <Image element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "StrikeThrough":
            _toolbars.push(
              <StrikeThrough element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Table":
            _toolbars.push(
              <Table element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Bullets":
            _toolbars.push(
              <Bullets element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "List":
            _toolbars.push(
              <List element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Indent":
            _toolbars.push(
              <Indent element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Outdent":
            _toolbars.push(
              <Outdent element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "ClearStyle":
            _toolbars.push(
              <ClearStyle element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "FontSize":
            _toolbars.push(
              <FontSize element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "BR":
            _toolbars.push(<br />);
            break;
          case "Block":
            _toolbars.push(
              <Block element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Link":
            _toolbars.push(
              <Link element={this.ref} {...this.props} editor={this} range={this.state.range} />
            );
            break;
          case "Template":
            _toolbars.push(
              <Template element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Images":
            _toolbars.push(
              <Images element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Preview":
            _toolbars.push(
              <Preview element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Wrap":
            _toolbars.push(
              <Wrap element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Unwrap":
            _toolbars.push(
              <Unwrap element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Paint":
            _toolbars.push(
              <Paint element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Css":
            _toolbars.push(
              <Css element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Paragraph":
            _toolbars.push(
              <Paragraph element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "AlignJustify":
              _toolbars.push(
                <AlignJustify element={this.ref} {...this.props} editor={this} />
              );
              break;
        }
      }

      if (typeof plugin === "object") {
      }

      this.setState({ toolbars: _toolbars });
    });
  }

  __initializeExtendedToolbar() {
    var _toolbars = this.state.extended_toolbars;

    this.props.config.extend_toolbar.map((plugin, i) => {
      if (typeof plugin === "string") {
        switch (plugin) {
          case "|":
            _toolbars.push(<span className="vertical-spliter"></span>);
            break;
          case "Underline":
            _toolbars.push(
              <Underline element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Bold":
            _toolbars.push(
              <Bold element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Italic":
            _toolbars.push(
              <Italic element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "AlignLeft":
            _toolbars.push(
              <AlignLeft element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "AlignCenter":
            _toolbars.push(
              <AlignCenter element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "AlignRight":
            _toolbars.push(
              <AlignRight element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Image":
            _toolbars.push(
              <Image element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "StrikeThrough":
            _toolbars.push(
              <StrikeThrough element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Table":
            _toolbars.push(
              <Table element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Bullets":
            _toolbars.push(
              <Bullets element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "List":
            _toolbars.push(
              <List element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Indent":
            _toolbars.push(
              <Indent element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Outdent":
            _toolbars.push(
              <Outdent element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "ClearStyle":
            _toolbars.push(
              <ClearStyle element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "FontSize":
            _toolbars.push(
              <FontSize element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "BR":
            _toolbars.push(<br />);
            break;
          case "Block":
            _toolbars.push(
              <Block element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Link":
            _toolbars.push(
              <Link element={this.ref} {...this.props} editor={this} range={this.state.range} />
            );
            break;
          case "Template":
            _toolbars.push(
              <Template element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Images":
            _toolbars.push(
              <Images element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Preview":
            _toolbars.push(
              <Preview element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Wrap":
            _toolbars.push(
              <Wrap element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Unwrap":
            _toolbars.push(
              <Unwrap element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Paint":
            _toolbars.push(
              <Paint element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Css":
            _toolbars.push(
              <Css element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "Paragraph":
            _toolbars.push(
              <Paragraph element={this.ref} {...this.props} editor={this} />
            );
            break;
          case "AlignJustify":
            _toolbars.push(
              <AlignJustify element={this.ref} {...this.props} editor={this} />
            );
            break;
        }
      }

      if (typeof plugin === "object") {
      }

      this.setState({ extended_toolbars: _toolbars });
    });
  }

  // async templateClick(e) {
  //   var t = e.currentTarget;
  //   var m = t.dataset.module;
  //   var f = t.dataset.onload;
  //   var s = t.dataset.stop !== undefined ? t.dataset.stop : false;

  //   const Modal = await import(`./plugins/templates/${f}/${m}.js`);
  //   const w = (
  //     <Modal.default
  //       editor={this}
  //       element={this.ref}
  //       insert={false}
  //       template={t}
  //     />
  //   );

  //   var ed = e.target.closest('[contenteditable="true"]');
  //   if(ed && ed.className.indexOf('gigaEditorContent') > -1){
  //       ed = null;
  //   }
  //   if(ed && s){
  //       return true;
  //   }

  //   if (s) {
  //     this.setState({ active: t, customModal: w, activeType: "template" });
  //   } else {
  //     this.setState({ modal: w, customModal: "" });
  //   }
  // }



  action(e) {

    if(this.state.showCssEditor) return true;
    // if (e.keyCode === 13) {
    //   var a = this.ref.current.querySelectorAll(".active");
    //   if (a && a.length) {
    //     for (var i = 0; i < a.length; i++) {
    //       var c = a[i];
    //       c.classList.remove("active");
    //     }
    //   }

    //   var tagName = "p";
    //   var f = window.getSelection().focusNode;
    //   if (f) {
    //     tagName = f.tagName;
    //   }

    //   if (tagName === "LI") {
    //   } else {
    //     if (this.prevEl === "LI" && tagName === "SPAN") {
    //       var tp = $('<p class="temp-paragraph">Continue here...</p>');
    //       $(tp).insertAfter(this.state.active);
    //       tp.focus();
    //       document.execCommand("delete", null, false);
    //     } else {
    //       document.execCommand("formatBlock", false, "p");
    //     }
    //   }
    //   this.prevEl = tagName;
    // }

    // if (!e.currentTarget.innerHTML) {
    //   document.execCommand("formatBlock", false, "p");
    //   var selectedElement = window.getSelection().focusNode.parentNode;
    //   selectedElement.classList.add("active");
    // }



    if(e.keyCode === 46){
      // delete key
      var ins = new InsertNode(this);
      ins.delete();
      document.execCommand('delete',true,'');

    }

    if(e.currentTarget.innerHTML === '<br>' || e.currentTarget.innerHTML === ''){
      e.currentTarget.innerHTML = '<p class="active"></p>'
    }

    this.registerChange();
  }

  registerChange(e) {
    if (this.props.onChange && ! this.state.showCssEditor ) {

        var content = this.getContent();
        this.props.onChange(content,()=>{
          
        });

        this.props.input.value = content;
      }
  }

  getContent(){

    if(!this.props.id){
      return {html:'',data:{}};
    }

      var e = new Export(this);
      var d = e.exportContent();

      if(!d){
        return {html:'',data:{}};
      }

      d.id = this.props.section+'-'+this.props.id +'-' + this.props.contentid;
      d.style = this.state.style;

      return  { html: this.ref.current.innerHTML, data: d };
  }

  getRange() {
    if (window.getSelection) {
      var sel, range;
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
      }
      return range;
    }
  }

  async onSelection(e) {

    var ac = new ActiveNode();
    ac.getFocusElement(this,e);

   var range = this.getRange();
   this.setState({range:range});

   if(e.currentTarget.innerHTML === '<br>' || e.currentTarget.innerHTML === ''){
    e.currentTarget.innerHTML = '<p class="active"></p>'
  }


    // var el = e.currentTarget;
    // var t = el.closest('.block-element-editor');

    // if(!t){
    //   if(el.className.indexOf('block-element-editor') > -1){
    //     t = el;
    //   }
    // }
    // console.log(t,el)
    // if(t){

    //   var CustomModal = await require(`./modals/${t.dataset.onload}`);

    //   this.setState({clickedElement:t,customModal:<CustomModal editor={this} element={this.ref} insert={false}  />, type:t.dataset.type});
    // } else {
    //   this.setState({clickedElement:el, customModal: <TextModal editor={this} element={el} />, type:'text'});
    // }

  }

  clickOptions(event) {
    event.stopPropagation();
    this.setState({
      activeOptions: this.state.activeOptions ? false : true,
      modal: "",
    });
    event.preventDefault();
    return false;
  }

  async code() {
    if (this.state.active) {
      var modal = "";
      switch (this.state.activeType) {
        case "text":
          modal = <TextModal editor={this} element={this.state.active} />;
          break;
        case "table":
          modal = (
            <TableModal
              editor={this}
              element={this.ref}
              insert={false}
              table={this.state.active}
            />
          );
          break;
        default:

          if (this.state.customModal) {
            modal = this.state.customModal;
          }
          break;
      }

      this.setState({ modal: modal });
    } else {
      this.setState({ modal: "" });
    }
  }


  updateStyle(e){
    var value = e.currentTarget.value;
    this.setState({style:value},()=>{
      
    });
  }

  tabArea(event){
    var el = event.target;
    var v = el.value;
    if(event.keyCode===9){
      event.preventDefault();
      event.stopPropagation();
      var s=el.selectionStart,
      e=el.selectionEnd;
      el.value = v.substring(0, s)+'\t'+v.substring(e);
      el.selectionStart = el.selectionEnd =s+1; 
      return true;
    };
  }


  onMouseMove(e){

    if(e.currentTarget.innerHTML === '<br>' || e.currentTarget.innerHTML === ''){
      e.currentTarget.innerHTML = '<p class="active"></p>'
    }

    var target = e.target;
    var t = null;
    if(target.className.indexOf('block-element-editor') > -1){
      t = target;
    } else {
      var c = target.closest('.block-element-editor');
      if(c){
        t = c;
      }
    }

    if(t){
      var temp = this.ref.current.querySelector('.temp-element');
      if(temp) temp.remove();
      this.mouseY = t;
      this.directionY = e.movementY || e.mozMovementY || e.webkitMovementY || 0;
      t.classList.add('editor-highlighted');
      $(t).off('mouseout');
      $(t).on('mouseout',(event)=>{
         event.currentTarget.classList.remove('editor-highlighted');
      })
    }

    if(!t && this.mouseY && this.directionY){
      // var a = this.ref.current.querySelector('.active');
      // if(a){
      //   a.classList.remove('active');
      // }

      if(this.directionY === 1){
        var p = document.createElement('p');
          p.classList.add('active');
          p.classList.add('temp-element');
          if(this.mouseY.parentNode){
            this.mouseY.parentNode.insertBefore(p, this.mouseY.nextSibling);
            $(p).on('mouseleave',(e)=>{
              e.currentTarget.remove();
            });
            $(p).on('click',(e)=>{
              $(e.currentTarget).off('mouseleave');
              $(e.currentTarget).removeClass('temp-element');
              this.registerChange();
            })
          }
      }
      if(this.directionY === -1){
          var p = document.createElement('p');
          p.classList.add('active');
          p.classList.add('temp-element');
          if(this.mouseY.parentNode){
            this.mouseY.parentNode.insertBefore(p, this.mouseY);
            $(p).on('mouseleave',(e)=>{
              e.currentTarget.remove();
            });
            $(p).on('click',(e)=>{
              $(e.currentTarget).off('mouseleave');
              $(e.currentTarget).removeClass('temp-element');
              this.registerChange();
            })
          }
      }

      this.directionY = null;
      this.mouseY = null;
    }


  }

  checkFocus(e){

    if(this.props.content.data){
      let id=$(e.currentTarget).closest('.gigaEditor').children('.gigaEditorContent').attr("id");
      if (!$(`#${id}`).is(":focus")) {
        this.setEndOfContenteditable(id)
      }
    }
  }


  setEndOfContenteditable(id){
    let contentEditableElement = document.getElementById(id);
    if(contentEditableElement){ 
      var range,selection;
      if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
      {
          range = document.createRange();//Create a range (a range is a like the selection but invisible)
          range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
          range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
          selection = window.getSelection();//get the selection object (allows you to change selection)
          selection.removeAllRanges();//remove any selections already made
          selection.addRange(range);//make the range you have just created the visible selection
      }
      // else if(document.selection)//IE 8 and lower
      // { 
      //     range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
      //     range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
      //     range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
      //     range.select();//Select the range (make it the visible selection
      // }
    }
  }
  onBlurEditor(event){
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({
        activeOptions:false,
        //todo
        // modal: ""
      })
    }
  }
  render() {

    var style = this.state.style;
    return (
      <>
      <style>
        {style}
      </style>
        <div className="gigaEditor" onBlur={(e)=>{this.onBlurEditor(e)}}>
          <div className="gigaEditorToolbar" ref={this.ref2} onClick={(e)=>this.checkFocus(e)}>
            {this.state.toolbars.map((t, i) => {
              return <span key={i}>{t}</span>;
            })}
            <div className="gigaEditorToolbarOptions">
              <span
                onMouseDown={this.clickOptions}
                className={this.state.activeOptions ? " opened" : "closed"}
              >
                <i className="fa fa-ellipsis-v" />
              </span>
              <div
                className={
                  "geto-droplist " +
                  (this.state.activeOptions ? " opened" : "closed")
                }
              >
                <span>
                  <button
                    className="get-btn selector-ga"
                    id={"Code-btn-" + this.props.id}
                    onClick={this.code}
                  >
                    <i className="fa fa-code" aria-hidden="true"></i>
                  </button>
                </span>
                {this.state.extended_toolbars &&
                this.state.extended_toolbars.length
                  ? this.state.extended_toolbars.map((t, i) => {
                      return t.type === "br" ? (
                        <br key={i} />
                      ) : (
                        <span key={i}>{t}</span>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
          <div
            id={this.props.section+'-'+this.props.id +'-' + this.props.contentid}
            ref={this.ref}
            className="gigaEditorContent"
            contentEditable
            spellCheck={false}
            onKeyUp={this.action}
            onClick={this.onSelection}
            onMouseMove={this.onMouseMove}
            style={{
              color: this.state.paint ? this.state.paint.color : "",
              backgroundColor:this.state.paint ? this.state.paint.backgroundColor : "",
              minHeight: this.props.height
            }}
          ></div>
          <Modal>{this.state.modal}</Modal>
          {this.state.showCssEditor?(<div className="css-editor" >
              <textarea value={style} onChange={this.updateStyle} onKeyDown={this.tabArea} />
          </div>):''}
        </div>
      </>
    );
  }
}

export default GigaEditor;
