import React, { useState } from 'react';
import $, { css } from "jquery";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Item from "./Item.js"; 
import { fetchApiHelper } from "../../../../../../helpers/fetchApiHelper";
import ResizeRangeField from '../../../fields/ResizeRangeField'; 
import AlertyBox from "../../../../../AletyBox";
import { fetchHelper } from "../../../../../../helpers/fetchHelper"; 
import { actionHelpers } from "./actionHelpers";
import { items_list_style } from "./ItemListCSS";
import EditorHTML from "./EditorHTML"; 

//TODO:fake data da ne vuce iz linka
//fake data dugme pored searcha da bi ima dalo prikaz sa svim podacima
//refaktorisati;
 
export default class ItemListPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // resize 

            
            layouts:null, 
            layout:{
                // default empty
                // we set layout in this order:  1.old layout if editing 2.if not then first from layouts list if such exists 3.else empty
                id:"",

                product_plugin_background_color:"white",
                items_wrapper_background_color:"white",
                items_wrapper_max_width:"1440",  

                horizontal_space_beatween_items:{
                    "7680":"0", 
                    "1440":"",
                    "1320":"",
                    "1200":"",
                    "1080":"",
                    "960" :"",
                    "840" :"",
                    "720" :"",
                    "600" :"",
                    "480" :"",
                    "360" :"", 
                },
                vertical_space_beatween_items:{
                    "7680":"0", 
                    "1440":"",
                    "1320":"",
                    "1200":"",
                    "1080":"",
                    "960" :"",
                    "840" :"",
                    "720" :"",
                    "600" :"",
                    "480" :"",
                    "360" :"", 
                },
                horizontal_space_around_items:{
                    "7680":"0", 
                    "1440":"",
                    "1320":"",
                    "1200":"",
                    "1080":"",
                    "960" :"",
                    "840" :"",
                    "720" :"",
                    "600" :"",
                    "480" :"",
                    "360" :"", 
                },
                vertical_space_around_items:{
                    "7680":"0", 
                    "1440":"",
                    "1320":"",
                    "1200":"",
                    "1080":"",
                    "960" :"",
                    "840" :"",
                    "720" :"",
                    "600" :"",
                    "480" :"",
                    "360" :"", 
                },

                breaking_points_columns:{
                    "7680":"4",
                    "1440":"",
                    "1320":"",
                    "1200":"",
                    "1080":"",
                    "960" :"",
                    "840" :"",
                    "720" :"",
                    "600" :"",
                    "480" :"",
                    "360" :"", 
                },
                
                item_html:{
                    "7680":{
                        element_name:"main_item_wrapper",
                        element_title:"Product wrapper",
                        wrapper_direction:"v",
                        nowrap:0,
                        removable:0,
                        can_change_position:0,
                        add_data:1,
                        is_link:false,
                        is_linkable:true,
                        css:{
                            opacaty: "1" ,
                            background: "white",
                            border: "1px solid #fff", 
                            padding:"47px 10px 10px 10px", 
                            boxShadow:"0 0 10px #cecece", 
                        },
                        content:[]
                    },
                    "1440":{},
                    "1320":{},
                    "1200":{},
                    "1080":{},
                    "960" :{},
                    "840" :{},
                    "720" :{},
                    "600" :{},
                    "480" :{},
                    "360" :{},
            
                },
                
 
                


            },
            chosen_screen_resolution:"auto",
          
            fake_data: {},
            // draggable_active_element:"",



            //"preview_layout_with_ids"
            show_overlay:true,
            active_breaking_point:"7680",  
            draggable_active_id:"", 
            change_sidenav_position:"",
 
 
        }

        this.emptyFallbackToWiderMediaQueryData=this.emptyFallbackToWiderMediaQueryData.bind(this);
 
    }
 
    //we set layout(this.state.layout) in this order: 1.old layout if editing 2.if not then first from layouts list if such exists 3.empty(which is default in state on mount)
    componentDidMount() {
        let data=this.props.data; 
        console.log("DATA",data)
        //1.set old layout if editnig
        let old_layout="";
        if(this.props.data.layout&&Object.getOwnPropertyNames(this.props.data.layout).length > 0){
            old_layout=JSON.parse(this.props.data.layout);  
            this.setState({ layout:old_layout ,old_layout_for_changing:old_layout})//
        }
        this.get_layouts("on_mount",old_layout);//fill layouts for select
        this.fake_data(); 
        
    }   

    async get_layouts(event,old_layout) {
        let r = await fetchHelper.get(`products/get_product_plugin_layout_list`);
        if(!r.status){
            // server error
            AlertyBox.error({message:r.message});
        }
        else{
            // [] or [...]
            this.setState({layouts:r.data});
            //2.set first from layout list
            if(!old_layout&&r.data.length){
                if(event=="on_mount"){ 
                    this.setState({layout:r.data[0]}) 
                }
            }
 
        }
    }
 

    async fake_data() {
        //staviti posle full fejk podatke
        let r = await fetchApiHelper.postApi(`core/product/ids2`, { ids: 256514 })
        if (r && r.status) {
            let x = r.data.items[0];
            this.setState({ fake_data: x })
        }

    }

 
    ///EDITOR FUNCTIONS 
    //if object is empty  
    emptyFallbackToWiderMediaQueryData(data){  
        //get last object data for bigger media query if current media query is empty;
        let x={};
        let brc_point=this.state.active_breaking_point;
        let brc_point_list=Object.keys(data);//
        for(let i=brc_point_list.length-1; i>=0; i--){ 
            let query=brc_point_list[i];
            if(Object.keys(data[query]).length){
                x=data[query];
            }
            if(brc_point_list[i]==brc_point){
                break;
            }
        } 
        return x;
    }
    //if value is empty
    emptyFallbackToWiderMediaQueryValue(name){
        //get last string data
        let value="";
        let option=this.state.layout[name];
        let active_bp=this.state.active_breaking_point;
        if(option[active_bp]){
            value=option[active_bp];
        }
        else{
            let found=false;
            let brc_point_list=Object.keys(option);
            for(let i=0; i<brc_point_list.length; i++){  
                let bp=brc_point_list[i]
                if(bp==active_bp){
                    found=true; 
                }
                else if(found&&option[bp]){
                    value=option[bp];
                    break;
                }

            }
        }
        return value;
    } 
    
    itemTreeAddDeleteChange(data,action){
        let new_layout=JSON.parse(JSON.stringify(this.state.layout.item_html)); 
        let new_html=this.emptyFallbackToWiderMediaQueryData(new_layout); 

        if(action=="add"){
            new_html.content=actionHelpers.add(new_html.content,data);
        }
        if(action=="delete"){ 
            new_html.content=actionHelpers.delete(new_html.content,data); 
        }
        if(action=="change_position"){ 
            new_html.content=actionHelpers.change_position(new_html.content,data);
        }
        if(action=="create_link"||action=="change_wrapper_direction"||action=="block_wrapping"){ 
            new_html=actionHelpers.change_element_options(new_html,data);
        }
        if(action=="change_css"){
            new_html=actionHelpers.change_css(new_html,data);
        }
        
        let brp=this.state.active_breaking_point;  
        this.setState(prevState => ({
            layout: {
                ...prevState.layout,
                item_html: {
                    ...prevState.layout.item_html,
                    [brp]: new_html
                }
            }
        }))
    } 
    onChange(e,type){
        console.log(e,type)
        let name="";
        let value="";
        if(type=="color"){
            name=Object.keys(e)[0];
            value=Object.values(e)[0];
        }
        else{
            let ct=e.currentTarget;
            name=ct.getAttribute("name_helper");
            console.log(name)
            value=ct.value;
        }
        this.setState({ layout: { ...this.state.layout,[name]:value}});
    }
 
    onChangeTableData(e,width){
        let ct=e.currentTarget; 
        let name=ct.getAttribute("name_helper"); 
        var val =ct.value;
        console.log(name,width,val)
        this.setState({layout: 
            {...this.state.layout, 
                [name]: {...this.state.layout[name],
                    [width]:val} 
                } 
            })  
    } 
     
    emptyHtml(width){
        let reset_data={}
        if(width=="7680"){
            return;
        }
        this.setState(prevState => ({
            layout: {
                ...prevState.layout,
                item_html: {
                    ...prevState.layout.item_html,
                    [width]: reset_data
                }
            }
        }))
    }
  

    show_focus_borders(){
        //todo da se ne prikazuje sem u editu
        return this.state.show_overlay ? 
            <span className={`final_step_overlay`}>
            <span/><span/><span/><span/>
        </span> : ""
    
    }

    content() {
        let s = this.state;
        let l = s.layout;
        let type = this.props.type;
        if(this.state.layouts==null){
            return "...loading"
        }
        else if (type == "choose_layout") {
            if(!this.state.layouts.length){
                return <p className="empty_msg">You haven't saved any layouts yet!</p>
            }
            else{
                return <div className="choose_layout_wrapper">
                <p className="clw_warrning">If you choose another layout you will reset all of your current settings.</p>
                {(s.layouts).map((layout,i) => { 
                        let id=layout.id;
                        return <div className="previewContainer" key={"prev" + i} >
                            <div className="btns_layout_list add_layout_btn" onClick={() => { this.setState({layout:layout})}}>
                                <i className={`${this.state.layout==layout ? " fa fa-check" : ""}`} aria-hidden="true" onClick={() => { this.setState({layout:layout})}}></i>
                                {/* this.state.layout==layout (not ids) because edited layout in type == "preview_layout_with_ids" is not the same as unedited layout in type == "choose_layout" with the same id */}
                            </div>
                            <div className="btns_layout_list remove_layout_btn" onClick={()=>{ 
                                this.remove_from_layout_list(id) }}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div> 
                                <div>
                                    <Item id={id} item_html={this.emptyFallbackToWiderMediaQueryData(layout.item_html)} data={s.fake_data}   testfix="1"/>

                                </div>
                        </div>
                    
                    })}

            </div>
            }
        }
        else if (type == "preview_layout_with_ids") {
            
            
            let horizontal_space_beatween_items=this.emptyFallbackToWiderMediaQueryValue("horizontal_space_beatween_items");
            let vertical_space_beatween_items=this.emptyFallbackToWiderMediaQueryValue("vertical_space_beatween_items");
            let horizontal_space_around_items=this.emptyFallbackToWiderMediaQueryValue("horizontal_space_around_items");
            let vertical_space_around_items=this.emptyFallbackToWiderMediaQueryValue("vertical_space_around_items");
            let n=100/this.emptyFallbackToWiderMediaQueryValue("breaking_points_columns");
            let item_width=`calc(${n.toFixed(3).replace(/\.000$/, '')}% + 1px)`;
            let real_data=this.props.active_items;
            
            let col_padding_left_right=Math.round(vertical_space_beatween_items/2)+"px";
            let col_padding_top=Math.round(horizontal_space_beatween_items)+"px";
            
            let items_wrapper_max_width= l.items_wrapper_max_width ?  l.items_wrapper_max_width+"px" : "100%";

            
            let item_html=this.emptyFallbackToWiderMediaQueryData(l.item_html);
            
            return <div className="preview_wrapper_main" > 
                {real_data&&real_data.length ?
                    <>
                        <div className="resolution_wrapper_main">
                        <div className="resolution_wrapper" style={{width:this.state.chosen_screen_resolution}}>

                        
                        
                        <div className={`product_plugin_wrapper_that_gets_inserted ${s.show_overlay&&"show_my_borders"}`} 
                            style={{
                            background:l.product_plugin_background_color,
                            overflow:"hidden",
                            }}>
                          

                            <div className={`items_wrapper clear ${s.show_overlay&&"show_my_borders"}`}  style={{ 
                                maxWidth: items_wrapper_max_width ,
                                background:l.items_wrapper_background_color,
                                marginTop:`-${col_padding_top}`,
                                padding:horizontal_space_around_items+"px "+ vertical_space_around_items+"px"

                                }}>
                                <div className="col_fix_wrapper" style={{
                                    margin:`0px -${col_padding_left_right}`
                                }}>
                                {real_data.map((data,i)=>{
                                        return  <div key={"item-"+i} 
                                        style={{
                                        width: item_width,
                                        padding:`${col_padding_top}  ${col_padding_left_right} 0px  ${col_padding_left_right}`
                                        }}
                                        className={`align_col col`} 
                                        >
                                            {this.show_focus_borders()}
                                            <div 
                                            onDragStart={(event)=>{ 
                                                event.target.classList.add("dragged_id");
                                                this.setState({draggable_active_id:data.id});
                                            }} 
                                            onDragEnd={(event)=>{ 
                                                event.target.classList.remove("dragged_id");
                                                this.setState({draggable_active_id:""});
                                            }}
                                            onDragOver={(event)=>{ event.preventDefault()}}
                                            
                                            onDragEnter={(event)=>{ 
                                                if ( event.target.classList.contains("dropzone")&&this.state.draggable_active_id!=data.id) {
                                                    event.target.classList.add("id_under_dragged_id")
                                                }
                                            }}
                                            onDragLeave={(event)=>{ 
                                                if ( event.target.classList.contains("dropzone")) {
                                                    event.target.classList.remove("id_under_dragged_id")
                                                }
                                            }}
                                            onDrop={(event)=>{ 
                                                if ( event.target.classList.contains("dropzone") ) {
                                                    event.target.classList.remove("id_under_dragged_id")
                                                    this.props.onDragAndDropSortElements(this.state.draggable_active_id,data.id);
                                                }
                                            }}
                                            draggable
                                            className={`dropzone`}
                                            >
                                            {this.state.show_overlay ?  
                                            <div className="remove_item_from_list" onClick={()=>{ this.props.onItemClick(data.id,"remove")} }>
                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            </div> : "" } 

                                                <Item id={l.id} item_html={item_html}   data={data}  testfix="2"/>
                                            </div>
                                        </div>
                                        
                                        
                                    }) 
                                }
                                </div>
                            </div>
                          
                        </div>
 
                      
                        <i className={this.state.show_overlay ? "fa fa-eye" : "fa fa-eye-slash"}  aria-hidden="true" onClick={()=>{this.setState({show_overlay:!this.state.show_overlay})}}/>
                        <i className={ `fa fa-angle-double-${this.state.change_sidenav_position ? "left" : "right"}`} onClick={()=>{this.setState({ change_sidenav_position:this.state.change_sidenav_position ? "" : "change_sidenav_position" })}}></i>
                        {
                            this.state.show_overlay ? 
                            <>
                                <EditorHTML 
                                    change_sidenav_position={this.state.change_sidenav_position} 
                                    layout={this.state.layout} 
                                    active_breaking_point={this.state.active_breaking_point}

                                    emptyHtml={(width)=>{this.emptyHtml(width)}}
                                    onChange={(e,type)=>{this.onChange(e,type)}} 
                                    onChangeTableData={(e,width_number)=>{this.onChangeTableData(e,width_number)}}
                                    emptyFallbackToWiderMediaQueryData={this.emptyFallbackToWiderMediaQueryData}
                                    itemTreeAddDeleteChange={(data,action)=>{this.itemTreeAddDeleteChange(data,action)}}

                                />
                        
                                
                            </> : ""
                        }
                        <ResizeRangeField
                            onChange={(holder_width)=>{ 
                            this.setState({ chosen_screen_resolution:holder_width });
                            let active_breaking_point=Object.keys(this.state.layout.breaking_points_columns).find((e) => {return e >=holder_width });
                            this.setState({ active_breaking_point:active_breaking_point });
                        }} /> 
                        
                        {this.save_layout_list()}
                        </div>
                        </div>
                    </>
                    : <p className="empty_msg">Your item list is empty.Add items in search bar to preview layout</p>
                }  
            </div>
 
        }

        else {
            return ""
        }

    }



   


 
    save_layout_list(){
        return <div className="save-items-btn" onClick={()=>{
            let data={...this.state.layout};
            delete data.id;
            data=JSON.stringify(data);
            AlertyBox.confirm(`Are you sure you want to add this layout ?`,"hide_title")
            .then(async (result)=>{
                if(result.isConfirmed){ 
                    AlertyBox.loading();
                    var response =  await fetchHelper.fetchPost('products/add_product_plugin_to_layout_list',{data}, r => {  return r })
                    if(response){ 
                        AlertyBox.loading();
                        this.get_layouts("on_save");
                        AlertyBox.message(response);
                    }
                }
            })
        }}>Save this design to layouts list</div>

    }
    
    remove_from_layout_list(id){
        console.log(id)
        AlertyBox.confirm(`Are you sure you want to remove this layout ?`,"hide_title")
        .then(async (result)=>{
            if(result.isConfirmed){ 
                AlertyBox.loading();
                var response = await fetchHelper.fetchDelete(`products/remove_product_plugin_from_layout_list/${id}`);
                if(response){
                    AlertyBox.message(response);
                    let layouts=[...this.state.layouts];
                    layouts=layouts.filter(( obj )=> {
                        return obj.id !== id;
                    });
                    this.setState({layouts:layouts}) 

                }
            }
        }) 

    }

    scrollPointer(destination){
        return   <div className={`scrollMeOnDrag going${destination}`}> 
             <i className={`fa fa-angle-left`}></i>
         </div>
    }

    render() { 
       
        let show_scroll_arrows=$('.items_wrapper').height()>$(window).height() && this.state.draggable_active_id ;

        return  <>
            <div className="item_types_wrapper">
                {show_scroll_arrows&&this.scrollPointer("Up")}
                {show_scroll_arrows&&this.scrollPointer("Down")}
                <input type="hidden" name="layout" value={JSON.stringify(this.state.layout)} /> 
                {this.content()} 
                {ReactHtmlParser(items_list_style())}  
            </div>
          
        </>
    }


}
