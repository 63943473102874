
export const items_list_style=(breaking_points_columns)=>{

    return ` <style> 

    .product_plugin_wrapper_that_gets_inserted *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .product_plugin_wrapper_that_gets_inserted .row {
        display: block;
        position: relative;
        clear: both;
    }
    .product_plugin_wrapper_that_gets_inserted .row:after {
        content: "";
        display: block;
        clear: both;
    }

    .product_plugin_wrapper_that_gets_inserted .col {
        position: relative;
        float: left;
        min-height: 1px;
    }

    .product_plugin_wrapper_that_gets_inserted .col:after {
        content: "";
        display: block;
        clear: both;
    }

    .product_plugin_wrapper_that_gets_inserted .clear:after {
        content: "";
        display: block;
        clear: both;
    }

    .product_plugin_wrapper_that_gets_inserted .items_wrapper{
        margin: 0px auto;
    }
    
    .product_plugin_wrapper_that_gets_inserted .align_col {
        margin: -1px 0 0 -1px;
    } 

    </style> ` ;  
     
 
}


 
