import React from 'react';

import './style.css';

class View extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            
      };

    }


    

    componentWillMount(){
       
       
        
       
    }


    componentDidMount(){
       
    }


   

    render(){

       return (<div className="preview-page">
           Page
       </div>);
    }
}

export default View;