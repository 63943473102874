import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchHelper } from "./../../../helpers/fetchHelper";
import GigaTable from './../../../libraries/GigaTable';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter} from "react-router";
import { date_sql_to_rs, date_rs_to_sql } from "../../../helpers/dateHelper";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      table: null,
      active_user:null
    }

    this.table = null;
    this.getTableInstance = this.getTableInstance.bind(this);
    this.onSave = this.onSave.bind(this);
    this.runRow = this.runRow.bind(this);
    this.resetActive=this.resetActive.bind(this);

  }

  componentDidMount(){
    
  }

  getTableInstance(table){
    this.table = table;
  }

  onSave(data){
    if(this.table && data.id){
      this.table.loadData(false,()=>{
        var row = this.table.state.rows.find(o => parseInt(o.id) === parseInt(data.id));
        if(row){
          this.setState({active_user:row});
        }
      });
    }
  }

  runRow(row){
    if(row)
    this.setState({active_user:row,selected:row.id})
  }


  resetActive() {
    //ne bacati na runRow 
    this.setState({active_user:null,selected:null})
  }
  render() {
    const cells = [
      {name:'ID',cell: 'id',width:90, fixed:true, className:'giga-td-first-fixed',sort:true},
      {name:'Naziv',cell: 'name',width:200, fixed:true, className:'wraptext'},
      {name:'Cena',cell: 'price_list', width:100,fixed:false,truncate:true,editable:true,className:'draggable', sort:true},
      // {name:'Kategorija',cell: 'category_id', width:130,fixed:true, className:'giga-td-last-fixed'},
      {name:'Brand',cell: 'brand_id', width:100,fixed:false, truncate:true,className:'draggable', sort: true},
      {name:'Opis',cell: 'description', width:300,fixed:false, truncate:true,editable:true,className:'draggable'},
      {name:'Prodato',cell: 'statistic_sold', width:100,fixed:false, truncate:true,editable:false,className:'draggable'},
      {name:'Posete',cell: 'statistic_visits', width:100,fixed:false, truncate:true,editable:false,className:'draggable'},
      {name:'Rejting',cell: 'statistic_rating', width:100,fixed:false, truncate:true,editable:false,className:'draggable'},
      {name:'Datum kreiranja',cell: 'date_created', width:180,fixed:false,editable:true,className:'draggable', onBefore:(value)=>{
        return date_sql_to_rs(value);
      }, onSave:(value)=>{
        return date_rs_to_sql(value);
      }},
      {name:'Datum izmene',cell: 'date_updated', width:180,fixed:false,editable:true,className:'draggable', onBefore:(value)=>{
        return date_sql_to_rs(value);
      }, onSave:(value)=>{
        return date_rs_to_sql(value);
      }},
      {name:'Arhiva',cell: 'archive', width:100,fixed:false, truncate:true,editable:false,className:'draggable'},
      {name:'Komentari',cell: 'statistic_comments', width:100,fixed:false, truncate:true,editable:false,className:'draggable'},
      {name:'Detalji', cell:'custom1', custom:true, width:55, align:'center', render:(row)=>{
        return row.id !== 1499709 ? <button onClick={()=>{ this.setState({active_user:row,selected:row.id}) }}><Link to={`/admin/products/${row.id}/edit`}><i className="fa fa-folder-open-o" /></Link></button> : ''
      }},
      {name:'Status', cell:'custom2', custom:true, width:55, align:'center', render:(row)=>{
        var value = parseInt(row.status); 
        return row.id !== 1499709 ? <button onClick={()=> this.table.updateRowCell(row,'status',value?0:1)}>{value === 1?(<i className="fa fa-check" />):(<i className="fa fa-times" />)}</button> : ''
      }},
    ];

    const buttons = [
      {title: 'Novi proizvod', type: 'link', link:'/admin/products/add'},
    ];
    const fetch = `products/${this.props.match.params.catMasterId}/${this.props.match.params.id}`
    
    return (
      <>
        <div className="admin-home__main-section">
        <div className="table-wrapper">
          <GigaTable 
            cells={cells} 
            pk='id' 
            height={300} 
            buttons={buttons} 
            id='products-table' 
            db="gigatron_product_data"
            fetch={fetch}
            delete={true} 
            selected={this.state.selected}
            getInstance={this.getTableInstance}
            run={this.runRow}
            resetActive={this.resetActive}
          />
        </div>
      </div>
      </>
    );
  }
}



  export default withRouter((Product));