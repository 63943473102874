import React from 'react';
{/*  
block_setting_from_input={true} ///when we remove input name(because we don't wanna set data directly from this form field to View),in parent component we can use name_helper,and set the data where we want..
*/}
export default class SelectFields extends React.Component {

    constructor(props){
        super(props);
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }


    onChange(e){
        if(this.props.onChange){
            console.log(e.target)
            this.props.onChange(e);
        }
    } 

    render(){
        let disabled=this.props.disabled ? disabled : "";
        console.log('-----',this.state.value)
        return <div className={`form-group ${this.props.className ? this.props.className : ""}`}>
            <label htmlFor="animation-style">{this.props.label}</label>
            <select 
                value={this.props.value}
                name={this.props.block_setting_from_input ? "" : this.props.name}  
                name_helper={this.props.name}
                id="animation-style"
                onChange={this.onChange} {...disabled}>
                {this.props.options && this.props.options.map((item,i) => {
                    return <option key={i} value={item.value}>{item.name}</option>
                })}
            </select>
        </div>
    }
}